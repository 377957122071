import React from 'react'

/* mui */
import Grid from '@material-ui/core/Grid'
/* mui */

const wrapper = props => {
  return (
    <Grid container spacing={3}>
      {props.children}
    </Grid>
  )
}

export default wrapper
