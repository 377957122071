import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_BANNERS: 'FETCH_BANNERS',
  FETCH_BANNERSCONTENTS: 'FETCH_BANNERSCONTENTS',
  RESET: 'RESET',
}

const fetchReset = async (state) => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}

const fetchPages = async (state, banners) => {
  state = {
    ...state,
    isStatus: banners.status,
    expireToken: sanitizeExpire({
      status: banners.status,
      data: banners.data,
      name: 'masterbanners',
    }),
    data: sanitizeData({
      status: banners.status,
      data: banners.data,
      name: 'masterbanners',
    }),
  }
  //state.data.mastertranslation = state.data.mastertranslation[0].json;
  return state
}

const fetchPagesContents = async (state, banners) => {
  state = {
    ...state,
    isStatus: banners.status,
    expireToken: sanitizeExpire({
      status: banners.status,
      data: banners.data,
      name: 'masterbannerscontents',
    }),
    data: sanitizeData({
      status: banners.status,
      data: banners.data,
      name: 'masterbannerscontents',
    }),
  }
  //state.data.mastertranslation = state.data.mastertranslation[0].json;
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BANNERS:
      return fetchPages(state, action.banners)
    case actionTypes.FETCH_BANNERSCONTENTS:
      return fetchPagesContents(state, action.banners)
    case actionTypes.RESET:
      return fetchReset(state)
    default:
      return state
  }
}

const banners = {
  init,
  actionTypes,
  reducer,
}

export default banners
