import React from 'react'
import { Route } from 'react-router-dom' // Switch
import Err404 from '../error/404.js'

/* packages */
import Privilege from '../../components/app-conf-privilege'
import { LocalStorage } from '../../components/lib-atomic-functions'
/* packages */

const importBody = (props) => {
  console.log("Privilege[process.env.REACT_APP_CLIENT]",Privilege[process.env.REACT_APP_CLIENT])
  console.log("LocalStorageGETLS",LocalStorage.getLS());
  console.log("LocalStorage",LocalStorage);
  const {
    rolesModules,
    rolesSubModules,
    roleslandingPages,
    rolesHandler,
    landingHandler,
  } = Privilege[process.env.REACT_APP_CLIENT]

  const availableContainers = rolesHandler(LocalStorage, rolesModules)
  const availableSubModules = rolesHandler(LocalStorage, rolesSubModules)
  const LandingPage = landingHandler(LocalStorage, roleslandingPages)
  // const [availableContainers, ModulesLandingPage] = rolesHandler(
  //   LocalStorage,
  //   rolesModules,
  //   rolesLandingPages
  // )
  // const [availableSubModules, SubModulesLandingPage] = rolesHandler(
  //   LocalStorage,
  //   rolesSubModules,
  //   rolesLandingPages
  // )
  console.log(rolesModules,"rolesModules")
  console.log(availableContainers,"availableContainers")
  const { module } = props.match.params // lang
  const { pathname } = props.location

  var pathnameRefined =
    pathname.slice(-1) === '/' ? pathname.slice(1, -1) : pathname

  // check if user try to visit unavailable banners; prepare flags for showing 404 page
  var modular404 = true
  var showPage404 = true
  // add more flags...

  for (const mod1 in availableContainers) {
    // console.log(mod1,"mod1")
    if (mod1 === module) {
      modular404 = false
      for (const mod in availableSubModules[mod1]) {
        const originalPrefix =
          availableSubModules[mod1][mod].Config.routes.prefix
        const originalPrefixArray = originalPrefix
          .split('/')
          .slice(1, originalPrefix.split('/').length)
        
        const pathArray = pathnameRefined
          .split('/')
          .slice(1, pathnameRefined.split('/').length)

        if (originalPrefixArray.length !== pathArray.length) continue
        var refined1 = []
        var refined2 = []
        for (var i = 0; i < originalPrefixArray.length; i++) {
          var item = originalPrefixArray[i]
          if (item.indexOf(':') === -1) {
           
            refined1.push(item)
            if (pathArray.length >= i) {
              refined2.push(pathArray[i])
            }
          }
        }
        const final1 = refined1.slice(0, refined1.length).join('/')
        const final2 = refined2.slice(0, refined2.length).join('/')
       
        if (final1 === final2) {
          console.log('final2',final1.length, final2.length)
          showPage404 = false
          break
        }
      }
    }
  }
 
  // pop up container level 404
  if (modular404) {
    const moduleObj = { module: 'ordering' }
    console.log('no matching module')
    // props.history.push('/404')
    return <Route component={Err404} />
  }
  console.log('showPage404',showPage404)
  // pop up 404 page for each module
  if (showPage404) return <Route component={Err404} />
  
  const { Routes } = availableContainers[module] // Config
  console.log('Routes',Routes)
  return <Routes {...props} />
}
export default importBody
