import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_ACTIVATION: 'FETCH_ACTIVATION',
  RESET: 'RESET',
}

const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}

const fetchActivation = async (state, activation) => {
  state = {
    ...state,
    isStatus: activation.status,
    expireToken: sanitizeExpire({
      status: activation.status,
      data: activation.data,
      name: 'activationobject',
    }),
    data: sanitizeData({
      status: activation.status,
      data: activation.data,
      name: 'activationobject',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACTIVATION:
      return fetchActivation(state, action.activation)
    case actionTypes.RESET:
      return fetchReset(state)
    default:
      return state
  }
}

const activation = {
  init,
  actionTypes,
  reducer,
}

export default activation
