import React, { useState } from 'react'
import { Captcha, Inputs, Buttons } from '../../lib-atomic'
import Iframe from 'react-iframe'
import { FormHook, WindowSize, LocalStorage } from '../../lib-atomic-functions'
import {
  ModPublicWrapp,
  ModXlWrapp,
  ModFormLabelText,
} from '../../lib-atomic-design-section'
import Typography from '@material-ui/core/Typography'

import CONFIG from '../config'

/* CONSTANTS */
const temp_points = CONFIG.setup.assets.temp_points

const FORM_OBJECTS = CONFIG.application.objects

const Default = (props) => {
  const { width, height } = WindowSize()
  const { country, lang } = LocalStorage.getLS()
  const currentlanguage = LocalStorage.getLS().translation.json
  const contactformurl = currentlanguage.contactus_link_page[country]
  // console.log("contactform",currentlanguage);
  return (
    <div
      style={{
        alignContent: 'center',
        width: width < 500 ? '100%' : '80%',
        margin: 'auto',
        padding: width < 500 ? '0 10px 20px 10px' : '0 30px 30px 30px',
      }}
    >
      <Typography
        variant="h5"
        style={{
          fontFamily: 'SubwayFootlong',
          textAlign: 'left',
          color: '#000',
          marginBottom: 5,
          marginTop: 20,
          margin:
            width < 500
              ? '20px 10px 5px 15px'
              : width < 650
              ? '20px 10px 5px 25px'
              : '20px 10px 5px 35px',
        }}
      >
        {currentlanguage.contactus_website_page.contactus_website_title}
      </Typography>
      <Iframe
        url={contactformurl}
        width="100%"
        height="1170px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"
        frameBorder="0"
        scrolling="no"
      />
    </div>
  )
}

export default Default
