import React from 'react'

import Typography from '@material-ui/core/Typography'

const feed = ({ error, translation }) => {
  return error.map((item, index) => {
    if (!item) return null
    let errorString = false
    if (translation !== undefined) {
      errorString = translation.errorresponse_formValidation[item.error]
        ? translation.errorresponse_formValidation[item.error]
        : false
    } else {
      errorString = error
    }
    if (!errorString) return null
    return (
      <Typography
        key={index}
        variant="caption"
        display="block"
        gutterBottom
        className="subway-six-inch"
      >
        {errorString}
      </Typography>
    )
  })
}

export default feed
