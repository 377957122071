import React, { useContext, useEffect } from 'react'

import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'
import { GlobalContext } from '../../lib-atomic-context'

const index = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  const { verification } = useContext(GlobalContext)
  const execVerificationReset = async () => {
    verification.dispatch({
      type: verification.actionTypes.RESET,
    })
  }
  const resetVeri = async () => {
    await execVerificationReset()
  }
  useEffect(() => {
    // GArecordPageView(cookies)
    resetVeri()
  }, [])

  return props.children
}

export default index
