import React, { useState, Suspense, useContext, Fragment } from 'react'
import Page404 from '../../../layout/error/404';

/* container */
import { GlobalProvider, GlobalContext } from '../../lib-atomic-context'
import SpinTheWheelContainer from '../../app-module-spin-the-wheel-container/default'
import Content from '../content'
import { ModPublicWrapp } from '../../lib-atomic-design-section'
import { LocalStorage } from '../../lib-atomic-functions'
/* container */
import ScrollToTop from '../../../scrollToTop'

/* modules */
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
/* modules */
import BannerBox from '../banner/bannerBox'
import { useEffect } from 'react';

const index = (props) => {
  const [tokenRes, setTokenRes] = useState({});
  const [isLoading, setLoading] = useState(true);
  const query = new URLSearchParams(props.location.search);
  const token = query.get('token');

  const spinApiUat = 'https://sub-spin.tr4ns.com/spin?token=';
  const spinApiLive = 'https://sub-spin.tranxactor.com/spin?token=';
  const useApi = process.env.REACT_APP_BUILD_ENV === "development" ? spinApiUat : spinApiLive;

useEffect(() => {
  const headers = { 'Content-Type': 'application/json' }
  fetch(useApi + token, {headers})
      .then(response => response.json())
      .then(data =>{
        console.log(useApi)
        setTokenRes(data);
        setLoading(false);
      })
    }, []);
  
  let contentLoad;
  
  // if(!token || tokenRes.outcomeCode !== '0' || tokenRes.outcomeCode === "GE_MAIN:VALIDATE_TOKEN:3" ){
  //   if(!isLoading) {
  //     contentLoad = <Page404 />
  //   } else {
  //     contentLoad = <div style={{ marginTop: 200 }}><Spinner2.Popup /></div>
  //   }
  // } else {
  //   if(!isLoading){
  //     contentLoad = <Content {...props} tokenRes={tokenRes} gameToken={token} />
  //   } else {
  //     contentLoad = <div style={{ marginTop: 200 }}><Spinner2.Popup /></div>
  //   }
  // }

  if(!isLoading){
    contentLoad = <Content {...props} tokenRes={tokenRes} gameToken={token} useApi={useApi} />
  } else {
    contentLoad = <div style={{ marginTop: 200 }}><Spinner2.Popup /></div>
  }
  
  return (
    <Fragment {...props}>
      <ScrollToTop {...props} />
      <SpinTheWheelContainer {...props}>
        <Suspense
          fallback={
            <div style={{ marginTop: 200 }}>
              <Spinner2.Popup />
            </div>
          }
        >
          {/* <Content {...props} tokenRes={tokenRes} /> */}
          {contentLoad}
        </Suspense>
      </SpinTheWheelContainer>
    </Fragment>
  )
}

// return (
//   <Fragment {...props}>
//     <ScrollToTop {...props} />
//     {/* <ModPublicWrapp {...props} imgToDisplay={imgToDisplay} /> */}
//     <SpinTheWheelContainer {...props}>
//       <Suspense
//         fallback={
//           <div style={{ marginTop: 200 }}>
//             <Spinner2.Popup />
//           </div>
//         }
//       >
//         <Content {...props} />
//       </Suspense>
//     </SpinTheWheelContainer>
//   </Fragment>
// )

  // const { bannerscontents, setup } = useContext(GlobalContext)
  // const imgToDisplay = bannerscontents.state.data.masterbannerscontents.filter(
  //   (page) => {
  //     return page.pageName === 'PreloginHome'
  //   }
  // )[0]


export default index
