// ------------------------
// public
// ------------------------
// ------------------------
/* home module */
import Home from '../../../../app-module-home-page-landing'
/* login module */
import Login from '../../../../app-module-login-page-landing'
/* account activation module */
import Activation from '../../../../app-module-activation-page-landing'
import ActivationPopup from '../../../../app-module-activation-page-submit-popup'
import ActivationResend from '../../../../app-module-activation-page-resend'
import ActivationResendPopup from '../../../../app-module-activation-page-resend-submit-popup'
/* account verification module */
import Verification from '../../../../app-module-verification-page-landing'
import VerificationPopup from '../../../../app-module-verification-page-submit-popup'
import VerificationResend from '../../../../app-module-verification-page-resend'
import VerificationResendPopup from '../../../../app-module-verification-page-resend-submit-popup'
/* register module */
import Register from '../../../../app-module-register-page-landing'
/* forgot password module */
import ForgotPassword from '../../../../app-module-forgot-password-page-landing'
import ForgotPasswordSubmit from '../../../../app-module-forgot-password-page-submit-popup'
/* reset password module */
import ResetPassword from '../../../../app-module-reset-password-page-landing'
import ResetPasswordPopup from '../../../../app-module-reset-password-page-submit-popup'
import Promotions from '../../../../app-module-promotions-page-landing'
import FAQ from '../../../../app-module-faq-page-landing'
import SubsquadFaq from '../../../../app-module-subsquadfaq-page-landing'
import ContactUs from '../../../../app-module-contactus-page-landing'
import Terms from '../../../../app-module-terms-page-landing'
import PrivacyNotice from '../../../../app-module-privacynotice-page-landing'
import Cookies from '../../../../app-module-cookies-page-landing'
import Logout from '../../../../app-module-logout-page-landing'
/* impressum */
import ImpressumLanding from '../../../../app-module-impressum-page-landing'
import Subsquad from '../../../../app-module-subsquad-page-landing'
import SubRewards from '../../../../app-module-subrewards-page-landing'
import Unsubscribe from '../../../../app-module-unsubscribe-page-landing'
import UnsubscribeSuccess from '../../../../app-module-unsubscribe-page-success'
import UnsubscribeFail from '../../../../app-module-unsubscribe-page-fail'
import Order from '../../../../app-module-onlineOrder-landing'
import Invitefriend from '../../../../app-module-invitefriend-page-landing'
import Invitefriendfaq from '../../../../app-module-invitefriendfaq-page-landing'
// ------------------------
// private
// ------------------------
// ------------------------

// import Promotions from '../../../../app-module-promotions-page-landing'
// /* post Login home module */
// import PostHome from '../../../../app-module-post-login-home-page-landing'
// /* iou module */
// import Iou from '../../../../app-module-iou-page-landing'
// import IouPopup from '../../../../app-module-iou-page-submit-popup'
// /* change password module */
// import ChangePassword from '../../../../app-module-change-password-page-landing'
// /* news module */
// import News from '../../../../app-module-news-page-landing'
// import NewsDetail from '../../../../app-module-news-page-detail'
// /* offers module */
// import Offers from '../../../../app-module-offers-page-landing'
// import OfferDetail from '../../../../app-module-offer-private-page-detail'
// /* rewards module */
// import Rewards from '../../../../app-module-rewards-page-landing'
// /* history module */
// import History from '../../../../app-module-my-history-page-landing'
// import HistoryDetail from '../../../../app-module-my-history-page-detail'
// import DashboardLanding from '../../../../app-module-dashboard-page-landing'
// import Subsquad from '../../../../app-module-subsquad-page-landing'
// import Points from '../../../../app-module-my-points-page-landing'
// import Profile from '../../../../app-module-my-profile-page-landing'
// import Preferences from '../../../../app-module-my-preferences-page-landing'
// import LostCard from '../../../../app-module-lostcard-page-landing'
// import Update from '../../../../app-module-update-page-landing'
// import TransferCard from '../../../../app-module-transfercard-page-landing'

/* campaign */
import Campaign from '../../../../app-module-campaign-landing'
import CampaignSubmit from '../../../../app-module-campaign-submit-landing'

/* home */
const homeModules = [Home]

/* login */
const loginModules = [Login]

/* activation */
const activationModules = [
  Activation,
  ActivationPopup,
  ActivationResend,
  ActivationResendPopup,
]

/* verification */
const verificationModules = [
  Verification,
  VerificationPopup,
  VerificationResend,
  VerificationResendPopup,
]

const registerModules = [Register]

/* forgot password */
const forgotPasswordModules = [ForgotPassword, ForgotPasswordSubmit]

/* reset password */
const resetPasswordModules = [ResetPassword, ResetPasswordPopup]

const promotionsModules = [Promotions]
const faqModules = [FAQ]
const squsuadfaqModules = [SubsquadFaq]
const contactUsModules = [ContactUs]
const termsModules = [Terms]
const privacyNoticeModules = [PrivacyNotice]
const cookiesModules = [Cookies]
const logoutModules = [Logout]

/* impressum */
const impressumModules = [ImpressumLanding]

const subsquadModules = [Subsquad]
const subRewardsModules = [SubRewards]
const invitefriendModule = [Invitefriend]
const invitefriendfaqModule = [Invitefriendfaq]
/* usubscribe */
const unsubscribeModules = [Unsubscribe, UnsubscribeSuccess, UnsubscribeFail]

const orderModules = [Order]
// const postHome = [PostHome]
// const transferCardModule = [TransferCard]
// const iouModules = [Iou, IouPopup]
/* change password */
// const changePasswordModules = [ChangePassword]

/* news */
// const newsModules = [News, NewsDetail]

/* offers */
// const offersModules = [Offers, OfferDetail]

/* rewards */
// const rewardsModules = [Rewards]

// /* history */
// const historyModules = [History, HistoryDetail]

// const updateModule = [Update]

// const dashboardModules = [DashboardLanding]
// const subsquadModules = [Subsquad]
// const pointsModules = [Points]
// const profileModules = [Profile]
// const preferencesModules = [Preferences]
// const lostcardModules = [LostCard]

/* campaign */
const campaignModules = [Campaign, CampaignSubmit]

const modules = {
  // public //
  home: homeModules,
  login: loginModules,
  activation: activationModules,
  verification: verificationModules,
  register: registerModules,
  forgot_password: forgotPasswordModules,
  // iou: iouModules,
  reset_password: resetPasswordModules,
  promotions: promotionsModules,
  faq: faqModules,
  subsquadfaq: squsuadfaqModules,
  contactus: contactUsModules,
  terms: termsModules,
  privacynotice: privacyNoticeModules,
  cookies: cookiesModules,
  logout: logoutModules,
  impressum: impressumModules,
  subsquad: subsquadModules,
  subrewards: subRewardsModules,
  unsubscribe: unsubscribeModules,
  invitefriend:invitefriendModule,
  invitefriendfaq:invitefriendfaqModule,
  // private //
  // posthome: postHome,
  // change_password: changePasswordModules, not used atm
  // news: newsModules,
  // offers: offersModules,
  // rewards: rewardsModules,
  // history: historyModules,
  // update: updateModule,
  // dashboard: dashboardModules,
  // subsquad: subsquadModules,
  // points: pointsModules,
  // profile: profileModules,
  // preferences: preferencesModules,
  // lostcard: lostcardModules,
  // transfercard: transferCardModule,
  campaign: campaignModules,
}

export default modules
