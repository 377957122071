import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_VERSION: 'FETCH_VERSION',
}

const fetchVersion = async (state, version) => {
  // console.log(version, 'test...')
  state = {
    ...state,
    isStatus: version.status,
    expireToken: sanitizeExpire({
      status: version.status,
      data: version.data,
      name: 'masterappversion',
    }),
    data: sanitizeData({
      status: version.status,
      data: version.data,
      name: 'masterappversion',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_VERSION:
      return fetchVersion(state, action.version)
    default:
      return state
  }
}

const version = {
  init,
  actionTypes,
  reducer,
}

export default version
