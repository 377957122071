import React, { useState, useContext } from 'react'
// import { Link } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'

// import { GlobalContext } from '../../lib-atomic-context'
/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import { Inputs, Buttons } from '../../lib-atomic'
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
} from '../../lib-atomic-functions'
import {
  ModXlWrapp,
  ModPageTitleWrapp,
  ModTitleText,
  ModContentText,
  ModFormLabelText,
} from '../../lib-atomic-design-section'
// import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

import CONFIG from '../config'

/* CONSTANTS */

const index = (props) => {
  const currentlanguage = LocalStorage.getLS().translation.json
  // console.log(currentlanguage)

  const isSuccessfullyUpdated = props.location.state
    ? props.location.state.isSuccessfullyUpdated
    : false

  const message = props.location.state ? props.location.state.message : false
  // const isAlreadyUsed = props.location.state
  //   ? props.location.state.isAlreadyUsed
  //   : false
  // const isAlreadyExpired = props.location.state
  //   ? props.location.state.isAlreadyExpired
  //   : false

  const { country, lang } = LocalStorage.getLS()

  const backButton = {
    fullWidth: true,
    onClick: () => {
      props.history.push('/activation')
    },
  }

  const forwardButton = {
    fullWidth: true,
    onClick: () => {
      // LocalStorage.removeLS()
      props.history.push('/dashboard')
    },
  }
  return (
    <div>
      <ModXlWrapp>
        <ModPageTitleWrapp>
          <ModTitleText>
            <b>
              {isSuccessfullyUpdated
                ? currentlanguage.activationpage.page_activ_congrats
                : 'Sorry'}
            </b>
          </ModTitleText>

          {/* content wording */}
          {message && message.length > 0 ? (
            <ModContentText>
              <b>{message}</b>
            </ModContentText>
          ) : null}
        </ModPageTitleWrapp>
      </ModXlWrapp>
      <ModXlWrapp>
        {!isSuccessfullyUpdated && (
          <Buttons.Normal {...backButton}>
            {currentlanguage.activationpage.page_activ_back}
          </Buttons.Normal>
        )}
        {isSuccessfullyUpdated && (
          <Buttons.Normal {...forwardButton}>
            {currentlanguage.activationpage.page_activ_activate}
          </Buttons.Normal>
        )}
      </ModXlWrapp>
    </div>
  )
}

export default index
