import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Update'
const server = null
const service = null
const options = {
  country_options: [
    { name: 'New Zealand', value: '18' },
    { name: 'United Kingdom', value: '17' },
    { name: 'Germany', value: '16' },
  ],
  mob_prefix_options: [
    { name: '+43', value: '+43', abbr: 'at' },
    { name: '+49', value: '+49', abbr: 'de' },
    { name: '+33', value: '+33', abbr: 'fr' },
    { name: '+44', value: '+44', abbr: 'gb' },
    { name: '+353', value: '+353', abbr: 'ie' },
    // { name: '+44', value: '+44', abbr: 'ni' }, 
    { name: '+31', value: '+31', abbr: 'nl' },
    { name: '+46', value: '+46', abbr: 'se' },
    { name: '+358', value: '+358', abbr: 'fi' },
  ],
}
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        endpoints: {
          list: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: null,
            },
            doc: 'https://tr4ns10.tr4ns.com/Westfield-Reporting',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.tranxactor.url[environment] + '/configs',
            method: 'GET',
          },
          auth: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: null,
            },
            doc: 'http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token',
            url: setup.setup.api.tranxactor.url[environment] + '/configs',
            method: 'POST',
          },
          valid: {
            headers: {
              'Content-Type': 'application/json',
              moduleCode: setup.setup.thor.moduleCode[environment],
              'Accept-Language': 'en',
              Authorization: null,
            },
            doc: 'https://subcarduat.tr4ns.com/tx-sub/validation',
            url: setup.setup.api.stormborn.url.api + '/validation',
            method: 'POST',
          },
          register: {
            headers: {
              'Content-Type': 'application/json',

              Authorization: null,
            },
            doc: 'https://subcarduat.tr4ns.com/tx-sub/registration',
            url: setup.setup.api.stormborn.url.api + '/members',
            method: 'PATCH',
          },
          enquiry: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: null,
              moduleCode: setup.setup.thor.moduleCode[environment],
            },
            url: setup.setup.api.subcard.url[environment] + '/tx-sub/members',
            method: 'GET',
          },
        },
      },

      objects: {
        postcode: {
          name: 'postcode',
          initial: '',
          placeholder: '',
          helperText: false,
          rules: ['isNumberAndLetterPostcode'],
        },
        mobprefix: {
          name: 'mobprefix',
          initial: '',
          options: options.mob_prefix_options,
          placeholder: 'Prefix',
          helperText: false,
          rules: false,
        },
        mobile: {
          name: 'mobile',
          initial: '',
          placeholder: '',
          helperText: false,
          rules: ['isMinMaxPhoneNumber', 'isNotNullMobile'],
        },
        isSmsActive: {
          name: 'isSmsActive',
          initial: '',
          helperText: false,
          rules: false,
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'Update Details',
    prefix: '/preferences',
  },
  setup,
}

export default config
