import React, { useState, State, useEffect, Fragment, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ModPublicWrapp } from '../../lib-atomic-design-section'
/* container */
import UpdateContainer from '../../app-module-update-container/default'
/* container */
import { GlobalContext } from '../../lib-atomic-context'
/* package */
import { ModMainWrapp } from '../../lib-atomic-design-section'
/* package */
//dialogBox
import DialogBox from '../../lib-atomic/dialog/dialogbox'
/* form */
import UpdateForm from '../form/update'
/* form */
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import GdprForm from '../form/gdprform'

import CONFIG from '../config'
import { LocalStorage } from '../../lib-atomic-functions'
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
/* constant */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    width: '50%',
    marginTop: '50px',
    marginBottom: '100px',
  },
  gridList: {
    width: 800,
  },
  style: {
    marginTop: '100px',
    marginBottom: '100px',
  },
  rootmobile: {
    flexGrow: 1,
    marginLeft: '20px',

    width: '90%',
  },
}))
/* constant */
const mediaPath = CONFIG.setup.assets.media
const ENDPOINTS = CONFIG.application.account.api.endpoints
const UPDATE = {
  url: ENDPOINTS.register.url,
  method: ENDPOINTS.register.method,
  headers: ENDPOINTS.register.headers,
  responseType: 'json',
}
const ENQUIRYCONFIG = {
  url: ENDPOINTS.enquiry.url,
  method: ENDPOINTS.enquiry.method,
  headers: ENDPOINTS.enquiry.headers,
  responseType: 'json',
}

const index = (props) => {
  const { token } = LocalStorage.getLS().login

  const [data, setdata] = React.useState()
  const [data2, setdata2] = React.useState()
  const [response, setresponse] = React.useState()
  const [open, setOpen] = useState(false)
  const { update, member } = useContext(GlobalContext)

  //Registration API
  const [updateData] = FetchContextApiV2(UPDATE)
  const updateExecute = async ({ body, newHeader }) => {
    update.dispatch({
      type: update.actionTypes.FETCH_UPDATE,
      update: await updateData(
        {
          body,
          newHeader,
        },
        update
      ),
    })
  }

  //memberEnquiry

  const [fetchMemberDetail] = FetchContextApiV2(ENQUIRYCONFIG)

  const fetchMemberDetailExe = async ({ newHeader }) => {
    member.dispatch({
      type: member.actionTypes.FETCH_MEMBER,
      member: await fetchMemberDetail(
        {
          newHeader,
        },
        member
      ),
    })
  }

  useEffect(() => {
    const newHeader = {
      ...ENQUIRYCONFIG.headers,
      Authorization: 'Bearer ' + token,
    }
    fetchMemberDetailExe({ newHeader })
  }, [])

  useEffect(() => {
    if (update.state.data) {
      const { updateObject } = update.state.data

      if (updateObject.response) {
        setresponse(updateObject.response.data)
      } else {
        setresponse(updateObject)

        // props.history.push('/nz/en/home')
      }
      // if (registerObject.response.data.outComeCode != 0) {
      // console.log("outComeCode",registerObject.response.data.outComeCode);
      // console.log("outComeMessage",registerObject.response.data.outComeMessage);
      // console.log("outComeUserMessage",registerObject.response.data.outComeUserMess
      // a ge); setresponse(registerObject.response.data); setOpen(true); }
    }
  }, [update.state])

  useEffect(() => {
    if (member.state.data) {
      const { memberObject } = member.state.data
      const { response } = memberObject
    }
  }, [member.state])

  if (member) {
    var propsValue = member.state.data.memberObject
  }
  const [activeStep, setActiveStep] = React.useState(1)

  const values = (values) => {
    const data = values
    setdata2(data)
  }
  const mediaPath = CONFIG.setup.assets.media
  const gdprvalues = async (t) => {
    
    const gdprvalu = t
    setdata(gdprvalu)
    const obj4 = data2
    const obj3 = gdprvalu
    var body = Object.assign({}, obj4, obj3)

    var newHeader = {
      Authorization: token,
    }

    await updateExecute({ body, newHeader })
  }
  const callbackFunction = (n) => {
    setActiveStep(n)
  }
  const stepBack = (b) => {
    setActiveStep(b)
  }
  const handleClose = (value) => {
    setOpen(false)
  }

  const stepOne = activeStep === 1 ? true : false

  const stepTwo = activeStep === 0 ? true : false
  const stepsData = {
    props,
  }

  const { bannerscontents } = useContext(GlobalContext)
  const imgToDisplay = bannerscontents.state.data.masterbannerscontents.filter(
    (page) => {
      return page.pageName === 'Login'
    }
  )[0]

  const classes = useStyles()
  if (window.screen.width < 800 && propsValue) {
    return (
      <UpdateContainer>
        <ModPublicWrapp {...props} imgToDisplay={imgToDisplay}>
          <div className={classes.style}>
            <div className={classes.rootmobile}>
              {stepOne && (
                <UpdateForm
                  {...stepsData}
                  {...propsValue}
                  parentCallback={callbackFunction}
                  values={values}
                />
              )}
              {stepTwo && (
                <GdprForm
                  {...stepsData}
                  {...propsValue}
                  {...data2}
                  gdprvalues={gdprvalues}
                  back={stepBack}
                  parentCallback={callbackFunction}
                />
              )}
            </div>
          </div>
          <DialogBox
            selectedValue={response}
            open={open}
            onClose={handleClose}
          />
        </ModPublicWrapp>
      </UpdateContainer>
    )
  }
  if (window.screen.width > 800 && propsValue) {
    return (
      <UpdateContainer>
        <ModPublicWrapp {...props} imgToDisplay={imgToDisplay}>
          <div className={classes.root}>
            {stepOne && (
              <UpdateForm
                {...stepsData}
                {...propsValue}
                parentCallback={callbackFunction}
                values={values}
              />
            )}
            {stepTwo && (
              <GdprForm
                {...stepsData}
                {...propsValue}
                {...data2}
                gdprvalues={gdprvalues}
                back={stepBack}
                parentCallback={callbackFunction}
              />
            )}
          </div>
          <DialogBox
            selectedValue={response}
            open={open}
            onClose={handleClose}
          />
        </ModPublicWrapp>
      </UpdateContainer>
    )
  } else {
    return <Spinner2.Popup />
  }
}

export default index
