import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_PAGES: 'FETCH_PAGES',
}

const fetchPages = async (state, spinthewheel) => {
  state = {
    ...state,
    isStatus: spinthewheel.status,
    expireToken: sanitizeExpire({
      status: spinthewheel.status,
      data: spinthewheel.data,
      name: 'spinthewheelimages',
    }),
    data: sanitizeData({
      status: spinthewheel.status,
      data: spinthewheel.data,
      name: 'spinthewheelimages',
    }),
  }
  //state.data.mastertranslation = state.data.mastertranslation[0].json;
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PAGES:
      return fetchPages(state, action.spinthewheel)
    default:
      return state
  }
}

const spinthewheel = {
  init,
  actionTypes,
  reducer,
}

export default spinthewheel
