import React, { useContext } from 'react'
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
} from '@material-ui/core'
import { LocalStorage, WindowSize } from '../../lib-atomic-functions'

import appConf from '../../app-conf'
import { GlobalContext } from '../../lib-atomic-context'
// import './index.scss'

const hotdrink = appConf[process.env.REACT_APP_CLIENT].assets.hotdrink
const subsixinch = appConf[process.env.REACT_APP_CLIENT].assets.subsixinch
const subfootlong = appConf[process.env.REACT_APP_CLIENT].assets.subfootlong
const coffee = appConf[process.env.REACT_APP_CLIENT].assets.coffee

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    display: 'flex',
    alignItems: 'stretch',
  },
  h6: {
    fontFamily: 'SubwayFootlong',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  circularProgress: {
    color: theme.palette.tertiary.yellow,
  },
  pointsHeader: {
    fontFamily: 'SubwayFootlong',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  pointsBalance: {
    fontFamily: 'SubwaySixInch',
    fontSize: '7em',
    fontWeight: 'bold',
    lineHeight: 'normal',
    textAlign: 'center',
  },
}))
const Default = (props) => {
  const { width, height } = WindowSize()
  const currentlanguage = LocalStorage.getLS().translation.json
  const { member } = useContext(GlobalContext)
  const { country } = LocalStorage.getLS()
  const user = member.state.data.memberObject
  const classes = useStyles()
  var rewards
  if (country === 'fr' || country === 'gb' || country === 'ie') {
    rewards = [
      {
        id: 1,
        icon: hotdrink,
        points: 100,
        circle: 100,
      },
      {
        id: 4,
        icon: coffee,
        points: 200,
        circle: 300,
      },
      {
        id: 2,
        icon: subsixinch,
        points: 500,
        circle: 500,
      },
      {
        id: 3,
        icon: subfootlong,
        points: 1000,
        circle: 100,
      },
    ]
  } else {
    rewards = [
      {
        id: 1,
        icon: hotdrink,
        points: 100,
        circle: 100,
      },
      {
        id: 2,
        icon: subsixinch,
        points: 500,
        circle: 500,
      },
      {
        id: 3,
        icon: subfootlong,
        points: 1000,
        circle: 100,
      },
    ]
  }
  return (
    <Box m={5} style={{ marginLeft: 5, marginRight: 5 }}>
      <Typography color="primary" className={classes.pointsHeader}>
        {currentlanguage.whatcaniget_website_page.whatcaniget_website_points}
      </Typography>

      <Typography color="primary" className={classes.pointsBalance}>
        {user.loyaltyBalance}
      </Typography>

      <Typography variant="h6" align="center" className={classes.h6}>
        {
          currentlanguage.whatcaniget_website_page
            .whatcaniget_website_whatcaniget
        }
      </Typography>

      <Grid id="rewards" container className={classes.root}>
        {rewards.map((item, index) => {
          return (
            <Grid
              item
              xs={12}
              md={rewards.length > 3 ? 6 : 4}
              style={{
                marginTop: 20,
                marginBottom: 20,
                paddingLeft:
                  width < 960
                    ? 0
                    : rewards.length > 3 && index % 2 !== 1
                    ? '20%'
                    : 0,
                paddingRight:
                  width < 960
                    ? 0
                    : rewards.length > 3 && index % 2 !== 0
                    ? '20%'
                    : 0,
              }}
            >
              <div
                className="reward"
                style={{
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: 'lightgrey',
                  margin: 20,
                  marginBottom: 0,
                  textAlign: 'center',
                  height: '100%',
                  minHeight: 310,
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    height: 200,
                  }}
                >
                  <img
                    style={{
                      position: 'absolute',
                      margin: 'auto',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      maxWidth: 80,
                      maxHeight: 80,
                      height: 'auto',
                    }}
                    src={item.icon}
                    alt="reward"
                  />
                  <CircularProgress
                    style={{
                      position: 'absolute',
                      margin: 'auto',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                    className={classes.circularProgress}
                    variant="static"
                    value={
                      user.loyaltyBalance > item.points ||
                      user.loyaltyBalance == item.points
                        ? item.circle
                        : ((user.loyaltyBalance / item.points) * 100).toFixed(0)
                    }
                    size={160}
                    thickness={1}
                  />
                </div>

                <Typography
                  className="points"
                  style={{ fontWeight: 'bold' }}
                  color="primary"
                >
                  <p className="subway-footlong">
                    {item.points}{' '}
                    {
                      currentlanguage.whatcaniget_website_page
                        .whatcaniget_website_point_word
                    }
                  </p>
                </Typography>
                <p
                  className="caption"
                  style={{
                    marginTop: 20,
                    paddingBottom: 0,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  <p className="subway-six-inch">
                    {item.points === 100
                      ? currentlanguage.whatcaniget_website_page
                          .whatcaniget_website_100text
                      : item.points === 500
                      ? currentlanguage.whatcaniget_website_page
                          .whatcaniget_website_500text
                      : item.points === 1000
                      ? currentlanguage.whatcaniget_website_page
                          .whatcaniget_website_1000text
                      : item.points === 200
                      ? currentlanguage.whatcaniget_website_page
                          .whatcaniget_website_200text
                      : null}
                  </p>
                </p>
              </div>
            </Grid>
          )
        })}
      </Grid>
      <div style={{ height: 50 }} />
    </Box>
  )
}

export default Default
