import { sanitizeData, sanitizeExpire } from './Sanitize'
// import { Carousel } from "react-responsive-carousel";

const init = {
  isLoading: false,
  isStatus: true,
  expireToken: false,
  data: false,
}

const actionTypes = {
  FETCH_CONTENTS: 'FETCH_CONTENTS',
  RESET_CONTENTS: 'RESET_CONTENTS',
  FETCH_CAROUSEL_CONTENTS: 'FETCH_CAROUSEL_CONTENTS',
}

const fetchContents = async ({ state, contents }) => {
  state = {
    ...state,
    isStatus: contents.status,
    expireToken: sanitizeExpire({
      status: contents.status,
      data: contents.data,
      name: 'mastercontents',
    }),
    data: sanitizeData({
      status: contents.status,
      data: contents.data,
      name: 'mastercontents',
    }),
  }
  return state
}

const fetchCarouselContents = async ({ state, contents, idInOrder }) => {
  const data = sanitizeData({
    status: contents.status,
    data: contents.data,
    name: 'mastercontents',
  })
  console.log(data)
  data.mastercontents = data.mastercontents
    .map((d) => {
      return { id: d.id, mastercontents: d }
    })
    .sort((a, b) => idInOrder.indexOf(a.id) - idInOrder.indexOf(b.id))

  const newcontents = state.data.mastercontents.map((sta) => {
    if (sta.type === 'Carousel') {
      sta.CarouselContents = data.mastercontents
    }
    return sta
  })

  state = {
    ...state,
    isStatus: contents.status,
    expireToken: sanitizeExpire({
      status: contents.status,
      data: contents.data,
      name: 'mastercontents',
    }),
    data: { mastercontents: newcontents },
  }
  return state
}

const resetContents = async (state) => {
  state = {
    ...state,
    ...init,
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONTENTS:
      return fetchContents({ state, contents: action.contents })
    case actionTypes.FETCH_CAROUSEL_CONTENTS:
      return fetchCarouselContents({
        state,
        initial: action.initial,
        contents: action.contents,
        idInOrder: action.idInOrder,
      })
    case actionTypes.RESET_CONTENTS:
      return resetContents(state)
    default:
      return state
  }
}

const contents = {
  init,
  actionTypes,
  reducer,
}

export default contents
