import { useState, useEffect } from 'react'

import Validation from '../form-validation-translation'

const Initial = (config) => {
  const initial = {}
  Object.keys(config).map((data, index) => {
    initial[data] = config[data].initial
  })
  return initial
}

const checkErrors = (errors) => {
  let isError = false
  Object.keys(errors).map((err) => {
    errors[err].map((e) => {
      if (e) isError = true
    })
  })
  return isError
}

const useFormHook = (callback, config) => {
   

  const [values, setValues] = useState(Initial(config))
  const [errors, setErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [isActiveFlag,setisActiveFlag] = useState(false)

  useEffect(() => {
    
    const isError = checkErrors(errors)
    if (!isError && isSubmit) {
   
      callback()
      setIsSubmit(false)
    }
  }, [errors, values, isSubmit])

  useEffect(() => {
    console.log("submit")
    const isError = checkErrors(errors)
    if(isActiveFlag  && !isError ){
      callback()
      setIsSubmit(false)
    }
  }, [isActiveFlag])

  const handleReset = (initial) => {
    // console.log(initial)
    setValues(Initial(initial))
  }

  const handleSubmit = (event) => {

    
    console.log("eventSubmit",event);
    if (event) event.preventDefault()
    console.log('valuesvaluesvaluesvalues', values)
    
    if(values.isActive == false){
      setisActiveFlag(true)
    }
    const { dataError } = Validation.multiValidation(
      // dataset
      config,
      values,
      errors,
      values
    )
   
   
    console.log(dataError, ' CHECKING...')
    setErrors(dataError)
    setIsSubmit(true)
  }

  const handleChange = (event) => {
 
   
    event.persist()
    
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
    const { dataError } = Validation.singleValidation(
      // dataset
      config,
      { [event.target.name]: event.target.value },
      errors,
      values
    )
    setErrors(dataError)
    setIsSubmit(false)
    
  }

  const handlePick = (name, value) => {
    //event.persist();
    console.log(name, value)
    setValues((values) => ({ ...values, [name]: value }))
    const { dataError } = Validation.singleValidation(
      // dataset
      config,
      { [name]: value },
      errors,
      values
    )
    setErrors(dataError)
    setIsSubmit(false)
  }

  const handleCheck = (event) => {
    event.persist()
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.checked,
    }))
    const { dataError } = Validation.singleValidation(
      // dataset
      config,
      { [event.target.name]: event.target.checked },
      errors,
      values
    )
    setErrors(dataError)
    setIsSubmit(false)
  }

  const handleValue = (key, value) => {
    setValues((values) => ({ ...values, [key]: value }))
    const { dataError } = Validation.singleValidation(
      // dataset
      config,
      { [key]: value },
      errors
    )
    setErrors(dataError)
    setIsSubmit(false)
  }

  return {
    // addressChange:handleChange2,
    onReset: handleReset,
    onChange: handleChange,
    onPick: handlePick,
    onCheck: handleCheck,
    onSubmit: handleSubmit,
    handleValue,
    values,
    setValues,
    error: errors,
  }
}

export default {
  useFormHook,
}
