import axios from 'axios'

const useFetch = async ({ url, config }) => {
  try {
    const response = await axios(url, config)
    const { data, status } = response
    // console.log(data, 'DATA AXIOS')
    // if (!response.ok) throw data;
    if (!status === 200) throw data
    return [true, { blob: false, json: data }]
  } catch (e) {
    return [false, { blob: false, json: e }]
  }
}

export default useFetch
