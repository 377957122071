import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_BANNERSCONTENTS: 'FETCH_BANNERSCONTENTS',
  FETCH_CAROUSEL_CONTENTS: 'FETCH_CAROUSEL_CONTENTS',
}

const fetchPagesContents = async (state, bannerscontents, banners) => {
  const modifiedObj = []
  banners.state.data.masterbanners[0].pages.map((bannerItem) => {
    const matchingBannersContents = bannerscontents.data.json.filter((item) => {
      return item.id === bannerItem.mastercontents.id
    })[0]
    const innerPrepared = {
      ...matchingBannersContents,
      pageName: bannerItem.page,
    }
    modifiedObj.push(innerPrepared)
  })
  const returnVal = {
    blob: false,
    json: modifiedObj,
  }
  state = {
    ...state,
    isStatus: bannerscontents.status,
    expireToken: sanitizeExpire({
      status: bannerscontents.status,
      data: returnVal,
      name: 'masterbannerscontents',
    }),
    data: sanitizeData({
      status: bannerscontents.status,
      data: returnVal,
      name: 'masterbannerscontents',
    }),
  }
  return state
}

const fetchCarouselContents = async ({
  state,
  parentId,
  idInOrder,
  bannerscontents,
}) => {
  const data = sanitizeData({
    status: bannerscontents.status,
    data: bannerscontents.data,
    name: 'mastercontents',
  })
  console.log(data)
  data.mastercontents = data.mastercontents
    .map((d) => {
      return { id: d.id, mastercontents: d }
    })
    .sort((a, b) => idInOrder.indexOf(a.id) - idInOrder.indexOf(b.id))

  const newcontents = state.data.masterbannerscontents.map((sta) => {
    if (sta.id.toString() === parentId.toString()) {
      sta.CarouselContents = data.mastercontents
    }
    return sta
  })
  state = {
    ...state,
    isStatus: bannerscontents.status,
    expireToken: sanitizeExpire({
      status: bannerscontents.status,
      data: bannerscontents.data,
      name: 'masterbannerscontents',
    }),
    data: { masterbannerscontents: newcontents },
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BANNERSCONTENTS:
      return fetchPagesContents(state, action.bannerscontents, action.banners)
    case actionTypes.FETCH_CAROUSEL_CONTENTS:
      return fetchCarouselContents({
        state,
        initial: action.initial,
        parentId: action.parentId,
        idInOrder: action.idInOrder,
        bannerscontents: action.bannerscontents,
      })
    default:
      return state
  }
}

const bannerscontents = {
  init,
  actionTypes,
  reducer,
}

export default bannerscontents
