import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_PRIVATE_PROMOTION: 'FETCH_PRIVATE_PROMOTION',
  RESET: 'RESET',
}

const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}

const fetchMasterPrivateContents = async (state, masterprivatecontents) => {
  state = {
    ...state,
    isStatus: masterprivatecontents.status,
    expireToken: sanitizeExpire({
      status: masterprivatecontents.status,
      data: masterprivatecontents.data,
      name: 'masterprivatecontentsobj',
    }),
    data: sanitizeData({
      status: masterprivatecontents.status,
      data: masterprivatecontents.data,
      name: 'masterprivatecontentsobj',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRIVATE_PROMOTION:
      return fetchMasterPrivateContents(state, action.masterprivatecontents)
    case actionTypes.RESET:
      return fetchReset(state)
    default:
      return state
  }
}

const masterprivatecontents = {
  init,
  actionTypes,
  reducer,
}

export default masterprivatecontents
