import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET: 'RESET',
}

const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}
const fetchForgotPassword = async (state, forgotpassword) => {
  state = {
    ...state,
    isStatus: forgotpassword.status,
    expireToken: sanitizeExpire({
      status: forgotpassword.status,
      data: forgotpassword.data,
      name: 'forgotpasswordobj',
    }),
    data: sanitizeData({
      status: forgotpassword.status,
      data: forgotpassword.data,
      name: 'forgotpasswordobj',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD:
      return fetchForgotPassword(state, action.forgotpassword)
    case actionTypes.RESET:
      return fetchReset(state)
    default:
      return state
  }
}

const forgotpassword = {
  init,
  actionTypes,
  reducer,
}

export default forgotpassword
