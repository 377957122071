import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { green } from '@material-ui/core/colors'
import Fab from '@material-ui/core/Fab'
import CheckIcon from '@material-ui/icons/Check'
import SaveIcon from '@material-ui/icons/Save'
import EmailIcon from '@material-ui/icons/Email'
import Dialog from '@material-ui/core/Dialog'
import HeaderPlaceholder from '../placeholderHeader/headerPlaceholder'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '0%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    margin: 'auto',
    justifyContent: 'center',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    margin: 'auto',
    margin: 15,
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -7.5,
    left: -7.5,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    margin: 'auto',
    justifyContent: 'center',
  },
  root2: {
    overflow: 'unset',
  },
}))
const Header = () => {
  return <HeaderPlaceholder />
}

const Popup = ({ selectedValue }) => {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(true)
  const [success, setSuccess] = React.useState(false)
  const [isMac, setIsMac] = React.useState(false)
  const timer = React.useRef()

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  })

  const checkDevice = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
      'macintel',
    ].some(function (exactPlatformString) {
      return window.navigator.platform === exactPlatformString
    })
  }

  React.useEffect(() => {
    const isMac1 = checkDevice() ? true : false
    setIsMac(isMac1)
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false)
      setLoading(true)
      timer.current = setTimeout(() => {
        setSuccess(true)
        setLoading(false)
      }, 2000)
    }
  }

  return (
    <div style={{ height: 400 }}>
      <Dialog
        maxWidth="sm"
        className={classes.root2}
        aria-labelledby="select-country"
        open={true}
        PaperProps={{
          style: {
            overflow: 'unset',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            justifyContent: 'center',
          },
        }}
      >
        <div className={classes.root}>
          <div className={classes.wrapper}>
            <Fab
              aria-label="save"
              color="primary"
              className={buttonClassname}
              onClick={handleButtonClick}
            >
              {success ? (
                <SaveIcon />
              ) : (
                <img
                  src="/assets/stormborn/logo_sm.png"
                  alt="logo"
                  width="62"
                  style={{
                    margin: 'auto',
                    padding: 15,
                    marginTop: isMac ? -7.5 : 0,
                    justifyContent: 'center',
                  }}
                />
              )}
            </Fab>
            {loading && (
              <CircularProgress size={68} className={classes.fabProgress} />
            )}
          </div>
        </div>
      </Dialog>
    </div>
  )
}
export default { Header, Popup }
