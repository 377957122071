const isLetter = (data) => {
  const key = 'isLetter'
  const { value } = data
  const pattern = /^[a-zA-Z]+$/
  const isMatchOk = value.match(pattern) ? true : false
  const error = isMatchOk ? false : key
  return error ? { error, data } : false
}

export default isLetter
