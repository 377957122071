import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Dashboard'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    config: {
      api: {
        endpoints: {
          enquiry: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: null,
              moduleCode: setup.setup.thor.moduleCode[environment],
            },
            url: setup.setup.api.subcard.url[environment] + '/tx-sub/members',
            method: 'GET',
          },
        },
      },
    },
  },
  routes: {
    label: 'My Points',
    prefix: '/points',
  },
  setup,
}

export default config
