// import React from 'react';

import Config from './config'
import Routes from './routes'
import Links from './links'
import Launcher from './launcher'
import App from './app'

export default {
  Config,
  Launcher,
  App,
  Routes,
  Links,
}
