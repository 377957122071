import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_CAMPAIGN_RESPONSE: 'FETCH_CAMPAIGN_RESPONSE',
  RESET: 'RESET',
}

const fetchReset = async (state) => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}

const fetch = async (state, campaignresponse) => {
  state = {
    ...state,
    isStatus: campaignresponse.status,
    expireToken: sanitizeExpire({
      status: campaignresponse.status,
      data: campaignresponse.data,
      name: 'campaignresponseobj',
    }),
    data: sanitizeData({
      status: campaignresponse.status,
      data: campaignresponse.data,
      name: 'campaignresponseobj',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGN_RESPONSE:
      return fetch(state, action.campaignresponse)
    case actionTypes.RESET:
      return fetchReset(state)
    default:
      return state
  }
}

const index = {
  init,
  actionTypes,
  reducer,
}

export default index
