import React from 'react'

/* mui */
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
/* mui */

/* CONSTANTS */
const useStyles = makeStyles((theme) => ({
  title: {
    position: 'relative',
  },
}))
/* CONSTANTS */

const xl = (props) => {
  /* mui */
  const classes = useStyles()
  /* mui */

  return (
    <Grid
      className={classes.title}
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      {props.children}
    </Grid>
  )
}

export default xl
