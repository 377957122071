import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'registrations'
const config = {
  details: {
    project,

    environment,
  },
  routes: {
    label: 'Dashboard',
    prefix: '/dashboard',
  },
}

export default config
