import React, { useState, State, useEffect, Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
// import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import Recaptcha from 'react-recaptcha'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
/* packages */
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
//switches//
/* container */
import { GlobalContext } from '../../lib-atomic-context'
import Spinner2 from '../../app-module-cms-section-page-spinner/default'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import MaskedInput from 'react-text-mask'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
//dialogBox
import DialogBox from '../../lib-atomic/dialog/dialogbox'
import { Captcha, Inputs, Buttons, Invalid } from '../../lib-atomic'
import { FormHook } from '../../lib-atomic-functions' // LocalStorage
import Checkbox from '@material-ui/core/Checkbox'
import { green } from '@material-ui/core/colors'
import CONFIG from '../config'
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import { LocalStorage, SessionStorage } from '../../lib-atomic-functions'
import {
  ModSubWrapp,
  ModXlWrapp,
  ModLongWrapp,
  ModLongFillWrapp,
  ModContentText,
  ModFormLabelText,
} from '../../lib-atomic-design-section'
/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.account.objects

const UNVERIFIEDRESPONSE = CONFIG.application.account.responses.unverified

const ENDPOINTS = CONFIG.application.account.api.endpoints
const REGISTRATION = {
  url: ENDPOINTS.register.url,
  method: ENDPOINTS.register.method,
  headers: ENDPOINTS.register.headers,
  responseType: 'json',
}
/* CONSTANTS */
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    paddingTop: '10px',
    paddingBottom: '10px',
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

const FormControlLabelDetails = withStyles((theme) => ({
  root: {
    left: '100px',
    // position: "relative"
  },
  paper: {
    // padding: theme.spacing(3),
    textAlign: 'center',
    color: 'black',
    marginBottom: '50px',
  },
  inline: {
    display: 'flex',
  },
}))(FormControlLabel)

const useStyles = makeStyles((theme) => ({
  margin: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  paper: {
    // padding: theme.spacing(3), color: theme.palette.text.secondary,
    color: 'black',
    marginBottom: '50px',
  },
}))
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#008938',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        margin: classes.margin,
      }}
      {...props}
    />
  )
})

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      className="dob-input"
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholder="DD/MM/YYYY" // showMask
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}
const login = (props) => {
  const { valid, cmscountry } = useContext(GlobalContext)
  const { mastercountry } = cmscountry.state.data
  LOGINOBJECTS.mobprefix.options = LOGINOBJECTS.mobprefix.options.filter((i) =>
    mastercountry.some((i2) => i2.forRegistration && i2.abbr === i.abbr)
  )

  const errorPopup =
    LocalStorage.getLS().translation.json.errorresponse_formValidation
  const errorResponses =
    LocalStorage.getLS().translation.json.errorresponse_registration
  
  const { Tempvalues } = LocalStorage.getLS()
  const { translation } = LocalStorage.getLS()
  // const { token } = LocalStorage.getLS().login
  const mainCurrentlanguage = LocalStorage.getLS().translation.json
  const currentlanguage = LocalStorage.getLS().translation.json.registrationpage

  // const currentlanguage = LocalStorage.getLS().translation[0].json const {
  // token } = LocalStorage.getLS().login;
  /* state */
  const [loaded, setLoaded] = useState(false)
  const [verified, setVerified] = useState(false)
  const [visiblePW, setVisiblePW] = useState(false)
  const [visiblePWC, setVisiblePWC] = useState(false) // setVisiblePWC

  const [selectedAll, setSelectedAll] = React.useState(false)
  const [isAPP, setSelectedAPP] = React.useState(false)
  const [isEmail, setSelectedEmail] = React.useState(false)
  const [isSms, setSelectedSMS] = React.useState(false)

  const [isEvrything, setSelectedEvrything] = React.useState(false)
  const [isOffers, setSelectedOffers] = React.useState(false)
  const [isCampaign, setSelectedCampaign] = React.useState(false)
  const [isNews, setSelectedNews] = React.useState(false)
  const [tnc, setSelectedtnc] = React.useState(false)
  const [isAllow, setSelectedisAllow] = React.useState(true)
  const [response, setresponse] = React.useState()
  const [open, setOpen] = useState(false)
  const [isMobilenumber, setisMobilenumber] = useState(false)
  const [isPostcode, setisPostcode] = useState(false)
  const [redirect, setredirect] = useState(false)
  const [openpop, setOpenpop] = useState(false)
  const [exprired, setExpired] = useState(false)


  // if (tempValue.Tempvalues && Object.keys(tempValue.Tempvalues).length > 0) {
  //   LOGINOBJECTS.dob.initial = tempValue.Tempvalues.dob
  // }

  if (translation) {
   
    const mCode = translation.mastercountries.abbr
    
    if (mCode == 'de') {
      LOGINOBJECTS.mobprefix.initial = '+49'
    } else if (mCode == 'at') {
      LOGINOBJECTS.mobprefix.initial = '+43'
    } else if (mCode == 'fr') {
      LOGINOBJECTS.mobprefix.initial = '+33'
    } else if (mCode == 'gb') {
      LOGINOBJECTS.mobprefix.initial = '+44'
    } else if (mCode == 'ie') {
      LOGINOBJECTS.mobprefix.initial = '+353'
    } 
    // else if (mCode == 'ni') {
    //   LOGINOBJECTS.mobprefix.initial = '+44' //Nicaragua
    // } 
    else if (mCode == 'se') {
      LOGINOBJECTS.mobprefix.initial = '+46'
    } else if (mCode == 'fi') {
      LOGINOBJECTS.mobprefix.initial = '+358'
    }else {
      // netherlands
      LOGINOBJECTS.mobprefix.initial = '+31'
    }
  }

  const handleClose = (value) => {
    setOpenpop(false)
    // setresponse(false)
  }

  const callback = () => {
    console.log('loaded recaptcha...')
  }

  // specifying verify callback function
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true)
      setLoaded(true)
    }
  }
  const { register } = useContext(GlobalContext)
  const [registerData] = FetchContextApiV2(REGISTRATION)
  const registerationExecute = async ({ body }) => {
    register.dispatch({
      type: register.actionTypes.FETCH_REGISTER,
      register: await registerData(
        {
          body,
        },
        register
      ),
    })
  }
  const captchaData = {
    verified,
    verifyCallback,
    expiredCallback,
  }

  const expiredCallback = (response) => {
    setExpired(true)
  }

  useEffect(() => {
    if (register.state.data) {
      const { registerObject } = register.state.data

      if (registerObject.response) {
        setresponse(registerObject.response.data)
        setOpen(false)
      } else {
        LocalStorage.addLS('emailAddress', props.email)
        setresponse(registerObject)
        setOpen(false)
        setredirect(true)
        SessionStorage.addLS('Tempvalues', {})
        //props.history.push('/at/de/activation')
      }
      // if (registerObject.response.data.outComeCode != 0) {
      // console.log("outComeCode",registerObject.response.data.outComeCode);
      // console.log("outComeMessage",registerObject.response.data.outComeMessage);
      // console.log("outComeUserMessage",registerObject.response.data.outComeUserMessa
      // ge); setresponse(registerObject.response.data); setOpen(false); }
      console.log('registerobject', registerObject)
    }
  }, [register.state])

  const handleSubmit = async () => {
    setOpen(true)
    var App = 0
    var Sms = 0
    var Email = 0
    var Offers = 0
    var Campaign = 0
    var News = 0

    if (selectedAll == true) {
      App = 1
      Sms = 1
      Email = 1
    }
    if (isEvrything == true) {
      Offers = 1
      Campaign = 1
      News = 1
    }
    if (isEmail == true) {
      Email = 1
      Offers = 1
      Campaign = 1
      News = 1
    }
    if (isSms == true) {
      Sms = 1
      Offers = 1
      Campaign = 1
      News = 1
    }
    if (isAPP == true) {
      App = 1
      Offers = 1
      Campaign = 1
      News = 1
    }

    if (isOffers == true) {
      Offers = 1
    }
    if (isCampaign == true) {
      Campaign = 1
    }
    if (isNews == true) {
      News = 1
    }
    // if (isSms == true && values.mobile == '') {
    //   setOpen(false)
    //   setOpenpop(true)
    //   var message = {
    //     outComeCode: '0',
    //     title: '',
    //     outComeMessage: errorPopup.isMobile,
    //   }
    //   setresponse(message)
    // }
    // if (
    //   (isOffers == true || isCampaign == true || isNews == true) &&
    //   values.postcode == ''
    // ) {
    //   setOpen(false)
    //   setOpenpop(true)
    //   var message = {
    //     outComeCode: '0',
    //     title: '',
    //     outComeMessage: errorPopup.isPostcode,
    //   }
    //   setresponse(message)
    // }
    // else {
    //   if (values.mobile != '' && values.postcode != '') {
    //     setVerified(true)
    //   }
      setVerified(true)
      setLoaded(true)

      var body = {
        channelApp: App,
        channelSMS: Sms,
        channelEmail: Email,
        typeOffer: Offers,
        typeCompetitions: Campaign,
        typeNews: News,
        mobile: values.mobile,
        postcode: values.postcode,
        email: props.email,
        firstName: props.firstname,
        lastName: props.lastname,
        accessCode: props.accessCode,
        card: props.card,
        promoCode: props.promocode,
        countryId: props.countryId,
        birthDate: values.dob,
        password: props.password,
      }
      console.log(captchaData)
      if (captchaData.verified && tnc) {
        await registerationExecute({ body })
      } else {
        setOpen(false)
      }
    // }
  }
  const backStep = () => {
    var bakValue = 1
    props.back(bakValue)
  }

  const { values, onChange, onPick, onSubmit, error } = FormHook.useFormHook(
    handleSubmit,
    LOGINOBJECTS
  )
  const mobile = {
    ...LOGINOBJECTS.mobile,
    error: error.mobile,
    value: values.mobile,
    placeholder: currentlanguage.page_reg_mobile,
    onChange,
    translation: mainCurrentlanguage,
  }
  const mobprefix = {
    ...LOGINOBJECTS.mobprefix,
    error: error.mobprefix,
    value: values.mobprefix,
    onChange,
    translation: mainCurrentlanguage,
  }
  const postcode = {
    ...LOGINOBJECTS.postcode,
    error: error.postcode,
    value: values.postcode,
    placeholder: currentlanguage.page_reg_postcode_0,
    onChange,
    translation: mainCurrentlanguage,
  }
  const dob = {
    ...props,
    ...LOGINOBJECTS.dob,
    error: error.dob,
    value: values.dob,
    placeholder: currentlanguage.page_reg_dob_1,
    formatDisplay: 'dd/MM/yyyy',
    onPick,
    translation: mainCurrentlanguage,
  }

  const submitButton = {
    fullWidth: true,
    // onClick: handleSubmit.bind(this),
  }
  const back = {
    fullWidth: true,
    onClick: backStep.bind(this),
  }
  // const failLogin = { error: [LOGINFAILRESPONSE] }
  const verifyLogin = {
    error: [UNVERIFIEDRESPONSE],
  }

  const handleChangeterms = (event) => {
    setSelectedtnc(!tnc)
    setSelectedisAllow(!isAllow)
  }

  //SelectALL
  const handleAll = (event) => {
    setSelectedAll(!selectedAll)
    if (!selectedAll == true) {
      setSelectedEmail(true)
      setSelectedAPP(true)
      setSelectedSMS(true)
      setisMobilenumber(true)
      setSelectedOffers(true)
      setSelectedCampaign(true)
      setSelectedNews(true)
      setisPostcode(true)
    }
    if (!selectedAll == false) {
      setSelectedEmail(false)
      setSelectedAPP(false)
      setSelectedSMS(false)
      setisMobilenumber(false)
      setSelectedOffers(false)
      setSelectedCampaign(false)
      setSelectedNews(false)
      setisPostcode(false)
    }
  }
  //setSelectedEmail
  const handlEmail = (event) => {
    setSelectedEmail(!isEmail)
  }
  //setSelectedSMS
  const handleSMS = (event) => {
    setSelectedSMS(!isSms)

    setisMobilenumber(!isMobilenumber)
  }
  //setSelectedAPP
  const handleApp = (event) => {
    setSelectedAPP(!isAPP)
  }

  //isEvrything
  const handleEvrything = (event) => {
    setSelectedEvrything(!isEvrything)
    if (!isEvrything == true) {
      setSelectedOffers(true)
      setSelectedCampaign(true)
      setSelectedNews(true)
      setisPostcode(true)
    }
    if (!isEvrything == false) {
      setSelectedOffers(false)
      setSelectedCampaign(false)
      setSelectedNews(false)
      setisPostcode(false)
    }
  }
  //setSelectedEmail
  const handlisOffers = (event) => {
    setSelectedOffers(!isOffers)
    if (!isOffers == true || isCampaign == true || isNews == true) {
      setisPostcode(true)
    } else {
      setisPostcode(false)
    }
  }

  //setSelectedAPP
  const handleisCampaign = (event) => {
    setSelectedCampaign(!isCampaign)
    if (isOffers == true || !isCampaign == true || isNews == true) {
      setisPostcode(true)
    } else {
      setisPostcode(false)
    }
  }
  const handleisNews = (event) => {
    setSelectedNews(!isNews)
    if (isOffers == true || isCampaign == true || !isNews == true) {
      setisPostcode(true)
    } else {
      setisPostcode(false)
    }
  }

  if (window.screen.width < 900) {
  }

  const classes = useStyles()

  if (redirect) {
    return <Redirect to="activation" />
  } else {
    return (
      <form onSubmit={onSubmit} noValidate>
        {open && <Spinner2.Popup selectedValue={register.state.isLoading} />}
        {response && (
          <DialogBox
            selectedValue={response}
            open={openpop}
            onClose={handleClose}
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} spacing={3}>
            <Typography
              variant="h5"
              className={classes.paper}
              style={{
                marginBottom: '20px',
              }}
            >
              <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                {currentlanguage.page_reg_gdpr_title_1}
              </p>
              <p
                style={{
                  fontFamily: 'SubwayFootlong',
                  fontSize: 18,
                  margin: 0,
                  marginTop: 10,
                  minHeight: 50,
                }}
              >
                {currentlanguage.page_reg_gdpr_sub_title_1}
              </p>
            </Typography>

            <div
              style={{
                display: 'flex',
                width:'50%'
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2">
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_reg_gdpr_select_all}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch checked={selectedAll} onChange={handleAll} />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                width:'50%'
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_reg_gdpr_email}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch checked={isEmail} onChange={handlEmail} />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width:'50%'
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_reg_gdpr_app_notif}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={<IOSSwitch checked={isAPP} onChange={handleApp} />}
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width:'50%'
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_reg_gdpr_sms}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={<IOSSwitch checked={isSms} onChange={handleSMS} />}
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>
            
            {isMobilenumber && (
              <div
                style={{
                  display: 'flex',
                width:'50%'
                }}
              >
                <div
                  style={{
                    display: 'inline-block',
                    float: 'left',
                    width: '100%',
                  }}
                >
                  <Typography variant="body2" className={classes.text}>
                    <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                      {currentlanguage.page_reg_mobile}
                    </p>
                  </Typography>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Inputs.Mobileselector {...mobprefix} />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Inputs.Text
                        {...mobile}
                        inputProps={{
                          maxLength: 10,
                          minLength: 9,
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {(isEmail || isSms || isAPP) && (
              <div
                style={{
                  display: 'flex',
                  width:'50%'
                }}
              >
                <div
                  style={{
                    display: 'inline-block',
                    float: 'left',
                    width: '100%',
                  }}
                >
                  <Typography variant="body2" className={classes.text}>
                    <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                      {currentlanguage.page_pref_postcode}
                    </p>
                    <Inputs.Text {...postcode} />
                  </Typography>
                </div>
              </div>
            )}

            {
            /* <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_reg_gdpr_email}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch checked={isEmail} onChange={handlEmail} />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_reg_gdpr_app_notif}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={<IOSSwitch checked={isAPP} onChange={handleApp} />}
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_reg_gdpr_sms}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={<IOSSwitch checked={isSms} onChange={handleSMS} />}
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            {isMobilenumber && (
              <div
                style={{
                  display: 'flex',
                  margin: 'auto',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'inline-block',
                    float: 'left',
                    width: '100%',
                  }}
                >
                  <Typography variant="body2" className={classes.text}>
                    <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                      {currentlanguage.page_reg_mobile}
                    </p>
                  </Typography>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Inputs.Mobileselector {...mobprefix} />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Inputs.Text
                        {...mobile}
                        inputProps={{
                          maxLength: 10,
                          minLength: 9,
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={6} spacing={3}>
            <Typography
              variant="h5"
              className={classes.paper}
              style={{
                marginBottom: '20px',
              }}
            >
              <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>{' '}
              <p
                style={{
                  fontFamily: 'SubwayFootlong',
                  margin: 0,
                  marginTop: 10,
                  fontSize: 18,
                  minHeight: 50,
                }}
              >
                {currentlanguage.page_reg_gdpr_title_2}
              </p>
            </Typography>
            <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  alignSelf: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_reg_gdpr_tell_me_everything}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch
                      checked={isEvrything}
                      onChange={handleEvrything}
                    />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_reg_gdpr_tell_me_special}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch checked={isOffers} onChange={handlisOffers} />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_reg_gdpr_tell_me_competition}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch
                      checked={isCampaign}
                      onChange={handleisCampaign}
                    />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_reg_gdpr_tell_me_news}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch checked={isNews} onChange={handleisNews} />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div> */}
           
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6} spacing={3}>
          <div
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              marginLeft: '15px',
            }}
          >
            <FormControlLabel
              control={
                <GreenCheckbox checked={tnc} onChange={handleChangeterms} />
              }
              label={
                <Typography variant="body2" className={classes.text}>
                  <strong>
                    <span
                      style={{
                        fontFamily: 'SubwayFootlong',
                        margin: 0,
                      }}
                    >
                      {' '}
                      <p
                        style={{
                          fontFamily: 'SubwayFootlong',
                          margin: 0,
                        }}
                      >
                        {currentlanguage.page_reg_gdpr_agree_wording}
                      </p>
                      <a
                        style={{
                          textDecoration: 'underline',
                          fontFamily: 'SubwayFootlong',
                        }}
                        href="terms"
                      >
                        {' '}
                        {currentlanguage.page_reg_gdpr_agree_link_1}{' '}
                      </a>
                      {currentlanguage.page_reg_gdpr_agree_wording_2}
                      <a
                        style={{
                          textDecoration: 'underline',
                          fontFamily: 'SubwayFootlong',
                        }}
                        href="privacynotice"
                      >
                        {' '}
                        {currentlanguage.page_reg_gdpr_agree_link_2}{' '}
                      </a>{' '}
                      {currentlanguage.page_reg_gdpr_agree_wording_3}
                    </span>
                  </strong>
                </Typography>
              }
            />
          </div>
        </Grid>
        <div>
          <Captcha.Basic {...captchaData} alignment="left" />
        </div>

        <br />
        <Grid container item xs={12} sm={6} spacing={3}>
          <Grid item xs={12} sm={5}>
            <div>
              <Buttons.Normal {...back}>
                {currentlanguage.page_reg_back_button}
              </Buttons.Normal>
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <div>
              <Buttons.Normal {...submitButton} disabled={isAllow}>
                {currentlanguage.page_reg_create_account_button}
              </Buttons.Normal>
            </div>
          </Grid>
        </Grid>
      </form>
    )
  }
}

export default login
