import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_BOXES: 'FETCH_BOXES',
}

const fetchBoxes = async (state, boxes) => {
  state = {
    ...state,
    isStatus: boxes.status,
    expireToken: sanitizeExpire({
      status: boxes.status,
      data: boxes.data,
      name: 'masterboxes',
    }),
    data: sanitizeData({
      status: boxes.status,
      data: boxes.data,
      name: 'masterboxes',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BOXES:
      return fetchBoxes(state, action.boxes)
    default:
      return state
  }
}

const boxes = {
  init,
  actionTypes,
  reducer,
}

export default boxes
