import React, { useContext, forwardRef, useMemo } from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { LocalStorage } from '../../../components/lib-atomic-functions'
import Privilege from '../../../components/app-conf-privilege'

const useStyles = makeStyles(() => ({
  nav: {
    width: '300px',
  },
  dividerColor: {
    backgroundColor: 'rgba(200, 200, 200, 0.3)',
  },
  menuItem: {
    textAlign: 'center',
  },
}))

const DrawerItems = (props) => {
  const classes = useStyles()
  const { country, lang, flOldUser } = LocalStorage.getLS()
  const currentlanguage = LocalStorage.getLS().translation.json
  const token = LocalStorage.getLS()
    ? LocalStorage.getLS().login
      ? LocalStorage.getLS().login.token
        ? LocalStorage.getLS().login.token
        : false
      : false
    : false
  const { rolesSubModules, rolesHandler } =
    Privilege[process.env.REACT_APP_CLIENT]
  const availableSubModules = rolesHandler(LocalStorage, rolesSubModules)

  const items = [
    token
      ? null
      : {
          text: currentlanguage.moremenu_website_page
            .moremenu_website_prelogin_home,
          url: 'home',
          name: 'home',
        },
    token && flOldUser == 0
      ? {
          text: currentlanguage.moremenu_website_page.moremenu_website_home,
          url: 'dashboard',
          name: 'dashboard',
        }
      : null,
    token && flOldUser == 0
      ? {
          text: currentlanguage.moremenu_website_page.moremenu_website_profile,
          url: 'profile',
          name: 'profile',
        }
      : null,
    token
      ? {
          text: currentlanguage.moremenu_website_page
            .moremenu_website_preference,
          url: 'preferences',
          name: 'preferences',
        }
      : null,
    token && country != 'fr' && flOldUser == 0
      ? {
          text: currentlanguage.moremenu_website_page.moremenu_website_lostcard,
          url: 'lostcard',
          name: 'lostcard',
        }
      : null,
    // country == 'gb' || (country == 'ie' && flOldUser == 0)
    //   ? {
    //       text: 'Order Ahead',
    //       url: 'order',
    //       name: 'order',
    //     }
    //   : null,

    {
      text: currentlanguage.moremenu_website_page.moremenu_website_faq,
      url: 'faq',
      name: 'faq',
    },
    {
      text: currentlanguage.moremenu_website_page.moremenu_website_contact,
      url:
        currentlanguage.moremenu_website_page.moremenu_website_contact_link &&
        currentlanguage.moremenu_website_page.moremenu_website_contact_link
          .length !== 0
          ? currentlanguage.moremenu_website_page.moremenu_website_contact_link
          : 'contactus',
      isExternal:
        currentlanguage.moremenu_website_page.moremenu_website_contact_link &&
        currentlanguage.moremenu_website_page.moremenu_website_contact_link
          .length !== 0
          ? true
          : false,
      name: 'contactus',
    },
    {
      text: currentlanguage.moremenu_website_page.moremenu_website_tns,
      url: 'terms',
      name: 'terms',
    },
    {
      text: currentlanguage.moremenu_website_page.moremenu_website_subway_com,
      url:
        currentlanguage.moremenu_website_page
          .moremenu_website_subway_com_link &&
        currentlanguage.moremenu_website_page.moremenu_website_subway_com_link
          .length !== 0
          ? currentlanguage.moremenu_website_page
              .moremenu_website_subway_com_link
          : '#',
      isExternal: true,
      name: 'displaysubwaylink',
    },
  ]

  const filteredItems = items.filter((i) => {
    const matchFoundFromSubModules = Object.keys(availableSubModules).some(
      (i2) => {
        return i && i2.toString() === i.name
      }
    )
    return (i && i.name === 'displaysubwaylink') || matchFoundFromSubModules
  })
  
  return (
    <List className={classes.nav}>
      {filteredItems.map((item, index) =>
        item ? (
          <div key={index}>
            <ListItemLink
              exact
              to={item.isExternal ? item.url : `/${item.url}`}
              primary={
                <Typography
                  className="subway-footlong"
                  style={{ textAlign: 'center' }}
                >
                  {item.text}
                </Typography>
              }
              activeClassName="Mui-selected"
              isExternal={item.isExternal}
            />

            <Divider
              variant="middle"
              classes={{ root: classes.dividerColor }}
            />
          </div>
        ) : null
      )}
    </List>
  )
}

function ListItemLink(props) {
  const { isExternal, primary, to, handleClose } = props

  const renderLink = useMemo(
    () =>
      forwardRef((itemProps, ref) =>
        isExternal ? (
          <a
            className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
            tabIndex="0"
            role="button"
            aria-disabled="false"
            href={to}
            target="_blank"
            rel="noopener noreferrer"
            ref={ref}
          >
            <div className="MuiListItemText-root">
              <div className="MuiTypography-root MuiTypography-body1">
                {primary}
              </div>
            </div>
            <span className="MuiTouchRipple-root"></span>
          </a>
        ) : (
          <NavLink to={to} {...itemProps} />
        )
      ),
    [to]
  )

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        onClick={() => {
          handleClose()
        }}
      >
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  )
}

export default withRouter(DrawerItems)
