const useFetch = async ({ url, config }) => {
  try {
    const response = await fetch(url, config)
    const data = await response.json()
    //if (response.status !== 200) throw data;
    if (!response.ok) throw data
    return [true, data]
  } catch (e) {
    return [false, e]
  }
}

export default useFetch
