import React from 'react'

/* packages */
import Privilege from '../app-conf-privilege'
import { LocalStorage } from '../lib-atomic-functions'
/* packages */

const Links = props => {
  const { rolesHandler, rolesModules } = Privilege[process.env.REACT_APP_CLIENT]
  const availableModules = rolesHandler(LocalStorage, rolesModules)
  return availableModules.map((Module, i) => {
    if (!Module.Links) return null
    return <Module.Links {...props} key={i} />
  })
}

export default Links
