import React, { useEffect } from 'react'
/* package */
import { GlobalContext } from '../../lib-atomic-context'
/* package */

import ScrollToTop from '../../../scrollToTop'
/* container */
import HistoryContainer from '../../app-module-my-history-container/default'
/* container */

/* modules */
import {
  WindowSize,
  WindowLayout,
  WindowFont,
} from '../../lib-atomic-functions'
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
/* modules */
import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'

import List from '../list/list'
/* lazy modules */

const index = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  useEffect(() => {
    // GArecordPageView(cookies)
  }, [])
  const PLATFORM = 'web'
  const { width, height } = WindowSize()
  const {
    layoutWidth,
    layoutHeight,
    layoutCols,
    getColsWidthHeight,
    getFullWidth,
  } = WindowLayout(width, height)
  const fullWidth = width < 500 ? (width * 90) / 100 : getFullWidth(PLATFORM)
  return (
    <HistoryContainer {...props}>
      <ScrollToTop {...props} />
      <div
        style={{
          width: fullWidth,
          margin: 'auto',
          marginTop: 2.5,
        }}
      >
        <List {...props} />
      </div>
    </HistoryContainer>
  )
}

export default index
