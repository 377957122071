import roleATPublic from './at/rolePublic'
import roleATPrivate from './at/rolePrivate'
import roleATPrivateOldUser from './at/rolePrivateOldUser'

import roleDEPublic from './de/rolePublic'
import roleDEPrivate from './de/rolePrivate'
import roleDEPrivateOldUser from './de/rolePrivateOldUser'

import roleNLPublic from './nl/rolePublic'
import roleNLPrivate from './nl/rolePrivate'
import roleNLPrivateOldUser from './nl/rolePrivateOldUser'

import roleFRPublic from './fr/rolePublic'
import roleFRPrivate from './fr/rolePrivate'
import roleFRPrivateOldUser from './fr/rolePrivateOldUser'

import roleGBPublic from './gb/rolePublic'
import roleGBPrivate from './gb/rolePrivate'
import roleGBPrivateOldUser from './gb/rolePrivateOldUser'

import roleIEPublic from './ie/rolePublic'
import roleIEPrivate from './ie/rolePrivate'
import roleIEPrivateOldUser from './ie/rolePrivateOldUser'

import roleSEPublic from './se/rolePublic'
import roleSEPrivate from './se/rolePrivate'
import roleSEPrivateOldUser from './se/rolePrivateOldUser'

import roleFIPublic from './fi/rolePublic'
import roleFIPrivate from './fi/rolePrivate'
import roleFIPrivateOldUser from './fi/rolePrivateOldUser'

export default [
  roleATPublic,
  roleATPrivate,
  roleATPrivateOldUser,

  roleDEPublic,
  roleDEPrivate,
  roleDEPrivateOldUser,

  roleNLPublic,
  roleNLPrivate,
  roleNLPrivateOldUser,

  roleFRPublic,
  roleFRPrivate,
  roleFRPrivateOldUser,

  roleGBPublic,
  roleGBPrivate,
  roleGBPrivateOldUser,

  roleIEPublic,
  roleIEPrivate,
  roleIEPrivateOldUser,

  roleSEPublic,
  roleSEPrivate,
  roleSEPrivateOldUser,

  roleFIPublic,
  roleFIPrivate,
  roleFIPrivateOldUser,
]
