const isPasswordlowerC = data => {

  let value = data.value
  const key = 'isPasswordlowerC'
  value = value.trim()

  const pattern = /(?=.*?[a-z])/
  const isTestOk = pattern.test(value) ? true : false
  const error = isTestOk ? false : key
  return error ? { error, data } : false
}

export default isPasswordlowerC
