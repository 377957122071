import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
/* package */
import { ModPublicWrapp } from '../../lib-atomic-design-section'
import { GlobalContext } from '../../lib-atomic-context'
import { LocalStorage } from '../../lib-atomic-functions'

/* package */
import { useCookies } from 'react-cookie'
/* container */
import UnsubscribeContainer from '../../app-module-unsubscribe-container/default'
/* container */

/* form */
import UnsubscribeForm from '../form/unsubscribe'
/* form */

import CONFIG from '../config'

/* CONSTANTS */
const mediaPath = CONFIG.setup.assets.media
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'space-between',
    // overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    paddingTop: 80,
    paddingBottom: 80,
    maxWidth: 380,
    // minWidth: 380,
    // maxWidth: 580,
    // minWidth: 320,
    margin: 'auto',
    marginTop: 0,
  },
  gridList: {
    width: 800,
  },
}))
const index = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies(['fromlogin'])
  // console.log(cookies)
  /* state */
  const classes = useStyles()
  const { bannerscontents } = useContext(GlobalContext)
  const imgToDisplay = bannerscontents.state.data.masterbannerscontents.filter(
    (page) => {
      return page.pageName === 'unsubscribe'
    }
  )[0]
  /* state */
  return (
    <UnsubscribeContainer {...props}>
      <ModPublicWrapp {...props} imgToDisplay={imgToDisplay}>
        <div className={classes.root}>
          <UnsubscribeForm {...props} />
        </div>
      </ModPublicWrapp>
    </UnsubscribeContainer>
  )
}

export default index
