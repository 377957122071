import React from 'react'

import Button from '@material-ui/core/Button'

/*
: properties
children
size
fullWidth
*/

const faq = (props) => {
  return (
    <Button
      {...props}
      className="subway-footlong"
      style={{ border: '2px solid #099144', fontSize: 'large' }}
      variant="contained"
      color="primary"
      type="submit"
    >
      {props.children}
    </Button>
  )
}

export default faq
