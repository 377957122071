import React from 'react'

/* mui */
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  quoted: {
    backgroundColor: '#039be5',
    color: '#fafafa',
  },
  approved: {
    backgroundColor: '#fbc02d',
    color: '#fafafa',
  },
  declined: {
    backgroundColor: '#e53935',
    color: '#fafafa',
  },
  confirmed: {
    backgroundColor: '#43a047',
    color: '#fafafa',
  },
  other: {
    backgroundColor: '#757575',
    color: '#fafafa',
  },
}))
/* CONSTANTS */

const wrapper = ({ orderStatusName }) => {
  /* mui */
  const classes = useStyles()
  /* mui */

  let style = [classes.chip]
  switch (orderStatusName) {
    case 'Quoted':
      style.push(classes.quoted)
      break
    case 'Approved':
      style.push(classes.approved)
      break
    case 'Declined':
      style.push(classes.declined)
      break
    case 'Payment Confirmed':
      style.push(classes.confirmed)
      break
    default:
      style.push(classes.other)
  }

  return <Chip size="small" label={orderStatusName} className={style} />
}

export default wrapper
