import React from 'react';
import '../styles.css';

const ErrorOverlay = (props) => {
  return (
  <>
    {props.outcomeCode !== "0" ? 
      <div className="invalidBackground">
        {props.outcomeCode === "GE_MAIN:VALIDATE_TOKEN:4" || props.outcomeCode === "GE_MAIN:VALIDATE_TOKEN:8" ?
        // If promotion has ended
        <div dangerouslySetInnerHTML={{__html: props.errPromoend}}></div>
        :
        // If token is invalid or already used
        <div dangerouslySetInnerHTML={{__html: props.errSpun}}></div>
        }
      </div>
      :
      ""
      }
  </>
  );
};

export default ErrorOverlay;
