import React, { lazy, Suspense, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

/* container */
import { GlobalProvider } from '../../lib-atomic-context'
import HomeContainer from '../../app-module-home-container/default'
import CMSContainer from '../../app-module-cms-container/default'
import {
  LocalStorageV2,
  LocalStorage,
  WindowSize,
} from '../../lib-atomic-functions'
/* container */

/* modules */
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
import Collapse from '@material-ui/core/Collapse'
import Grow from '@material-ui/core/Grow'
import Slide from '@material-ui/core/Slide'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

/* modules */

/* Icon */
import CloseIcon from '@material-ui/icons/Close'
import { Buttons } from '../../lib-atomic'

import CONFIG from '../config'

const logo = CONFIG.setup.assets.logo_v2

/* lazy modules */
const CMSLayout = lazy(() =>
  import('../../app-module-cms-section-layout/default')
)
/* lazy modules */

/* constant */
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  paper: {
    // display: 'relative',
    width: '100%',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: 'auto',
    textAlign: 'left',
    overflow: 'scroll',
  },
}))
/* constant */

const index = (props) => {
  const currentlanguage = LocalStorage.getLS().translation.json
  const { country, lang } = LocalStorage.getLS()
  const classes = useStyles()

  const [visibleModalContent, setVisibleModalContent] = useState(false)
  const [displayContent, setDisplayContent] = useState(true)
  const [transition, setTransition] = useState(false)

  const { width, height } = WindowSize()

  const popupModal = () => {
    setVisibleModalContent(!visibleModalContent)
  }

  return (
    <Slide direction="up" in={displayContent} mountOnEnter unmountOnExit>
      {/* <Grow in={displayContent} style={{ transformOrigin: '0 0 0' }}> */}
      <div
        style={{
          flex: 1,
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#fff',
          color: '#939594',
          padding: 'auto',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 10,
          paddingBottom: 20,
          margin: 'auto',
          marginTop: 10,
          //   display: displayContent ? 'block' : 'none',
        }}
      >
        <div
          style={{
            marginTop: 10,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* header */}
          <div style={{ width: '60%' }}>
            By clicking “Accept All Cookies”, you agree to the storing of
            cookies on your device to enhance site navigation, analyze site
            usage, and assist in our marketing efforts.
          </div>
          {/* contents */}
          <div
            style={{
              display: width < 750 ? 'block' : 'flex',
              alignItems: 'center',
              margin: 'auto',
              marginLeft: '5%',
              width: '35%',
              justifyContent: 'flex-end',
              // textAlign: width < 750 ? 'left' : 'right',
              alignSelf: 'right',
            }}
          >
            <p
              className="cookies-settings"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                // marginRight: 15,
                color: '#038d43',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                popupModal()
              }}
            >
              {currentlanguage.cookies.cookies_text_settings_btn}
            </p>
            <div style={{ margin: 5, marginTop: 7 }} />
            <Buttons.Normal
              style={{ justifyContent: 'center', margin: 'auto' }}
              onClick={() => {
                // LocalStorage.addLS('pinnedMessage', { isFirstVisit: false })
                setDisplayContent(false)
              }}
            >
              Reject All
            </Buttons.Normal>
            <div style={{ margin: 5 }} />
            <Buttons.Normal
              style={{ justifyContent: 'center' }}
              onClick={() => {
                // LocalStorage.addLS('pinnedMessage', { isFirstVisit: false })
                setDisplayContent(false)
              }}
            >
              Accept Cookies
            </Buttons.Normal>
          </div>
        </div>
        {/* modal */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={visibleModalContent}
          onClose={popupModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 700,
          }}
        >
          <Fade in={visibleModalContent}>
            <div className={classes.paper}>
              {/* <div
                style={{
                  textAlign: 'right',
                  alignItems: 'flex-end',
                  position: 'absolute',
                  top: '15%',
                  // left: 0,
                  right: 10,
                }}
              >
                <CloseIcon
                  color="#4a4a4a"
                  onClick={() => {
                    LocalStorage.addLS('pinnedMessage', { isFirstVisit: false })
                    setDisplayContent(false)
                  }}
                />
              </div> */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <img
                    src={logo}
                    style={{
                      width: '40%',
                      paddingBottom: 20,
                      paddingRight: 10,
                    }}
                    alt="logo"
                  />
                </div>
                <div
                  style={{
                    position: 'fixed',
                    // width: '55%',
                    width: '55%',
                    minWidth: 300,
                    maxWidth: 500,
                    textAlign: 'right',
                    paddingRight: 40,
                    cursor: 'pointer',
                  }}
                >
                  <CloseIcon
                    color="white"
                    onClick={() => {
                      popupModal()
                    }}
                  />
                </div>
              </div>
              <p
                style={{
                  color: '#696969',
                  fontSize: 20,
                  textAlign: 'left',
                  fontWeight: 800,
                  marginBottom: 10,
                }}
              >
                {currentlanguage.cookies.cookies_text_preference_title}
              </p>
              <p style={{ color: '#696969', fontSize: 11, lineHeight: 1.4 }}>
                {currentlanguage.cookies.cookies_text_preference_body}{' '}
                <span
                  style={{
                    color: '#696969',
                    fontSize: 11,
                    textDecoration: 'underline',
                    lineHeight: 1.4,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    props.history.push('/cookies')
                  }}
                >
                  More information
                </span>
              </p>
              <div
                style={{
                  width: 150,
                  // margin: 'auto',
                  marginBottom: 30,
                }}
                onClick={() => {
                  // props.history.push('/web/' + country + '/' + lang + '/register')
                }}
              >
                <Buttons.Normal
                  onClick={() => {
                    popupModal()
                    // LocalStorage.addLS('pinnedMessage', { isFirstVisit: false })
                    setDisplayContent(false)
                  }}
                >
                  {currentlanguage.cookies.cookies_text_preference_allow_all}
                </Buttons.Normal>
              </div>
              <p
                style={{
                  color: '#696969',
                  fontSize: 20,
                  textAlign: 'left',
                  fontWeight: 800,
                  marginBottom: 10,
                }}
              >
                {currentlanguage.cookies.cookies_text_preference_manage}
              </p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p
                  style={{
                    color: '#696969',
                    fontSize: 14,
                    textAlign: 'left',
                    fontWeight: 800,
                    marginBottom: 10,
                  }}
                >
                  {
                    currentlanguage.cookies
                      .cookies_text_preference_necessary_title
                  }
                </p>
                <p
                  style={{
                    color: '#417AE2',
                    fontSize: 14,
                    textAlign: 'left',
                    fontWeight: 800,
                    marginBottom: 10,
                  }}
                >
                  {
                    currentlanguage.cookies
                      .cookies_text_preference_always_active
                  }
                </p>
              </div>
              <p style={{ color: '#696969', fontSize: 11, lineHeight: 1.4 }}>
                {currentlanguage.cookies.cookies_text_preference_necessary_body}
              </p>

              <p
                style={{
                  color: '#696969',
                  fontSize: 14,
                  textAlign: 'left',
                  fontWeight: 800,
                  marginBottom: 10,
                }}
              >
                {
                  currentlanguage.cookies
                    .cookies_text_preference_performance_title
                }
              </p>
              <p style={{ color: '#696969', fontSize: 11, lineHeight: 1.4 }}>
                {
                  currentlanguage.cookies
                    .cookies_text_preference_performanc_body
                }
              </p>

              <p
                style={{
                  color: '#696969',
                  fontSize: 14,
                  textAlign: 'left',
                  fontWeight: 800,
                  marginBottom: 10,
                }}
              >
                {
                  currentlanguage.cookies
                    .cookies_text_preference_functional_title
                }
              </p>
              <p style={{ color: '#696969', fontSize: 11, lineHeight: 1.4 }}>
                {
                  currentlanguage.cookies
                    .cookies_text_preference_functional_body
                }
              </p>

              <p
                style={{
                  color: '#696969',
                  fontSize: 14,
                  textAlign: 'left',
                  fontWeight: 800,
                  marginBottom: 10,
                }}
              >
                {
                  currentlanguage.cookies
                    .cookies_text_preference_targeting_title
                }
              </p>
              <p style={{ color: '#696969', fontSize: 11, lineHeight: 1.4 }}>
                {currentlanguage.cookies.cookies_text_preference_targeting_body}
              </p>
            </div>
          </Fade>
        </Modal>
      </div>
    </Slide>
  )
}

export default index
