const isPasswordNumber = data => {

  let value = data.value
  const key = 'isPasswordNumber'
  value = value.trim()
  const minLimit = 3
  const pattern = /(\d{1,3})?$/
  const isTestOk = pattern.test(value) ? true : false
  const error = isTestOk ? false : key
  return error ? { error, data } : false
}

export default isPasswordNumber
