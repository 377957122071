// public
import home from '../../../../app-module-home-container'
import login from '../../../../app-module-login-container'
import activation from '../../../../app-module-activation-container'
import verification from '../../../../app-module-verification-container'
import register from '../../../../app-module-register-container'
import forgot_password from '../../../../app-module-forgot-password-container'
import reset_password from '../../../../app-module-reset-password-container'
import promotions from '../../../../app-module-promotions-container'
import faq from '../../../../app-module-faq-container'
import closure_faq from '../../../../app-module-faq-container-closure-faq'
import subsquadfaq from '../../../../app-module-subsquadfaq-container'
import contactus from '../../../../app-module-contactus-container'
import terms from '../../../../app-module-terms-container'
import privacynotice from '../../../../app-module-privacynotice-container'
import cookies from '../../../../app-module-cookies-container'
import logout from '../../../../app-module-logout-container'
import impressum from '../../../../app-module-impressum-container'
import subsquad from '../../../../app-module-subsquad-container'
import subrewards from '../../../../app-module-subrewards-container'
import unsubscribe from '../../../../app-module-unsubscribe-container'
import campaign from '../../../../app-module-campaign-container'

const Modules = {
  // public //
  home,
  login,
  activation,
  verification,
  register,
  forgot_password,
  reset_password,
  logout,
  impressum,
  promotions,
  closure_faq,
  faq,
  subsquadfaq,
  contactus,
  terms,
  privacynotice,
  cookies,
  subsquad,
  subrewards,
  unsubscribe,
  campaign,
}
export default Modules
