import React, { useContext, useState, Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'
import moment from 'moment'

/* packages */
import { Lists } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApiV2,
  LocalStorage,
  NumberCurrency,
  WindowSize,
} from '../../lib-atomic-functions'
import {
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleTitle,
  ModuleText,
  ModuleSubTitle,
} from '../../lib-atomic-design-section'
// import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

/* stores */
// import { AuthLogin } from "../../app-module-admin-container/stores";
/* stores */

import CONFIG from '../config'

const logo = CONFIG.setup.assets.logo_green

const transactionList = (props) => {
  const calcTotal = (products) => {
    var total = 0
    products.map((item) => {
      total = total + item.value * item.quantity
    })
    return total
  }
  const currentlanguage = LocalStorage.getLS().translation.json
  const { id } = props.match.params
  const { width, height } = WindowSize()
  const { country, lang } = LocalStorage.getLS()
  const { transactiondetail } = useContext(GlobalContext)
  // const { token } = LocalStorage.getLS().login;
  const { transactiondetailobj } = transactiondetail.state.data
  /* state */

  return (
    <div
      style={{
        marginTop: 90,
        marginLeft: 10,
        marginRight: 10,
        width: '100%',
        height: '100%',
        paddingTop: 20,
        paddingBottom: 0,
        paddingLeft: 26,
        paddingRight: 26,
        borderStyle: 'solid',
        borderColor: '#939594',
        borderWidth: 1,
        borderRadius: 20,
        backgroundColor: '#f0f6f1',
        fontFamily: 'SubwaySixInch',
      }}
    >
      <img src={logo} style={{ width: '60%', paddingBottom: 20 }} alt="logo" />
      <p
        style={{
          fontWeight: 700,
          fontSize: 12,
          lineHeight: 1,
          paddingBottom: 0,
        }}
      >
        Subway #{id}
      </p>
      <p
        style={{
          fontWeight: 700,
          fontSize: 12,
          lineHeight: 1,
          flexWrap: 'wrap',
          marginTop: -10,
          width: '90%',
        }}
      >
        {transactiondetailobj.merchantAddress}
      </p>
      <p
        style={{
          fontWeight: 500,
          fontSize: 12,
          lineHeight: 1,
          flexWrap: 'wrap',
          width: '90%',
          marginBottom: 40,
        }}
      >
        {currentlanguage.transactionhistorypage.page_history_served_by}{' '}
        {!transactiondetailobj.cashier
          ? 'N/A'
          : transactiondetailobj.cashier.toString().length === 3
          ? ('0000' + transactiondetailobj.cashier).slice(-3)
          : transactiondetailobj.cashier.toString().length <= 2
          ? ('000' + transactiondetailobj.cashier).slice(-2)
          : transactiondetailobj.cashier}{' '}
        &nbsp;&nbsp;
        {moment(transactiondetailobj.transactionDate).format('D/M/YYYY h:MM A')}
      </p>

      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
          }}
        >
          <p style={{ color: '#939594', fontSize: 12, width: '50%' }}>
            {currentlanguage.transactionhistorypage.page_history_item}{' '}
          </p>
          <p style={{ color: '#939594', fontSize: 12, width: '30%' }}>
            {currentlanguage.transactionhistorypage.page_history_qty}{' '}
          </p>
          <p
            style={{
              color: '#939594',
              fontSize: 12,
              width: '20%',
              textAlign: 'right',
              paddingRight: 10,
            }}
          >
            {currentlanguage.transactionhistorypage.page_history_price}{' '}
          </p>
        </div>
        <div
          style={{
            backgroundColor: '#939594',
            height: 1,
            marginTop: -5,
            marginBottom: 0,
          }}
        />
        {transactiondetailobj.products.map((item) => {
          return (
            <div style={{ display: 'flex', width: '100%' }}>
              <p style={{ fontSize: 12, fontWeight: 600, width: '50%' }}>
                {item.name}
              </p>
              <p style={{ fontSize: 12, fontWeight: 600, width: '30%' }}>
                {item.quantity}
              </p>
              <p
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  width: '20%',
                  textAlign: 'right',
                  paddingRight: 10,
                }}
              >
                {parseFloat(item.value, 2).toFixed(2)}
              </p>
            </div>
          )
        })}

        <div
          style={{
            backgroundColor: '#939594',
            height: 1,
          }}
        />
        <div
          style={{
            display: 'flex',
            width: '100%',
            fontWeight: '800',
            fontSize: 12,
            paddingTop: 10,
          }}
        >
          <p style={{ width: '80%' }}>
            {currentlanguage.transactionhistorypage.page_history_total} Total
          </p>
          <p style={{ width: '20%', textAlign: 'right', paddingRight: 10 }}>
            {calcTotal(transactiondetailobj.products).toFixed(2)}
          </p>
        </div>

        <div
          style={{
            backgroundColor: '#939594',
            height: 1,
          }}
        />

        {/*  */}
        {transactiondetailobj.campaigns.length > 0 ? (
          <div>
            <p style={{ color: '#939594', fontSize: 12, paddingTop: 20 }}>
              {currentlanguage.transactionhistorypage.page_history_earned}
            </p>
            <div
              style={{
                backgroundColor: '#939594',
                height: 0,
                marginTop: -5,
                marginBottom: 0,
              }}
            />
            {transactiondetailobj.campaigns.map((item) => {
              return (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p style={{ fontSize: 12, fontWeight: 600 }}>{item.name}</p>
                  <p
                    style={{ fontSize: 12, fontWeight: 600, paddingRight: 10 }}
                  >
                    +{item.value}
                  </p>
                </div>
              )
            })}

            <div
              style={{
                backgroundColor: '#939594',
                height: 1,
              }}
            />
          </div>
        ) : null}

        <p style={{ color: '#939594', fontSize: 12, paddingTop: 20 }}>
          {currentlanguage.transactionhistorypage.page_history_redeemed}
        </p>
        <div
          style={{
            backgroundColor: '#939594',
            height: 0,
            marginTop: -5,
            marginBottom: 0,
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 600,
            fontSize: 12,
            paddingRight: 10,
          }}
        >
          <p>
            {moment(transactiondetailobj.transactionDate).format('D/MM/YYYY')}
          </p>
          <p>{transactiondetailobj.redeemedPoints}</p>
        </div>
        {transactiondetailobj.vouchers.length > 0 ? (
          <div
            style={{
              backgroundColor: '#939594',
              height: 1,
            }}
          />
        ) : null}
      </div>
      {transactiondetailobj.vouchers.length > 0 ? (
        <div>
          <p style={{ color: '#939594', fontSize: 12, paddingTop: 20 }}>
            {currentlanguage.transactionhistorypage.page_history_vouchertran}
          </p>
          {transactiondetailobj.vouchers.map((voucher) => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 600,
                  fontSize: 12,
                  paddingRight: 10,
                }}
              >
                <p>{voucher.name}</p>
                <p>Redeemed</p>
              </div>
            )
          })}

          <div
            style={{
              backgroundColor: '#939594',
              height: width < 500 ? 0 : 1,
              marginBottom: 15,
            }}
          />
        </div>
      ) : null}
    </div>
  )
}

export default transactionList
