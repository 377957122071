import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'unsubscribe'

const config = {
  details: {
    project,
    environment,
  },
  application: {
    unsubscribe: {
      api: {
        endpoints: {
          auth: {
            headers: {
              'Content-Type': 'application/json',
              ModuleCode: setup.setup.thor.moduleCode[environment],
            },
            doc: '{{host}}//gdpr/unsubscribe',
            url: setup.setup.api.subway.url[environment] + '/gdpr/unsubscribe',
            method: 'PUT',
          },
        },
      },
    },
  },
  routes: {
    label: 'Unsubscribe',
    prefix: '/unsubscribe/:modulecode/:token',
  },
  setup,
}

export default config
