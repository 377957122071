import React, { useState, useEffect, useContext } from 'react'
// import { Link } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import { Inputs, Buttons } from '../../lib-atomic'
import { FormHook, LocalStorage } from '../../lib-atomic-functions'
import {
  ModXlWrapp,
  ModPageTitleWrapp,
  ModTitleText,
  ModContentText,
  ModFormLabelText,
} from '../../lib-atomic-design-section'
// import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

import CONFIG from '../config'

/* CONSTANTS */
const ACTIVATIONOBJECTS = CONFIG.application.account.objects
const ENDPOINTS = CONFIG.application.account.api.endpoints
const ACTIVATIONCONFIG = {
  url: ENDPOINTS.activation.url,
  method: ENDPOINTS.activation.method,
  headers: ENDPOINTS.activation.headers,
  responseType: 'json',
}
/* CONSTANTS */

const index = (props) => {
  const { country, lang } = LocalStorage.getLS()
  const currentlanguage = LocalStorage.getLS().translation.json

  /* state */
  /* ---- activation ----  */

  /* -- global state : states --  */
  const { activation } = useContext(GlobalContext)
  /* -- global state : states --  */

  /* -- recaptcha : states -- */
  const [loaded, setLoaded] = useState(false)
  const [verified, setVerified] = useState(false)
  const [exprired, setExpired] = useState(false)

  const verifyCallback = (response) => {
    if (response) {
      setVerified(true)
      setLoaded(true)
      setExpired(false)
    }
  }
  const expiredCallback = (response) => {
    setExpired(true)
  }
  /* -- recaptcha : states -- */

  /* -- execute activation : states --  */
  const [fetchActivation] = FetchContextApiV2(ACTIVATIONCONFIG)
  const execActivation = async (body) => {
    activation.dispatch({
      type: activation.actionTypes.FETCH_ACTIVATION,
      activation: await fetchActivation({ body }, activation),
    })
  }
  const execActivationReset = async () => {
    activation.dispatch({
      type: activation.actionTypes.RESET,
    })
  }
  /* -- execute activation : states --  */

  /* -- form handles : states -- */
  const handleActivation = async () => {
    if (verified && loaded) {
      const body = {
        email: values.email,
      }
      execActivation(body)
    }
  }
  /* -- form handles : states -- */

  /* -- effects : activation : use-effects */
  useEffect(() => {
    if (activation.state.data) {
      try {
        const { isStatus, data } = activation.state
        const { activationobject } = data
        if (!activationobject) throw { status: isStatus, err: false }
        const { outComeCode } = activationobject
        if (outComeCode !== '0')
          throw { status: isStatus, err: activationobject }
        execRedirects()
        //handleClearLS();
        //window.location.replace(generatepaymentobject.uri)
      } catch ({ status, err }) {
        console.log(status, err)
        execRedirects({ status, err })
        //if (!e.status) handleClearLS()
      }
    }
  }, [activation.state])
  /* -- effects : activation : use-effects */

  const execRedirects = async (e) => {
    const { isStatus, data } = activation.state
    const { activationobject } = data
    if (e && !e.status && e.err) {
      const { response } = activationobject
      const outComeCode = response
        ? response.data.outComeCode
        : activationobject.outComeCode
      // const { outComeCode } = response.data
      const key = outComeCode.toString()
      const manipulatedOutComeCode = key.replace('\r\n', '')
      // console.log(manipulatedOutComeCode)
      const isSuccessfullyUpdated =
        data && manipulatedOutComeCode === '0' ? true : false
      const message = currentlanguage.errorresponse_resendCode[
        manipulatedOutComeCode
      ]
        ? currentlanguage.errorresponse_resendCode[manipulatedOutComeCode]
        : 'Unknown Error'
      // await execActivationReset()
      props.history.push('/activation/resend/confirm', {
        isSuccessfullyUpdated,
        message,
      })
    } else {
      if (activationobject) {
        // console.log(activationobject, 'activationobject')
        const { outComeCode } = activationobject
        const key = outComeCode.toString()
        const manipulatedOutComeCode = key.replace('\r\n', '')
        const isSuccessfullyUpdated =
          data && manipulatedOutComeCode === '0' ? true : false
        const message = currentlanguage.errorresponse_resendCode[
          manipulatedOutComeCode
        ]
          ? currentlanguage.errorresponse_resendCode[manipulatedOutComeCode]
          : 'Unknown Error'
        // await execActivationReset()
        props.history.push('/activation/resend/confirm', {
          isSuccessfullyUpdated,
          message,
        })
      }
    }
  }

  /* ---- activation ----  */
  /* state */

  /* -- data props --   */
  const isSubmitButtonDisabled = !verified || !loaded || exprired ? true : false
  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleActivation,
    ACTIVATIONOBJECTS
  )
  const email = {
    ...ACTIVATIONOBJECTS.email,
    error: error.email,
    value: values.email,
    onChange,
  }
  const submitButton = { fullWidth: true }
  const backButton = {
    fullWidth: true,
    onClick: () => {
      props.history.push('/activation')
    },
  }
  /* -- data props --   */

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModXlWrapp>
        <ModPageTitleWrapp>
          <ModTitleText>
            <b>{currentlanguage.activationpage.page_activ_activate_title}</b>
          </ModTitleText>
          <ModContentText>
            <b>{currentlanguage.activationpage.page_activ_email_activate}</b>
          </ModContentText>
        </ModPageTitleWrapp>
      </ModXlWrapp>
      <ModXlWrapp>
        <Inputs.Text {...email} />
      </ModXlWrapp>
      <br />
      <Recaptcha
        sitekey="6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE"
        render="explicit"
        verifyCallback={verifyCallback}
      />
      <br />
      <ModXlWrapp>
        <Buttons.Normal {...submitButton}>
          {currentlanguage.activationpage.page_activ_submit}
        </Buttons.Normal>
      </ModXlWrapp>
      <br />
      <ModXlWrapp>
        <Buttons.Text {...backButton}>
          {currentlanguage.activationpage.page_activ_back}
        </Buttons.Text>
      </ModXlWrapp>
    </form>
  )
}

export default index
