import rolesModules from './roles-modules'
import rolesSubModules from './roles-sub-modules'
import roleslandingPages from './roles-landing-pages'
// handlers
import rolesHandler from '../handler/module-handler'
import landingHandler from '../handler/landing-handler'

export default {
  rolesModules,
  rolesSubModules,
  roleslandingPages,
  // handlers
  rolesHandler,
  landingHandler,
}
