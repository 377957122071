import React, { useContext, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Link from '../../../components/lib-atomic/link'

import {
  Drawer,
  Box,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

import { GlobalContext } from '../../../components/lib-atomic-context'

import DrawerItems from './drawer-items'
import { LocalStorage } from '../../../components/lib-atomic-functions'

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.primary.contrastText,
  },
  bottomLinks: {
    fontWeight: 'bold',
    padding: theme.spacing(1),
    '& *': {
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(1),
    },
  },
}))

const MainDrawer = (props) => {
  const { isVisible, setIsVisible } = props
  const classes = useStyles()
  const currentlanguage = LocalStorage.getLS().translation.json
  const token = LocalStorage.getLS()
    ? LocalStorage.getLS().login
      ? LocalStorage.getLS().login.token
        ? LocalStorage.getLS().login.token
        : false
      : false
    : false
  //hide drawer on route change
  // history.listen(() =>
  //   drawer.dispatch({
  //     type: drawer.actionTypes.SET_VISIBILITY,
  //     payload: false,
  //   })
  // )
  const handleClose = () => {
    setIsVisible(!isVisible)
  }
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={isVisible}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        onBackdropClick: () => {
          setIsVisible(!isVisible)
        },
      }}
    >
      <Box component={Typography} align="center" color="primary.contrastText">
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={() => {
              setIsVisible(!isVisible)
            }}
            aria-label="close drawer"
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <DrawerItems handleClose={handleClose} />

        <Box className={classes.bottomLinks}>
          {token ? (
            <Link
              onClick={() => {
                setIsVisible(!isVisible)
              }}
              to="/logout"
            >
              {currentlanguage.moremenu_website_page.moremenu_website_logout}
            </Link>
          ) : (
            <>
              <Link
                onClick={() => {
                  setIsVisible(!isVisible)
                }}
                to="/login"
              >
                {currentlanguage.moremenu_website_page.moremenu_website_signin}
              </Link>{' '}
              |{' '}
              <Link
                onClick={() => {
                  setIsVisible(!isVisible)
                }}
                to="/register"
              >
                {
                  currentlanguage.moremenu_website_page
                    .moremenu_website_register
                }
              </Link>
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  )
}

export default MainDrawer
