import React from 'react'

import { LocalStorage } from '../../lib-atomic-functions'

const index = (props) => {
  window.location.replace('/login')
  return <div>...</div>
}

export default index
