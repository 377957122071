import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Update'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  routes: {
    label: 'Transfer Card',
    prefix: '/transfercard',
  },
  objects: {
    newCard: {
      name: 'newCard',
      initial: '',
      placeholder: '',
      helperText: false,
      rules: false,
    },
    accessCode: {
      name: 'accessCode',
      initial: '',
      placeholder: '',
      helperText: false,
      rules: false,
    },
  },
  endpoints: {
    transfer: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: null,
      },
      doc: '',

      url: setup.setup.api.stormborn.url.api + '/card/replace',
      // url: 'https://subcarduat.tr4ns.com/tx-sub/card/replace',
      method: 'POST',
    },
  },
  setup,
}

export default config
