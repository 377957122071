import React from 'react'

import { withStyles } from '@material-ui/core/styles'
/* modules */
import { TableBody, TableRow } from '@material-ui/core'
/* modules */
import MuiTableCell from '@material-ui/core/TableCell'
const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell)
/*
:properties
config
data - list / collection - row-body.js
*/

const row = ({ config, data }) => {
  return (
    <TableBody>
      {data.map((item, index) => {
        // const columnData = { config, item }
        return (
          <TableRow>
            {config.map((conf, index) => {
              const content = conf.template
                ? conf.template(item[conf.field], item)
                : item[conf.field]
              return <TableCell>{content}</TableCell>
            })}
          </TableRow>
        )
      })}
    </TableBody>
  )
}

export default row
