import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Home'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    config: {
      api: {
        endpoints: {
          mastercampaign: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/mastercampaigns',
            method: 'GET',
          },
          campaignresponse: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.cms.url[environment] + '/mastercampaignresponses',
            method: 'GET',
          },
          enquiry: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: null,
              moduleCode: setup.setup.thor.moduleCode[environment],
            },
            url: setup.setup.api.subcard.url[environment] + '/tx-sub/members',
            method: 'GET',
          },
          mastercontents: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'GET',
          },
        },
      },
    },
  },
  routes: {
    label: 'Campaign',
    prefix: '/campaign',
  },
  setup,
}

export default config
