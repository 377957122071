import React from 'react'
import { withRouter, Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'

const CustomLink = ({ to, match, children, ...otherProps }) => {
  const prefix = '/'

  return (
    <Link
      className="subway-footlong"
      component={RouterLink}
      to={to}
      {...otherProps}
    >
      {children}
    </Link>
  )
}

export default withRouter(CustomLink)
