import React, { useContext, useEffect } from 'react'

import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'
import { GlobalContext } from '../../lib-atomic-context'

const index = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  const { activation } = useContext(GlobalContext)

  const execActivationReset = async () => {
    activation.dispatch({
      type: activation.actionTypes.RESET,
    })
  }
  const resetActi = async () => {
    await execActivationReset()
  }
  useEffect(() => {
    resetActi()
    // GArecordPageView(cookies)
  }, [])

  return props.children
}

export default index
