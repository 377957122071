import React from 'react'

/* container */
import CampaignContainer from '../../app-module-campaign-container/default'
/* container */

/* layout */
import ContentLayout from '../layout/contentLayout'
/* layout */

const Index = (props) => {
  return (
    <CampaignContainer {...props}>
      <ContentLayout {...props} />
    </CampaignContainer>
  )
}

export default Index
