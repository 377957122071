import React from 'react'
import TransferCardContainer from '../../app-module-transfercard-container/default'
import PageContent from '../page/lostcardpage'

import ScrollToTop from '../../../scrollToTop'

const index = (props) => {
  return (
    <TransferCardContainer {...props}>
      <ScrollToTop {...props} />
      <PageContent {...props} />
    </TransferCardContainer>
  )
}

export default index
