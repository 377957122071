import React, { useEffect, useState, Fragment, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import QueryString from 'query-string'

import { GlobalContext } from '../../lib-atomic-context'
import { Buttons } from '../../lib-atomic'
import { LocalStorage, FetchContextApiV2 } from '../../lib-atomic-functions'
import { ModXlWrapp } from '../../lib-atomic-design-section'

import CONFIG from '../config'

/* CONSTANTS */
const ENDPOINTS = CONFIG.application.unsubscribe.api.endpoints
const UNSUBSCRIBEOBJECTSCONFIG = {
  url: ENDPOINTS.auth.url,
  method: ENDPOINTS.auth.method,
  headers: ENDPOINTS.auth.headers,
  responseType: 'json',
}
/* CONSTANTS */

const index = (props) => {
  // const unsubscribeToken = QueryString.parse(props.location.search).token
  //   ? QueryString.parse(props.location.search).token
  //   : null
  const { modulecode, token } = props.match.params
  const currentlanguage = LocalStorage.getLS().translation.json
  const { unsubscribe } = useContext(GlobalContext)

  /* --- unsubscribe */
  UNSUBSCRIBEOBJECTSCONFIG.headers.ModuleCode = modulecode
  const [fetchUnsubscribeObj] = FetchContextApiV2(UNSUBSCRIBEOBJECTSCONFIG)

  useEffect(() => {
    if (unsubscribe.state.data) {
      if (!unsubscribe.state.isStatus) {
        props.history.push('/unsubscribe/fail')
      } else {
        const { unsubscribeobj } = unsubscribe.state.data
        const { outcomeCode } = unsubscribeobj
        if (outcomeCode === '0' || outcomeCode === 0) {
          props.history.push('/unsubscribe/success')
        } else {
          props.history.push('/unsubscribe/fail')
        }
      }
    }
  }, [unsubscribe.state])
  const fetchUnsubscribeExecute = async (body) => {
    unsubscribe.dispatch({
      type: unsubscribe.actionTypes.FETCH_UNSUBSCRIBE,
      unsubscribe: await fetchUnsubscribeObj({ body }, unsubscribe),
    })
    // const unsubs = await fetchUnsubscribeObj({ body }, unsubscribe)
    // try {
    //   const isTrue = unsubs.data.json.outcomeCode === 0 ? true : false
    //   if (!isTrue) throw false
    //   props.history.push('/unsubscribe/success')
    // } catch (e) {
    //   props.history.push('/unsubscribe/fail')
    // }
  }

  const handleUnsubscribe = async () => {
    const body = {
      token,
      flEmail: 0,
      flSms: null,
    }
    await fetchUnsubscribeExecute(body)
  }

  /* -- data props --   */
  const submitButton = { fullWidth: true, onClick: () => handleUnsubscribe() }
  /* -- data props --   */

  return (
    <ModXlWrapp>
      <Buttons.Normal {...submitButton}>
        {currentlanguage.unsubscribe_website_page.unsubscribe_website_button}
      </Buttons.Normal>
    </ModXlWrapp>
  )
}

export default withRouter(index)
