import React from "react";

import { withStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
import MuiTableCell from "@material-ui/core/TableCell";
const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);
/*
:properties
label
*/

const column = ({ label }) => {
  return <TableCell>{label}</TableCell>;
  // return (
  //   <Grid item xs>
  //     {label}
  //   </Grid>
  // );
};

export default column;
