import React, { useState, useContext } from 'react'
import { Wheel } from "./components/Roulette";
import { makeStyles} from "@material-ui/core";
import { RotateDisplayPrize } from "./components/styles.js";
import ErrorOverlay from './components/ErrorOverlay';
import { GlobalContext } from '../../lib-atomic-context'
import { BackgroundSettings } from "./components/styles.js";
import './styles.css';  

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    width: "100%",
    opacity: "1",
    maxWidth: "210px",
    cursor: "pointer",
    transformOrigin: 'center',
    filter: "drop-shadow(0px 3px 2px #333333) brightness(1.1)",
    transition: "filter 0.1s ease-out;",
    '&:hover': {
       filter: "drop-shadow(0px 3px 2px #333333) brightness(1.2)",
    },
    '&:active': {
       filter: "drop-shadow(0px 3px 2px #333333) brightness(0.9)",
       marginTop: "2px",
       transform: "scale(0.96)",
    },
  },
}));

const index = (props) => {
  const classes = useStyles();
  const [hasClicked, setHasClicked] = useState(false);
  const [hasClickedStop, setHasClickedStop] = useState(false);
  const [prizeNum, setPrizeNum] = useState();
  const [prizeNumDebug, setPrizeNumDebug] = useState();
  const [noWin, setNoWin] = useState();
  const [mustSpin, setMustSpin] = useState(false);
  const [open, setOpen] = useState(false);
  const [resetCheck, setResetCheck] = useState(false);
  const [spinRemaining, setSpinRemaining] = useState();
  const {spinthewheel} = useContext(GlobalContext)
  const wheelcontent = spinthewheel.state.data.spinthewheelimages[0];

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  };

  const getPrize = (e) => {
    const bottlePrize = {
      subway: ["200pts", "bob", "casque", "freemium", "photo", "sneakers"],
      pepsi: ["bob", "projo", "sneakers", "freemium","photo","200pts"],
      orangina: ["bob", "enceinte", "sneakers","freemium","photo","200pts"],
      oasis: ["bob", "ps5", "sneakers", "freemium","photo","200pts"],
      lipton: ["bob", "switch", "sneakers", "freemium","photo","200pts"],
    }
    const bottlePrize2 = {
      1:"orangina",
      2:"subway",
      3:"oasis",
      4:"lose",
      5:"lipton",
      6:"lose",
      7:"pepsi",
      8:"lose"
    }
    if(bottlePrize2[e] !== "lose" && e ){
    console.log(prizeLength);
    const prizeLength = bottlePrize[bottlePrize2[e]].length-1;
      return `/assets/stormborn/spinthebottle/${bottlePrize2[e]}/${bottlePrize2[e]}_${bottlePrize[bottlePrize2[e]][getRandomInt(0, prizeLength+1)]}.png`
    } else {
      return `/assets/stormborn/spinthebottle/lose.png`
    }
  }
 
  const handleOpen = () => {
    setOpen(true);
  };

  const resetWheel = () => {
    setResetCheck(true)
    setOpen(false);
    setTimeout(() => {
      setResetCheck(false)
      setMustSpin(true);
      setHasClickedStop(false);
      setHasClicked(true);
      setPrizeNum()
      console.log('SET OPEN TO FALSE')
      onClick();
    }, 450);
  }

  // Iterate through Reveal Images
  function toObject(arr) {
    var rv = {};
    for (var i = 1; i < arr.length+1; ++i)
      rv[i] = arr[i-1].url;
    return rv;
  }

  const frPrizes = toObject(wheelcontent.Reveal_images)

  // Get prize length from prizes object
  const prizeLength = Object.keys(frPrizes);

  // FOR DEVELOPMENT - MOCK API CALL
  const onClick = () => {
    const randomPrize = getRandomInt(1, 8);
    if (!hasClicked) {
      setMustSpin(true);
    }
    setTimeout(() => {
        if(prizeNumDebug){
          console.log("PRIZE NUM DEBUG: ",prizeNumDebug * 1);
          setPrizeNum(prizeNumDebug * 1);
        } else {
          console.log("RANDOM PRIZE RESULT: ",randomPrize);
          setPrizeNum(randomPrize);
        }
        setNoWin(3);
      setHasClickedStop(true);
    }, 900);
    setHasClicked(true);
  };

  // POST REQUEST FOR SPIN TOKEN - ONE TIME USE TOKEN. USE WITH 'SPIN AGAIN' FUNCTIONALITY
    // const onClick = async ()  => {
    //     try{
    //       await fetch(props.useApi + props.gameToken, {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         }
    //       })
    //       .then(response => response.json())
    //       .then(data =>{
    //         console.log(data);
    //         setMustSpin(true);
    //         setSpinRemaining(data.spinLeft);
    //         // Adjust prizeId based on PrizeID from backend
    //         setTimeout(() => {
    //           if(wheelcontent.mastercountries.abbr === 'fr'){
    //             setPrizeNum(data.prizes[0].prizeId - 36);
    //             setNoWin(6);
    //           } else if (wheelcontent.mastercountries.abbr === 'nl') {
    //             setPrizeNum(data.prizes[0].prizeId - 42);
    //             setNoWin(8);
    //           } else if (wheelcontent.mastercountries.abbr === 'gb') {
    //             setPrizeNum(data.prizes[0].prizeId - 55);
    //             setNoWin(6);
    //           } else if (wheelcontent.mastercountries.abbr === 'ie') {
    //             setPrizeNum(data.prizes[0].prizeId - 65);
    //             setNoWin(6);
    //           } 
    //           else {
    //             setPrizeNum(data.prizes[0].prizeId);
    //             setNoWin(5);
    //           }
    //           setHasClickedStop(true);
    //           console.log("PRIZE WON", data.prizes[0].prizeId);
    //         }, 3000);
    //       });
    //     } catch (error) {
    //       console.error(error);
    //     }
    //     setHasClicked(true);
    // };

  return (
  <div className='spincontainer'>
    {/* <ErrorOverlay 
    outcomeCode={props.tokenRes.outcomeCode} 
    errPromoend={wheelcontent.error_promotionended} 
    errSpun={wheelcontent.error_alreadyspun} 
    />   */}
    <BackgroundSettings bgImage={"/assets/stormborn/spinthebottle/background.png"}>
        <div className={`wheelContainer`}>
          <div className={`mealDealArrow mealDealArrow${wheelcontent.mastercountries.abbr} ${hasClicked ? "" : classes.mealDealArrowClick}`}>
            <img style={{ width:"13.5rem", zIndex:"3" }} 
              className={`addMarginTopBottle`} 
              src={"/assets/stormborn/spinthebottle/title.png"} alt="Meal Deal Banner" />
          </div>
          <div className={`spinButtonBottle`}>
            <button onClick={() => onClick()} style={{width:'50%', height:"80px", border: "none"}} disabled={hasClicked}>

            </button>
          </div>
          <div className='bottleWrapper'>
            <Wheel
              wheelImage={"/assets/stormborn/spinthebottle/bottle.png"}
              country={wheelcontent.mastercountries.abbr}
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNum}
              prizeTotal = {8} // Set prizeTotal based on no of prizes in strapi
              onClickingStop={hasClickedStop}
              onStopSpinning={() => {
                setMustSpin(false);
                handleOpen();
              }}
            />
          </div>
          <div style={{ position:"absolute", zIndex:"1", display:"flex", justifyContent:"center", top:"215px"}}>
            <img style={{width:"100%", maxWidth:"200px", }} src="/assets/stormborn/spinthebottle/date.png"/>
          </div>
          <div style={{ position:"absolute", zIndex:"1", display:"flex", justifyContent:"center", top:"45%"}}>
            <img style={{width:"100%"}} src="/assets/stormborn/spinthebottle/wheel.png"/>
          </div>
          <div className={`prizetowinfr ${open ? 'fade-out' : ''}`}>
            <img style={{ width:"100%"}} src={"/assets/stormborn/spinthebottle/bubblemsg.png"} alt="" />
          </div>
        </div>
        {/* Winning display message */}
        <div className={`displayMessageBottle ${open ? "placeinfront" : ""}`}>
        <div className={`${resetCheck ? '' : ''}`}>
          <RotateDisplayPrize 
            className={`${open ? "start-rotate" : "end-rotate" }`}
            src={getPrize(prizeNum)} 
          />
          {
            spinRemaining !== 0 && (wheelcontent.mastercountries.abbr === 'gb' || wheelcontent.mastercountries.abbr === 'ie') ? 
            <button className={`rejour-button ${open ? 'rejour-anim' : '' }`} onClick={()=> resetWheel()}>SPIN AGAIN</button> 
            : ""
          }
        </div>

      </div>
      <div className='tandc'>
        <p>Voir règlement complet du jeu <a href="/promotions">en cliquant ici</a></p>
      </div>

        {/* DEBUG UI */}
        {process.env.REACT_APP_BUILD_ENV === 'production' ? "" : 
          <div className='debugOption'>
            <p style={{marginBottom:"0", fontSize:"0.8rem", fontWeight:"bold" }}>TEST MENU - Set Prize No.</p>
            <p style={{marginBottom:"0.2rem", fontSize:"0.8rem" }}>1 = Orangina - Anti Clockwise - 8 = Lose</p>
            <input min={1} max={5} maxLength={1} placeholder='Leave empty to randomize' type='number' 
              onChange={(e)=>setPrizeNumDebug(e.target.value)} 
              value={prizeNumDebug} 
            />
            <button onClick={()=>resetWheel()}>Start Spin (Again)</button>
          </div>
        }
    </BackgroundSettings>
  </div>
  );
}

export default index
