import React, { Fragment, useContext, useState } from 'react'

import { AppBar, Toolbar, Grid, makeStyles } from '@material-ui/core'
import appConf from '../../app-conf'

const logo = appConf[process.env.REACT_APP_CLIENT].assets.logo

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
}))
const Header = (props) => {
  const classes = useStyles()
  return (
    <Fragment>
      <AppBar position="sticky" color="primary" className={classes.appBar}>
        <Toolbar>
          <Grid container>
            <Grid container item justify="center" alignItems="center" xs={12}>
              <img src={logo} alt="logo" width="120" />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Fragment>
  )
}

export default Header
