import React from 'react'

/* mui */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    color: theme.palette.text.secondary,
  },
}))
/* CONSTANTS */

const text = props => {
  /* mui */
  const classes = useStyles()
  /* mui */

  return (
    <Typography variant="caption" className={classes.title}>
      <b>{props.children}</b>
    </Typography>
  )
}

export default text
