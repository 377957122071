import React, { lazy, Suspense, useEffect, useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'

/* container */
import { GlobalProvider } from '../../lib-atomic-context'
import HomeContainer from '../../app-module-home-container/default'
import CMSContainer from '../../app-module-cms-container/default'
import {
  LocalStorageV2,
  LocalStorage,
  WindowSize,
} from '../../lib-atomic-functions'
/* container */

/* modules */
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { Buttons } from '../../lib-atomic'
/* modules */
import queryString from 'query-string'
import CONFIG from '../config'

const logo = CONFIG.setup.assets.logo_v2

/* lazy modules */
const CMSLayout = lazy(() =>
  import('../../app-module-cms-section-layout/default')
)
/* lazy modules */

/* constant */
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    margin: 'auto',
    textAlign: 'center',
  },
}))
/* constant */

const index = (props, history) => {
  const { country, lang } = LocalStorage.getLS()
  const currentlanguage = LocalStorage.getLS().translation.json
  const { width, height } = WindowSize()
  const { visibleLogin, popupModal } = props
  const classes = useStyles()
  const onlineOrder = queryString.parse(props.location.search)
  var isVisable = visibleLogin
  if (onlineOrder.myiro) {
    isVisable = true
  }
  const submitButton = { fullWidth: true }
  return (
    <Modal
      id="myiro"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isVisable}
      onClose={popupModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 700,
      }}
    >
      <Fade in={isVisable}>
        <div className={classes.paper}>
          <h3
            id="transition-modal-title"
            style={{
              paddingBottom: 20,
              fontFamily: 'SubwayFootlong',
              fontSize: width < 500 ? 17 : 25,
            }}
          >
            {currentlanguage.login_register_popup_page.register_header}
          </h3>
          <div
            style={{
              width: 150,
              margin: 'auto',
              marginBottom: 30,
            }}
            onClick={() => {
              props.history.push('/register')
            }}
          >
            <Buttons.Normal {...submitButton}>
              {currentlanguage.login_register_popup_page.register_btn}
            </Buttons.Normal>
          </div>
          <div
            style={{
              height: 1,
              backgroundColor: '#8b8b8b',
              marginLeft: 0,
              marginRight: 0,
            }}
          />
          <h3
            id="transition-modal-title"
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              fontFamily: 'SubwayFootlong',
              fontSize: width < 500 ? 17 : 25,
            }}
          >
            {currentlanguage.login_register_popup_page.login_header}
          </h3>

          <div
            style={{
              width: 150,
              margin: 'auto',
              marginBottom: country == 'gb' || country == 'ie' ? 30 : 10,
            }}
            onClick={() => {
              // LocalStorage.removeLS()
              if (country === 'gb' || country === 'ie') {
                props.history.push('/login?orderOnline=true')
              } else {
                props.history.push('/login')
              }
            }}
          >
            <Buttons.NormalYellow {...submitButton}>
              {currentlanguage.login_register_popup_page.login_btn}
            </Buttons.NormalYellow>
          </div>

          {country === 'gb' || country === 'ie' ? (
            <div>
              <div
                style={{
                  height: 1,
                  backgroundColor: '#8b8b8b',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
              <h3
                id="transition-modal-title"
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                  fontFamily: 'SubwayFootlong',
                  fontSize: width < 500 ? 17 : 25,
                }}
              >
                {currentlanguage.login_register_popup_page.guest_header}
              </h3>

              <div
                style={{
                  width: 150,
                  margin: 'auto',
                  marginBottom: 10,
                }}
                onClick={() => {
                  // LocalStorage.removeLS()
                  window.location.href =
                    'https://order.subway.co.uk/Home/Welcome'
                }}
              >
                <Buttons.Blue {...submitButton}>
                  {currentlanguage.login_register_popup_page.guest_btn}
                </Buttons.Blue>
              </div>
            </div>
          ) : null}
        </div>
      </Fade>
    </Modal>
  )
}

export default index
