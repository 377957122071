import React, { useEffect, useContext, useState } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import QueryString from 'query-string'

/* GA */
import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'
/* GA */

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2 } from '../../lib-atomic-functions'

import { LocalStorage } from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
/* module */
import SomethingWentWrong from '../../app-something-went-wrong/default'

import CONFIG from '../config'
import { set } from 'react-ga'
import { init } from '../../lib-atomic-context/reducers/init'

const CAMPAIGNMASTER = {
  SPECIALCAMPAIGNCODE: 'wpoejfpaowejfpojaespfoj',
  CONTENTID: 1259,
  TICKBOX: false,
  TANDCLINKURL: 'https://subwayrewards.uk/promotions',
}
const ENDPOINTS = CONFIG.application.config.api.endpoints

const CONTENT = {
  url: ENDPOINTS.mastercontents.url,
  method: ENDPOINTS.mastercontents.method,
  headers: ENDPOINTS.mastercontents.headers,
  responseType: 'json',
}
const CAMPAIGN = {
  url: ENDPOINTS.mastercampaign.url,
  method: ENDPOINTS.mastercampaign.method,
  headers: ENDPOINTS.mastercampaign.headers,
  responseType: 'json',
}
const CAMPAIGNRESPONSE = {
  url: ENDPOINTS.campaignresponse.url,
  method: ENDPOINTS.campaignresponse.method,
  headers: ENDPOINTS.campaignresponse.headers,
  responseType: 'json',
}
const ENQUIRYCONFIG = {
  url: ENDPOINTS.enquiry.url,
  method: ENDPOINTS.enquiry.method,
  headers: ENDPOINTS.enquiry.headers,
  responseType: 'json',
}

const isBetweenTwoDate = (theDate, PublishFrom, PublishTo) => {
  const noFromDate = PublishFrom === null
  const noToDate = PublishTo === null
  const publishFrom = noFromDate
    ? ' '
    : moment(PublishFrom).format('YYYY MM DD')
  const publishTo = noToDate ? ' ' : moment(PublishTo).format('YYYY MM DD')
  const isBetweenFromTo =
    publishFrom === ' ' && publishTo === ' '
      ? true
      : moment(theDate).isBetween(
          moment(PublishFrom).format('YYYY MM DD'),
          moment(PublishTo).format('YYYY MM DD')
        )
  return isBetweenFromTo
}
const Index = (props) => {
  // console.log(props)
  // const { params } = props.match
  // const { campaignCode, token } = params
  const parsed = QueryString.parse(props.location.search)
  const { campaignCode, token } = parsed
  // alert(campaignCode)
  // console.log(props, campaignCode, token)
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  const [bypass, setBypass] = useState(false)
  const [resetCRDone, setResetCRDone] = useState(false)
  const [resetMemberFlag, setResetMemberFlag] = useState(false)
  /* state */
  const { campaign, campaignresponse2, member, contents } =
    useContext(GlobalContext)
  /* state */

  const resetLoginObj = () => {
    LocalStorage.addLS('login', {})
  }
  /* campaign */
  const [fetchCampaign] = FetchContextApiV2(CAMPAIGN)
  const resetContents = async () => {
    await contents.dispatch({
      type: contents.actionTypes.RESET_CONTENTS,
    })
  }
  const execHardcodedCampaign = async (
    contentId,
    campaignCode,
    tickBox,
    tandcURL
  ) => {
    await resetContents()
    const [fetchContent] = FetchContextApiV2(CONTENT)
    const result = await fetchContent(
      { query: '?id_in=' + contentId },
      campaign
    )
    const resultObj =
      result.data.json &&
      result.data.json.response &&
      result.data.json.response.data.statusCode === 404
        ? []
        : result.data.json
    // console.log(resultObj)
    const obj = {
      id: 1,
      mastercontents: resultObj && resultObj.length === 0 ? null : resultObj[0],
      mastertandc: {
        id: 1,
        name: 'UK-EN-PrizeDraw-TandC-V1',
        type: 'Prizedraw',
        mastercountries: 7,
        masterlanguages: 5,
        json: {
          title: 'Terms and Conditions',
          content: 'Blah Blah Blah',
          text_phrase_1: 'Yes, I agree with the Subway® app ',
          text_phrase_2: 'Terms and Conditions',
          text_phrase_1: '',
          haveTickBox: tickBox,
          isLink: { url: tandcURL },
        },
        created_at: '2021-03-30T21:16:36.000Z',
        updated_at: '2021-05-27T21:31:58.000Z',
      },
      campaignCode: campaignCode,
      campaignType: 'BooleanType',
      questions: [],
    }
    await campaign.dispatch({
      type: campaign.actionTypes.FETCH_CAMPAIGN,
      campaign: {
        status: true,
        data: {
          blob: false,
          json: [obj],
        },
      },
    })
    await resetContents()
  }
  const execFetchCampaign = async ({ query }) => {
    const result = await fetchCampaign({ query }, campaign)
    const { memberObject } = member.state.data
    const filtered =
      result.data.json &&
      result.data.json.response &&
      result.data.json.response.data.statusCode === 404
        ? []
        : result.data.json

    // .filter((campaign) => {
    //     return isBetweenTwoDate(
    //       moment(),
    //       campaign.publishFrom,
    //       campaign.publishTo
    //     )
    //     // && memberObject.countryId === campaign.mastercountries.countryId
    //   })
    campaign.dispatch({
      type: campaign.actionTypes.FETCH_CAMPAIGN,
      campaign: {
        status: true,
        data: {
          blob: false,
          json: filtered,
        },
      },
    })
  }
  /* campaign */
  const [fetchMemberDetail] = FetchContextApiV2(ENQUIRYCONFIG)

  const fetchMemberDetailExe = async ({ newHeader }) => {
    member.dispatch({
      type: member.actionTypes.FETCH_MEMBER,
      member: await fetchMemberDetail({ newHeader }, member),
    })
  }

  const execMemberReset = async () => {
    member.dispatch({
      type: member.actionTypes.CLEAR_DATA,
    })
  }

  const fetchCampaignResponseExecute = async ({ query }) => {
    const [fetchCall] = FetchContextApiV2(CAMPAIGNRESPONSE)
    const fetchResult = await fetchCall({ query }, campaignresponse2)
    // console.log(fetchResult)
    campaignresponse2.dispatch({
      type: campaignresponse2.actionTypes.FETCH_CAMPAIGN_RESPONSE,
      campaignresponse2: fetchResult,
    })
  }
  const execCampaignResponseReset = async () => {
    campaignresponse2.dispatch({
      type: campaignresponse2.actionTypes.RESET,
    })
  }
  const init = async () => {
    // GArecordPageView(cookies)
    if (campaignCode === undefined || campaignCode === null) {
      setBypass(true)
    } else {
      if (campaignCode === '') {
        setBypass(true)
      } else {
        setBypass(false)
        // const { login } = LocalStorage.getLS()
        // const tokenPrepared = token ? token : login ? login.token : null
        // console.log(login)
        // // const { token } = login
        // const newHeader = {
        //   ...ENQUIRYCONFIG.headers,
        //   Authorization: 'Bearer ' + tokenPrepared,
        // }
        // fetchMemberDetailExe({ newHeader })

        const { login } = LocalStorage.getLS()
        const tokenPrepared = token ? token : login ? login.token : null
        if (tokenPrepared) {
          LocalStorage.addLS('login', {
            ...LocalStorage.getLS().login,
            token: tokenPrepared,
          })
          const newHeader = {
            ...ENQUIRYCONFIG.headers,
            Authorization: 'Bearer ' + tokenPrepared,
          }
          await fetchMemberDetailExe({ newHeader })
        } else {
          setResetMemberFlag(true)
        }
      }
    }
  }
  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (resetMemberFlag) {
      execMemberReset()
    }
  }, [resetMemberFlag])
  // const prepareHardcodedData = async () => {
  //   await execHardcodedCampaign(
  //     CAMPAIGNMASTER.CONTENTID,
  //     CAMPAIGNMASTER.SPECIALCAMPAIGNCODE,
  //     CAMPAIGNMASTER.TICKBOX,
  //     CAMPAIGNMASTER.TANDCLINKURL
  //   )
  // }

  useEffect(() => {
    if (member.state.data && !resetMemberFlag) {
      const { memberObject } = member.state.data
      const { response } = memberObject
      if (response && response.status === 400) {
        // token expired
        LocalStorage.addLS('campaignCode', campaignCode)
        setResetMemberFlag(true)
      } else {
        execFetchCampaign({
          query:
            '?campaignCode=' +
            campaignCode +
            '&publishFrom_lte=' +
            moment().format('YYYY-MM-DD') +
            '&publishTo_gte=' +
            moment().format('YYYY-MM-DD'),
        })
      }
    }
    if (resetMemberFlag) {
      setResetMemberFlag(false)
      LocalStorage.addLS('login', {})
      LocalStorage.addLS('campaignCode', campaignCode)
      props.history.push('/login')
    }
  }, [member.state])

  useEffect(() => {
    if (campaign.state.data) {
      const { campaignobj } = campaign.state.data
      const { login } = LocalStorage.getLS()
      const tokenPrepared = token ? token : login ? login.token : null
      const decoded = JSON.parse(atob(tokenPrepared.split('.')[1]))

      const campaignFound =
        campaignobj && campaignobj.length > 0 ? { ...campaignobj[0] } : null
      if (!campaignFound) {
        setBypass(true)
      } else {
        if (campaignCode !== CAMPAIGNMASTER.SPECIALCAMPAIGNCODE) {
          const query =
            '?userId=' +
            decoded.traderId +
            '&masterCampaignId=' +
            campaignFound.id

          fetchCampaignResponseExecute({ query })
        } else {
          setBypass(true)
        }
      }
    }
  }, [campaign.state])
  const doResetCR = async () => {
    await execCampaignResponseReset({})
    setResetCRDone(true)
  }
  useEffect(() => {
    if (campaignresponse2.state.data) {
      const { campaignresponse2obj } = campaignresponse2.state.data
      if (campaignresponse2obj.length > 0) {
        execCampaignResponseReset({})
        const currentlanguage =
          LocalStorage.getLS().translation.json.campaign_page
        props.history.push('/campaign/submit', {
          title: currentlanguage.alreadysubmittedtitle,
          message: currentlanguage.alreadysubmitteddescription,
        })
      } else {
        doResetCR()
        // setBypass(true)
      }
    }
  }, [campaignresponse2.state])

  const isCampaignReady =
    !campaign.state.isLoading &&
    campaign.state.data &&
    campaign.state.data.campaignobj &&
    campaign.state.isStatus
      ? true
      : false

  const isMemberDataReady =
    !member.state.isLoading &&
    member.state.data &&
    member.state.data.memberObject &&
    member.state.isStatus
      ? true
      : false
  // const isCampaignResponseDataReady =
  //   !campaignresponse.state.isLoading &&
  //   campaignresponse.state.data &&
  //   campaignresponse.state.data.campaignresponseobj &&
  //   campaignresponse.state.isStatus
  //     ? true
  //     : false
  // console.log(isCampaignReady, campaignresponse2, bypass)
  if (bypass) return props.children
  if (!resetCRDone || !isMemberDataReady || !isCampaignReady)
    return <Spinner2.Popup />
  return props.children
}

export default Index
