import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_PRIVATE_OFFER_LIST: 'FETCH_PRIVATE_OFFER_LIST',
  RESET: 'RESET',
}

const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}

const fetchPrivateOfferList = async (state, privateofferlist) => {
  state = {
    ...state,
    isStatus: privateofferlist.status,
    expireToken: sanitizeExpire({
      status: privateofferlist.status,
      data: privateofferlist.data,
      name: 'privateofferlistobj',
    }),
    data: sanitizeData({
      status: privateofferlist.status,
      data: privateofferlist.data,
      name: 'privateofferlistobj',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRIVATE_OFFER_LIST:
      return fetchPrivateOfferList(state, action.privateofferlist)
    case actionTypes.RESET:
      return fetchReset(state)
    default:
      return state
  }
}

const privateofferlist = {
  init,
  actionTypes,
  reducer,
}

export default privateofferlist
