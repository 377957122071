import React, { useState, useEffect } from 'react'

import Typography from '@material-ui/core/Typography'
import { Grid, Box, makeStyles } from '@material-ui/core'
import { Carousel } from 'react-responsive-carousel'
import { WindowSize, LocalStorage } from '../lib-atomic-functions'

const useStyles = makeStyles((theme) => ({
  banner: {
    //padding: 10,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: 'transparent',
  },
  bannerText: {
    paddingLeft: 10,
    paddingRight: 10,
    color: '#ffffff',
    '&a': {
      color: '#ffffff !important',
    },
  },
  textlink: {
    color: '#ffffff',
  },
}))

const index = (props) => {
  const classes = useStyles()
  const currentlanguage = LocalStorage.getLS().translation.json.mangebouger_FR
  if (!currentlanguage) return null

  const slidesRaw = Object.keys(currentlanguage).map((data, index) => {
    let link = null
    if (currentlanguage.mangebouger_link)
      link = currentlanguage.mangebouger_link
    if (data !== 'mangebouger_link') {
      return {
        text: currentlanguage[data],
        link,
      }
    }
  })
  const slides = slidesRaw.filter(Boolean)
  const isSlides = slides.length > 0 ? true : false
  if (!isSlides) return null

  const { width, height } = WindowSize()
  const calcWidth = width < 1000 ? width : 1000
  return (
    <Grid container spacing={0} direction="column" alignItems="center">
      <Grid
        className={classes.banner}
        // component={Box}
        item
      >
        <div
          className={classes.bannerText}
          style={{
            width: calcWidth,
          }}
        >
          <Carousel
            interval={12000}
            autoPlay={true}
            showStatus={false}
            showThumbs={false}
            width={'100%'}
            infiniteLoop={true}
            showIndicators={false}
          >
            {slides.map((data, index) => {
              return (
                <Typography key={index} variant="body2">
                  <span>
                    {data.text}{' '}
                    <a className={classes.textlink} href={data.link}>
                      {data.link}
                    </a>
                  </span>
                </Typography>
              )
            })}
          </Carousel>
        </div>
      </Grid>
    </Grid>
  )
}

export default index
