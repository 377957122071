import React, { useContext } from 'react'
/* package */
import { GlobalContext } from '../../lib-atomic-context'
import {
  ModulePaddedWrapperLg,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
} from '../../lib-atomic-design-section'
/* package */

/* container */
import HistoryContainer from '../../app-module-my-history-container/default'
import CMSContainer from '../../app-module-cms-container/default'
/* container */

/* modules */
import {
  WindowSize,
  WindowLayout,
  WindowFont,
  LocalStorage,
} from '../../lib-atomic-functions'
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
import LocationMap from '../locationMap/map'
import LeftReceipt from '../leftReceipt/receipt'
import RightReceipt from '../rightReceipt/receipt'
/* modules */

import List from '../list/list'
/* lazy modules */

const index = (props) => {
  const currentlanguage = LocalStorage.getLS().translation.json
  const { transactiondetail } = useContext(GlobalContext)
  const { transactiondetailobj } = transactiondetail.state.data
  const PLATFORM = 'web'
  const { width, height } = WindowSize()
  const {
    layoutWidth,
    layoutHeight,
    layoutCols,
    getColsWidthHeight,
    getFullWidth,
  } = WindowLayout(width, height)
  const fullWidth = width < 500 ? (width * 90) / 100 : getFullWidth(PLATFORM)
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        overflow: 'wrap',
        position: 'relative',
        width: fullWidth,
        //minHeight: 1000,
        margin: 'auto',
        marginTop: 2.5,
      }}
    >
      <div style={{ width: '100%' }}>
        <p
          style={{
            justifyContent: 'center',
            textAlign: 'center',
            color: '#444444',
            fontWeight: 900,
            fontSize: 16,
            marginTop: 50,
            fontFamily: 'SubwayFootlong',
          }}
        >
          {currentlanguage.transactionhistorypage.page_history_title}
        </p>
        <LocationMap {...props} />

        <ModulePaddedWrapperLg>
          {width < 500 ? (
            <ModuleFormWrapper>
              <LeftReceipt {...props} />
              <RightReceipt {...props} />
            </ModuleFormWrapper>
          ) : (
            <ModuleFormWrapper>
              <ModuleFormItemWrapper>
                <LeftReceipt {...props} />
              </ModuleFormItemWrapper>
              <ModuleFormItemWrapper>
                <RightReceipt {...props} />
              </ModuleFormItemWrapper>
            </ModuleFormWrapper>
          )}
        </ModulePaddedWrapperLg>
      </div>
    </div>
  )
}

export default index
