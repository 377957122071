import React, { useState, useContext, useEffect } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { FormHook, LocalStorage } from '../../lib-atomic-functions'
import Button from '@material-ui/core/Button'

import axios from 'axios'

const DialogBox = ({ onClose, selectedValue, open }) => {
  
  const currentlanguage = LocalStorage
        .getLS()
        .translation
        .json
        .popup_box;
  const [countries, setCountries] = useState([])
  const [selected, setSelected] = React.useState('nz')

  console.log("selectedValue",selectedValue);
  const handleClose = () => {
    onClose(selectedValue)
  }
  console.log("open",open)

  const handleListItemClick = abbr => {
    // onClose(value)
    setSelected(abbr)
  }
  if(selectedValue!= undefined){
    // console.log("sss",selectedValue)
    
    var outComeMessage = selectedValue.outComeMessage;
    var title = selectedValue.title;
  }




  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="select-country"
      open={open}
    >
<DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText style={{ fontSize: 'smaller', color:'black' }}>
         {outComeMessage}
        </DialogContentText>

      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          fullWidth={true}
          onClick={handleClose}
          variant="contained"
          color="primary"
        >
          {currentlanguage.close_button}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogBox
