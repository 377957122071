import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_UNSUBSCRIBE: 'FETCH_UNSUBSCRIBE',
  RESET: 'RESET',
}

const fetchReset = async (state) => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}

const fetchUnsubscribe = async (state, unsubscribe) => {
  state = {
    ...state,
    isStatus: unsubscribe.status,
    expireToken: sanitizeExpire({
      status: unsubscribe.status,
      data: unsubscribe.data,
      name: 'unsubscribeobj',
    }),
    data: sanitizeData({
      status: unsubscribe.status,
      data: unsubscribe.data,
      name: 'unsubscribeobj',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UNSUBSCRIBE:
      return fetchUnsubscribe(state, action.unsubscribe)
    case actionTypes.RESET:
      return fetchReset(state)
    default:
      return state
  }
}

const token = {
  init,
  actionTypes,
  reducer,
}

export default token
