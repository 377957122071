import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Global Container'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    cms: {
      api: {
        endpoints: {
          mastererrorresponse: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc:
              'https://stormborn-strapi.tr4ns.com/masterappversions?device=mob',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/mastererrorresponses',
            method: 'GET',
          },
          masterappversion: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc:
              'https://stormborn-strapi.tr4ns.com/masterappversions?device=mob',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/masterappversions',
            method: 'GET',
          },
          mastercountrylanguage: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/mastercountrylanguages',
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.cms.url[environment] + '/mastercountrylanguages',
            method: 'GET',
          },
          mastertranslation: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/mastertranslations',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/mastertranslations',
            method: 'GET',
          },
          masterbanners: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/masterbanners',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/masterbanners',
            method: 'GET',
          },
          masterbannerscontents: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/mastercontents?',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/mastercontents?',
            method: 'GET',
          },
          mastercontents: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'GET',
          },
          enquiry: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'token',
              moduleCode: setup.setup.thor.moduleCode[environment],
            },
            url: setup.setup.api.stormborn.url.mockapi + '/members',
            method: 'GET',
          },
        },
      },
    },
    newapi: {
      apiendpoints: {
        enquiry: {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'token',
            moduleCode: setup.setup.thor.moduleCode[environment],
          },
          url: setup.setup.api.stormborn.url.mockapi + '/members',
          method: 'GET',
        },
      },
    },
  },
  routes: {
    label: 'Pinned Box',
    prefix: '/',
  },
  setup,
}

export default config
