import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_ONLINEORDER: 'FETCH_ONLINEORDER',
  RESET: 'RESET',
}
const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}

const fetchVersion = async (state, order) => {
  // console.log(version, "test...");
  state = {
    ...state,
    isStatus: order.status,
    expireToken: sanitizeExpire({
      status: order.status,
      data: order.data,
      name: 'orderObject',
    }),
    data: sanitizeData({
      status: order.status,
      data: order.data,
      name: 'orderObject',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ONLINEORDER:
      return fetchVersion(state, action.order)
      case actionTypes.RESET:
        return fetchReset(state)
    default:
      return state
  }
}

const order = {
  init,
  actionTypes,
  reducer,
}

export default order
