const isConfirm = (data) => {
  const key = 'isConfirm'
  const value = data.value.trim()
  const config = data.config
  const confirmTo = data.allValues[config.confirm_to]
  const nullAllow = config.allow_null ? true : false
  const finalCheck =
    nullAllow && value.length < 1 && confirmTo.length < 1
      ? key
      : value === confirmTo
      ? false
      : key
  return finalCheck ? { error: finalCheck, data } : false
}

export default isConfirm
