import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_PAGES: 'FETCH_PAGES',
}

const fetchPages = async (state, pages) => {
  state = {
    ...state,
    isStatus: pages.status,
    expireToken: sanitizeExpire({
      status: pages.status,
      data: pages.data,
      name: 'masterpages',
    }),
    data: sanitizeData({
      status: pages.status,
      data: pages.data,
      name: 'masterpages',
    }),
  }
  //state.data.mastertranslation = state.data.mastertranslation[0].json;
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PAGES:
      return fetchPages(state, action.pages)
    default:
      return state
  }
}

const pages = {
  init,
  actionTypes,
  reducer,
}

export default pages
