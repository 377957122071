import React from 'react';
import '../styles.css';

const ErrorOverlay = (props) => {
  return (
  <>
    {props.outcomeCode !== "0" ? 
      <div className="invalidBackground">
        {props.outcomeCode === "GE_MAIN:VALIDATE_TOKEN:4" || props.outcomeCode === "GE_MAIN:VALIDATE_TOKEN:8" ?
        // If promotion has ended
        <div>
          <p class='invalidBackgroundbold'>Oops! It looks like the promotion has ended.</p>
        </div>
        :
        <div>
          <p class='invalidBackgroundsmall'>Oops! It looks like you've already pushed.</p>
          <p class='invalidBackgroundbold'>Buy another Footlong for another push!</p>
        </div>
        }
      </div>
      :
        null
      }
  </>
  );
};

export default ErrorOverlay;
