import React, { useContext } from 'react'

import { GlobalContext } from '../../lib-atomic-context'

import ScrollToTop from '../../../scrollToTop'

import { ModPublicWrapp } from '../../lib-atomic-design-section'
import ContactUsContainer from '../../app-module-contactus-container/default'
import ContactForm from '../form'
import ContactIframe from '../iframe'

import CONFIG from '../config'

const mediaPath = CONFIG.setup.assets.media

const index = (props) => {
  const { bannerscontents } = useContext(GlobalContext)
  const imgToDisplay = bannerscontents.state.data.masterbannerscontents.filter(
    (page) => {
      return page.pageName === 'ContactUs'
    }
  )[0]
  return (
    <ContactUsContainer {...props}>
      <ScrollToTop {...props} />
      <ModPublicWrapp {...props} imgToDisplay={imgToDisplay}>
        {/* <ContactForm {...props} /> */}
        <ContactIframe {...props} />
      </ModPublicWrapp>
    </ContactUsContainer>
  )
}

export default index
