import React, { useContext, useEffect } from 'react'
import { LocalStorage } from '../../lib-atomic-functions'
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2 } from '../../lib-atomic-functions'

import Spinner2 from '../../app-module-cms-section-page-spinner/default'
import CONFIG from '../config'

import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'

const ENDPOINTS = CONFIG.application.account.api.endpoints
const ENQUIRYCONFIG = {
  url: ENDPOINTS.enquiry.url,
  method: ENDPOINTS.enquiry.method,
  headers: ENDPOINTS.enquiry.headers,
  responseType: 'json',
}
const index = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  const { token } = LocalStorage.getLS().login
  const { member } = useContext(GlobalContext)

  //memberEnquiry

  const [fetchMemberDetail] = FetchContextApiV2(ENQUIRYCONFIG)

  const fetchMemberDetailExe = async ({ newHeader }) => {
    member.dispatch({
      type: member.actionTypes.FETCH_MEMBER,
      member: await fetchMemberDetail(
        {
          newHeader,
        },
        member
      ),
    })
  }
  const execResetMemberReset = async () => {
    member.dispatch({
      type: member.actionTypes.CLEAR_DATA,
    })
  }

  useEffect(() => {
    // GArecordPageView(cookies)
    const newHeader = {
      ...ENQUIRYCONFIG.headers,
      Authorization: 'Bearer ' + token,
    }
    fetchMemberDetailExe({ newHeader })
  }, [])

  useEffect(() => {
    if (member.state.data) {
      const { memberObject } = member.state.data
      const { response } = memberObject
      if (response && response.status === 400) {
        // token expired
        const { country, lang } = LocalStorage.getLS()
        // LocalStorage.removeLS()
        execResetMemberReset()
        LocalStorage.addLS('login', {})
        props.history.push('/login')
      }
      if (response && response.status === 401) {
        // unauthorised
        const { country, lang } = LocalStorage.getLS()
        // LocalStorage.removeLS()
        execResetMemberReset()
        LocalStorage.addLS('login', {})
        props.history.push('/login')
      }
    }
  }, [member.state])

  const isMemberDataReady =
    !member.state.isLoading &&
    member.state.data &&
    member.state.data.memberObject &&
    member.state.isStatus
      ? true
      : false
  if (!isMemberDataReady) return <Spinner2.Popup />

  return props.children
}

export default index
