import React, { useState, useEffect, useContext } from 'react'
// import { Link } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import { Captcha, Inputs, Buttons } from '../../lib-atomic'
import { FormHook, LocalStorage } from '../../lib-atomic-functions'
import {
  ModXlWrapp,
  ModPageTitleNarrowSpaceWrapp,
  ModTitleText,
  ModContentText,
  ModFormLabelText,
} from '../../lib-atomic-design-section'
// import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

import CONFIG from '../config'
// import verification from '../../lib-atomic-context/reducers/verificationReducer'

/* CONSTANTS */
const VERIFICATIONOBJECTS = CONFIG.application.account.objects
const ENDPOINTS = CONFIG.application.account.api.endpoints
const VERIFICATIONCONFIG = {
  url: ENDPOINTS.verification.url,
  method: ENDPOINTS.verification.method,
  headers: ENDPOINTS.verification.headers,
  responseType: 'json',
}
/* CONSTANTS */

const index = (props) => {
  const { country, lang } = LocalStorage.getLS()
  const currentlanguage = LocalStorage.getLS().translation.json

  /* state */
  /* ---- verification ----  */

  /* -- global state : states --  */
  const { verification, auth } = useContext(GlobalContext)
  /* -- global state : states --  */

  /* -- recaptcha : states -- */
  const [loaded, setLoaded] = useState(false)
  const [verified, setVerified] = useState(false)
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true)
      setLoaded(true)
    }
  }
  const expiredCallback = (response) => {
    setVerified(false)
  }
  /* -- recaptcha : states -- */

  /* -- execute verification : states --  */
  const [fetchVerification] = FetchContextApiV2(VERIFICATIONCONFIG)
  const execVerification = async (body) => {
    verification.dispatch({
      type: verification.actionTypes.FETCH_VERIFICATION,
      verification: await fetchVerification({ body }, verification),
    })
  }
  const execVerificationReset = async () => {
    verification.dispatch({
      type: verification.actionTypes.RESET,
    })
  }
  /* -- execute verification : states --  */

  /* -- form handles : states -- */
  const handleVerification = async () => {
    if (verified && loaded) {
      const body = {
        email: values.email,
      }
      execVerification(body)
    }
  }
  /* -- form handles : states -- */

  /* -- effects : verification : use-effects */
  useEffect(() => {
    if (verification.state.data) {
      try {
        const { isStatus, data } = verification.state
        const { verificationobject } = data
        if (!verificationobject) throw { status: isStatus, err: false }
        const { outComeCode } = verificationobject
        if (outComeCode !== '0')
          throw { status: isStatus, err: verificationobject }
        execRedirects()
        //handleClearLS();
        //window.location.replace(generatepaymentobject.uri)
      } catch ({ status, err }) {
        execRedirects({ status, err })
        //if (!e.status) handleClearLS()
      }
    }
  }, [verification.state])
  /* -- effects : verification : use-effects */

  const execRedirects = async (e) => {
    // console.log(errorResponse)
    // const { verificationAccount } = errorResponse
    const { isStatus, data } = verification.state
    const { verificationobject } = data
    if (e && !e.status && e.err) {
      const { response } = verificationobject
      const outComeCode = response
        ? response.data.outComeCode
        : verificationobject.outComeCode
      // const { outComeCode } = response.data
      const key = outComeCode.toString()
      const manipulatedOutComeCode = key.replace('\r\n', '')
      const isSuccessfullyUpdated =
        data && manipulatedOutComeCode === '0' ? true : false
      const message = currentlanguage.errorresponse_resendCode[
        manipulatedOutComeCode
      ]
        ? currentlanguage.errorresponse_resendCode[manipulatedOutComeCode]
        : 'Unknown Error'
      execVerificationReset()
      props.history.push('/verification/resend/confirm', {
        isSuccessfullyUpdated,
        message,
      })
    } else {
      if (verificationobject) {
        const { outComeCode } = verificationobject
        const key = outComeCode.toString()
        const manipulatedOutComeCode = key.replace('\r\n', '')
        const isSuccessfullyUpdated =
          data && manipulatedOutComeCode === '0' ? true : false
        const message = currentlanguage.errorresponse_resendCode[
          manipulatedOutComeCode
        ]
          ? currentlanguage.errorresponse_resendCode[manipulatedOutComeCode]
          : 'Unknown Error'
        await execVerificationReset()
        props.history.push('/verification/resend/confirm', {
          isSuccessfullyUpdated,
          message,
        })
      }
    }
  }

  /* ---- verification ----  */
  /* state */

  /* -- data props --   */
  const { isLoading } = verification.state
  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleVerification,
    VERIFICATIONOBJECTS
  )
  const email = {
    ...VERIFICATIONOBJECTS.email,
    error: error.email,
    value: values.email,
    onChange,
    translation: currentlanguage,
  }
  const isSubmitButtonDisabled =
    !verified || !loaded || isLoading ? true : false
  const submitButton = { fullWidth: true }
  const backButton = {
    fullWidth: true,
    onClick: () => {
      props.history.push('/verification')
    },
  }
  const captchaData = {
    verified,
    verifyCallback,
    expiredCallback,
  }
  /* -- data props --   */

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModXlWrapp>
        <ModPageTitleNarrowSpaceWrapp>
          <ModTitleText>
            {currentlanguage.verification.page_verify_verify}
          </ModTitleText>
        </ModPageTitleNarrowSpaceWrapp>
        <ModContentText>
          <p style={{ textAlign: 'center', marginBottom: 40 }}>
            {currentlanguage.verification.page_verify_email_verif}
          </p>
        </ModContentText>
      </ModXlWrapp>
      <ModXlWrapp>
        <Inputs.Text {...email} />
      </ModXlWrapp>
      {/* <Recaptcha
        sitekey="6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE"
        render="explicit"
        verifyCallback={verifyCallback}
      /> */}
      <Captcha.Basic {...captchaData} />
      <br />
      <ModXlWrapp>
        <Buttons.Normal {...submitButton}>
          {currentlanguage.verification.page_verify_submit}
        </Buttons.Normal>
      </ModXlWrapp>
      <br />
      <ModXlWrapp>
        <Buttons.Text {...backButton}>
          {currentlanguage.verification.page_verify_back}
        </Buttons.Text>
      </ModXlWrapp>
    </form>
  )
}

export default index
