import React, { useEffect, useContext, useState } from 'react'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import { LocalStorage } from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
/* module */
import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'

import CONFIG from '../config'

const ENDPOINTS = CONFIG.application.config.api.endpoints
// const NEWAPIMOCKUP = CONFIG.application.newapi.apiendpoints
const ENQUIRYCONFIG = {
  url: ENDPOINTS.enquiry.url,
  method: ENDPOINTS.enquiry.method,
  headers: ENDPOINTS.enquiry.headers,
  responseType: 'json',
}
const index = (props) => {
  
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  const [resetMemberFlag, setResetMemberFlag] = useState(false)
  const { member, verification } = useContext(GlobalContext)
  const [fetchMemberDetail] = FetchContextApiV2(ENQUIRYCONFIG)

  const fetchMemberDetailExe = async ({ newHeader }) => {
    member.dispatch({
      type: member.actionTypes.FETCH_MEMBER,
      member: await fetchMemberDetail({ newHeader }, member),
    })
  }

  const execMemberReset = async () => {
    member.dispatch({
      type: member.actionTypes.CLEAR_DATA,
    })
  }
  // member data ////////////////////////////
  useEffect(() => {
    // GArecordPageView(cookies)
    const { token } = LocalStorage.getLS().login
    if (!token) {
      props.history.push('/home')
      return null
    }
    const newHeader = {
      ...ENQUIRYCONFIG.headers,
      Authorization: 'Bearer ' + token,
    }
    fetchMemberDetailExe({ newHeader })
  }, [])

  useEffect(() => {
    if (resetMemberFlag) {
      execMemberReset()
    }
  }, [resetMemberFlag])
  useEffect(() => {
    if (member.state.data && !resetMemberFlag) {
      const { memberObject } = member.state.data
      const { response } = memberObject
      if (response && response.status === 400) {
        // token expired
        setResetMemberFlag(true)
      }
      // console.log(member)
    }
    if (resetMemberFlag) {
      setResetMemberFlag(false)
      const { country, lang } = LocalStorage.getLS()
      LocalStorage.addLS('login', {})
      props.history.push('/login')
    }
  }, [member.state])

  const isMemberDataReady =
    !member.state.isLoading &&
    member.state.data &&
    member.state.data.memberObject &&
    member.state.isStatus
      ? true
      : false
  if (!isMemberDataReady) return <Spinner2.Popup />
  return props.children
}

export default index
