const isNumberAndLetterPostcode = (data) => {
  
  // const pattern = /^[a-zA-Z0-9]+$/
  // const { value } = data
  // value.trim()
  // return value.match(pattern) ? true : ''

  const { value } = data
  const pattern = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/
  const key = 'isNumberAndLetter'
  if (value==""){
    return false;
  }else {
  const isTestOk = pattern.test(value) ? true : false
  const error = isTestOk ? false : key
  return error ? { error, data } : false
}
}

export default isNumberAndLetterPostcode
