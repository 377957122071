import React, { Fragment, useContext, useState } from 'react'

import {
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { LocalStorage } from '../../components/lib-atomic-functions'
import appConf from '../../components/app-conf'
import CountrySelector from './country-selector'
import MenuDrawer from './menu-drawer'

import Link from '../../components/lib-atomic/link'

const logo = appConf[process.env.REACT_APP_CLIENT].assets.logo

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    width: '100%',
  },
}))
const Header = (props) => {
  const classes = useStyles()
  const [isVisible, setIsVisible] = useState(false)
  const token = LocalStorage.getLS()
    ? LocalStorage.getLS().login
      ? LocalStorage.getLS().login.token
        ? LocalStorage.getLS().login.token
        : false
      : false
    : false
  const drawerProps = { ...props, isVisible, setIsVisible }
  return (
    <Fragment>
      <AppBar position="sticky" color="primary" className={classes.appBar}>
        <Toolbar>
          <Grid container>
            <Grid
              container
              item
              justify="flex-start"
              alignItems="center"
              xs={4}
            >
              <CountrySelector />
            </Grid>
            <Grid container item justify="center" alignItems="center" xs={4}>
              {token ? (
                <Link to="/dashboard">
                  <img src={logo} alt="logo" width="120" />
                </Link>
              ) : (
                <Link to="/home">
                  <img src={logo} alt="logo" width="120" />
                </Link>
              )}
            </Grid>
            <Grid container item justify="flex-end" alignItems="center" xs={4}>
              <IconButton
                aria-label="open drawer"
                onClick={() => {
                  setIsVisible(!isVisible)
                }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <MenuDrawer {...drawerProps} />
    </Fragment>
  )
}

export default Header
