import React, { useContext, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'

/* packages */
import { Lists } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import { NumberCurrency, LocalStorage } from '../../lib-atomic-functions'
import { ModuleText } from '../../lib-atomic-design-section'

const IDTPL = (data, obj) => {
  const link = '/orders/detail/' + data
  return (
    <ModuleText>
      <Link to={link}>
        <b>{data}</b>
      </Link>
    </ModuleText>
  )
}
//   const AMOUNTTPL = (data, obj) => {
//     const amount = NumberCurrency(data);
//     return <ModuleText>${amount}</ModuleText>
//   }
//   const COMPANYTPL = (data, obj) => {
//     return <Fragment>
//       <ModuleText>
//         <b>{data}</b>
//       </ModuleText>
//     </Fragment>
//   }
const TOTALTPL = (data, obj) => {
  if (!data) return null
  const amount = NumberCurrency(data.toFixed(2))
  return (
    <Fragment>
      <ModuleText>${amount}</ModuleText>
    </Fragment>
  )
}
const DATETPL = (data, obj) => {
  return (
    <ModuleText>
      <b>
        <Moment format="DD/MM/YYYY">{data}</Moment>
      </b>{' '}
      <br />
      <small>
        <Moment format="HH:mm:ss">{data}</Moment>
      </small>{' '}
      <br />
      <small>
        <Moment fromNow>{data}</Moment>
      </small>
    </ModuleText>
  )
}
const LABEL = (data) => {
  return (
    <ModuleText>
      <br />
      <small>
        <b>{data}</b>
      </small>
      <br />
      <br />
    </ModuleText>
  )
}

const LISTTEMPLATECONFIG = [
  { field: 'id', template: IDTPL, label: LABEL('ORDER #') },
  { field: 'date', template: DATETPL, label: LABEL('DATE') },
  { field: 'spent', template: TOTALTPL, label: LABEL('SPENT') },
  { field: 'points', template: LABEL, label: LABEL('POINTS') },
]

const transactionList = (props) => {
  const { country, lang } = LocalStorage.getLS()
  const { transactionlist } = useContext(GlobalContext)
  // const { token } = LocalStorage.getLS().login;
  const { transactionlistobj } = transactionlist.state.data
  const listData = {
    ...props,
    data: transactionlistobj.slice(0, 20),
    config: LISTTEMPLATECONFIG,
    link: '/history/',
  }
  /* state */

  return <Lists.Normal {...listData} />
}

export default transactionList
