import React, { useContext, useEffect } from 'react'
/* package */
import { GlobalContext } from '../../lib-atomic-context'
import {
  ModulePaddedWrapperLg,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
} from '../../lib-atomic-design-section'
/* package */

import ScrollToTop from '../../../scrollToTop'

/* container */
import HistoryContainer from '../../app-module-my-history-container/default'
import Contents from '../../app-module-my-history-page-detail/content/content'
/* container */
import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'

const index = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  useEffect(() => {
    // GArecordPageView(cookies)
  }, [])
  return (
    <HistoryContainer {...props}>
      <ScrollToTop {...props} />
      <Contents {...props} />
    </HistoryContainer>
  )
}

export default index
