import { useContext } from 'react'

/* packages */
// import Fetch from '../fetch';
import FetchBlob from '../fetch-blob'
import FetchJson from '../fetch-json'
/* packages */

const index = ({ url, method, headers, responseType }, contextStore) => {
  const funcContext = useContext(contextStore)
  const funcMethod = async ({ body, query }) => {
    funcContext.setLoading({
      loading: true,
      status: true,
      data: { blob: false, json: false },
    })
    var new_url = JSON.parse(JSON.stringify(url))
    if (query) new_url = new_url + query
    let fetch = null
    if (responseType === 'blob') {
      // blob
      fetch = await FetchBlob({
        url: new_url,
        config: {
          method,
          headers,
          body,
        },
      })
    } else {
      // json
      fetch = await FetchJson({
        url: new_url,
        config: {
          method,
          headers,
          body,
        },
      })
    }
    // console.log(fetch)
    funcContext.setLoading({ loading: false, status: fetch[0], data: fetch[1] })
  }
  return [funcContext, funcMethod]
}

export default index
