import React, { useRef, useEffect, useState, Fragment, useContext } from 'react'
import Recaptcha from 'react-recaptcha'

import { GlobalContext } from '../../lib-atomic-context'

import { Inputs, Buttons, Invalid, Link } from '../../lib-atomic'
import {
  LocalStorage,
  FormHook,
  WindowSize,
  WindowLayout,
} from '../../lib-atomic-functions'
import {
  ModXlWrapp,
  ModFormLabelText,
  ModContentText,
} from '../../lib-atomic-design-section'
//dialogBox
import DialogBox from '../../lib-atomic/dialog/dialogbox'
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
import { FetchContextApiV2, Geolocation } from '../../lib-atomic-functions'

import CONFIG from '../config'

/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.account.objects
const ENDPOINTS = CONFIG.application.account.api.endpoints
const IOUCONFIG = {
  url: ENDPOINTS.processiou.url,
  method: ENDPOINTS.processiou.method,
  headers: ENDPOINTS.processiou.headers,
  responseType: 'json',
}
/* CONSTANTS */

const index = (props) => {
  const { country, lang } = LocalStorage.getLS()
  const currentlanguage = LocalStorage.getLS().translation.json
  const PLATFORM = 'web'
  const { width, height } = WindowSize()
  const {
    layoutWidth,
    layoutHeight,
    layoutCols,
    getColsWidthHeight,
    getFullWidth,
  } = WindowLayout(width, height)
  const fullWidth = width < 500 ? (width * 90) / 100 : getFullWidth(PLATFORM)

  const { token } = LocalStorage.getLS().login
  const { member, processiou } = useContext(GlobalContext)
  const { memberObject } = member.state.data
  const { loyaltyBalance } = memberObject
  const [loaded, setLoaded] = useState(false)
  const [open, setOpen] = useState(false)
  const [openpop, setOpenpop] = useState(false)
  const [response, setresponse] = React.useState()

  const [fetchIouProcess] = FetchContextApiV2(IOUCONFIG)

  const execFetchIouProcess = async ({ newHeader, body }) => {
    processiou.dispatch({
      type: processiou.actionTypes.FETCH_PROCESS_IOU,
      // transactionlist: { status: true, data: { blob: null, json: dummy } },
      processiou: await fetchIouProcess({ newHeader, body }, processiou),
    })
  }

  const execIouReset = () => {
    processiou.dispatch({
      type: processiou.actionTypes.RESET,
    })
  }
  useEffect(() => {
    try {
      const { isStatus, data } = processiou.state
      const { processiouobj } = data
      if (!processiouobj) throw { status: isStatus, err: false }
      const { outComeCode } = processiouobj
      if (outComeCode !== '0') throw { status: isStatus, err: processiouobj }
      execRedirects()
    } catch ({ status, err }) {
      execRedirects({ status, err })
    }
  }, [processiou.state])
  /* -- effects : verification : use-effects */

  const execRedirects = (e) => {
    if (!e.status && e.err) {
      const { isStatus, data } = processiou.state
      const { processiouobj } = data
      const { response } = processiouobj
      const outComeCode = response
        ? response.data.outComeCode
        : processiouobj.outComeCode
      // const { outComeCode } = response.data
      const key = outComeCode.toString()
      const manipulatedOutComeCode = key.replace('\r\n', '')
      const isSuccessfullyUpdated =
        data && manipulatedOutComeCode === '0' ? true : false
      const message = currentlanguage.errorresponse_iou[manipulatedOutComeCode]
        ? currentlanguage.errorresponse_iou[manipulatedOutComeCode]
        : 'Unknown Error'
      execIouReset()

      setOpen(false)
      setOpenpop(true)
      var messageToBePoppedUp = {
        outComeCode: '0',
        title: '',
        outComeMessage: message,
      }
      setresponse(messageToBePoppedUp)

      // props.history.push('/web/' + country + '/' + lang + '/iou/confirm', {
      //   isSuccessfullyUpdated,
      //   message,
      // })
    } else {
      // verification fetch success
      // console.log('default', e)
      // here we go to login page
      // 1. fetch token from this page
      // 2. fetch token from login form then save that from here

      const { isStatus, data } = processiou.state
      const { processiouobj } = data
      if (processiouobj) {
        const { outComeCode } = processiouobj
        const key = outComeCode.toString()
        const manipulatedOutComeCode = key.replace('\r\n', '')
        const isSuccessfullyUpdated =
          data && manipulatedOutComeCode === '0' ? true : false
        const message = currentlanguage.errorresponse_iou[
          manipulatedOutComeCode
        ]
          ? currentlanguage.errorresponse_iou[manipulatedOutComeCode]
          : 'Unknown Error'
        execIouReset()

        setOpen(false)
        setOpenpop(true)
        var messageToBePoppedUp = {
          outComeCode: outComeCode,
          title: '',
          outComeMessage: message,
        }
        setresponse(messageToBePoppedUp)

        // props.history.push('/web/' + country + '/' + lang + '/iou/confirm', {
        //   isSuccessfullyUpdated,
        //   message,
        // })
        // 3. go to home page
      }
    }
  }

  const handleLogin = async () => {
    setOpen(true)
    const iouNum =
      iou1.value + iou2.value + iou3.value + iou4.value + iou5.value
    const body = {
      iou: iouNum,
    }
    setLoaded(true)
    // await fetchTokenExecute({ body })
    const newHeader = {
      ...IOUCONFIG.headers,
      Authorization: token,
    }
    await execFetchIouProcess({ newHeader, body })
  }

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleLogin,
    LOGINOBJECTS
  )
  useEffect(() => {
    if (values.iou1.length > 3) {
      iou2.inputRef.current.focus()
    }
    if (values.iou1.length === 4 && values.iou2.length > 3) {
      iou3.inputRef.current.focus()
    }
    if (
      values.iou1.length === 4 &&
      values.iou2.length === 4 &&
      values.iou3.length > 3
    ) {
      iou4.inputRef.current.focus()
    }
    if (
      values.iou1.length === 4 &&
      values.iou2.length === 4 &&
      values.iou3.length === 4 &&
      values.iou4.length > 3
    ) {
      iou5.inputRef.current.focus()
    }
  }, [values])
  
  const iou1 = {
    ...LOGINOBJECTS.iou1,
    error: error.iou1,
    value: values.iou1,
    placeholder:currentlanguage.iou.page_iou_receipt_number,
    onChange,
    inputRef: useRef(null),
    translation: currentlanguage,
  }
  const iou2 = {
    ...LOGINOBJECTS.iou2,
    error: error.iou2,
    value: values.iou2,
    placeholder:currentlanguage.iou.page_iou_receipt_number,
    onChange,
    inputRef: useRef(null),
    translation: currentlanguage,
  }
  const iou3 = {
    ...LOGINOBJECTS.iou3,
    error: error.iou3,
    value: values.iou3,
    placeholder:currentlanguage.iou.page_iou_receipt_number,
    onChange,
    inputRef: useRef(null),
    translation: currentlanguage,
  }
  const iou4 = {
    ...LOGINOBJECTS.iou4,
    error: error.iou4,
    value: values.iou4,
    placeholder:currentlanguage.iou.page_iou_receipt_number,
    onChange,
    inputRef: useRef(null),
    translation: currentlanguage,
  }
  const iou5 = {
    ...LOGINOBJECTS.iou5,
    error: error.iou5,
    value: values.iou5,
    placeholder:currentlanguage.iou.page_iou_receipt_number,
    onChange,
    inputRef: useRef(null),
    translation: currentlanguage,
  }
  const submitButton = { fullWidth: true, style: { color: '#fff !important' } }

  const handleClose = (value) => {
    setOpenpop(false)
    // setresponse(false)
  }
  return (
    <form onSubmit={onSubmit} noValidate>
      <div
        style={{
          overflow: 'wrap',
          position: 'relative',
          width: fullWidth,
          //minHeight: 1000,
          margin: 'auto',
          // marginTop: 2.5,
          fontFamily: 'SubwayFootlong',
        }}
      >
        <p
          style={{
            color: '#038d43',
            fontSize: 20,
            fontWeight: 700,
            letterSpacing: 1.5,
            textAlign: 'center',
            fontFamily: 'SubwayFootlong',
          }}
        >
          {currentlanguage.iou.page_iou_title_my_total}
        </p>
        <p
          style={{
            color: '#038d43',
            fontSize: 80,
            fontWeight: 700,
            letterSpacing: 1.5,
            textAlign: 'center',
            fontFamily: 'SubwayFootlong',
          }}
        >
          {loyaltyBalance}
        </p>
        <div style={{ textAlign: 'center', paddingBottom: 20 }}>
          <ModContentText>
            <p
              style={{
                fontSize: 16,
              }}
            >
              {currentlanguage.iou.page_iou_title_double_points}
            </p>
          </ModContentText>
        </div>
        {/* <ModXlWrapp> */}
        <div
          style={{
            height: 1,
            width: '100%',
            backgroundColor: '#bbbbbb',
            marginTop: 10,
            marginBottom: 40,
          }}
        />
        {/* </ModXlWrapp> */}
        <ModXlWrapp>
          <ModFormLabelText>
            <p
              style={{
                color: '#444444',
                fontSize: 20,
                textAlign: 'center',
              }}
            >
              {currentlanguage.iou.page_iou_title_claim}
            </p>
          </ModFormLabelText>
        </ModXlWrapp>
        <ModXlWrapp>
          <ModContentText>
            <p
              style={{
                color: '#444444',
                textAlign: 'center',
                width: width < 500 ? '90%' : '50%',
                justifyContent: 'center',
                margin: 'auto',
                marginTop: width < 500 ? 15 : 30,
                marginBottom: 20,
                fontSize: 16,
              }}
            >
              {currentlanguage.iou.page_iou_forgotten}
            </p>
          </ModContentText>
        </ModXlWrapp>
        <ModXlWrapp>
          <ModContentText>
            <p
              style={{
                textAlign: 'center',
                width: '90%',
                justifyContent: 'center',
                margin: 'auto',
                marginTop: 20,
                marginBottom: 50,
                fontSize: 16,
              }}
            >
              {currentlanguage.iou.page_iou_receipt_copy}
            </p>
          </ModContentText>
        </ModXlWrapp>
        {width < 500 ? (
          <ModXlWrapp>
            <div style={{ display: 'flex' }}>
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Inputs.Max4Digits {...iou1} />
              </div>
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Inputs.Max4Digits {...iou2} />
              </div>
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Inputs.Max4Digits {...iou3} />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                width: '67%',
                margin: 'auto',
              }}
            >
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Inputs.Max4Digits {...iou4} />
              </div>
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Inputs.Max4Digits {...iou5} />
              </div>
            </div>
          </ModXlWrapp>
        ) : (
          <ModXlWrapp>
            <div style={{ display: 'flex' }}>
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Inputs.Max4Digits {...iou1} />
              </div>
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Inputs.Max4Digits {...iou2} />
              </div>
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Inputs.Max4Digits {...iou3} />
              </div>
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Inputs.Max4Digits {...iou4} />
              </div>
              <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Inputs.Max4Digits {...iou5} />
              </div>
            </div>
          </ModXlWrapp>
        )}

        <ModXlWrapp>
          <ModContentText>
            <p
              style={{
                fontSize: 16,
                textAlign: 'center',
                width: '90%',
                justifyContent: 'center',
                margin: 'auto',
                marginTop: 40,
                marginBottom: 40,
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    currentlanguage.iou.page_iou_receipt_copy_2 +
                    currentlanguage.iou.page_iou_points_copy,
                }}
              />
              {/* {currentlanguage.iou.page_iou_receipt_copy_2}
              
              {currentlanguage.iou.page_iou_points_copy} */}
            </p>
          </ModContentText>
        </ModXlWrapp>

        <div
          style={{
            margin: 'auto',
            textAlign: 'center',
            width: width < 500 ? '100%' : '50%',
          }}
        >
          <Buttons.Normal
            {...submitButton}
            disabled={
              !(
                iou1.value.length === 4 &&
                iou2.value.length === 4 &&
                iou3.value.length === 4 &&
                iou4.value.length === 4 &&
                iou5.value.length === 4
              )
            }
          >
            <p
              style={{
                lineHeight: 1.1,
                justifyContent: 'center',
                margin: 'auto',
                padding: 5,
              }}
            >
              {currentlanguage.iou.page_iou_add_points}
            </p>
          </Buttons.Normal>
        </div>
      </div>

      {open && <Spinner2.Popup selectedValue={processiou.state.isLoading} />}
      {response && (
        <DialogBox
          selectedValue={response}
          open={openpop}
          onClose={handleClose}
        />
      )}
    </form>
  )
}

export default index
