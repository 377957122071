import React, { useEffect, Fragment, useState } from 'react'
import SettingsIcon from '@material-ui/icons/Settings'

const index = (props) => {
  function getCookie(cname) {
    let name = cname + '='
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return false
  }
  console.log('cookiesss',document.cookie)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    console.log('getCookie: ', getCookie('OptanonAlertBoxClosed'))
    if (
      getCookie('OptanonAlertBoxClosed') &&
      getCookie('OptanonAlertBoxClosed').length > 0
    ) {
      setLoading(true)
    }
  }, [])
  useEffect(() => {
    console.log('getCookie: ', getCookie('OptanonAlertBoxClosed'))
    if (
      getCookie('OptanonAlertBoxClosed') &&
      getCookie('OptanonAlertBoxClosed').length > 0
    ) {
      setLoading(true)
    }
  }, [document.cookie])
  return (
    loading && (
      <Fragment>
        <div id="loadingCOOK" style={{ position: 'relative' }}>
          <button
            id="ot-sdk-btn"
            className="ot-sdk-show-settings btn btn-primary"
            style={{
              position: 'absolute',
              top: 15,
              left: 15,
              borderRadius: 15,
              backgroundColor: '#008938',
              padding: 3,
              alignSelf: 'center',
              borderStyle: 'none',
              zIndex: 99,
            }}
            title="Cookie Settings"
          >
            <SettingsIcon
              id="ot-sdk-btn"
              aria-haspopup="true"
              style={{ width: 25, height: 25, color: 'white', zIndex: 90 }}
            />
          </button>
        </div>
      </Fragment>
    )
    // {/* <CookieBox {...data} /> */}
  )
}

export default index
