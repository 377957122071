import React from 'react'

/* mui */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
/* mui */

/* CONSTANTS */
const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: '#111111',
    fontSize: 25,
  },
}))
/* CONSTANTS */

const title = (props) => {
  /* mui */
  const classes = useStyles()
  /* mui */

  return (
    <Typography variant="h4" className={classes.title}>
      <p className="subway-footlong">{props.children}</p>
    </Typography>
  )
}

export default title
