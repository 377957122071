import React, { Fragment } from 'react'

import Checkbox from '@material-ui/core/Checkbox'

/* atoms */
import { Invalid } from '../feedbacks'
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

const checkbox = props => {
  const { name, value, label } = props
  return (
    <Fragment>
      <span>{label} </span>
      {value && <Checkbox checked value={name} {...props} />}
      {!value && <Checkbox value={name} {...props} />}

      <Invalid {...props} />
    </Fragment>
  )
}

export default checkbox
