import React from 'react'

/* mui */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
/* mui */

/* CONSTANTS */
const useStyles = makeStyles((theme) => ({
  text: {
    padding: '2px',
    color: '#333333',
  },
}))
/* CONSTANTS */

const title = (props) => {
  /* mui */
  const classes = useStyles()
  /* mui */

  return (
    <Typography variant="body2" className={classes.text}>
      <p className="subway-six-inch">{props.children}</p>
    </Typography>
  )
}

export default title
