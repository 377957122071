import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]

const config = {
  routes: {
    label: 'Subsquad',
    prefix: '/subsquad',
  },
  setup,
}

export default config
