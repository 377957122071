const isDate = (data) => {
  const key = 'isDate'
  const { value } = data
  if (value === '') {
    return false
  } else {
    const config = data.config
    const format = config.format
    const delimiter = config.delimiter
    const ymd = {
      '-': /^(\d{4})-(\d{1,2})-(\d{1,2})$/,
      '/': /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/,
    }
    const mdy = {
      '-': /^(\d{1,2})-(\d{1,2})-(\d{4})$/,
      '/': /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/,
    }
    const dmy = {
      '-': /^(\d{1,2})-(\d{1,2})-(\d{4})$/,
      '/': /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/,
    }
    let isValid = false
    switch (format) {
      case 'yyyymmdd':
        isValid = ymd[delimiter].test(value)
        break
      case 'mmddyyyy':
        isValid = mdy[delimiter].test(value)
        break
      case 'ddmmyyyy':
        isValid = dmy[delimiter].test(value)
        break
      default:
        break
    }
    const error = isValid ? false : key
    return error ? { error, data } : false
    // return isValid ? true : 'isDate'
  }
}

export default isDate
