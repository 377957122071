import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_COUNTRY: 'FETCH_COUNTRY',
}

const fetchCountry = async (state, cmscountry) => {
  state = {
    ...state,
    isStatus: cmscountry.status,
    expireToken: sanitizeExpire({
      status: cmscountry.status,
      data: cmscountry.data,
      name: 'mastercountry',
    }),
    data: sanitizeData({
      status: cmscountry.status,
      data: cmscountry.data,
      name: 'mastercountry',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_COUNTRY:
      return fetchCountry(state, action.cmscountry)
    default:
      return state
  }
}

const cmscountry = {
  init,
  actionTypes,
  reducer,
}

export default cmscountry
