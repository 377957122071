const isNotNull = (data) => {
  const key = 'isNotEmpty'
  const { value } = data
  const isEmpty = value === '' || value === null ? true : false
  const error = isEmpty ? key : false
  console.log(error, data, ' error, data ERRR')
  return error ? { error, data } : false
}

export default isNotNull
