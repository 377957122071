import { sanitizeData, sanitizeExpire } from './Sanitize'

const init = {
  isLoading: false,
  isStatus: true,
  expireToken: false,
  data: false,
}

const actionTypes = {
  FETCH_REWARDS_CONTENTS: 'FETCH_REWARDS_CONTENTS',
}

const fetchContents = async (state, rewardsContents) => {
  state = {
    ...state,
    isStatus: rewardsContents.status,
    expireToken: sanitizeExpire({
      status: rewardsContents.status,
      data: rewardsContents.data,
      name: 'rewardscontents',
    }),
    data: sanitizeData({
      status: rewardsContents.status,
      data: rewardsContents.data,
      name: 'rewardscontents',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REWARDS_CONTENTS:
      return fetchContents(state, action.rewardsContents)
    default:
      return state
  }
}

const rewardsContents = {
  init,
  actionTypes,
  reducer,
}

export default rewardsContents
