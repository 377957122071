import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

// import // WindowSize,
// WindowLayout,
// WindowFont
// '../../lib-atomic-functions'
// import moment from 'moment'

const actionTypes = {
  FETCH_SETUPS: 'FETCH_SETUPS',
  FETCH_CLEAN_SETUPS: 'FETCH_CLEAN_SETUPS',
}

//
// utility functions
//
// const isBetweenTwoDate = (theDate, PublishFrom, PublishTo) => {
//   const noFromDate = PublishFrom === null
//   const noToDate = PublishTo === null
//   const publishFrom = noFromDate
//     ? ' '
//     : moment(PublishFrom).format('YYYY MM DD')
//   const publishTo = noToDate ? ' ' : moment(PublishTo).format('YYYY MM DD')
//   const isBetweenFromTo =
//     publishFrom === ' ' && publishTo === ' '
//       ? true
//       : moment(theDate).isBetween(
//           moment(PublishFrom).format('YYYY MM DD'),
//           moment(PublishTo).format('YYYY MM DD')
//         )
//   return isBetweenFromTo
// }

const contentVisibilityBasedOnExpiryDate = (layout, authToken) => {

  const isCarousel = layout.mastercontents.type === 'Carousel'
  if (isCarousel) {
    const itemToUseFromCarouselContents = []
    if (
      layout.mastercontents.CarouselContents === undefined ||
      layout.mastercontents.CarouselContents.length === 0
    )
      return null
    layout.mastercontents.CarouselContents.map((item) => {
      // const isBetweenFromTo = isBetweenTwoDate(
      //   moment(),
      //   item.mastercontents.PublishFrom,
      //   item.mastercontents.PublishTo
      // );
      if (item.mastercontents.contentVisibility === 'postlogin' && !authToken)
        return null
      // if (isBetweenFromTo) itemToUseFromCarouselContents.push(item);
      itemToUseFromCarouselContents.push(item)
      return null
    })
    if (itemToUseFromCarouselContents.length === 0) {
      return null
    } else {
      return itemToUseFromCarouselContents
    }
  } else {
    // const isBetweenFromTo = isBetweenTwoDate(
    //   moment(),
    //   layout.mastercontents.PublishFrom,
    //   layout.mastercontents.PublishTo
    // )
    if (layout.mastercontents.contentVisibility === 'postlogin' && !authToken)
      return null
    // if (!isBetweenFromTo) return null;
  }
}

// sort content from coordinates from left to right, top to bottom
const sortContentByCoordinate = (arrayObject, authToken) => {
  if (!authToken) {
    const x = arrayObject.sort((item1, item2) => {
      if (item1.startX < item2.startX) return -1
      if (item1.startX > item2.startX) return 1
    })
    const y = x.sort((item1, item2) => {
      if (item1.startY < item2.startY) return -1
      if (item1.startY > item2.startY) return 1
    })
    return y
  } else {
    const x = arrayObject.sort((item1, item2) => {
      if (item1.startXForPostLogin < item2.startXForPostLogin) return -1
      if (item1.startXForPostLogin > item2.startXForPostLogin) return 1
    })
    const y = x.sort((item1, item2) => {
      if (item1.startYForPostLogin < item2.startYForPostLogin) return -1
      if (item1.startYForPostLogin > item2.startYForPostLogin) return 1
    })
    return y
  }
}
// check if content has invalid coordinates based in preview edit update
const isInvalidCoordinate = (layout, authToken) => {
  const emptyCoordFound =
    layout.startX === null ||
    layout.startY === null ||
    layout.endX === null ||
    layout.endY === null ||
    (layout.startX.toString().replace(/\s/g, '') === '0' &&
      layout.startY.toString().replace(/\s/g, '') === '0' &&
      layout.endX.toString().replace(/\s/g, '') === '0' &&
      layout.endY.toString().replace(/\s/g, '') === '0') ||
    layout.startX.toString().replace(/\s/g, '') === '' ||
    layout.startY.toString().replace(/\s/g, '') === '' ||
    layout.endX.toString().replace(/\s/g, '') === '' ||
    layout.endY.toString().replace(/\s/g, '') === ''
  const emptyCoordFoundForPostLogin =
    layout.startXForPostLogin === null ||
    layout.startYForPostLogin === null ||
    layout.endXForPostLogin === null ||
    layout.endYForPostLogin === null ||
    (layout.startXForPostLogin.toString().replace(/\s/g, '') === '0' &&
      layout.startYForPostLogin.toString().replace(/\s/g, '') === '0' &&
      layout.endXForPostLogin.toString().replace(/\s/g, '') === '0' &&
      layout.endYForPostLogin.toString().replace(/\s/g, '') === '0') ||
    layout.startXForPostLogin.toString().replace(/\s/g, '') === '' ||
    layout.startYForPostLogin.toString().replace(/\s/g, '') === '' ||
    layout.endXForPostLogin.toString().replace(/\s/g, '') === '' ||
    layout.endYForPostLogin.toString().replace(/\s/g, '') === ''
  if (!authToken) {
    return emptyCoordFound
  } else {
    return emptyCoordFoundForPostLogin
  }
}
//
// utility functions
//

const fetchSetups = async (state, setup) => {
  state = {
    ...state,
    isLoading: true,
    isStatus: setup.status,
    expireToken: sanitizeExpire({
      status: setup.status,
      data: setup.data,
      name: 'mastersetup',
    }),
    data: sanitizeData({
      status: setup.status,
      data: setup.data,
      name: 'mastersetup',
    }),
  }
  return state
}

const cleanSetups = ({ state, authToken, contents, thePlatform }) => {
  const cleanData = state.data.mastersetup.map((setup) => {
    // console.log(setup, authToken, ' WEBSITE SETUPS...')
    const idToRemove = []
    setup[thePlatform].map((layout, index) => {
      if (isInvalidCoordinate(layout, authToken)) {
        idToRemove.push(layout.id)
        // setup.WebContents.splice(index, 1);
      } else {
        const correctContent = contents.mastercontents.find(
          (item) => item.id === layout.mastercontents.id
        )
        layout.mastercontents = correctContent
        layout = { ...layout }
      }

      // return null if content is expired
      // return undefined if no action is required
      // return array if layout is carousel & there is filtered content existing
      const visibilityBasedOnExpiryDate = contentVisibilityBasedOnExpiryDate(
        layout,
        authToken
      )
      if (visibilityBasedOnExpiryDate === null) idToRemove.push(layout.id)
      if (visibilityBasedOnExpiryDate !== undefined)
        layout.mastercontents.CarouselContents = visibilityBasedOnExpiryDate
      /* layout */

      return layout
    })
    const newSetup = setup[thePlatform].filter((layout) => {
      return !idToRemove.some((i) => i === layout.id)
    })

    const sortedSetup = sortContentByCoordinate(newSetup, authToken)
    return sortedSetup
  })
  state = {
    ...state,
    isLoading: false,
    data: { mastersetup: cleanData },
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SETUPS:
      return fetchSetups(state, action.setup)
    case actionTypes.FETCH_CLEAN_SETUPS:
      return cleanSetups({
        state,
        authToken: action.authToken,
        contents: action.contents,
        thePlatform: action.thePlatform,
      })
    default:
      return state
  }
}

const setup = {
  init,
  actionTypes,
  reducer,
}

export default setup
