import React from 'react'

const _input_error_cleaner = (props) => {
  let check = false
  const cleanProps = { ...props }
  if (cleanProps.error !== undefined) {
    if (cleanProps.error.length > 0) {
      cleanProps.error.map((item) => {
        if (item.error) check = true
      })
    }
  }
  if (cleanProps.error === undefined || !check) delete cleanProps.error
  return cleanProps
}

export default _input_error_cleaner
