import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'

/* container */
import ActivationContainer from '../../app-module-activation-container/default'
/* container */

/* package */
import { ModPublicWrapp } from '../../lib-atomic-design-section'
import { GlobalContext } from '../../lib-atomic-context'
/* package */

/* form */
import ActivationPopup from '../popup/activation'
/* form */

import CONFIG from '../config'

/* CONSTANTS */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 80,
    maxWidth: 380,
    minWidth: 300,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 'auto',
  },
  gridList: {
    width: 800,
  },
}))
const mediaPath = CONFIG.setup.assets.media
/* CONSTANTS */

const index = (props) => {
  const classes = useStyles()
  /* state */
  const { bannerscontents } = useContext(GlobalContext)
  const imgToDisplay = bannerscontents.state.data.masterbannerscontents.filter(
    (page) => {
      return page.pageName === 'ActivationCongrat'
    }
  )[0]
  /* state */

  return (
    <ActivationContainer>
      <ModPublicWrapp {...props} imgToDisplay={imgToDisplay}>
        <div className={classes.root}>
          <ActivationPopup {...props} />
        </div>
      </ModPublicWrapp>
    </ActivationContainer>
  )
}

export default index
