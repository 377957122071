const environment = process.env.NODE_ENV
const project = 'Register'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  routes: {
    label: 'Register',
    prefix: '/register',
  },
}

export default config
