import React, { useEffect, useContext } from 'react'

import QueryString from 'query-string'

/* packages */
import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'
import { GlobalContext } from '../../lib-atomic-context'
import { LocalStorage } from '../../lib-atomic-functions'

const index = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  if (props.match.path === '/reset_password') {
    const { country, lang } = LocalStorage.getLS()

    const parsed = QueryString.parse(props.location.search)
    const { token } = parsed
    if (token === undefined || token === '') {
      // handle invalid token
      // console.log('token not found')
      props.history.push('/404')
    }

    const { forgotpasswordreset } = useContext(GlobalContext)

    const execForgotPasswordReset = () => {
      forgotpasswordreset.dispatch({
        type: forgotpasswordreset.actionTypes.RESET,
      })
    }

    const resetVeri = async () => {
      await execForgotPasswordReset()
    }
    useEffect(() => {
      // GArecordPageView(cookies)
      resetVeri()
    }, [])
  }
  return props.children
}

export default index
