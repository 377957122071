import React, { useState, useEffect, Fragment, useContext } from 'react'
// import { Link } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import { Captcha, Inputs, Buttons, Invalid } from '../../lib-atomic'
import { FormHook, LocalStorage } from '../../lib-atomic-functions'
import {
  ModXlWrapp,
  ModPageTitleNarrowSpaceWrapp,
  ModTitleText,
  ModContentText,
  ModFormLabelText,
} from '../../lib-atomic-design-section'
import Spinner2 from '../../app-module-cms-section-page-spinner/default'

import PopupResend from '../popup'
// import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

import CONFIG from '../config'
// import verification from
// '../../lib-atomic-context/reducers/verificationReducer'

/* CONSTANTS */
const VERIFICATIONOBJECTS = CONFIG.application.account.objects
const ENDPOINTS = CONFIG.application.account.api.endpoints
const VERIFICATIONCONFIG = {
  url: ENDPOINTS.verification.url,
  method: ENDPOINTS.verification.method,
  headers: ENDPOINTS.verification.headers,
  responseType: 'json',
}
const VERIFICATIONRESENDCONFIG = {
  url: ENDPOINTS.verificationresend.url,
  method: ENDPOINTS.verificationresend.method,
  headers: ENDPOINTS.verificationresend.headers,
  responseType: 'json',
}

const ONLINEORDERCONFIG = {
  url: ENDPOINTS.onlineOrder.url,
  method: ENDPOINTS.onlineOrder.method,
  headers: ENDPOINTS.onlineOrder.headers,
  responseType: 'json',
}
/* CONSTANTS */

const index = (props) => {
  const { emailAddress } = LocalStorage.getLS()
  const { onlineOrder } = LocalStorage.getLS()
  const currentlanguage = LocalStorage.getLS().translation.json

  /* state */
  /* ---- verification ----  */
  const messageError = props.location.state
    ? props.location.state.message
    : false
  /* -- global state : states --  */
  const { verification, auth, order } = useContext(GlobalContext)
  /* -- global state : states --  */
  console.log('messageError', messageError)
  /* -- recaptcha : states -- */
  const [verificationResendClicked, setVerificationResendClicked] = useState(
    false
  )
  const [errorMessagePrepared, setErrorMessagePrepared] = useState('')
  const [visibleResend, setVisibleResend] = useState(false)
  const [open, setOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [verified, setVerified] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true)
      setLoaded(true)
    }
  }
  const expiredCallback = (response) => {
    setVerified(false)
  }

  useEffect(() => {
    notmatchLogin = {
      error: [errorMessagePrepared],
    }
  }, [errorMessagePrepared])
  /* -- recaptcha : states -- */

  /* -- execute verification : states --  */
  const [fetchVerification] = FetchContextApiV2(VERIFICATIONCONFIG)
  const execVerification = async ({ query }) => {
    verification.dispatch({
      type: verification.actionTypes.FETCH_VERIFICATION,
      verification: await fetchVerification(
        {
          query,
        },
        verification
      ),
    })
  }
  const execVerificationReset = async () => {
    verification.dispatch({ type: verification.actionTypes.RESET })
  }
  const [fetchVerificationResend] = FetchContextApiV2(VERIFICATIONRESENDCONFIG)
  const execVerificationResend = async ({ body }) => {
    verification.dispatch({
      type: verification.actionTypes.FETCH_VERIFICATION,
      verification: await fetchVerificationResend(
        {
          body,
        },
        verification
      ),
    })
  }

  const [fetchOnlineOrder] = FetchContextApiV2(ONLINEORDERCONFIG)
  const execOnlineOrder = async ({ body }) => {
    order.dispatch({
      type: order.actionTypes.FETCH_ONLINEORDER,
      order: await fetchOnlineOrder(
        {
          body,
        },
        order
      ),
    })
  }

  /* -- execute verification : states --  */

  /* -- form handles : states -- */
  const handleVerification = async () => {
    if (verified && loaded) {
      setOpen(true)
      const query = values.code
      execVerification({ query })
    }
  }
  /* -- form handles : states -- */
  useEffect(() => {
    if (order.state.data) {
      const { isStatus, data } = order.state
      const { orderObject } = data

      if (orderObject.Message == null) {
        LocalStorage.addLS('orderUrl', orderObject.Url)
        if (onlineOrder.orderOnline) {
          window.location.href = orderObject.Url
        }
        setOpen(false)
      }

      // props.history.push('/order')
    }
  }, [order.state])

  /* -- effects : verification : use-effects */
  useEffect(() => {
    if (verification.state.data) {
      console.log(verification)
      try {
        const { isStatus, data } = verification.state
        const { verificationobject } = data
        if (!verificationobject && !verificationobject.response)
          throw { status: isStatus, err: false }
        const { outComeCode } = verificationobject
        if (outComeCode !== '0' && verificationobject.response)
          throw { status: isStatus, err: verificationobject }
        execRedirects()
        //handleClearLS(); window.location.replace(generatepaymentobject.uri)
      } catch ({ status, err }) {
        execRedirects({ status, err })
        //if (!e.status) handleClearLS()
      }
    }
  }, [verification.state])
  /* -- effects : verification : use-effects */
  useEffect(() => {
    if (!visibleResend && verificationResendClicked) {
      setVerificationResendClicked(false)
      window.location.reload()
    }
  }, [visibleResend])

  const execRedirects = async (e) => {
    // const { verificationAccount } = errorResponse
    if (e && !e.status && e.err) {
      const { isStatus, data } = verification.state
      const { verificationobject } = data
      const { response } = verificationobject
      const outComeCode = response
        ? response.data.outComeCode
        : verificationobject.outComeCode
      // const { outComeCode } = response.data
      const key = outComeCode.toString()
      const manipulatedOutComeCode = key.replace('\r\n', '')
      const isSuccessfullyUpdated =
        data && manipulatedOutComeCode === '0' ? true : false
      setErrorMessagePrepared(
        currentlanguage.errorresponse_verificationAccount[
          manipulatedOutComeCode
        ]
          ? currentlanguage.errorresponse_verificationAccount[
              manipulatedOutComeCode
            ]
          : 'Unknown Error'
      )

      if (verificationResendClicked) {
        setOpen(false)
        setVisibleResend(!visibleResend)
      } else {
        setOpen(false)
        setShowErrorMessage(true)
      }
    } else {
      // verification fetch success console.log('default', e) here we go to login page
      // 1. fetch token from this page
      // 2. fetch token from login form then save that from here
      if (verificationResendClicked) {
        setOpen(false)
        setVisibleResend(!visibleResend)
      } else {
        setOpen(false)
        const { isStatus, data } = verification.state
        const { verificationobject } = data
        console.log(verificationobject)
        if (verificationobject) {
          const { outComeCode, login, flOldUser } = verificationobject

          if (outComeCode === '0') {
            LocalStorage.addLS('login', login)
            LocalStorage.addLS('flOldUser', flOldUser)

            const body = {
              DeviceId: '5477625D-7D9B-49CC-B6F0-F09A752C980C',
              Platform: 'web',
              PlatformVersion: '1.0',
              Token: login.token,
              MasterToken: login.masterToken,
              Expiration: login.exp,
              Latitude: '51.3753717',
              Longitude: '-2.3797541',
            }

            await execOnlineOrder({ body })

            if (flOldUser === 1) {
              props.history.push('/preferences')
              setOpen(false)
            } else {
              // const isCampaignCodeFound = LocalStorage.getLS().campaignCode
              // if (isCampaignCodeFound) {
              //   const { campaignCode } = LocalStorage.getLS()
              //   LocalStorage.addLS('campaignCode', null)
              //   props.history.push('/campaign?campaignCode=' + campaignCode)
              // } else {
                props.history.push('/dashboard')
              // }
              setOpen(false)
            }
          }
          // 3. go to home page
        }
      }

      // const { country, lang } = LocalStorage.getLS() const { isStatus, data } =
      // verification.state const { verificationobject } = data if
      // (verificationobject) {   const { outComeCode } = verificationobject   const
      // key = outComeCode.toString()   const manipulatedOutComeCode =
      // key.replace('\r\n', '')   const isSuccessfullyUpdated =     data &&
      // manipulatedOutComeCode === '0' ? true : false   const messageObj =
      // verificationAccount[manipulatedOutComeCode]   execVerificationReset()
      // props.history.push(     '/verification/confirm',     { isSuccessfullyUpdated,
      //       message: messageObj,     }   ) }
    }
  }

  /* -- data props --   */
  const { isLoading } = verification.state
  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleVerification,
    VERIFICATIONOBJECTS
  )
  const code = {
    ...VERIFICATIONOBJECTS.code,
    error: error.code,
    value: values.code,
    onChange,
    translation: currentlanguage,
  }
  const submitButton = {
    fullWidth: true,
  }

  const resendButton = {
    fullWidth: true,
    onClick: async () => {
      // props.history.push('/verification/resend')
      setOpen(true)
      console.log(emailAddress)
      await execVerificationReset()
      const body = {
        email: emailAddress ? emailAddress : '',
      }
      setVerificationResendClicked(true)
      await execVerificationResend({ body })
    },
  }
  const captchaData = {
    verified,
    verifyCallback,
    expiredCallback,
  }
  /* -- data props --   */
  let notmatchLogin = {
    error: [errorMessagePrepared],
  }
  return (
    <form onSubmit={onSubmit} noValidate>
      {open && <Spinner2.Popup selectedValue={null} />}

      <ModXlWrapp>
        <ModPageTitleNarrowSpaceWrapp>
          <ModTitleText>
            {currentlanguage.verification.page_verify_verify}
          </ModTitleText>
        </ModPageTitleNarrowSpaceWrapp>
        <ModContentText>
          <p
            style={{
              textAlign: 'center',
              marginBottom: 40,
            }}
          >
            {currentlanguage.verification.page_verify_email_verif}
          </p>
        </ModContentText>
      </ModXlWrapp>
      <ModXlWrapp>
        <Inputs.Text {...code} />{' '}
        {showErrorMessage ? (
          <p
            style={{
              textAlign: 'center',
            }}
          >
            <Invalid {...notmatchLogin} />
          </p>
        ) : null}
      </ModXlWrapp>
      <PopupResend
        visibleResend={visibleResend}
        setVisibleResend={setVisibleResend}
        {...props}
      />
      <Captcha.Basic {...captchaData} />
      <br />
      <ModXlWrapp>
        <Buttons.Normal {...submitButton}>
          {currentlanguage.verification.page_verify_submit}
        </Buttons.Normal>
      </ModXlWrapp>
      <br />
      <ModXlWrapp>
        <Buttons.Text {...resendButton}>
          <p
            style={{
              margin: 0,
              textDecoration: 'underline',
            }}
          >
            {currentlanguage.verification.page_verify_resend}
          </p>
        </Buttons.Text>
      </ModXlWrapp>
    </form>
  )
}

export default index
