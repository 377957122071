import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'registrations'
const server = null
const service = null
// const options = {
//   preference_options: [
//     { name: 'Preference Type 1', value: '1' },
//     { name: 'Preference Type 2', value: '2' },
//   ],
// }
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    config: {
      api: {
        endpoints: {
          list: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: null,
            },
            doc: 'https://tr4ns10.tr4ns.com/Westfield-Reporting',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.tranxactor.url[environment] + '/configs',
            method: 'GET',
          },
        },
      },
      objects: {
        companyname: {
          name: 'companyname',
          initial: '',
          placeholder: 'Company Name',
          helperText: false,
          rules: ['isNotNull'],
        },
        abn: {
          name: 'abn',
          initial: '',
          placeholder: 'ABN',
          helperText: false,
          rules: ['isNotNull'],
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'History Detail',
    prefix: '/history/:id',
  },
  setup,
}

export default config
