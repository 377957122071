import React from 'react'

import Header from './header'
import Body from './body'
import Footer from './footer'

import appConf from '../../components/app-conf'

import { GlobalProvider } from '../../components/lib-atomic-context'
import GlobalContainer from '../../components/app-module-global-container/default'

const Themes = appConf[process.env.REACT_APP_CLIENT].themes
const Main = (props) => {
  return (
    <Themes>
      <GlobalProvider>
        <GlobalContainer {...props}>
          <Header {...props} />
          <div className="green-link">
            <Body {...props} />
          </div>
          <Footer {...props} />
          {/* <PinnedBoxContainer {...props} /> */}
        </GlobalContainer>
      </GlobalProvider>
    </Themes>
  )
}

export default Main
