import React, { useState, useContext } from 'react'
import { Grid, Box, makeStyles } from '@material-ui/core'
import { WindowSize } from '../lib-atomic-functions'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

import { GlobalContext } from '../lib-atomic-context'
import { LocalStorage } from '../lib-atomic-functions'
import LoginRegisterPopup from '../app-module-login-register-box-container/default'

import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const mediaPath = setup.assets.media

const useStyles = (calcWidth, calcHeight) => {
  return makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    image: {
      width: calcWidth,
      maxWidth: 1000,
      height: calcHeight,
      margin: 'auto',
      // maxWidth: 1000,
    },
  }))()
}
const index = (props) => {
  const { member } = useContext(GlobalContext)
  const { memberObject } = member.state.data
  const isLoggedIn = LocalStorage.getLS()
    ? LocalStorage.getLS().login
      ? LocalStorage.getLS().login.token
        ? true
        : false
      : false
    : false
  const { imgToDisplay } = props
  if (!imgToDisplay) return <div>{props.children}</div>
  const ratio = ['preloginhome', 'dashboard'].some((widerBannerName) => {
    return widerBannerName === imgToDisplay.pageName.toLowerCase()
  })
    ? 2.5
    : 2.05
  console.log('imgToDisplay', imgToDisplay)
  const [visibleLogin, setVisibleLogin] = useState(false)
  const popupModal = () => {
    setVisibleLogin(!visibleLogin)
  }

  const { country, lang } = LocalStorage.getLS()

  const { width, height } = WindowSize()
  const calcWidth = width < 1000 ? width : 1000
  const calcHeight = calcWidth / ratio
  const classes = useStyles(calcWidth, calcHeight)
  let bannerBg =
    imgToDisplay &&
    imgToDisplay.homeImage &&
    imgToDisplay.homeImage.url &&
    imgToDisplay.type !== 'Carousel'
      ? imgToDisplay.homeImage
        ? 'url(' +
          mediaPath +
          imgToDisplay.homeImage.url +
          ') no-repeat center center'
        : false
      : false
  console.log('preapring....', imgToDisplay.CarouselContents)
  // bannerBg = !imgToDisplay?.homeImage?.url ? false : bannerBg
  console.log(
    { bannerBg, imgToDisplay },
    ' imgToDisplayimgToDisplayimgToDisplay bannerBgbannerBgbannerBgbannerBg'
  )
  const carouselContents =
    imgToDisplay && imgToDisplay.type === 'Carousel' ? true : false
  // const CarouselToDisplay = prepareCarousel(props, classes, imgToDisplay)
  return (
    <Grid
      container
      className={classes.root}
      spacing={0}
      direction="column"
      alignItems="center"
    >
      {carouselContents ? (
        <Carousel
          className={classes.image}
          showStatus={false}
          showThumbs={false}
          width={'100%'}
          infiniteLoop={true}
        >
          {imgToDisplay.CarouselContents.filter((carousel) => {
            const { mastercountries } = carousel.mastercontents
            return memberObject
              ? memberObject.countryId === mastercountries.countryId
                ? true
                : false
              : true
          }).map((carousel) => {
            console.log(carousel)
            const { type, contentFormat, homeImage } = carousel.mastercontents
            const typeUrl =
              type.toLowerCase() === 'promotions'
                ? 'offers'
                : type.toLowerCase()
            const imageItem = homeImage
              ? 'url(' + mediaPath + homeImage.url + ') no-repeat center center'
              : ''
            const imageItem2 = homeImage ? mediaPath + homeImage.url : ''
            return (
              <div
                onClick={() => {
                  if (isLoggedIn) {
                    // props.history.push(
                    //   '/web/' + country + '/' + lang + '/news/' + mastercontents.id
                    // )
                    if (carousel.mastercontents.externalLink) {
                      window.location = carousel.mastercontents.externalLink
                    }
                  } else {
                    if (
                      carousel.mastercontents.externalLink &&
                      carousel.mastercontents.externalLink.toLowerCase() ===
                        'overlay'
                    ) {
                      popupModal()
                    } else {
                      if (carousel.mastercontents.externalLink) {
                        window.location = carousel.mastercontents.externalLink
                      }
                    }
                  }
                }}
                style={{
                  cursor: 'pointer',
                  borderRadius: 10,
                  flex: '1 1 0%',
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'nowrap',
                  justifyContent: 'center',
                  alignContent: 'stretch',
                  alignItems: 'stretch',
                  backgroundColor: 'transparent',
                  width: calcWidth,
                  height: calcHeight,
                  // background: imageItem,
                  // backgroundSize: '100% 100%',
                  // backgroundPosition: '50% 50%',
                }}
              >
                <img style={{ width: '100%' }} src={imageItem2} />
              </div>
            )
          })}
        </Carousel>
      ) : null}
      {bannerBg ? (
        <div
          style={{
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            width: calcWidth,
            maxWidth: 1000,
            height: calcHeight,
            background: bannerBg,
            backgroundSize: 'cover',
            // maxWidth: 1000,
          }}
        />
      ) : (
        ''
      )}
      <LoginRegisterPopup
        visibleLogin={visibleLogin}
        popupModal={popupModal}
        {...props}
      />

      <Grid
        style={{ padding: 10, paddingTop: 0, maxWidth: 1000 }}
        // component={Box}
        container
        item
        xs={12}
        sm={10}
        md={8}
        lg={6}
        xl={6}
        p={5}
        {...props}
      >
        {props.children}
      </Grid>
    </Grid>
  )
}

export default index
