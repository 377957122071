import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Dashboard'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    objects: {
      firstName: {
        name: 'firstname',
        // placeholder: 'First name',
        helperText: false,
        rules: ['isNotNull'],
      },
      lastName: {
        name: 'lastname',
        // placeholder: 'Last name',
        helperText: false,
        rules: ['isNotNull'],
      },
      email: {
        name: 'email',
        // placeholder: 'E-mail address',
        helperText: false,
        rules: ['isNotNull'],
      },
      rewardsNumber: {
        name: 'rewardsNumber',
        // placeholder: 'Rewards number if known',
        helperText: false,
        //rules: ['isNotNull'],
      },
      queryType: {
        name: 'type',
        // placeholder: 'Query type',
        helperText: false,
        rules: ['isNotNull'],
        options: [
          {
            value: 1,
            name: 'Registration',
          },
          {
            value: 2,
            name: 'Account',
          },
          {
            value: 3,
            name: 'Rewards',
          },
          {
            value: 4,
            name: 'Recent Offer',
          },
          {
            value: 5,
            name: 'My App',
          },
          {
            value: 6,
            name: 'General Restaurant feedback”',
          },
        ],
      },
      comment: {
        name: 'comment',
        // placeholder: 'Please tell us about your query',
        helperText: false,
        rules: ['isNotNull'],
        rows: 6,
      },
    },
  },
  routes: {
    label: 'Contact Us',
    prefix: '/contactus',
  },
  setup,
}

export default config
