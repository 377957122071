import React, { useEffect, useState } from 'react'

/* modules */
import { LocalStorage } from '../../lib-atomic-functions'
import DialogBox from '../../lib-atomic/dialog/dialogbox'

const index = (props) => {
  const { visiblePopup, setVisiblePopup } = props
  const currentlanguage = LocalStorage.getLS().translation.json

  var message = {
    outComeCode: '0',
    title: '',
    outComeMessage:
      currentlanguage.errorresponse_resetPassword[
        'IDENT:RESET_PWD:7IDENT:UPD_IDENTS_PWD:6'
      ],
  }

  useEffect(() => {
    setVisiblePopup(visiblePopup)
    // setVisibleModalContent(visiblePopup)
  }, [visiblePopup])

  const popupModal = () => {
    setVisiblePopup(!visiblePopup)
  }

  return (
    <>
      {visiblePopup && (
        <DialogBox
          selectedValue={message}
          open={visiblePopup}
          onClose={popupModal}
        />
      )}
    </>
  )
}

export default index
