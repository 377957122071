const sanitizeData = ({ status, data, name }) => {
  if (!status && !data.json) return { [name]: false }
  return { [name]: data.json }
}

const sanitizeExpire = ({ status, data, name }) => {
  const expireLookup = [203]
  const sanitize_data = sanitizeData({ status, data, name })
  if (expireLookup.includes(sanitize_data[name].status)) return true
  return false
}
// const sanitizeDataV2 = ({
//     status,
//     data,
//     name,
//     contentCallFunc,
//     contentReducerToUse
//   }) => {
//     if (!status && !data.json) return { [name]: false };
//     data.json.map(async content => {
//       if (content.type === "Carousel") {
//         const parameters = content.CarouselContents.map(content => {
//           return content.mastercontents.id;
//         });
//         const qs = QueryString.stringify({ id_in: parameters });
//         const newlyAdded = await contentCallFunc(
//           { query: "?" + qs },
//           contentReducerToUse
//         );
//         content.CarouselContents = newlyAdded.data.json;
//       }
//     });
//     return { [name]: data.json };
//   };

export { sanitizeData, sanitizeExpire }
