import React from 'react'

/* container */
import PromotionContainer from '../../app-module-promotions-container/default'
/* container */

import Contents from '../contents/contents'

import ScrollToTop from '../../../scrollToTop'

const index = (props) => {
  return (
    <PromotionContainer {...props}>
      <ScrollToTop {...props} />
      <Contents {...props} />
    </PromotionContainer>
  )
}

export default index
