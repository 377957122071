import Page404 from '../../../../components/app-404/default'
import Page404ForcePreference from '../../../../components/app-404-force-preference/default'
import Page404ForceLogin from '../../../../components/app-404-force-login/default'

const landingPages = {
  '0': Page404,
  '1': Page404,
  '2': Page404ForcePreference,
  '3': Page404ForceLogin,
}

export default landingPages
