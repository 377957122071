import React, { Fragment } from 'react'

import TextField from '@material-ui/core/TextField'

/* atoms */
import { Invalid } from '../feedbacks'
/* atoms */

/* function */
import inputErrorCleaner from './_input_error_cleaner'
/* function */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

const text = (props) => {

  const disabled = props.disabled ? true : false
  const defaultValues = props.initial
  const cleanProps = inputErrorCleaner(props)

  const noError = props.error
    ? !props.error.some((i) => {
        return i
      })
    : true
    

  return (
    <Fragment>
      <TextField
        id="standard-full-width"
        {...cleanProps}
        disabled={disabled}
        fullWidth
        variant="outlined"
        defaultValue={defaultValues}
        margin="dense"
        className="subway-six-inch"
        InputLabelProps={{
          style: { color: 'grey' },
        }}
        InputProps={{
          style: { backgroundColor: '#d3d3d352', fontFamily: 'SubwaySixInch' },
        }}
      />
      {!noError && <Invalid {...props} />}
    </Fragment>
  )
}

export default text
