import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import PageSomethingWentWrong from './components/app-something-went-wrong/default'
import { withCookies } from 'react-cookie'
import Main from './layout/main'
import Err404 from './layout/error/404.js'
// import Default from './layout/default'
// import InitCountry from './init-country'
// import InitLang from './init-lang'

const App = () => {
  if (process.env.NODE_ENV === 'production')
    console.log = function no_console() {}
  return (
    <Switch>
      {/* <Route path="/u/:modulecode/:token" component={Main} /> */}
      {/* <Route exact path="/" component={InitCountry} />
      <Route exact path="/:country" component={InitLang} /> */}
      {/* <Route exact path="/web">
        <Redirect to="/web/at/de/home" component={Main} />
      </Route> */}
      <Route exact path="/">
        <Redirect to="/home" component={Main} />
      </Route>
      <Route exact path="/404" component={Err404} />
      <Route path="/:module" component={Main} />
      <Redirect exact from="/" to="/home" />
      <Route component={Err404} />
      <Route component={PageSomethingWentWrong} />
    </Switch>
  )
}

export default withCookies(App)
