import isNotNull from './isNotNull'
import isNotNullDOB from './isNotNullDOB'
import isMinMaxDOB from './isMinMaxDOB'
import isNotNullEmail from './isNotNullEmail'
import isNotNullFirstname from './isNotNullFirstname'
import isNotNullLastname from './isNotNullLastname'
import isNotNullPassword from './isNotNullPassword'
import isEmail from './isEmail'
import isPassword from './isPassword'
import isNumber from './isNumber'
import isNumberAndLetter from './isNumberAndLetter'
import isLetter from './isLetter'
import isMin from './isMin'
import isMinMax from './isMinMax'
import isMinMaxPhoneNumber from './isMinMaxPhoneNumber'
import isDate from './isDate'
import isJson from './isJson'
import isConfirm from './isConfirm'
import isMinVal from './isMinVal'
import isMaxVal from './isMaxVal'
import isName from './isName'
import isNull from './isNull'
import isPasswordAlpa from './isPasswordAlpa'
import isPasswordNumber from './isPasswordNumber'
import isPasswordSpecialC from './isPasswordSpecialC'
import isPasswordlowerC from './isPasswordlowerC'
import isNotThreeConsecutive from './isNotThreeConsecutive'
import isNotNullCardAccess from './isNotNullCardAccess'
import isNotNullMobile from './isNotNullMobile'
import isNotEmpty from './isNotEmpty'
import isNumberAndLetterPostcode from './isNumberAndLetterPostcode'


export default {
  isPasswordlowerC,
  isPasswordSpecialC,
  isPasswordNumber,
  isPasswordAlpa,
  isNull,
  isNotNull,
  isNotNullDOB,
  isMinMaxDOB,
  isNotNullEmail,
  isNotNullFirstname,
  isNotNullLastname,
  isNotNullPassword,
  isEmail,
  isPassword,
  isNumber,
  isNumberAndLetter,
  isLetter,
  isMin,
  isMinMax,
  isMinMaxPhoneNumber,
  isDate,
  isJson,
  isConfirm,
  isMinVal,
  isMaxVal,
  isName,
  isNotThreeConsecutive,
  isNotNullCardAccess,
  isNotNullMobile,
  isNotEmpty,
  isNumberAndLetterPostcode,
}
