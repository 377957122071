import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_SETUPS_ALL: 'FETCH_SETUPS_ALL',
}

const fetchSetupAll = async (state, setupAll) => {
  state = {
    ...state,
    isStatus: setupAll.status,
    expireToken: sanitizeExpire({
      status: setupAll.status,
      data: setupAll.data,
      name: 'mastersetupall',
    }),
    data: sanitizeData({
      status: setupAll.status,
      data: setupAll.data,
      name: 'mastersetupall',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SETUPS_ALL:
      return fetchSetupAll(state, action.setupAll)
    default:
      return state
  }
}

const setupAll = {
  init,
  actionTypes,
  reducer,
}

export default setupAll
