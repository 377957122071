import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_UPDATE: 'FETCH_UPDATE',
  RESET: 'RESET',
}

const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}
const fetchVersion = async (state, update) => {
  // console.log(version, "test...");
  state = {
    ...state,
    isStatus: update.status,
    expireToken: sanitizeExpire({
      status: update.status,
      data: update.data,
      name: 'updateObject',
    }),
    data: sanitizeData({
      status: update.status,
      data: update.data,
      name: 'updateObject',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UPDATE:
      return fetchVersion(state, action.update)
      case actionTypes.RESET:
        return fetchReset(state)
    default:
      return state
  }
}

const update = {
  init,
  actionTypes,
  reducer,
}

export default update
