import React, { useContext } from 'react'

import { GlobalContext } from '../../lib-atomic-context'

/* container */
import TermsContainer from '../../app-module-terms-container/default'
/* container */

import Contents from '../contents/contents'

import ScrollToTop from '../../../scrollToTop'

const index = (props) => {
  return (
    <TermsContainer {...props}>
      <ScrollToTop {...props} />
      <Contents {...props} />
    </TermsContainer>
  )
}

export default index
