import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_COUNTRYLANGUAGE: 'FETCH_COUNTRYLANGUAGE',
}

const fetchCountryLanguage = async (state, countrylanguage) => {
  state = {
    ...state,
    isStatus: countrylanguage.status,
    expireToken: sanitizeExpire({
      status: countrylanguage.status,
      data: countrylanguage.data,
      name: 'mastercountrylanguage',
    }),
    data: sanitizeData({
      status: countrylanguage.status,
      data: countrylanguage.data,
      name: 'mastercountrylanguage',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSLATION:
      return fetchCountryLanguage(state, action.countrylanguage)
    default:
      return state
  }
}

const countrylanguage = {
  init,
  actionTypes,
  reducer,
}

export default countrylanguage
