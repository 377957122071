const manager = (LocalStorage) => {
  // roles = 0 = guest user
  // roles = 1 = logged in user
  // roles = 2 = force preference
  const roles = {
    at: {
      public: 0,
      private: 1,
      privateolduser: 2,
    },
    de: {
      public: 3,
      private: 4,
      privateolduser: 5,
    },
    nl: {
      public: 6,
      private: 7,
      privateolduser: 8,
    },
    fr: {
      public: 9,
      private: 10,
      privateolduser: 11,
    },
    gb: {
      public: 12,
      private: 13,
      privateolduser: 14,
    },
    ie: {
      public: 15,
      private: 16,
      privateolduser: 17,
    },
    se: {
      public: 18,
      private: 19,
      privateolduser: 20,
    },
    fi: {
      public: 21,
      private: 22,
      privateolduser: 23,
    },
  }

  try {
    // check if token exist in login LS
    const { login, country } = LocalStorage.getLS()
    const e = { msg: 'token is undefined', country: country }
    if (!login || !login.token) throw e
    const forcepreference = LocalStorage.getLS().flOldUser

    if (forcepreference === 1) {
      return roles[country].privateolduser
    } else {
      return roles[country].private
    }
  } catch (e) {
    console.log(e)
    return roles[e.country].public
  }
}

export default manager
