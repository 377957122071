import React, { Fragment, useState } from 'react'

import TextField from '@material-ui/core/TextField'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
/* atoms */
import { Invalid } from '../feedbacks'
/* atoms */

/* function */
import inputErrorCleaner from './_input_error_cleaner'
/* function */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

const password = (props, visible) => {
  const [vv, setvv] = useState(visible)
  const defaultValues = props.initial
  const handleClickShowPassword = () => {
    setvv(!vv)
  }
  const cleanProps = inputErrorCleaner(props)
  const noError = props.error
    ? !props.error.some((i) => {
        return i
      })
    : true
  return (
    <Fragment>
      <OutlinedInput
        {...cleanProps}
        id="outlined-adornment-weight"
        type={vv ? 'password' : 'text'}
        fullWidth
        variant="outlined"
        margin="dense"
        defaultValue={defaultValues}
        inputProps={{
          style: { backgroundColor: '#d3d3d352', fontFamily: 'SubwaySixInch' },
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              InputProps={{ style: { backgroundColor: '#d3d3d352' } }}
              onClick={handleClickShowPassword}
              edge="end"
            >
              {vv ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
        labelWidth={0}
      />
      {!noError && <Invalid {...props} />}
    </Fragment>
  )
}

export default password
