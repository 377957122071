import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'registrations'

const config = {
  details: {
    project,

    environment,
  },
  application: {
    account: {
      api: {
        endpoints: {
          auth: {
            headers: {
              'Content-Type': 'application/json',
              ModuleCode: setup.setup.thor.moduleCode[environment],
              platform: 'web',
              appVersion: '1.0',
              deviceID: null,
              latitude: null,
              longitude: null,
            },
            doc: 'https://subwayuat.tr4ns.com/tx-auth/auth/login',
            url: setup.setup.api.tranxactor.url[environment] + '/auth/logon',
            method: 'POST',
          },
        },
      },
      objects: {
        email: {
          name: 'email',
          initial: '',
          // initial: 'greet21@gmail.com',
          placeholder: '',
          helperText: false,
          rules: ['isNotNullEmail', 'isEmail'],
        },
        password: {
          name: 'password',
          initial: '',
          // initial: 'Test12345!',
          placeholder: '',
          helperText: false,
          rules: ['isNotNullPassword'],
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
    recaptcha: {
      siteKey: '6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE',
    },
  },
  routes: {
    label: 'Login',
    prefix: '/login',
  },
  setup,
}

export default config
