import { sanitizeData, sanitizeExpire } from './Sanitize'

const init = {
  isLoading: false,
  isStatus: true,
  expireToken: false,
  data: false,
}

const actionTypes = {
  FETCH_TOKEN_VALIDITY: 'FETCH_TOKEN_VALIDITY',
  RESET: 'RESET',
}

const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}

const fetchResetTokenValidity = async (state, validresettoken) => {
  state = {
    ...state,
    isStatus: validresettoken.status,
    expireToken: sanitizeExpire({
      status: validresettoken.status,
      data: validresettoken.data,
      name: 'validresettokenobj',
    }),
    data: sanitizeData({
      status: validresettoken.status,
      data: validresettoken.data,
      name: 'validresettokenobj',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TOKEN_VALIDITY:
      return fetchResetTokenValidity(state, action.validresettoken)
    case actionTypes.RESET:
      return fetchReset(state)
    default:
      return state
  }
}

const validresettoken = {
  init,
  actionTypes,
  reducer,
}

export default validresettoken
