import React from 'react'

/* mui */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    color: '#111111',
  },
}))
/* CONSTANTS */

const title = props => {
  /* mui */
  const classes = useStyles()
  /* mui */

  return (
    <Typography variant="subtitle1" className={classes.title}>
      {props.children}
    </Typography>
  )
}

export default title
