import React, {Fragment} from 'react'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
/* atoms */
import {Invalid} from '../feedbacks'
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
options
*/

const options = ({options}) => {
    // console.log("options",options);
    return options.map((option, index) => {
       
        return (
            <MenuItem key={index} value={option.value}>
                <ListItemIcon>
                    <img
                        style={{
                        height: 15,
                        width: 30,
                        marginRight: 10
                    }}
                        // src={`https://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/${option.abbr}.svg`}/>
                        src={`https://flagcdn.com/${option.abbr}.svg`}
                        />
                    <p
                        className="subway-footlong"
                        style={{
                        margin: 0
                    }}>
                        {option.name}
                    </p>
                </ListItemIcon>

            </MenuItem>
        )
    })
}

const mobileselector = (props) => {
    console.log("props",props);
    const selectOptions = options(props)
    const defaultValues = props.initial
    console.log('defaultValues', defaultValues)
    return (
        <Fragment>
            <TextField
                {...props}
                select
                fullWidth
                variant="outlined"
                defaultValue={defaultValues}
                margin="dense"
                InputLabelProps={{
                style: {
                    color: 'grey'
                }
            }}
                InputProps={{
                style: {
                    backgroundColor: '#d3d3d352'
                }
            }}>
                {selectOptions}
            </TextField>

            <Invalid {...props}/>
        </Fragment>
    )
}

export default mobileselector
