const environment = process.env.NODE_ENV
const project = 'Home'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  //redirect to Azure page
  routes: {
    label: 'Order',
    prefix: '/order',
  },
}

export default config
