import React from 'react'

/* mui */
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  title: {
    position: 'relative',
  },
}))
/* CONSTANTS */

const wrapper = props => {
  /* mui */
  const classes = useStyles()
  /* mui */

  return (
    <Grid className={classes.title} item xs={5} sm={5} md={5} lg={5} xl={5}>
      {props.children}
    </Grid>
  )
}

export default wrapper
