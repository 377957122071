import React from 'react'
import LostCardContainer from '../../app-module-lostcard-container/default'
import PageContent from '../page/lostcardpage'

import ScrollToTop from '../../../scrollToTop'

const index = (props) => {
  return (
    <LostCardContainer {...props}>
      <ScrollToTop {...props} />
      <PageContent {...props} />
    </LostCardContainer>
  )
}

export default index
