import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_VALIDATION: 'FETCH_VALIDATION',
  RESET: 'RESET',
}
const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}

const fetchVersion = async (state, valid) => {
  // console.log(version, "test...");
  state = {
    ...state,
    isStatus: valid.status,
    expireToken: sanitizeExpire({
      status: valid.status,
      data: valid.data,
      name: 'validObject',
    }),
    data: sanitizeData({
      status: valid.status,
      data: valid.data,
      name: 'validObject',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_VALIDATION:
      return fetchVersion(state, action.valid)
      case actionTypes.RESET:
        return fetchReset(state)
    default:
      return state
  }
}

const valid = {
  init,
  actionTypes,
  reducer,
}

export default valid
