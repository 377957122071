import React, { useEffect } from 'react'
import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'
import { LocalStorage } from '../../lib-atomic-functions'

const index = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  useEffect(() => {
    // GArecordPageView(cookies)
    const { token } = LocalStorage.getLS().login
    if (token) {
    } else {
      props.history.push('/login')
    }
  }, [])
  return props.children
}

export default index
