import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import { Inputs, Buttons } from '../../lib-atomic'
import { FormHook, LocalStorage } from '../../lib-atomic-functions'
import {
  ModXlWrapp,
  ModPageTitleWrapp,
  ModTitleText,
  ModContentText,
  ModFormLabelText,
} from '../../lib-atomic-design-section'
import PopupResend from '../popup'

// import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

import CONFIG from '../config'

/* CONSTANTS */
const ACTIVATIONOBJECTS = CONFIG.application.account.objects
const ENDPOINTS = CONFIG.application.account.api.endpoints
const ACTIVATIONCONFIG = {
  url: ENDPOINTS.activation.url,
  method: ENDPOINTS.activation.method,
  headers: ENDPOINTS.activation.headers,
  responseType: 'json',
}
const ACTIVATIONRESENDCONFIG = {
  url: ENDPOINTS.activationresend.url,
  method: ENDPOINTS.activationresend.method,
  headers: ENDPOINTS.activationresend.headers,
  responseType: 'json',
}
/* CONSTANTS */

const index = (props) => {
  const { emailAddress } = LocalStorage.getLS()
  const currentlanguage = LocalStorage.getLS().translation.json

  /* state */
  /* ---- activation ----  */

  /* -- global state : states --  */
  const { activation, auth } = useContext(GlobalContext)
  /* -- global state : states --  */

  /* -- recaptcha : states -- */
  const [activationResendClicked, setActivationResendClicked] = useState(false)
  const [visibleResend, setVisibleResend] = useState(false)
  const [open, setOpen] = useState(false)
  const [resendResult, setResendResult] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [verified, setVerified] = useState(false)
  const [exprired, setExpired] = useState(false)

  const verifyCallback = (response) => {
    if (response) {
      setVerified(true)
      setLoaded(true)
      setExpired(false)
    }
  }
  const expiredCallback = (response) => {
    setExpired(true)
  }
  /* -- recaptcha : states -- */

  /* -- execute activation : states --  */
  const [fetchActivation] = FetchContextApiV2(ACTIVATIONCONFIG)
  const execActivation = async ({ query }) => {
    activation.dispatch({
      type: activation.actionTypes.FETCH_ACTIVATION,
      activation: await fetchActivation({ query }, activation),
    })
  }
  const execActivationReset = async () => {
    activation.dispatch({
      type: activation.actionTypes.RESET,
    })
  }
  const [fetchActivationResend] = FetchContextApiV2(ACTIVATIONRESENDCONFIG)
  const execActivationResend = async ({ body }) => {
    activation.dispatch({
      type: activation.actionTypes.FETCH_ACTIVATION,
      activation: await fetchActivationResend({ body }, activation),
    })
  }
  /* -- execute activation : states --  */

  /* -- form handles : states -- */
  const handleActivation = async () => {
    if (verified && loaded) {
      const query = values.code
      execActivation({ query })
    }
  }
  /* -- form handles : states -- */

  /* -- effects : activation : use-effects */
  useEffect(() => {
    if (activation.state.data) {
      try {
        const { isStatus, data } = activation.state
        const { activationobject } = data
        if (!activationobject) throw { status: isStatus, err: false }
        const { outComeCode } = activationobject
        if (outComeCode !== '0')
          throw { status: isStatus, err: activationobject }
        execRedirects()
        //handleClearLS();
        //window.location.replace(generatepaymentobject.uri)
      } catch ({ status, err }) {
        console.log(status, err)
        execRedirects({ status, err })
        //if (!e.status) handleClearLS()
      }
    }
  }, [activation.state])
  /* -- effects : activation : use-effects */

  useEffect(() => {
    if (!visibleResend && activationResendClicked) {
      setActivationResendClicked(false)
      window.location.reload()
    }
  }, [visibleResend])

  const execRedirects = async (e) => {
    if (e && !e.status && e.err) {
      const { isStatus, data } = activation.state
      const { activationobject } = data
      const { response } = activationobject
      const outComeCode = response
        ? response.data.outComeCode
        : activationobject.outComeCode
      // const { outComeCode } = response.data
      const key = outComeCode.toString()
      const manipulatedOutComeCode = key.replace('\r\n', '')
      const isSuccessfullyUpdated =
        data && manipulatedOutComeCode === '0' ? true : false
      const message = currentlanguage.errorresponse_accountActivation[
        manipulatedOutComeCode
      ]
        ? currentlanguage.errorresponse_accountActivation[
            manipulatedOutComeCode
          ]
        : ''
      // await execActivationReset()
      if (activationResendClicked) {
        setResendResult('')
        setOpen(false)
        setVisibleResend(!visibleResend)
      } else {
        setOpen(false)
        props.history.push('/activation/confirm', {
          isSuccessfullyUpdated,
          message,
        })
      }
    } else {
      if (activationResendClicked) {
        setResendResult('')
        setOpen(false)
        setVisibleResend(!visibleResend)
      } else {
        setOpen(false)
        const { isStatus, data } = activation.state
        const { activationobject } = data
        if (activationobject) {
          const { outComeCode, login } = activationobject
          const key = outComeCode.toString()
          const manipulatedOutComeCode = key.replace('\r\n', '')
          const isSuccessfullyUpdated =
            data && manipulatedOutComeCode === '0' ? true : false
          const message = isSuccessfullyUpdated
            ? ''
            : currentlanguage.errorresponse_accountActivation[
                manipulatedOutComeCode
              ]
            ? currentlanguage.errorresponse_accountActivation[
                manipulatedOutComeCode
              ]
            : ''
          LocalStorage.addLS('login', login)
          // await execActivationReset()
          /* auth login */
          props.history.push('/activation/confirm', {
            isSuccessfullyUpdated,
            message,
          })
        }
      }
    }
  }

  /* ---- activation ----  */
  /* state */

  /* -- data props --   */
  const isSubmitButtonDisabled = !verified || !loaded || exprired ? true : false
  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleActivation,
    ACTIVATIONOBJECTS
  )
  const code = {
    ...ACTIVATIONOBJECTS.code,
    error: error.code,
    value: values.code,
    onChange,
    translation: currentlanguage,
  }
  const submitButton = { fullWidth: true }
  const resendButton = {
    fullWidth: true,
    onClick: async () => {
      // props.history.push('/activation/resend')
      setOpen(true)
      await execActivationReset()
      const body = {
        email: emailAddress ? emailAddress : '',
      }
      setActivationResendClicked(true)
      await execActivationResend({ body })
    },
  }
  /* -- data props --   */

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModXlWrapp>
        <ModPageTitleWrapp>
          <ModTitleText>
            {currentlanguage.activationpage.page_activ_activate_title}
          </ModTitleText>
          <ModContentText>
            <p style={{ padding: 0, margin: 0 }}>
              {currentlanguage.activationpage.page_activ_email_activate}
            </p>
          </ModContentText>
        </ModPageTitleWrapp>
      </ModXlWrapp>
      <ModXlWrapp>
        <div style={{ margin: 0, marginTop: -30, padding: 0 }}>
          <Inputs.Text {...code} />
        </div>
      </ModXlWrapp>

      <PopupResend
        visibleResend={visibleResend}
        setVisibleResend={setVisibleResend}
        message={resendResult}
        {...props}
      />
      <br />
      <Recaptcha
        sitekey="6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE"
        render="explicit"
        verifyCallback={verifyCallback}
      />
      <br />
      <ModXlWrapp>
        <Buttons.Normal {...submitButton}>
          {currentlanguage.activationpage.page_activ_submit}
        </Buttons.Normal>
      </ModXlWrapp>
      <br />
      <ModXlWrapp>
        <Buttons.Text {...resendButton}>
          <p style={{ margin: 0, textDecoration: 'underline' }}>
            {currentlanguage.activationpage.page_activ_resend}
          </p>
        </Buttons.Text>
      </ModXlWrapp>
    </form>
  )
}

export default index
