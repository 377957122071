import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
// import Recaptcha from "react-recaptcha";

/* mui */
import { makeStyles } from '@material-ui/core/styles'
/* mui */

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  WindowSize,
  WindowLayout,
  // WindowFont
} from '../../lib-atomic-functions'
/* packages */

import CONFIG from '../config'

const map_placeholder = CONFIG.setup.assets.map_placeholder

/* modules */
// const NewsBox = lazy(() => import("./newsBox"));
/* modules */

/* constant */
const MEDIAPATH = CONFIG.setup.assets.media
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: 'auto',
    textAlign: 'center',
  },
  root: {
    borderRadius: 10,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    // backgroundColor: "#028940",
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 550,
    minWidth: 300,
    height: 400,
    padding: 1,
    background: 'linear-gradient(90deg,#f7c626 15%,#f68c2f 40%,#e5127d 85%)',
    margin: 'auto',
    alignSelf: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  content: {
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    background: 'white',
    height: '100%',
  },
  gridList: {
    width: 800,
  },
}))
/* constant */
const login = (props) => {
  console.log(props)
  const { title, message } =
    props.location && props.location.state
      ? props.location.state
      : {
          title: 'Something Went Wrong',
          message: '',
        }
  const classes = useStyles()

  const { width, height } = WindowSize()
  const {
    layoutWidth,
    layoutHeight,
    // layoutCols,
    // getColsWidthHeight
  } = WindowLayout(width, height)
  // const { getFontSizes } = WindowFont();
  return (
    <div className={classes.content}>
      <div
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 10,
          margin: 'auto',
          marginTop: '1%',
          width: '100%',
          height: '100%',
          backgroundColor: '#ffffff',
          textAlign: 'center',
          minHeight: 400,
        }}
      >
        <div
          style={{
            color: '#006230',
            fontSize: width < 500 ? 30 : 40,
            fontWeight: 800,
            marginTop: 35,
          }}
        >
          {title ? title : 'Something Went Wrong'}
        </div>
        <div
          style={{
            paddingTop: 20,
            color: '#111111',
            fontSize: 15,
            fontWeight: 500,
          }}
        >
          {message ? message : ''}
        </div>
      </div>
    </div>
  )
}

export default login
