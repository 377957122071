const INITIAL_STATE = {
  visible: false,
}

const actionTypes = {
  TOGGLE_VISIBILITY: 'TOGGLE_VISIBILITY',
  SET_VISIBILITY: 'SET_VISIBILITY',
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_VISIBILITY:
      return {
        ...state,
        visible: !state.visible,
      }
    case actionTypes.SET_VISIBILITY:
      return {
        ...state,
        visible: action.payload,
      }
    default:
      return state
  }
}

const drawer = {
  init: INITIAL_STATE,
  actionTypes,
  reducer,
}

export default drawer
