import React, { useContext } from 'react'

import { GlobalContext } from '../../lib-atomic-context'

import {
  Box,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const index = (props) => {
  const { pages } = useContext(GlobalContext)
  const { masterpages } = pages.state.data
  const { header, subtitle, json } =
    masterpages && masterpages.length > 0 ? masterpages[0] : null
  return (
    <Container
      component={Box}
      my={5}
      maxWidth="sm"
      disableGutters
      style={{ paddingLeft: 20, paddingRight: 20 }}
    >
      {header && header.length > 0 ? (
        <Typography
          color="primary"
          variant="h4"
          gutterBottom
          className="subway-footlong"
        >
          {header}
        </Typography>
      ) : null}
      {json && json.promotions && (
        <Box my={3}>
          {json.promotions.title && json.promotions.title.length > 0 ? (
            <Typography
              key={index}
              color="primary"
              variant="h5"
              gutterBottom
              className="subway-footlong"
            >
              {json.promotions.title}
            </Typography>
          ) : null}
          {json.promotions.subtitle && json.promotions.subtitle.length > 0 ? (
            <Typography
              style={{ marginTop: 10 }}
              gutterBottom
              className="subway-six-inch"
            >
              {
                <span
                  dangerouslySetInnerHTML={{ __html: json.promotions.subtitle }}
                />
              }
            </Typography>
          ) : null}

          <Box my={3} style={{ marginBottom: 100 }}>
            {json.promotions.contents &&
              json.promotions.contents.length > 0 &&
              json.promotions.contents.map(({ q, a }, index) => (
                <div key={index} style={{ marginTop: 35, marginBottom: 35 }}>
                  <Typography
                    className="subway-footlong"
                    style={{ wrap: 'flex', fontSize: 18 }}
                  >
                    {<div dangerouslySetInnerHTML={{ __html: q }} />}
                    <span style={{ fontFamily: 'SubwaySixInch', fontSize: 16 }}>
                      {<div dangerouslySetInnerHTML={{ __html: a }} />}
                    </span>
                  </Typography>
                </div>
              ))}
          </Box>
        </Box>
      )}
    </Container>
  )
}

export default index
