import React, { lazy, Suspense, useContext, Fragment } from 'react'

/* container */
import { GlobalProvider, GlobalContext } from '../../lib-atomic-context'
import HomeContainer from '../../app-module-home-container/default'
import CMSContainer from '../../app-module-cms-container/default'
import { ModPublicWrapp } from '../../lib-atomic-design-section'
import { LocalStorage } from '../../lib-atomic-functions'
/* container */
import ScrollToTop from '../../../scrollToTop'

/* modules */
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
/* modules */
import BannerBox from '../banner/bannerBox'
/* lazy modules */
const CMSLayout = lazy(() =>
  import('../../app-module-cms-section-layout/default')
)
/* lazy modules */

const index = (props) => {
  const { bannerscontents, setup } = useContext(GlobalContext)
  const imgToDisplay = bannerscontents.state.data.masterbannerscontents.filter(
    (page) => {
      return page.pageName === 'PreloginHome'
    }
  )[0]
  return (
    <Fragment {...props}>
      <ScrollToTop {...props} />
      <ModPublicWrapp {...props} imgToDisplay={imgToDisplay} />
      <HomeContainer {...props}>
        <CMSContainer {...props}>
          <Suspense
            fallback={
              // <div style={{ marginTop: 200 }}>
              //   <Spinner2.Popup />
              // </div>
              <></>
            }
          >
            <CMSLayout {...props} />
          </Suspense>
        </CMSContainer>
      </HomeContainer>
    </Fragment>
  )
}

export default index
