import React, { useEffect, useContext, useState } from 'react'
import { withRouter } from 'react-router-dom'
import QueryString from 'query-string'
import Header from '../../../layout/main/header'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2 } from '../../lib-atomic-functions'

import { LocalStorage, FetchCountryLang } from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
/* module */
import SomethingWentWrong from '../../app-something-went-wrong/default'
import CONFIG from '../config'

/* CONSTANTS */
const DEVICE = 'mob'
// const CONTENTENVIRONMENT = CONFIG.environment
const DEVTOKEN = CONFIG.setup.setup.dev_env_token
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
// const NEWAPIMOCKUP = CONFIG.application.newapi.apiendpoints

const CMSMASTERSETUPCONFIG = {
  url: CMSENDPOINTS.mastersetup.url,
  method: CMSENDPOINTS.mastersetup.method,
  headers: CMSENDPOINTS.mastersetup.headers,
  responseType: 'json',
}

const CMSMASTERCONTENTSCONFIG = {
  url: CMSENDPOINTS.mastercontents.url,
  method: CMSENDPOINTS.mastercontents.method,
  headers: CMSENDPOINTS.mastercontents.headers,
  responseType: 'json',
}

const CMSMASTERERRORRESPONSECONFIG = {
  url: CMSENDPOINTS.mastererrorresponse.url,
  method: CMSENDPOINTS.mastererrorresponse.method,
  headers: CMSENDPOINTS.mastererrorresponse.headers,
  responseType: 'json',
}

const CMSMASTERAPPVERSIONCONFIG = {
  url: CMSENDPOINTS.masterappversion.url,
  method: CMSENDPOINTS.masterappversion.method,
  headers: CMSENDPOINTS.masterappversion.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRYLANGUAGECONFIG = {
  url: CMSENDPOINTS.mastercountrylanguage.url,
  method: CMSENDPOINTS.mastercountrylanguage.method,
  headers: CMSENDPOINTS.mastercountrylanguage.headers,
  responseType: 'json',
}
const CMSMASTERTRANSLATIONCONFIG = {
  url: CMSENDPOINTS.mastertranslation.url,
  method: CMSENDPOINTS.mastertranslation.method,
  headers: CMSENDPOINTS.mastertranslation.headers,
  responseType: 'json',
}
const CMSMASTERBANNERSCONFIG = {
  url: CMSENDPOINTS.masterbanners.url,
  method: CMSENDPOINTS.masterbanners.method,
  headers: CMSENDPOINTS.masterbanners.headers,
  responseType: 'json',
}

const CMSMASTERBANNERSCONTENTSCONFIG = {
  url: CMSENDPOINTS.masterbannerscontents.url,
  method: CMSENDPOINTS.masterbannerscontents.method,
  headers: CMSENDPOINTS.masterbannerscontents.headers,
  responseType: 'json',
}

const CMSMASTERPAGESCONFIG = {
  url: CMSENDPOINTS.masterpages.url,
  method: CMSENDPOINTS.masterpages.method,
  headers: CMSENDPOINTS.masterpages.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRYCONFIG = {
  url: CMSENDPOINTS.mastercountry.url,
  method: CMSENDPOINTS.mastercountry.method,
  headers: CMSENDPOINTS.mastercountry.headers,
  responseType: 'json',
}

const SPINTHEWHEELCONFIG = {
  url: CMSENDPOINTS.spinthewheel.url,
  method: CMSENDPOINTS.spinthewheel.method,
  headers: CMSENDPOINTS.spinthewheel.headers,
  responseType: 'json',
}
// const POSTLOGINAPI = {
//   url: CMSENDPOINTS.enquiry.url,
//   method: CMSENDPOINTS.enquiry.method,
//   headers: CMSENDPOINTS.enquiry.headers,
//   responseType: 'json',
// }
/* CONSTANTS */

const index = (props) => {
  const [isSetupReady1, setIsSetupReady] = useState(false)
  const [isFirstBannerFetch, setIsFirstBannerFetch] = useState(false)
  /* state */
  const {
    setup,
    version,
    translation,
    banners,
    bannerscontents,
    countrylanguage,
    contents,
    spinthewheel,
    pages,
    cmscountry,
  } = useContext(GlobalContext)
  /* state */

  // #################################################
  // VERSION CHECK

  const versionCheck = (version) => {
    const localVersion = LocalStorage.getLS('application').application.version
    if (localVersion) {
      if (version.state.data) {
        const serverVersion = version.state.data.masterappversion[0].version
        if (localVersion === serverVersion) {
          return true
        } else {
          console.log(
            localVersion,
            serverVersion,
            version.state.data,
            ' !== version state not ready'
          )
        }
      } else {
        console.log(' version state not ready')
      }
    }
    console.log(localVersion, ' false version check')
    return false
  }

  // VERSION CHECK
  // #################################################

  // #################################################
  // MASTERFILES
  // console.log('// MASTERFILES')
  // #################################################

  /* --- setup */
  const [fetchMasterSetups] = FetchContextApiV2(CMSMASTERSETUPCONFIG)

  const fetchMasterSetupsExecute = async ({ query }) => {
    setup.dispatch({
      type: setup.actionTypes.FETCH_SETUPS,
      setup: await fetchMasterSetups({ query }, setup),
    })
  }

  /* masterfile: version */
  const [fetchMasterAppVersion] = FetchContextApiV2(CMSMASTERAPPVERSIONCONFIG)
  const execFetchMasterAppVersion = async () => {
    version.dispatch({
      type: version.actionTypes.FETCH_VERSION,
      version: await fetchMasterAppVersion({ query: '?device=mob' }, version),
    })
  }
  /* masterfile: version */

  /* masterfile: country */
  const [fetchMasterCountry] = FetchContextApiV2(CMSMASTERCOUNTRYCONFIG)
  const execFetchMasterCountry = async ({ query }) => {
    cmscountry.dispatch({
      type: cmscountry.actionTypes.FETCH_COUNTRY,
      cmscountry: await fetchMasterCountry({ query }, cmscountry),
    })
  }
  /* masterfile: country */

  /* masterfile: country and language */
  const [fetchMasterCountryLanguage] = FetchContextApiV2(
    CMSMASTERCOUNTRYLANGUAGECONFIG
  )
  const execFetchMasterCountryLanguage = async ({ query }) => {
    countrylanguage.dispatch({
      type: countrylanguage.actionTypes.FETCH_TRANSLATION,
      // language: { status: true, data: { blob: null, json: langToBeUsed } }
      countrylanguage: await fetchMasterCountryLanguage(
        { query },
        countrylanguage
      ),
    })
  }

  /* masterfile: country and language */

  /* masterfile compound call: version , country language */
  // this compound needs to run first and to have values
  const execSetupFetch = async ({ country, lang }) => {
    const parsed = QueryString.parse(props.location.search)
    const { forcedDev } = parsed
    if (forcedDev) {
      if (forcedDev === DEVTOKEN) {
        LocalStorage.addLS('forcedDev', true)
      }
    }
    const isProdEnv =
      LocalStorage.getLS() && LocalStorage.getLS().forcedDev
        ? 'development'
        : 'production'
    const CONTENTENVIRONMENT = isProdEnv //CONFIG.environment;
    await fetchMasterSetupsExecute({
      query:
        '?mastercountries.abbr=' +
        country +
        '&masterlanguages.abbr=' +
        lang +
        '&environment=' +
        CONTENTENVIRONMENT,
    })
  }

  const execComp_Version_CountryLanguage = async () => {
    const parsed = QueryString.parse(props.location.search)
    const { forcedDev } = parsed
    if (forcedDev) {
      if (forcedDev === DEVTOKEN) {
        LocalStorage.addLS('forcedDev', true)
      }
    }
    const isProdEnv =
      LocalStorage.getLS() && LocalStorage.getLS().forcedDev
        ? 'development'
        : 'production'
    const CONTENTENVIRONMENT = isProdEnv //CONFIG.environment;
    await execFetchMasterAppVersion()
    await execFetchMasterCountryLanguage({
      query: '?environment=' + CONTENTENVIRONMENT,
      // query: '',
    })
  }
  /* masterfile compound call: version , country language */

  /* masterfile effect : */
  useEffect(() => {
    console.log('global')
    execComp_Version_CountryLanguage()
  }, [])
  /* masterfile effect : */

  // #################################################
  // MASTERFILES
  // console.log('// MASTERFILES END')
  // #################################################

  // #################################################
  // SLAVEFILES
  // console.log('// SLAVEFILES')
  // #################################################

  /* slavefile: translation */
  const [fetchMasterTranslation] = FetchContextApiV2(CMSMASTERTRANSLATIONCONFIG)
  const execFetchMasterTranslation = async ({ country, lang }) => {
    translation.dispatch({
      type: translation.actionTypes.FETCH_TRANSLATION,
      translation: await fetchMasterTranslation(
        {
          query:
            '?mastercountries.abbr=' +
            country +
            '&masterlanguages.abbr=' +
            lang,
        },
        translation
      ),
    })
  }
  /* slavefile: translation */

  /* slavefile: pages */
  const [fetchMasterPages] = FetchContextApiV2(CMSMASTERPAGESCONFIG)
  const execFetchMasterPages = async ({ country, lang }) => {
    pages.dispatch({
      type: pages.actionTypes.FETCH_PAGES,
      pages: await fetchMasterPages(
        {
          query:
            '?mastercountries.abbr=' +
            country +
            '&masterlanguages.abbr=' +
            lang,
        },
        pages
      ),
    })
  }

  const [fetchSpinthewheel] = FetchContextApiV2(SPINTHEWHEELCONFIG)
  const execFetchSpinthewheel = async ({ country, lang }) => {
    spinthewheel.dispatch({
      type: spinthewheel.actionTypes.FETCH_PAGES,
      spinthewheel: await fetchSpinthewheel(
        {
          query:
            '?mastercountries.abbr=' +
            country +
            '&masterlanguages.abbr=' +
            lang,
        },
        spinthewheel
      ),
    })
  }
  /* slavefile: pages */

  /* slavefile: banner */
  const [fetchMasterBanners] = FetchContextApiV2(CMSMASTERBANNERSCONFIG)
  const execFetchMasterBanners = async ({ country, lang }) => {
    banners.dispatch({
      type: banners.actionTypes.FETCH_BANNERS,
      banners: await fetchMasterBanners(
        {
          query:
            '?mastercountries.abbr=' +
            country +
            '&masterlanguages.abbr=' +
            lang,
        },
        banners
      ),
    })
  }
  const execResetMasterBanners = async ({ country, lang }) => {
    banners.dispatch({
      type: banners.actionTypes.RESET,
    })
  }
  /* slavefile: banner */

  useEffect(() => {
    if (setup.state.data) {
      if (setup.state.data.mastersetup.length < 1) {
        props.history.push('/404')
      } else {
        setIsSetupReady(true)
      }
    }
  }, [setup.state])

  /* slavefile compound call: translations, pages */
  // this compound runs only when version states changes
  const execComp_Translation_Pages_Banners = async ({ country, lang }) => {
    // await execFetchMasterCountry({ query: '?abbr=' + country })
    await execFetchMasterCountry({})
    await execSetupFetch({ country, lang })
    await execFetchMasterTranslation({ country, lang })
    await execFetchMasterPages({ country, lang })
    await execFetchSpinthewheel({ country, lang })
    await execResetMasterBanners({ country, lang })
    await execFetchMasterBanners({ country, lang })
  }
  /* slavefile compound call: translations, pages */

  /* slavefile effect : */
  useEffect(() => {
    if (version.state.data && countrylanguage.state.data) {
      const countryFromLS = LocalStorage.getLS() && LocalStorage.getLS().country
      const langFromLS = LocalStorage.getLS() && LocalStorage.getLS().lang
      ////////////////////////////
      // remove later once environment is introduced to mastercountrylanguage
      if (
        countrylanguage.state.data.mastercountrylanguage.response &&
        countrylanguage.state.data.mastercountrylanguage.response.status === 400
      ) {
        props.history.push('/404')
      } else {
        const { country, lang } = FetchCountryLang({
          mastercountrylanguage:
            countrylanguage.state.data.mastercountrylanguage,
          countryFromLS,
          langFromLS,
        })
        LocalStorage.addLS('country', country)
        LocalStorage.addLS('lang', lang)
        execComp_Translation_Pages_Banners({ country, lang })
      }
    }
  }, [version.state, countrylanguage.state])
  /* slavefile effect : */

  // #################################################
  // SLAVEFILES
  // console.log('// SLAVEFILES END')
  // #################################################

  // #################################################
  // SLAVEFILES level 2
  // console.log('// SLAVEFILES LVL 2')
  // #################################################

  /* slavefile: banner contents */
  const [fetchMasterBannersContents] = FetchContextApiV2(
    CMSMASTERBANNERSCONTENTSCONFIG
  )
  const execFetchMasterBannersContents = async () => {
    console.log(banners)
    const bannnerContentIds = banners.state.data.masterbanners[0].pages.map(
      (content) => {
        return content.mastercontents.id
      }
    )
    const query = QueryString.stringify({ id_in: bannnerContentIds })
    bannerscontents.dispatch({
      type: bannerscontents.actionTypes.FETCH_BANNERSCONTENTS,
      bannerscontents: await fetchMasterBannersContents(
        { query },
        bannerscontents
      ),
      banners,
    })
  }
  /* slavefile: banner contents */

  /* slavefile compound call: banners contents */
  // this compound runs only when banners states changes
  const execComp_BannersContents = async () => {
    await execFetchMasterBannersContents()
  }
  /* slavefile compound call: banners contents */

  /* slavefile lvl 2 effect : */
  useEffect(() => {
    if (banners.state.data) {
      // console.log('run second lvl 2....')
      setIsFirstBannerFetch(true)
      execComp_BannersContents()
    }
  }, [banners.state.data])
  /* slavefile lvl 2 effect : */

  // #################################################
  // SLAVEFILES level 2
  // console.log('// SLAVEFILES LVL 2 END')
  // #################################################

  // #################################################
  // SLAVEFILES level 3
  // console.log('// SLAVEFILES LVL 3')
  // #################################################

  /* slavefile: master contents */
  const [fetchMasterContents] = FetchContextApiV2(CMSMASTERCONTENTSCONFIG)
  const fetchCarouselMasterContentsExecute = async ({
    parentId,
    idInOrder,
    query,
  }) => {
    bannerscontents.dispatch({
      type: bannerscontents.actionTypes.FETCH_CAROUSEL_CONTENTS,
      parentId,
      idInOrder,
      bannerscontents: await fetchMasterContents({ query }, bannerscontents),
    })
  }
  /* slavefile: banner contents */

  /* slavefile compound call: banners contents */
  // this compound runs only when banners states changes
  const execComp_BannersCarouselContents = async () => {
    const { masterbannerscontents } = bannerscontents.state.data
    const CarouselContentsList = masterbannerscontents.filter(
      (content) => content.type === 'Carousel'
    )
    CarouselContentsList.map(async (carousel, index) => {
      const carouselItemsIds = carousel.CarouselContents.map((items) => {
        return items.mastercontents.id
      })

      const isCarouselsItems =
        carouselItemsIds && carouselItemsIds.length > 0 ? true : false
      const isCarouselItemsExist = CarouselContentsList.some((carousellist) => {
        return carousellist.CarouselContents.some((cc) => {
          return carouselItemsIds.indexOf(cc.id) > -1
        })
      })
      /* if carousel is present and carousel items not yet updated  */
      if (isCarouselsItems && !isCarouselItemsExist) {
        console.log(carouselItemsIds)
        const qs = QueryString.stringify({ id_in: carouselItemsIds })
        await fetchCarouselMasterContentsExecute({
          parentId: carousel.id,
          idInOrder: carouselItemsIds,
          query: '?' + qs,
        })
        // carousel.CarouselContents = carouselCont ? carouselCont : []
      }
    })
  }
  /* slavefile compound call: banners contents */

  /* slavefile lvl 2 effect : */
  useEffect(() => {
    if (bannerscontents.state.data) {
      console.log('run second lvl 3....')
      if (isFirstBannerFetch) {
        console.log('run second lvl 3 INNER....')
        setIsFirstBannerFetch(false)
        execComp_BannersCarouselContents()
      }
      console.log(bannerscontents, ' bannerscontents 384')
    }
  }, [bannerscontents.state.data])
  /* slavefile lvl 2 effect : */

  // #################################################
  // SLAVEFILES level 3
  // console.log('// SLAVEFILES LVL 3 END')
  // #################################################

  // #################################################
  // local storages
  // console.log('// LOCAL STORAGE')
  // #################################################
  const setLocalStorages = () => {
    LocalStorage.addLS('application', {
      ...version.state.data.masterappversion[0],
    })
    LocalStorage.addLS('countrylanguage', {
      ...countrylanguage.state.data.mastercountrylanguage,
    })
    LocalStorage.addLS(
      'translation',
      translation.state.data.mastertranslation[0]
    )
  }
  // #################################################
  // local storages
  // console.log('// LOCAL STORAGE END')
  // #################################################

  // check data ready..
  const isSetupReady =
    setup.state.data && setup.state.data.mastersetup && setup.state.isStatus
      ? true
      : false

  const isVersionReady =
    !version.state.isLoading &&
    version.state.data &&
    version.state.data.masterappversion &&
    version.state.isStatus
      ? true
      : false
  const isCountryLanguageReady =
    !countrylanguage.state.isLoading &&
    countrylanguage.state.data &&
    countrylanguage.state.data.mastercountrylanguage &&
    countrylanguage.state.isStatus
      ? true
      : false
  const isBannersReady =
    !banners.state.isLoading &&
    banners.state.data &&
    banners.state.data.masterbanners &&
    banners.state.isStatus
      ? true
      : false
  const isBannersContentsReady =
    !bannerscontents.state.isLoading &&
    bannerscontents.state.data &&
    bannerscontents.state.data.masterbannerscontents &&
    bannerscontents.state.isStatus
      ? true
      : false
  const isPagesReady =
    !pages.state.isLoading &&
    pages.state.data &&
    pages.state.data.masterpages &&
    pages.state.isStatus
      ? true
      : false
  const isSpintheWheelReady =
    !spinthewheel.state.isLoading &&
    spinthewheel.state.data &&
    spinthewheel.state.isStatus
      ? true
      : false
  const isTranslationReady =
    !translation.state.isLoading &&
    translation.state.data &&
    translation.state.data.mastertranslation &&
    translation.state.isStatus
      ? true
      : false
  if (
    !isSetupReady1 ||
    !isVersionReady ||
    !isCountryLanguageReady ||
    !isBannersReady ||
    !isBannersContentsReady ||
    !isPagesReady ||
    !isTranslationReady ||
    !isSpintheWheelReady
  ) {
    return (
      // <div>
      //   <Spinner2.Header />
      //   <Spinner2.Popup />
      // </div>
      <></>
    )
  } else {
    setLocalStorages()
  }

  return props.children
}

export default withRouter(index)
