const isMinMaxPhoneNumber = data => {
  const key = 'isMinMaxPhoneNumber'
  const { value } = data
  const minLimit = 9
  if (value==""){
    return false;
  }else {
    console.log("isNaN(value) ",!isNaN(value) );
  const isNumeric = value.length === minLimit && !isNaN(value) || value.length > minLimit && !isNaN(value) ? true : false
  const error = isNumeric ? false : key
  return error ? { error, data } : false
}
}
    


export default isMinMaxPhoneNumber
