import { sanitizeData, sanitizeExpire } from './Sanitize'

const init = {
  isLoading: false,
  isStatus: true,
  expireToken: false,
  data: false,
}

const actionTypes = {
  FETCH_NEWS_CONTENTS: 'FETCH_NEWS_CONTENTS',
}

const fetchContents = async (state, newsContents) => {
  state = {
    ...state,
    isStatus: newsContents.status,
    expireToken: sanitizeExpire({
      status: newsContents.status,
      data: newsContents.data,
      name: 'newscontents',
    }),
    data: sanitizeData({
      status: newsContents.status,
      data: newsContents.data,
      name: 'newscontents',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NEWS_CONTENTS:
      return fetchContents(state, action.newsContents)
    default:
      return state
  }
}

const newsContents = {
  init,
  actionTypes,
  reducer,
}

export default newsContents
