import React, { useContext, Fragment } from 'react'
import Moment from 'react-moment'

/* packages */
import { Lists } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import { NumberCurrency, LocalStorage } from '../../lib-atomic-functions'
import { ModuleText } from '../../lib-atomic-design-section'
/* packages */

const IDTPL = (data, obj) => {
  return (
    <ModuleText>
      <div>
        <small>
          <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>{data}</p>
        </small>
      </div>
    </ModuleText>
  )
}
const TOTALTPL = (data, obj) => {
  const countrySet = LocalStorage.getLS().country
  const symbol =
    countrySet && (countrySet === 'gb' || countrySet === 'ie')
      ? '£'
      : countrySet && countrySet === 'se'
      ? 'kr'
      : '€'
  if (!data) return null
  const amount = NumberCurrency(data.toFixed(2))
  return (
    <Fragment>
      <ModuleText className="subway-six-inch">
        <small>
          {symbol}
          {amount}
        </small>
      </ModuleText>
    </Fragment>
  )
}
const DATETPL = (data, obj) => {
  return (
    <ModuleText>
      <small>
        <p style={{ fontFamily: 'SubwayFootlong', margin: 0, marginBottom: 2 }}>
          <Moment format="DD.MM.YYYY">{data}</Moment>
        </p>
        <p style={{ fontFamily: 'SubwaySixInch', margin: 0, marginBottom: 2 }}>
          <Moment format="HH:mm:ss">{data}</Moment>
        </p>
        {/* <p style={{ fontFamily: 'SubwaySixInch', margin: 0 }}>
          <Moment fromNow>{data}</Moment>
        </p> */}
      </small>
    </ModuleText>
  )
}
const LABEL = (data) => {
  return (
    <ModuleText>
      <br />
      <small>
        <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>{data}</p>
      </small>
      <br />
    </ModuleText>
  )
}
const POINTLABEL = (data) => {
  return (
    <ModuleText>
      <br />
      <small>
        <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
          {data > 0 ? '+' + data : data}
        </p>
      </small>
      <br />
    </ModuleText>
  )
}

const transactionList = (props) => {
  const currentlanguage = LocalStorage.getLS().translation.json
  const { country, lang } = LocalStorage.getLS()
  const { transactionlist } = useContext(GlobalContext)
  // const { token } = LocalStorage.getLS().login;
  const { transactionlistobj } = transactionlist.state.data

  const goToDetail = (id) => {
    const link = '/history/' + id
    props.history.push(link)
  }

  const LISTTEMPLATECONFIG = [
    {
      field: 'transactionId',
      template: IDTPL,
      label: LABEL(
        currentlanguage.transactionhistorypage.page_history_order
          ? currentlanguage.transactionhistorypage.page_history_order.toUpperCase() +
              ' #'
          : 'ORDER #'
      ),
    },
    {
      field: 'transactionDate',
      template: DATETPL,
      label: LABEL(
        currentlanguage.transactionhistorypage.page_history_date
          ? currentlanguage.transactionhistorypage.page_history_date.toUpperCase()
          : 'DATE'
      ),
    },
    {
      field: 'purchaseValue',
      template: TOTALTPL,
      label: LABEL(
        currentlanguage.transactionhistorypage.page_history_spent
          ? currentlanguage.transactionhistorypage.page_history_spent.toUpperCase()
          : 'SPENT'
      ),
    },
    {
      field: 'loyaltyValue',
      template: POINTLABEL,
      label: LABEL(
        currentlanguage.transactionhistorypage.page_history_points
          ? currentlanguage.transactionhistorypage.page_history_points.toUpperCase()
          : 'POINTS'
      ),
    },
  ]

  const listData = {
    ...props,
    listTitle: currentlanguage.transactionhistorypage.page_history_title,
    toText: currentlanguage.transactionhistorypage.page_history_to,
    listFilterTitle:
      currentlanguage.transactionhistorypage.page_history_filter_empty,
    listEmpty: currentlanguage.transactionhistorypage.page_history_filter_empty,
    filterBtn: currentlanguage.transactionhistorypage.page_history_filter_btn,
    resetBtn: currentlanguage.transactionhistorypage.page_history_reset_btn,
    viewsText: currentlanguage.transactionhistorypage.page_history_view,
    data: transactionlistobj.transactions.slice(0, 20),
    config: LISTTEMPLATECONFIG,
    linkToDetail: goToDetail,
  }
  /* state */

  return <Lists.Normal {...listData} />
}

export default transactionList
