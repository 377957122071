import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'registrations'

const config = {
  details: {
    project,

    environment,
  },
  application: {
    account: {
      api: {
        endpoints: {
          processiou: {
            headers: {
              'Content-Type': 'application/json',
            },
            doc: 'https://subwayuat.tr4ns.com/tx-sub/members/iou',
            url:
              setup.setup.api.subcard.url[environment] + '/tx-sub/members/iou',
            method: 'POST',
          },
          enquiry: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: null,
              moduleCode: setup.setup.thor.moduleCode[environment],
            },
            url: setup.setup.api.stormborn.url.mockapi + '/enquiry',
            method: 'GET',
          },
        },
      },
      objects: {
        iou1: {
          name: 'iou1',
          initial: '',
          // placeholder: '4 digit',
          helperText: false,
          rules: ['isNumberAndLetter'],
        },
        iou2: {
          name: 'iou2',
          initial: '',
          // placeholder: '4 digit',
          helperText: false,
          rules: ['isNumberAndLetter'],
        },
        iou3: {
          name: 'iou3',
          initial: '',
          // placeholder: '4 digit',
          helperText: false,
          rules: ['isNumberAndLetter'],
        },
        iou4: {
          name: 'iou4',
          initial: '',
          // placeholder: '4 digit',
          helperText: false,
          rules: ['isNumberAndLetter'],
        },
        iou5: {
          name: 'iou5',
          initial: '',
          // placeholder: '4 digit',
          helperText: false,
          rules: ['isNumberAndLetter'],
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'Iou',
    prefix: '/iou',
  },
  setup,
}

export default config
