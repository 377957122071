import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_MEMBER: 'FETCH_MEMBER',
  CLEAR_DATA: 'CLEAR_DATA',
}

const fetchMember = async (state, member2) => {
  state = {
    ...state,
    isStatus: member2.status,
    expireToken: sanitizeExpire({
      status: member2.status,
      data: member2.data,
      name: 'member2Object',
    }),
    data: sanitizeData({
      status: member2.status,
      data: member2.data,
      name: 'member2Object',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MEMBER:
      return fetchMember(state, action.member2)
    case actionTypes.CLEAR_DATA:
      return init
    default:
      return state
  }
}

const member = {
  init,
  actionTypes,
  reducer,
}

export default member
