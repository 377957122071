import React, { useState, useContext, useEffect } from 'react'
// import { Link } from "react-router-dom";
import Recaptcha from 'react-recaptcha'
import QueryString from 'query-string'

/* packages */
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { Inputs, Buttons } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import {
  FormHook,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
import {
  ModXlWrapp,
  // ModPageTitleWrapp,
  // ModTitleText,
  // ModContentText,
  ModFormLabelText,
  ModFormLabelTextSm,
} from '../../lib-atomic-design-section'

import PopupMessage from '../popup'
// import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

/* stores */
// import { AuthLogin } from "../../app-module-admin-container/stores";
/* stores */

import CONFIG from '../config'

import appConf from '../../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV

/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.account.objects
const ENDPOINTS = CONFIG.application.account.api.endpoints
const RESETPASSWORDCONFIG = {
  url: ENDPOINTS.resetpassword.url,
  method: ENDPOINTS.resetpassword.method,
  headers: ENDPOINTS.resetpassword.headers,
  responseType: 'json',
}
// const LOGINFAILRESPONSE = CONFIG.application.account.responses.fail;
// const UNVERIFIEDRESPONSE = CONFIG.application.account.responses.unverified;
/* CONSTANTS */

const login = (props) => {
  const currentlanguage = LocalStorage.getLS().translation.json

  const parsed = QueryString.parse(props.location.search)
  const { token } = parsed
  if (token === undefined) {
    // handle invalid token
    console.log('token not found')
  }
  // const { token } = LocalStorage.getLS().login;
  /* state */
  const { forgotpasswordreset } = useContext(GlobalContext)

  const [loaded, setLoaded] = useState(false)
  const [verified, setVerified] = useState(false)
  const [visiblePW, setVisiblePW] = useState(false)
  const [visiblePWC, setVisiblePWC] = useState(false) // setVisiblePWC
  const [visibleErrorPopup, setVisibleErrorPopup] = useState(false)

  const [fetchResetPassword] = FetchContextApiV2(RESETPASSWORDCONFIG)
  // console.log(fetchMasterAppVersion, 'fetchMasterAppVersion')

  const fetchResetPasswordExe = async ({ newHeader, body }) => {
    forgotpasswordreset.dispatch({
      type: forgotpasswordreset.actionTypes.FORGOT_PASSWORD_RESET,
      // transactiondetail: { status: true, data: { blob: null, json: dummy2 } },
      forgotpasswordreset: await fetchResetPassword(
        { newHeader, body },
        forgotpasswordreset
      ),
    })
  }
  const execForgotPasswordReset = () => {
    forgotpasswordreset.dispatch({
      type: forgotpasswordreset.actionTypes.RESET,
    })
  }

  const callback = () => {
    console.log('loaded recaptcha...')
  }

  // specifying verify callback function
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true)
      setLoaded(true)
    }
  }

  const handleLogin = async () => {
    setLoaded(true)
    if (verified) {
      const newHeader = {
        ...RESETPASSWORDCONFIG.headers,
        moduleCode: setup.setup.thor.moduleCode[environment],
      }
      const body = {
        token,
        password: values.password,
      }
      fetchResetPasswordExe({ newHeader, body })
    }
  }

  useEffect(() => {
    if (forgotpasswordreset.state.data) {
      try {
        const { isStatus, data } = forgotpasswordreset.state
        const { forgotpasswordresetobj } = data
        if (!forgotpasswordresetobj) throw { status: isStatus, err: false }
        const { outComeCode } = forgotpasswordresetobj
        if (outComeCode !== '0')
          throw { status: isStatus, err: forgotpasswordresetobj }
        execRedirects()
      } catch ({ status, err }) {
        execRedirects({ status, err })
      }
    }
  }, [forgotpasswordreset.state])

  const execRedirects = (e) => {
    // const errorResponse = LocalStorage.getLS().translation.json
    //   .errorresponse_resetPassword
    // const errorResponse = LocalStorage.getLS().translation.json.errorresponse
    // const { resetPassword } = errorResponse
    if (!e.status && e.err) {
      // fetch fail
      const { country, lang } = LocalStorage.getLS()
      const { isStatus, data } = forgotpasswordreset.state
      const { forgotpasswordresetobj } = data
      const { response } = forgotpasswordresetobj
      const { outComeCode } = response.data
      const key = outComeCode.toString()
      const manipulatedOutComeCode = key.replace('\r\n', '')
      const isSuccessfullyUpdated =
        data && manipulatedOutComeCode === '0' ? true : false
      const message =
        currentlanguage.errorresponse_resetPassword[manipulatedOutComeCode]
      if (
        manipulatedOutComeCode === 'IDENT:RESET_PWD:7IDENT:UPD_IDENTS_PWD:6'
      ) {
        execForgotPasswordReset({})
        setVisibleErrorPopup(!visibleErrorPopup)
      } else {
        props.history.push('/reset_password/confirm', {
          isSuccessfullyUpdated,
          message,
        })
      }
    } else {
      // fetch success
      const { country, lang } = LocalStorage.getLS()
      const { isStatus, data } = forgotpasswordreset.state
      const { forgotpasswordresetobj } = data
      const { outComeCode } = forgotpasswordresetobj
      const key = outComeCode.toString()
      const manipulatedOutComeCode = key.replace('\r\n', '')
      const isSuccessfullyUpdated =
        data && manipulatedOutComeCode === '0' ? true : false
      const message =
        currentlanguage.errorresponse_resetPassword[manipulatedOutComeCode]
      props.history.push('/reset_password/confirm', {
        isSuccessfullyUpdated,
        message,
      })
    }
  }

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleLogin,
    LOGINOBJECTS
  )

  const password = {
    ...LOGINOBJECTS.password,
    error: error.password,
    value: values.password,
    onChange,
    translation: currentlanguage,
  }
  const passwordconfirm = {
    ...LOGINOBJECTS.passwordconfirm,
    error: error.passwordconfirm,
    value: values.passwordconfirm,
    onChange,
    translation: currentlanguage,
  }
  const submitButton = { fullWidth: true }
  console.log(currentlanguage.errorresponse_formValidation)
  return (
    <form onSubmit={onSubmit} noValidate>
      <ModFormLabelText>
        {currentlanguage.resetpasswordpage.page_header}
      </ModFormLabelText>
      <ModFormLabelTextSm>
        <div
          style={{
            display: 'flex',
            margin: 0,
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <div style={{ paddingRight: 10 }}>•</div>
          <div>{currentlanguage.resetpasswordpage.page_condition_1}</div>
        </div>
        <div
          style={{
            display: 'flex',
            margin: 0,
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <div style={{ paddingRight: 10 }}>•</div>
          <div>{currentlanguage.resetpasswordpage.page_condition_2}</div>
        </div>
        <div
          style={{
            display: 'flex',
            margin: 0,
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <div style={{ paddingRight: 10 }}>•</div>
          <div>{currentlanguage.resetpasswordpage.page_condition_3}</div>
        </div>
        <div
          style={{
            display: 'flex',
            margin: 0,
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <div style={{ paddingRight: 10 }}>•</div>
          <div>{currentlanguage.resetpasswordpage.page_condition_4}</div>
        </div>
        <div
          style={{
            display: 'flex',
            margin: 0,
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <div style={{ paddingRight: 10 }}>•</div>
          <div>{currentlanguage.resetpasswordpage.page_condition_5}</div>
        </div>
        <div
          style={{
            display: 'flex',
            margin: 0,
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <div style={{ paddingRight: 10 }}>•</div>
          <div>{currentlanguage.resetpasswordpage.page_condition_6}</div>
        </div>
      </ModFormLabelTextSm>
      <br />
      <ModXlWrapp>
        <ModFormLabelText>
          {currentlanguage.resetpasswordpage.page_new_password}
        </ModFormLabelText>
        <Inputs.Password
          style={{ backgroundColor: '#d3d3d352' }}
          {...password}
          visible={visiblePW}
        />
        <div
          onClick={() => {
            setVisiblePW(!visiblePW)
          }}
        ></div>
      </ModXlWrapp>
      <br />
      <ModXlWrapp>
        <ModFormLabelText>
          {currentlanguage.resetpasswordpage.page_new_password_confirm}
        </ModFormLabelText>
        <Inputs.Password
          style={{ backgroundColor: '#d3d3d352' }}
          {...passwordconfirm}
          visible={visiblePWC}
        />
        <div
          onClick={() => {
            setVisiblePWC(!visiblePWC)
          }}
        ></div>
      </ModXlWrapp>
      <br />
      <Recaptcha
        sitekey="6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE"
        render="explicit"
        verifyCallback={verifyCallback}
        onloadCallback={callback}
      />
      <br />
      <PopupMessage
        visiblePopup={visibleErrorPopup}
        setVisiblePopup={setVisibleErrorPopup}
        {...props}
      />
      <ModXlWrapp>
        <Buttons.Normal {...submitButton}>
          {currentlanguage.resetpasswordpage.page_update_password}
        </Buttons.Normal>
      </ModXlWrapp>
    </form>
  )
}

export default login
