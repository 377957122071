import React from 'react' // useContext

import { Paper, makeStyles } from '@material-ui/core'

/* package */
import { ModPublicWrapp } from '../../lib-atomic-design-section'
import { Buttons } from '../../lib-atomic'

// import { GlobalContext } from '../../lib-atomic-context'
/* package */

// import CONFIG from '../config'

/* CONSTANTS */
// const mediaPath = CONFIG.setup.assets.media
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      padding: theme.spacing(10),
    },
  },
}))

const index = (props) => {
  const classes = useStyles()

  // const { country, lang } = LocalStorage.getLS()

  // const backButton = {
  //   fullWidth: true,
  //   onClick: () => {
  //     LocalStorage.removeLS()
  //     props.history.push('/web/' + country + '/' + lang + '/login')
  //   },
  // }
  return (
    <ModPublicWrapp {...props} imgToDisplay={null}>
      <div className={classes.root}>
        <Paper>
          <h1>Error</h1>

          <div style={{ height: 20 }} />
          <p>Something went wrong. Please try again later.</p>
          <div style={{ height: 10 }} />
          {/* <Buttons.Normal {...backButton}>Back to home</Buttons.Normal> */}
        </Paper>
      </div>
    </ModPublicWrapp>
  )
}

export default index
