import React from 'react'

/* mui */
import { makeStyles } from '@material-ui/core/styles'
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  wrapp: {
    margin: theme.spacing(15),
  },
}))
/* CONSTANTS */

const index = props => {
  /* mui */
  const classes = useStyles()
  /* mui */

  return <div className={classes.wrapp}>{props.children}</div>
}

export default index
