import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_PASSWORD: 'FETCH_PASSWORD',
}

const fetchVersion = async (state, password) => {
  // console.log(version, "test...");
  state = {
    ...state,
    isStatus: password.status,
    expireToken: sanitizeExpire({
      status: password.status,
      data: password.data,
      name: 'passwordObject',
    }),
    data: sanitizeData({
      status: password.status,
      data: password.data,
      name: 'passwordObject',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PASSWORD:
      return fetchVersion(state, action.password)
    default:
      return state
  }
}

const password = {
  init,
  actionTypes,
  reducer,
}

export default password
