import Normal from './normal'
import NormalYellow from './normalYellow'
import Primary from './primary'
import Text from './text'
import Box from './box'
import Back from './back'
import Faq from './faq'
import Blue from './normalBlue'

export default {
  Normal,
  NormalYellow,
  Primary,
  Text,
  Box,
  Back,
  Faq,
  Blue,
}
