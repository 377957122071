import React from 'react'

import Button from '@material-ui/core/Button'

/*
: properties
children
size
fullWidth
*/

const back = (props) => {
  return (
    <Button
      {...props}
      style={{
        border: '2px solid #099144',

        // height: 50,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
        margin: 'auto',
        justifyContent: 'center',
      }}
      className="subway-footlong"
      variant="contained"
      color="white"
      type="submit"
    >
      {props.children}
    </Button>
  )
}

export default back
