import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'cms'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    cms: {
      api: {
        endpoints: {
          campaignresponse: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.cms.url[environment] + '/mastercampaignresponses',
            method: 'POST',
          },
        },
      },
      objects: {
        email: {
          name: 'email',
          initial: '',
          placeholder: 'Email address',
          helperText: false,
          rules: ['isNotNull'],
        },
        password: {
          name: 'password',
          initial: '',
          placeholder: 'Password',
          helperText: false,
          rules: ['isNotNull'],
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'Campaign',
    prefix: '/campaign/submit',
  },
  setup,
}

export default config
