import React, { useState } from 'react'
import { Captcha, Inputs, Buttons } from '../../lib-atomic'
import { FormHook, WindowSize, LocalStorage } from '../../lib-atomic-functions'
import {
  ModPublicWrapp,
  ModXlWrapp,
  ModFormLabelText,
} from '../../lib-atomic-design-section'

import CONFIG from '../config'

/* CONSTANTS */
const temp_points = CONFIG.setup.assets.temp_points

const FORM_OBJECTS = CONFIG.application.objects

const Default = () => {
  const currentlanguage = LocalStorage.getLS().translation.json
  const { width, height } = WindowSize()

  /* -- recaptcha : states -- */
  const [loaded, setLoaded] = useState(false)
  const [verified, setVerified] = useState(false)
  const [exprired, setExpired] = useState(false)
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true)
      setLoaded(true)
      setExpired(false)
    }
  }
  const expiredCallback = (response) => {
    setExpired(true)
  }
  /* -- recaptcha : states -- */

  const handleSubmit = async () => {
    // const body = {
    //   username: values.email,
    //   password: values.password,
    // }

    if (verified) {
      // await fetchTokenExecute(body)
    }
  }

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleSubmit,
    FORM_OBJECTS
  )

  const firstName = {
    ...FORM_OBJECTS.firstName,
    error: error.firstName,
    value: values.firstName,
    onChange,
    translation: currentlanguage,
  }
  const lastName = {
    ...FORM_OBJECTS.lastName,
    error: error.lastName,
    value: values.lastName,
    onChange,
    translation: currentlanguage,
  }
  const email = {
    ...FORM_OBJECTS.email,
    error: error.email,
    value: values.email,
    onChange,
    translation: currentlanguage,
  }
  const rewardsNumber = {
    ...FORM_OBJECTS.rewardsNumber,
    error: error.rewardsNumber,
    value: values.rewardsNumber,
    onChange,
    translation: currentlanguage,
  }
  FORM_OBJECTS.queryType.options = [
    {
      value: 1,
      name: currentlanguage.contactus_website_page.contactus_website_querytype1,
    },
    {
      value: 2,
      name: currentlanguage.contactus_website_page.contactus_website_querytype2,
    },
    {
      value: 3,
      name: currentlanguage.contactus_website_page.contactus_website_querytype3,
    },
    {
      value: 4,
      name: currentlanguage.contactus_website_page.contactus_website_querytype4,
    },
    {
      value: 5,
      name: currentlanguage.contactus_website_page.contactus_website_querytype5,
    },
    {
      value: 6,
      name: currentlanguage.contactus_website_page.contactus_website_querytype6,
    },
  ]
  const queryType = {
    ...FORM_OBJECTS.queryType,
    error: error.queryType,
    value: values.queryType,
    onChange,
    translation: currentlanguage,
  }
  const comment = {
    ...FORM_OBJECTS.comment,
    error: error.comment,
    value: values.comment,
    onChange,
    translation: currentlanguage,
  }

  const isSubmitButtonDisabled = !verified || !loaded || exprired ? true : false
  const captchaData = {
    verified,
    verifyCallback,
    expiredCallback,
  }
  const submitButton = { fullWidth: true, disabled: isSubmitButtonDisabled }

  return (
    <form
      noValidate
      onSubmit={onSubmit}
      style={{
        margin: 'auto',
        width: width < 600 ? '100%' : '70%',
      }}
    >
      <ModXlWrapp>
        <ModFormLabelText>
          {currentlanguage.contactus_website_page.contactus_website_fname}
        </ModFormLabelText>
        <Inputs.Text {...firstName} />
      </ModXlWrapp>

      <ModXlWrapp>
        <ModFormLabelText>
          {currentlanguage.contactus_website_page.contactus_website_lname}
        </ModFormLabelText>
        <Inputs.Text {...lastName} />
      </ModXlWrapp>

      <ModXlWrapp>
        <ModFormLabelText>
          {currentlanguage.contactus_website_page.contactus_website_email}
        </ModFormLabelText>
        <Inputs.Text {...email} />
      </ModXlWrapp>

      <ModXlWrapp>
        <ModFormLabelText>
          {currentlanguage.contactus_website_page.contactus_website_rewards}
        </ModFormLabelText>
        <Inputs.Text {...rewardsNumber} />
      </ModXlWrapp>

      <ModXlWrapp>
        <ModFormLabelText>
          {currentlanguage.contactus_website_page.contactus_website_querytitle}
        </ModFormLabelText>
        <Inputs.Select {...queryType} />
      </ModXlWrapp>

      <ModXlWrapp>
        <ModFormLabelText>
          {currentlanguage.contactus_website_page.contactus_website_message}
        </ModFormLabelText>
        <Inputs.Textarea {...comment} />
      </ModXlWrapp>

      <Captcha.Basic {...captchaData} />
      <br />
      <ModXlWrapp>
        <Buttons.Normal {...submitButton}>
          {currentlanguage.contactus_website_page.contactus_website_submit}
        </Buttons.Normal>
      </ModXlWrapp>
    </form>
  )
}

export default Default
