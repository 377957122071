const isNumberLetter = (data) => {
  // const pattern = /^[a-zA-Z0-9]+$/
  // const { value } = data
  // value.trim()
  // return value.match(pattern) ? true : ''

  const { value } = data
  const pattern = /^[a-zA-Z0-9]+$/
  const key = 'isNumberAndLetter'
  const isTestOk = pattern.test(value) ? true : false
  const error = isTestOk ? false : key
  return error ? { error, data } : false
}

export default isNumberLetter
