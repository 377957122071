import React, { useEffect, useContext, useState } from 'react'

/* packages */
import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import { LocalStorage } from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
/* module */

import CONFIG from '../config'

const ENDPOINTS = CONFIG.application.config.api.endpoints
// const NEWAPIMOCKUP = CONFIG.application.newapi.apiendpoints
const TRANSACTIONLISTCONFIG = {
  url: ENDPOINTS.list.url,
  method: ENDPOINTS.list.method,
  headers: ENDPOINTS.list.headers,
  responseType: 'json',
}
const TRANSACTIONDETAILCONFIG = {
  url: ENDPOINTS.detail.url,
  method: ENDPOINTS.detail.method,
  headers: ENDPOINTS.detail.headers,
  responseType: 'json',
}
const index = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  const { id } = props.match.params
  const { token } = LocalStorage.getLS().login

  const [isHeader, setIsHeader] = useState(true)
  const [isResettingList, setIsResettingList] = useState(false)
  const [isResettingDetail, setIsResettingDetail] = useState(false)
  const { transactionlist, transactiondetail } = useContext(GlobalContext)

  const [fetchTransactionList] = FetchContextApiV2(TRANSACTIONLISTCONFIG)

  const execFetchTransactionList = async ({ newHeader }) => {
    transactionlist.dispatch({
      type: transactionlist.actionTypes.FETCH_TRANSACTION_LIST,
      // transactionlist: { status: true, data: { blob: null, json: dummy } },
      transactionlist: await fetchTransactionList(
        { newHeader },
        transactionlist
      ),
    })
  }
  const execTransactionReset = async () => {
    transactionlist.dispatch({
      type: transactionlist.actionTypes.RESET,
    })
  }

  const [fetchTransactionDetail] = FetchContextApiV2(TRANSACTIONDETAILCONFIG)
  // console.log(fetchMasterAppVersion, 'fetchMasterAppVersion')

  const execFetchTransactionDetail = async ({ query, newHeader }) => {
    transactiondetail.dispatch({
      type: transactiondetail.actionTypes.FETCH_TRANSACTION_DETAIL,
      // transactiondetail: { status: true, data: { blob: null, json: dummy2 } },
      transactiondetail: await fetchTransactionDetail(
        { query, newHeader },
        transactiondetail
      ),
    })
  }
  const execTransactionDetailReset = async () => {
    transactiondetail.dispatch({
      type: transactiondetail.actionTypes.RESET,
    })
  }

  if (props.match.path === '/history') {
    // transaction list ////////////////////////////
    useEffect(() => {
      // GArecordPageView(cookies)
      setIsResettingList(true)
    }, [])
    useEffect(() => {
      if (isResettingList) {
        transactionlist.dispatch({
          type: transactionlist.actionTypes.RESET,
        })
      }
    }, [isResettingList])

    useEffect(() => {
      if (transactionlist.state.data && !isResettingList) {
        const { transactionlistobj } = transactionlist.state.data
        if (
          transactionlistobj.response &&
          transactionlistobj.response.status === 400
        ) {
          const { country, lang } = LocalStorage.getLS()
          // LocalStorage.removeLS()
          execTransactionReset()
          LocalStorage.addLS('login', {})
          props.history.push('/login')
        }
      } else if (isResettingList) {
        setIsResettingList(false)
        const newHeader = {
          ...TRANSACTIONLISTCONFIG.headers,
          Authorization: token,
        }
        execFetchTransactionList({ newHeader })
      }
    }, [transactionlist.state])
    const isTransactionListReady =
      !isResettingList &&
      !transactionlist.state.isLoading &&
      transactionlist.state.data &&
      transactionlist.state.data.transactionlistobj &&
      transactionlist.state.isStatus
        ? true
        : false
    if (!isTransactionListReady) return <Spinner2.Popup />
    return props.children
    /////////////////////////////////////////////////////////////////
  } else {
    // trnasaction detail /////////////////////////////////////////////
    useEffect(() => {
      // GArecordPageView(cookies)
      setIsResettingDetail(true)
    }, [])
    useEffect(() => {
      if (isResettingDetail) {
        transactiondetail.dispatch({
          type: transactiondetail.actionTypes.RESET,
        })
      }
    }, [isResettingDetail])

    useEffect(() => {
      if (transactiondetail.state.data && !isResettingDetail) {
        const { transactiondetailobj } = transactiondetail.state.data
        if (
          transactiondetailobj.response &&
          transactiondetailobj.response.status === 400
        ) {
          const { country, lang } = LocalStorage.getLS()
          // LocalStorage.removeLS()
          execTransactionDetailReset()
          LocalStorage.addLS('login', {})
          props.history.push('/login')
        }
      } else if (isResettingDetail) {
        setIsResettingDetail(false)
        const newHeader = {
          ...TRANSACTIONDETAILCONFIG.headers,
          Authorization: token,
        }
        execFetchTransactionDetail({ query: '/' + id, newHeader })
      }
    }, [transactiondetail.state])
    const isTransactionDetailReady =
      !isResettingDetail &&
      !transactiondetail.state.isLoading &&
      transactiondetail.state.data &&
      transactiondetail.state.data.transactiondetailobj &&
      transactiondetail.state.isStatus
        ? true
        : false

    if (!isTransactionDetailReady)
      return (
        <div style={{ marginTop: 150 }}>
          <Spinner2.Popup />
        </div>
      )
    return props.children
    /////////////////////////////////////////////////////////////////
  }
}

export default index
