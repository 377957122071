import React, { useContext } from 'react'
import { Grid, Box, Typography, Divider, makeStyles } from '@material-ui/core'
// import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { Link } from '../../lib-atomic'

import { GlobalContext } from '../../lib-atomic-context'

import appConf from '../../app-conf'
import { LocalStorage } from '../../lib-atomic-functions'
// import './dashboardpage.scss'

// temporary
const temp_points = appConf[process.env.REACT_APP_CLIENT].assets.temp_points

const subsquad = appConf[process.env.REACT_APP_CLIENT].assets.subsquad
const claim_points = appConf[process.env.REACT_APP_CLIENT].assets.claim_points
const my_history = appConf[process.env.REACT_APP_CLIENT].assets.my_history
const my_profile = appConf[process.env.REACT_APP_CLIENT].assets.my_profile

const useStyles = makeStyles((theme) => ({
  middle: {
    [theme.breakpoints.down('md')]: {
      order: -1,
    },
  },
  box: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // position: 'relative', // if chevron is present
    '& img': {
      'max-width': '100px',
      'max-height': '50px',
      marginBottom: theme.spacing(2),
    },
  },
  // chevron: {
  //   position: 'absolute',
  //   right: '5px',
  // },
  divider: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  pointsHeader: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  pointsBalance: {
    fontSize: '7em',
    fontWeight: 'bold',
    lineHeight: 'normal',
    textAlign: 'center',
  },
  pointsLink: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  fullName: {
    fontSize: '1em',
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  cardDetails: {
    fontSize: '0.8em',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
}))

const boxProps = {
  border: 2,
  borderRadius: '10px',
  borderColor: 'primary.main',
}

const index = () => {
  const currentlanguage = LocalStorage.getLS().translation.json
  const { member, cmscountry } = useContext(GlobalContext)
  const { mastercountry } = cmscountry.state.data
  const plasticFlag = mastercountry[0].plasticVisibility
  const user = member.state.data.memberObject
  const classes = useStyles()

  const LinkBox = ({ img, to, label }) => {
    return (
      <Grid component={Box} item xs={12} p={1} height="50%">
        <Box
          className={classes.box}
          {...boxProps}
          component={Link}
          to={to}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <img src={img} alt={label} />
          <Typography variant="body2">{label}</Typography>

          {/* <ChevronRightIcon className={classes.chevron} /> */}
        </Box>
      </Grid>
    )
  }

  return (
    <Grid
      container
      style={{ maxWidth: 750, margin: 'auto', textAlign: 'center' }}
    >
      <Grid container item xs={12} md={6} lg={3}>
        <LinkBox
          img={subsquad}
          to="/subsquad"
          label={
            currentlanguage.dashboard_website_page.dashboard_website_subsquad
          }
        />
        <LinkBox
          img={my_history}
          to="/history"
          label={
            currentlanguage.dashboard_website_page.dashboard_website_history
          }
        />
      </Grid>

      <Grid
        className={classes.middle}
        component={Box}
        container
        item
        xs={12}
        md={12}
        lg={6}
        p={1}
      >
        <Box className={classes.box} {...boxProps} width="100%" height="100%">
          <Typography color="primary" className={classes.pointsHeader}>
            {
              currentlanguage.dashboard_website_page
                .dashboard_website_totalpoints
            }
          </Typography>

          <Typography color="primary" className={classes.pointsBalance}>
            {user.loyaltyBalance}
          </Typography>

          <Typography
            variant="body2"
            className={classes.pointsLink}
            component={Link}
            color="textPrimary"
            to="/points"
          >
            {
              currentlanguage.dashboard_website_page
                .dashboard_website_what_to_get
            }
          </Typography>

          <Divider className={classes.divider} />

          <Typography className={classes.fullName} color="textPrimary">
            <Box py={1}>
              {user.firstName} {user.lastName}
            </Box>
          </Typography>

          <Typography className={classes.cardDetails} color="textPrimary">
            <br />
            {
              currentlanguage.dashboard_website_page
                .dashboard_website_card_header
            }
            {user && user.virtualCard && (
              <Box py={1}>
                {
                  currentlanguage.dashboard_website_page
                    .dashboard_website_app_card
                }{' '}
                <strong>{user.virtualCard}</strong>
                <br />
              </Box>
            )}
            {plasticFlag && user && user.physicalCard && (
              <Box py={1}>
                <span>
                  {
                    currentlanguage.dashboard_website_page
                      .dashboard_website_plastic_card
                  }
                </span>{' '}
                <strong>{user.physicalCard}</strong>
              </Box>
            )}
          </Typography>

          {/* <ChevronRightIcon className="chevron" /> */}
        </Box>
      </Grid>

      <Grid container item xs={12} md={6} lg={3}>
        <LinkBox
          img={claim_points}
          to="/iou"
          label={
            currentlanguage.dashboard_website_page.dashboard_website_claimpoints
          }
        />
        <LinkBox
          img={my_profile}
          to="/profile"
          label={
            currentlanguage.dashboard_website_page.dashboard_website_profile
          }
        />
      </Grid>
    </Grid>
  )
}

export default index
