import React, { Fragment } from 'react'

import Autosuggest from 'react-autosuggest';

/* atoms */
import { Invalid } from '../feedbacks';
/* atoms */

/* CONSTANTS */
const theme_valid = {
  container:                'space-tiny-top space-tiny-bottom',
  containerOpen:            'space-tiny-top space-tiny-bottom',
  input:                    'form-control',
  inputOpen:                'react-autosuggest__input--open',
  inputFocused:             'react-autosuggest__input--focused',
  suggestionsContainer:     'react-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  suggestionsList:          'react-autosuggest__suggestions-list',
  suggestion:               'react-autosuggest__suggestion',
  suggestionFirst:          'react-autosuggest__suggestion--first',
  suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
  sectionContainer:         'react-autosuggest__section-container',
  sectionContainerFirst:    'react-autosuggest__section-container--first',
  sectionTitle:             'react-autosuggest__section-title'
}

const theme_invalid = {
  container:                'space-tiny-top space-tiny-bottom',
  containerOpen:            'space-tiny-top space-tiny-bottom',
  input:                    'is-invalid form-control',
  inputOpen:                'react-autosuggest__input--open',
  inputFocused:             'react-autosuggest__input--focused',
  suggestionsContainer:     'react-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  suggestionsList:          'react-autosuggest__suggestions-list',
  suggestion:               'react-autosuggest__suggestion',
  suggestionFirst:          'react-autosuggest__suggestion--first',
  suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
  sectionContainer:         'react-autosuggest__section-container',
  sectionContainerFirst:    'react-autosuggest__section-container--first',
  sectionTitle:             'react-autosuggest__section-title'
}
/* CONSTANTS */

const suggest = ({children, name, initial, label, error, value, onChange, suggestionProps, isSelected, onResetSelected, selectedValue, displayTemplate, removeText}) => {

  const theme = !error ? theme_valid : theme_invalid;

  let selectedDisplay = null;
  suggestionProps.inputProps.placeholder = label;
  if (selectedValue) {
    const { suggestion } = JSON.parse(selectedValue);
    selectedDisplay = displayTemplate(suggestion);
  }

  return <Fragment>
    { !isSelected && <Autosuggest theme={theme} {...suggestionProps} /> }
    <input type="hidden" name={name} />
    <Invalid error={error} />
    { isSelected && selectedDisplay }
    { isSelected && children }
  </Fragment>

}

export default suggest;
