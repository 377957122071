import React from 'react'

import { LocalStorage } from '../../lib-atomic-functions'

const index = (props) => {
  const { country, lang } = LocalStorage.getLS()
  props.history.push('/preferences')
  return <div>...</div>
}

export default index
