import moment from 'moment'

const isMinMaxDOB = (data) => {
  const key = 'isMinMaxDOB'
  const { value, config } = data
  if (value === '') {
    return false
  } else {
    const { min, max } = config.isMinMaxDOB

    var birthday = moment(value, 'DD/MM/YYYY')
    const theAge = moment().diff(birthday, 'years')
    const isAgeBetweenMinMax = theAge >= min && theAge <= max ? true : false
    const error = !isAgeBetweenMinMax ? key : false
    return error ? { error, data } : false

    // const today = new Date()
    // const birthDate = new Date(value)
    // const m = today.getMonth() - birthDate.getMonth()
    // let age = today.getFullYear() - birthDate.getFullYear()
    // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //   age--
    // }
    // const isAge = age >= min && age <= max ? true : false
    // const error = !isAge ? key : false
    // return error ? { error, data } : false
  }
}

export default isMinMaxDOB
