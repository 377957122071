import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_TRANSLATION: 'FETCH_TRANSLATION',
}

const fetchTranslation = async (state, translation) => {
  state = {
    ...state,
    isStatus: translation.status,
    expireToken: sanitizeExpire({
      status: translation.status,
      data: translation.data,
      name: 'mastertranslation',
    }),
    data: sanitizeData({
      status: translation.status,
      data: translation.data,
      name: 'mastertranslation',
    }),
  }
  //state.data.mastertranslation = state.data.mastertranslation[0].json;
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSLATION:
      return fetchTranslation(state, action.translation)
    default:
      return state
  }
}

const translation = {
  init,
  actionTypes,
  reducer,
}

export default translation
