import React, { useContext, useState, Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'
import Moment from 'react-moment'

/* packages */
import { Lists } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApiV2,
  LocalStorage,
  NumberCurrency,
  WindowSize,
} from '../../lib-atomic-functions'
import {
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleTitle,
  ModuleText,
  ModuleSubTitle,
} from '../../lib-atomic-design-section'
// import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

/* stores */
// import { AuthLogin } from "../../app-module-admin-container/stores";
/* stores */

import CONFIG from '../config'

const map_placeholder = CONFIG.setup.assets.map_placeholder

const transactionList = (props) => {
  const { width, height } = WindowSize()
  const { country, lang } = LocalStorage.getLS()
  const { transactiondetail } = useContext(GlobalContext)
  // const { token } = LocalStorage.getLS().login;
  const { transactiondetailobj } = transactiondetail.state.data
  const addy =
    transactiondetailobj.merchantAddress.trim() === ''
      ? ''
      : transactiondetailobj.merchantAddress
          .trim()
          .replace(' ', '%20')
          .replace('+', '%2B')

  /* state */
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#008938',
      }}
    >
      <div
        style={{
          width: addy === '' ? '70%' : '100%',
          height: 350,
          justifyContent: 'center',
          textAlign: 'center',
          fontFamily: 'SubwaySixInch',
          margin: 'auto',
          backgroundSize: 'contain',
        }}
      >
        {addy === '' && (
          <img src={map_placeholder} style={{ width: '100%' }} alt="logo" />
        )}
        {addy !== '' ? (
          <iframe
            width="100%"
            height="350"
            id="gmap_canvas"
            src={
              'https://maps.google.com/maps?q=' +
              addy +
              '&t=&z=13&ie=UTF8&iwloc=&output=embed'
            }
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          ></iframe>
        ) : // <iframe
        //   width="100%"
        //   height="350"
        //   id="gmap_canvas"
        //   src={
        //     'https://www.google.com/maps/embed/v1/place?key=&q=' +
        //     addy
        //   }
        //   frameBorder="0"
        //   scrolling="no"
        //   marginHeight="0"
        //   marginWidth="0"
        // ></iframe>
        null}
      </div>
      <div
        style={{
          backgroundColor: '#008938',
          width: '100%',
          paddingTop: 20,
          paddingBottom: 10,
          paddingLeft: width < 500 ? 20 : 40,
          paddingRight: width < 500 ? 20 : 40,
        }}
      >
        <p
          style={{
            color: 'white',
            fontWeight: 800,
            lineHeight: 1,
            fontFamily: 'SubwaySixInch',
          }}
        >
          {transactiondetailobj.merchantName}
        </p>
        <p
          style={{
            color: 'white',
            lineHeight: 1,
            fontFamily: 'SubwaySixInch',
          }}
        >
          {transactiondetailobj.merchantAddress}
        </p>
      </div>
    </div>
  )
}

export default transactionList
