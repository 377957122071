import React from 'react'

import Privilege from '../app-conf-privilege'
import { LocalStorage } from '../lib-atomic-functions'

const Routes = props => {
  const { rolesHandler, rolesSubModules } = Privilege[
    process.env.REACT_APP_CLIENT
  ]
  const availableModules = rolesHandler(LocalStorage, rolesSubModules)
  return availableModules.privacy_notice_new.map((Module, i) => {
    return <Module.Routes key={i} />
  })
}
export default Routes
