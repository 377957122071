import { sanitizeData, sanitizeExpire } from './Sanitize'

const init = {
  isLoading: false,
  isStatus: true,
  expireToken: false,
  data: false,
}

const actionTypes = {
  FETCH_PROMOTIONS_CONTENTS: 'FETCH_PROMOTIONS_CONTENTS',
}

const fetchContents = async (state, promotionsContents) => {
  state = {
    ...state,
    isStatus: promotionsContents.status,
    expireToken: sanitizeExpire({
      status: promotionsContents.status,
      data: promotionsContents.data,
      name: 'promotionscontents',
    }),
    data: sanitizeData({
      status: promotionsContents.status,
      data: promotionsContents.data,
      name: 'promotionscontents',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROMOTIONS_CONTENTS:
      return fetchContents(state, action.promotionsContents)
    default:
      return state
  }
}

const promotionsContents = {
  init,
  actionTypes,
  reducer,
}

export default promotionsContents
