const isNotNullMobile = (data) => {

console.log("dataisNotNullMobile",data)
  if(data.allValues.isActive == true){
    const key = 'isNotNullCardAccess'
    const { value } = data
    const isEmpty = value == "" ? true : false
    const error = isEmpty ? key : false
   
    return error ? { error, data } : false
  }else{
    return false 
  }

}

export default isNotNullMobile
