import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Home'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    config: {
      api: {
        endpoints: {
          memberequiry: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/masterappversions?device=mob',
            url: 'https://sub-spin.tr4ns.com/spin?token=',
            method: 'GET',
          },
        },
      },
    },
  },
  routes: {
    label: 'Spin the wheel',
    prefix: '/spin_the_wheel',
  },
  setup,
}

export default config
