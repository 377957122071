import React, { useContext, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
// import Recaptcha from "react-recaptcha";

/* mui */
import { makeStyles, withStyles } from '@material-ui/core/styles'
/* mui */

/* packages */
import { GlobalContext } from '../../lib-atomic-context'

import { Buttons, Inputs } from '../../lib-atomic'
import {
  FormHook,
  WindowSize,
  WindowLayout,
  LocalStorage,
  FetchContextApiV2,
} from '../../lib-atomic-functions'
/* packages */

import { green } from '@material-ui/core/colors'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

import CloseIcon from '@material-ui/icons/Close'

import CONFIG from '../config'
import { Typography } from '@material-ui/core'

const logo = CONFIG.setup.assets.logo_v2

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    paddingTop: '10px',
    paddingBottom: '10px',
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  paper: {
    // display: 'relative',
    width: '100%',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: 'auto',
    textAlign: 'left',
    overflowY: 'scroll',
  },
  root: {
    borderRadius: 10,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    // backgroundColor: "#028940",
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 550,
    minWidth: 300,
    height: 400,
    padding: 1,
    background: 'linear-gradient(90deg,#f7c626 15%,#f68c2f 40%,#e5127d 85%)',
    margin: 'auto',
    alignSelf: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    height: '100%',
    width: '100%',
  },
  gridList: {
    width: 800,
  },
}))
/* constant */
function onlyOne({ name, value }) {
  var checkboxes = document.getElementsByName(name)
  checkboxes.forEach((item) => {
    if (item.defaultValue !== value) item.checked = false
  })
}

const ENDPOINTS = CONFIG.application.cms.api.endpoints

const THORCAMPAIGNRESPONSE = {
  url: ENDPOINTS.campaignresponsetothor.url,
  method: ENDPOINTS.campaignresponsetothor.method,
  headers: ENDPOINTS.campaignresponsetothor.headers,
  responseType: 'json',
}
const ENQUIRYCONFIG = {
  url: ENDPOINTS.enquiry.url,
  method: ENDPOINTS.enquiry.method,
  headers: ENDPOINTS.enquiry.headers,
  responseType: 'json',
}

const login = (props) => {
  const { login } = LocalStorage.getLS()
  const currentlanguage = LocalStorage.getLS().translation.json
  const [tAndCCheckBox, setTAndCCheckBox] = useState(false)
  const [visibleModalContent, setVisibleModalContent] = useState(false)
  const [warningMessage, setWarningMessage] = useState(false)
  const classes = useStyles()

  const { width, height } = WindowSize()
  const { layoutWidth, layoutHeight } = WindowLayout(width, height)
  const { campaignCode, tandc, jsonProps } = props
  // console.log(jsonProps)
  const { campaignresponse, member2 } = useContext(GlobalContext)
  /* --- setup */
  const [fetchCampaignResponseTHOR] = FetchContextApiV2(THORCAMPAIGNRESPONSE)

  const fetchCampaignResponseThorExecute = async ({ newHeader, query }) => {
    campaignresponse.dispatch({
      type: campaignresponse.actionTypes.FETCH_CAMPAIGN_RESPONSE,
      campaignresponse: await fetchCampaignResponseTHOR(
        { newHeader, query },
        campaignresponse
      ),
    })
  }
  const execCampaignResponseReset = () => {
    campaignresponse.dispatch({
      type: campaignresponse.actionTypes.RESET,
    })
  }

  const [fetchMemberDetail] = FetchContextApiV2(ENQUIRYCONFIG)

  const fetchMemberDetailExe = async ({ newHeader }) => {
    member2.dispatch({
      type: member2.actionTypes.FETCH_MEMBER,
      member2: await fetchMemberDetail({ newHeader }, member2),
    })
  }

  const execMemberReset = async () => {
    member2.dispatch({
      type: member2.actionTypes.CLEAR_DATA,
    })
  }

  const execRedirects = (e) => {
    if (!e.status && e.err) {
      // fetch fail
      const { country, lang } = LocalStorage.getLS()
      const { isStatus, data } = campaignresponse.state
      const { campaignresponseobj } = data
      const { response } = campaignresponseobj
      const { outComeCode, status } = response.data
      const key = outComeCode ? outComeCode.toString() : status.toString()
      const manipulatedOutComeCode = key.replace('\r\n', '')
      const isSuccessfullyUpdated =
        data && manipulatedOutComeCode === '0' ? true : false
      // console.log(
      //   currentlanguage.errorresponse_campaign,
      //   manipulatedOutComeCode,
      //   currentlanguage.errorresponse_campaign[manipulatedOutComeCode]
      // )
      execCampaignResponseReset({})
      props.history.push('/campaign/submit', {
        title: currentlanguage.campaign_page.failtitle,
        message: currentlanguage.errorresponse_campaign[manipulatedOutComeCode],
      })
    } else {
      // fetch success
      props.history.push('/campaign/submit', {
        title: currentlanguage.campaign_page.successtitle,
        message: jsonProps.isOverWriteSuccessTranslation
          ? jsonProps.successMessage
          : currentlanguage.campaign_page.successdescription,
      })
    }
  }

  useEffect(() => {
    if (campaignresponse.state.data) {
      try {
        const { isStatus, data } = campaignresponse.state
        const { campaignresponseobj } = data
        if (!campaignresponseobj) throw { status: isStatus, err: false }
        const { outComeCode } = campaignresponseobj
        if (outComeCode !== '0')
          throw { status: isStatus, err: campaignresponseobj }
        execRedirects()
      } catch ({ status, err }) {
        execRedirects({ status, err })
      }
    }
  }, [campaignresponse.state])

  useEffect(() => {
    if (member2.state.data) {
      const { member2Object } = member2.state.data
      const { response } = member2Object
      if (response && response.status === 400) {
        LocalStorage.addLS('login', {})
        LocalStorage.addLS('campaignCode', campaignCode)
        props.history.push('/login')
      } else {
        const { token } = login
        const newHeader = {
          ...THORCAMPAIGNRESPONSE.headers,
          Authorization: 'Bearer ' + token,
        }
        const query = '/' + campaignCode
        fetchCampaignResponseThorExecute({ newHeader, query })
      }
    }
  }, [member2.state])

  const submitFunc = async () => {
    // e.preventDefault()
    if (tandc.json.haveTickBox) {
      if (!tAndCCheckBox) {
        setWarningMessage('Please check Terms and Condition')
      } else {
        setWarningMessage('')
        const { token } = login

        const newHeader = {
          ...ENQUIRYCONFIG.headers,
          Authorization: 'Bearer ' + token,
        }
        fetchMemberDetailExe({ newHeader })

        // const newHeader = {
        //   ...THORCAMPAIGNRESPONSE.headers,
        //   Authorization: 'Bearer ' + token,
        // }
        // const query = '/' + campaignCode
        // fetchCampaignResponseThorExecute({ newHeader, query })
      }
    } else {
      setWarningMessage('')
      const { token } = login

      const newHeader = {
        ...ENQUIRYCONFIG.headers,
        Authorization: 'Bearer ' + token,
      }
      fetchMemberDetailExe({ newHeader })
    }
  }
  const submitBtn = {
    fullWidth: false,
    customStyle: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 12,
      paddingBottom: 12,
    },
    onClick: () => {
      submitFunc()
    },
    // disabled: newUpdate.length !== mastersetup[0].length ? true : false,
  }
  // const cancelBtn = {
  //   fullWidth: false,
  //   onClick: (e) => {
  //     e.preventDefault()
  //     props.history.push('/dashboard')
  //   },
  // }
  const popupModal = () => {
    const x = !visibleModalContent
    setVisibleModalContent(x)
  }
  const handleChangeterms = () => {
    setTAndCCheckBox(!tAndCCheckBox)
  }
  const tandcPrint = () => {
    return (
      <span style={{ fontSize: 15 }}>
        {jsonProps && jsonProps.isOverWriteTAndCTranslation
          ? jsonProps.custom_t_and_c_phrase_1
          : currentlanguage.campaign_page.t_and_c_phrase_1}
        {tandc.json.isLink ? (
          <a
            target="_blank"
            href={tandc.json.url}
            style={{
              color: '#008938',
              textDecoration: 'underline',
            }}
            className="link-hover"
          >
            {jsonProps && jsonProps.isOverWriteTAndCTranslation
              ? jsonProps.custom_t_and_c_phrase_2
              : currentlanguage.campaign_page.t_and_c_phrase_2}
          </a>
        ) : (
          <a
            onClick={() => {
              handleChangeterms()
              popupModal()
            }}
            style={{
              color: '#008938',
              textDecoration: 'underline',
            }}
            className="link-hover"
          >
            {jsonProps && jsonProps.isOverWriteTAndCTranslation
              ? jsonProps.custom_t_and_c_phrase_2
              : currentlanguage.campaign_page.t_and_c_phrase_2}
          </a>
        )}
        {jsonProps && jsonProps.isOverWriteTAndCTranslation
          ? jsonProps.custom_t_and_c_phrase_3
          : currentlanguage.campaign_page.t_and_c_phrase_3}
      </span>
    )
  }
  const tandcPrintPopupLink = () => {
    return (
      <span style={{ fontSize: 15 }}>
        {tandc.json.isLink ? (
          <a
            target="_blank"
            href={tandc.json.url}
            style={{
              color: '#008938',
              textDecoration: 'underline',
            }}
            className="link-hover"
          >
            {jsonProps && jsonProps.isOverWriteTAndCTranslation
              ? jsonProps.custom_t_and_c_phrase_2
              : currentlanguage.campaign_page.t_and_c_phrase_2}
          </a>
        ) : (
          <a
            onClick={() => {
              handleChangeterms()
              popupModal()
            }}
            style={{
              color: '#008938',
              textDecoration: 'underline',
            }}
            className="link-hover"
          >
            {jsonProps && jsonProps.isOverWriteTAndCTranslation
              ? jsonProps.custom_t_and_c_phrase_2
              : currentlanguage.campaign_page.t_and_c_phrase_2}
          </a>
        )}
      </span>
    )
  }
  return (
    <div className={classes.content}>
      <div
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          margin: 'auto',
          marginTop: '1%',
          width: '100%',
          height: '100%',
          backgroundColor: '#ffffff',
        }}
      >
        {tandc.json.haveTickBox ? (
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={tAndCCheckBox}
                onChange={handleChangeterms}
              />
            }
            label={tandcPrint()}
          />
        ) : null}

        {jsonProps && jsonProps.isAcceptVisible && (
          <Typography align="center">
            <Buttons.Normal {...submitBtn}>
              {jsonProps && jsonProps.isOverWriteTranslation
                ? jsonProps.acceptBtnText
                : currentlanguage.campaign_page.acceptbtn}
            </Buttons.Normal>
          </Typography>
        )}
        <br />
        {tandc.json.haveTickBox ? null : (
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            {tandcPrintPopupLink()}
          </div>
        )}
        <div style={{ marginTop: 20, marginBottom: 35, color: '#ef9a9a' }}>
          {warningMessage}
        </div>
        {/* modal */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={visibleModalContent}
          onClose={popupModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 700,
          }}
        >
          <Fade in={visibleModalContent}>
            <div className={classes.paper}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <img
                    src={logo}
                    style={{
                      width: '40%',
                      paddingBottom: 20,
                      paddingRight: 10,
                    }}
                    alt="logo"
                  />
                </div>
                <div
                  style={{
                    position: 'fixed',
                    // width: '55%',
                    width: '55%',
                    minWidth: 300,
                    maxWidth: 500,
                    textAlign: 'right',
                    paddingRight: 70,
                    cursor: 'pointer',
                  }}
                >
                  <CloseIcon
                    color="white"
                    onClick={() => {
                      popupModal()
                    }}
                  />
                </div>
              </div>
              <p
                style={{
                  color: '#696969',
                  fontSize: 20,
                  fontWeight: 800,
                  marginBottom: 10,
                }}
                className="subway-footlong"
              >
                {tandc.json.title}
              </p>
              <p
                style={{ color: '#696969', fontSize: 11, lineHeight: 1.4 }}
                className="subway-six-inch"
              >
                <div
                  className="green-link"
                  dangerouslySetInnerHTML={{
                    __html: tandc.json.content,
                  }}
                />
              </p>
              <div
                style={{
                  width: 150,
                  // margin: 'auto',
                  marginBottom: 30,
                }}
              ></div>
            </div>
          </Fade>
        </Modal>
        {/* <Buttons.Text {...cancelBtn}>{currentlanguage.cancelbtn}</Buttons.Text> */}
      </div>
    </div>
  )
}

export default login
