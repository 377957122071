import React from 'react'

/* container */
import CookiesContainer from '../../app-module-cookies-container/default'
/* container */

import Contents from '../contents/contents'

import ScrollToTop from '../../../scrollToTop'

const index = (props) => {
  return (
    <CookiesContainer {...props}>
      <ScrollToTop {...props} />
      <Contents {...props} />
    </CookiesContainer>
  )
}

export default index
