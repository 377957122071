import React, { useState, Fragment, useContext, useEffect } from 'react' // useEffect, useContext
import PropTypes from 'prop-types'
// import { Link } from "react-router-dom";
import Recaptcha from 'react-recaptcha'
// import Gdpr from './gdprform' import { GlobalContext } from
// "../../lib-atomic-context";
/* packages */
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { GlobalContext } from '../../lib-atomic-context'
import MaskedInput from 'react-text-mask'

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
//dialogBox
import DialogBox from '../../lib-atomic/dialog/dialogbox'
// import Input from "@material-ui/core/Input";
import { Inputs, Buttons, Invalid } from '../../lib-atomic'
import { FormHook, LocalStorage } from '../../lib-atomic-functions' // LocalStorage

import {
  ModuleWrapperXl,
  ModuleSubTitle,
  ModSubWrapp,
  ModXlWrapp,
  ModLongWrapp,
  ModLongFillWrapp,
  ModTitleText,
  ModContentText,
  ModFormLabelText,
} from '../../lib-atomic-design-section'
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import CONFIG from '../config'

/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.account.objects
//const { token } = LocalStorage.getLS().login
const ENDPOINTS = CONFIG.application.account.api.endpoints
const LOGINOBJECTSCONFIG = {
  url: ENDPOINTS.auth.url,
  method: ENDPOINTS.auth.method,
  headers: ENDPOINTS.auth.headers,
  responseType: 'json',
}
const VALIDATION = {
  url: ENDPOINTS.valid.url,
  method: ENDPOINTS.valid.method,
  headers: ENDPOINTS.valid.headers,
  responseType: 'json',
}
// const LOGINFAILRESPONSE = CONFIG.application.account.responses.fail
const UNVERIFIEDRESPONSE = CONFIG.application.account.responses.unverified

/* CONSTANTS */

// Styles
const useStyles = makeStyles((theme) => ({
  column: {
    flexBasis: '50%',
  },
  column2: {
    flexBasis: '100%',
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
  paper: {
    // padding: theme.spacing(3),
    color: 'black',
    marginBottom: '50px',
  },
  inline: {
    display: 'flex',
  },
  text: {
    marginTop: '10px',
    marginBottom: '10px',
  },
}))

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    minHeight: 56,
    paddingLeft: '0px',
    paddingRight: '0px',

    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: '0',
  },
}))(MuiExpansionPanelDetails)

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#008938',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#008938',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        margin: classes.margin,
      }}
      {...props}
    />
  )
})

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      className="dob-input"
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholder="DD/MM/YYYY" // showMask
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}
const login = (props) => {
  const { token } = LocalStorage.getLS().login
  const currentlanguage = LocalStorage.getLS().translation.json.registrationpage

  const { valid } = useContext(GlobalContext)
  const { firstName, lastName, email } = props

  LOGINOBJECTS.firstName.initial = firstName
  LOGINOBJECTS.lastName.initial = lastName
  LOGINOBJECTS.email.initial = email

  /* state */
  const [response, setresponse] = React.useState()
  const [data, setdata] = React.useState()
  const [open, setOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [isNext, seisNext] = useState(1)
  // const [verified,     setVerified] = useState(false)
  const [visiblePW, setVisiblePW] = useState(false)
  const [visiblePWC, setVisiblePWC] = useState(false) // setVisiblePWC
  const [isSms, setisSms] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [spin, setSpin] = useState(false)
  //Email,promocode,accesscode validation API//

  const [validData] = FetchContextApiV2(VALIDATION)

  const validationExecute = async ({ body }) => {
    valid.dispatch({
      type: valid.actionTypes.FETCH_VALIDATION,
      valid: await validData(
        {
          body,
        },
        valid
      ),
    })
  }
  useEffect(() => {
    if (valid.state.data) {
      const { validObject } = valid.state.data
      setdata(validObject)

      if (validObject.outComeCode) {
      } else {
      }
    }
  }, [valid.state])

  // specifying verify callback function

  const handleSubmit = async () => {
    setSpin(true)

    seisNext(1)
    if (values.email == LOGINOBJECTS.email.initial) {
      setSpin(false)

      seisNext(0)
      props.parentCallback(0)
    } else {
      var body = {
        email: values.email,
      }
      await validationExecute({ body })
    }
  }

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleSubmit,
    LOGINOBJECTS
  )

  console.log('LOGINOBJECTS.firstName,', LOGINOBJECTS.firstName)

  const userfirstName = {
    ...LOGINOBJECTS.firstName,
    error: error.firstName,
    value: values.firstName,
    onChange,
  }
  const userlastName = {
    ...LOGINOBJECTS.lastName,
    error: error.lastName,
    value: values.lastName,
    onChange,
  }

  const useremailAddress = {
    ...LOGINOBJECTS.email,
    error: error.email,
    value: values.email,
    onChange,
  }
  const emailConfirm = {
    ...LOGINOBJECTS.emailconfirm,
    error: error.emailconfirm,
    value: values.emailconfirm,
    onChange,
  }

  const handleClose = (value) => {
    setOpen(false)
    // setresponse(false)
  }

  const handleChange = (event) => {
    setisSms(!isSms)
  }

  const handleChange2 = (event) => {
    setExpanded(!expanded)
    setisSms(!isSms)
  }
  const classes = useStyles()
  const submitButton = {
    fullWidth: true,
    onClick: handleSubmit.bind(this),
  }

  const resetButton = {
    fullWidth: true,
  }
  // const failLogin = { error: [LOGINFAILRESPONSE] }
  const verifyLogin = {
    error: [UNVERIFIEDRESPONSE],
  }
  // console.log("Window", window.screen.width);

  return (
    <form onSubmit={onSubmit} noValidate>
      {spin && <Spinner2.Popup />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} spacing={3}>
          <Typography variant="h5" className={classes.paper}>
            <b>{currentlanguage.page_reg_details}</b>
          </Typography>

          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                width: '100%',
              }}
            >
              <Typography variant="body2" className={classes.text}>
                <strong>{currentlanguage.page_reg_firstname}</strong>
                <Inputs.Text {...userfirstName} />
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                width: '100%',
              }}
            >
              <Typography variant="body2" className={classes.text}>
                <strong>{currentlanguage.page_reg_lastname}</strong>
                <Inputs.Text {...userlastName} />
              </Typography>
            </div>
          </div>
        </Grid>
        {/* <br/> */}
        <Grid item xs={12} sm={6} spacing={3}>
          <Typography variant="h5" className={classes.paper}>
            <b>Contact Details</b>
          </Typography>

          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                width: '100%',
              }}
            >
              <Typography variant="body2" className={classes.text}>
                <strong>{currentlanguage.page_reg_email}</strong>
                <Inputs.Text {...useremailAddress} disabled />
              </Typography>
            </div>
          </div>
        </Grid>
        {/* </Grid> */}
        <br />
      </Grid>

      <Grid container item xs={12} sm={6} spacing={3}>
        <Grid item xs={12} sm={6}>
          <div>
            <Buttons.Primary {...submitButton}>
              {currentlanguage.page_reg_next}
            </Buttons.Primary>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <Buttons.Primary {...resetButton} href="reset-password">
              Rest password
            </Buttons.Primary>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default login
