import React, { useContext, useState, Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'
import Moment from 'react-moment'

/* packages */
import { Lists } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import { LocalStorage, NumberCurrency } from '../../lib-atomic-functions'
import {
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleTitle,
  ModuleText,
  ModuleSubTitle,
} from '../../lib-atomic-design-section'
// import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

/* stores */
// import { AuthLogin } from "../../app-module-admin-container/stores";
/* stores */

import CONFIG from '../config'

const transactionList = (props) => {
  const calcTotal = (products) => {
    var total = 0
    products.map((item) => {
      total = total + item.value * item.quantity
    })
    return total.toFixed(2)
  }
  const currentlanguage = LocalStorage.getLS().translation.json
  const { country, lang } = LocalStorage.getLS()
  const symbol =
    country && (country === 'gb' || country === 'ie')
      ? '£'
      : country && country === 'se'
      ? 'kr'
      : '€'
  const { transactiondetail } = useContext(GlobalContext)
  // const { token } = LocalStorage.getLS().login;
  const { transactiondetailobj } = transactiondetail.state.data
  /* state */
  const total = calcTotal(transactiondetailobj.products)
  return (
    <div
      style={{
        width: '100%',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <p style={{ color: '#bbbbbb', fontFamily: 'SubwaySixInch' }}>
        {currentlanguage.transactionhistorypage.page_history_total}
      </p>
      <p
        style={{
          fontWeight: 900,
          fontSize: 25,
          paddingLeft: 5,
          paddingRight: 5,
          fontFamily: 'SubwayFootlong',
        }}
      >
        {symbol} {total}
      </p>
      <div
        style={{
          backgroundColor: '#bbbbbb',
          height: 1,
          marginTop: 10,
          marginBottom: 7,
        }}
      />
      {transactiondetailobj.campaigns.length > 0 ? (
        <div>
          <p style={{ color: '#bbbbbb', fontFamily: 'SubwaySixInch' }}>
            {currentlanguage.transactionhistorypage.page_history_earned}
          </p>
          <div style={{ width: '100%', paddingLeft: 5, paddingRight: 5 }}>
            {transactiondetailobj.campaigns.map((campaign) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    fontFamily: 'SubwaySixInch',
                  }}
                >
                  <p style={{ width: '70%', fontWeight: 600 }}>
                    {campaign.name}
                  </p>
                  <p
                    style={{ width: '30%', fontWeight: 700, color: '#008938' }}
                  >
                    +{campaign.value}
                  </p>
                </div>
              )
            })}
          </div>

          <div
            style={{
              backgroundColor: '#bbbbbb',
              height: 1,
              marginTop: 10,
              marginBottom: 7,
            }}
          />
        </div>
      ) : null}

      <p style={{ color: '#bbbbbb', fontFamily: 'SubwaySixInch' }}>
        {currentlanguage.transactionhistorypage.page_history_redeemed}
      </p>

      <div style={{ width: '100%', paddingLeft: 5, paddingRight: 5 }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            fontFamily: 'SubwaySixInch',
          }}
        >
          <p style={{ width: '70%', fontWeight: 600, color: 'orange' }}>
            {currentlanguage.transactionhistorypage.page_history_points}
          </p>
          <p style={{ width: '30%', fontWeight: 700, color: 'orange' }}>
            {transactiondetailobj.redeemedPoints}
          </p>
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#bbbbbb',
          height: 1,
          marginTop: 10,
          marginBottom: 7,
        }}
      />
    </div>
  )
}

export default transactionList
