import React, { useState, useContext, useEffect } from 'react'
// import { Link } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'

// import { GlobalContext } from '../../lib-atomic-context'
/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import { Captcha, Inputs, Buttons } from '../../lib-atomic'
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
} from '../../lib-atomic-functions'
import {
  ModXlWrapp,
  ModPageTitleWrapp,
  ModTitleText,
  ModContentText,
  ModFormLabelText,
} from '../../lib-atomic-design-section'
// import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

import CONFIG from '../config'
import errorresponse from '../../lib-atomic-context/reducers/errorResponseReducer'

import appConf from '../../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV

/* CONSTANTS */
const OBJECT = CONFIG.application.account.objects
const ENDPOINTS = CONFIG.application.account.api.endpoints
const FORGOTPASSWORDCONFIG = {
  url: ENDPOINTS.forgotpassword.url,
  method: ENDPOINTS.forgotpassword.method,
  headers: ENDPOINTS.forgotpassword.headers,
  responseType: 'json',
}
// const LOGINFAILRESPONSE = CONFIG.application.account.responses.fail
// const UNVERIFIEDRESPONSE = CONFIG.application.account.responses.unverified
/* CONSTANTS */

const index = (props) => {
  const { country, lang } = LocalStorage.getLS()
  const currentlanguage = LocalStorage.getLS().translation.json
  /* state */
  const [loaded, setLoaded] = useState(false)
  const [verified, setVerified] = useState(false)
  const [exprired, setExpired] = useState(false)

  /* -- recaptcha : states -- */
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true)
      setLoaded(true)
      setExpired(false)
    }
  }
  const expiredCallback = (response) => {
    setExpired(true)
  }
  /* -- recaptcha : states -- */

  const { forgotpassword } = useContext(GlobalContext)

  /* --- member enquiry  & transactions */
  const [fetchForgottenPassword] = FetchContextApiV2(FORGOTPASSWORDCONFIG)
  const fetchForgottenPasswordExecute = async ({ body, newHeader }) => {
    forgotpassword.dispatch({
      type: forgotpassword.actionTypes.FORGOT_PASSWORD,
      forgotpassword: await fetchForgottenPassword(
        { body, newHeader },
        forgotpassword
      ),
    })
  }
  // const resetForgotPassword = () => {
  //   forgotpassword.dispatch({
  //     type: forgotpassword.actionTypes.RESET,
  //   })
  // }
  // const callback = () => {
  //   console.log('loaded recaptcha...')
  // }

  // specifying verify callback function

  const handleLogin = async () => {
    setLoaded(true)
    if (verified) {
      const body = {
        email: values.email,
      }
      const newHeader = {
        ...FORGOTPASSWORDCONFIG.headers,
        moduleCode: setup.setup.thor.moduleCode[environment],
      }
      fetchForgottenPasswordExecute({ body, newHeader })
    }
  }

  const execForgotPasswordReset = () => {
    forgotpassword.dispatch({
      type: forgotpassword.actionTypes.RESET,
    })
  }
  useEffect(() => {
    if (forgotpassword.state.data) {
      //
      try {
        const { isStatus, data } = forgotpassword.state
        const { forgotpasswordobj } = data
        if (!forgotpasswordobj) throw { status: isStatus, err: false }
        const { outComeCode } = forgotpasswordobj
        if (outComeCode !== '0')
          throw { status: isStatus, err: forgotpasswordobj }
        execRedirects()
        //handleClearLS();
        //window.location.replace(generatepaymentobject.uri)
      } catch ({ status, err }) {
        execRedirects({ status, err })
      }
      // resetForgotPassword()
      // props.history.push('/web/' + country + '/' + lang + 'forgot_password/submit')
    }
  }, [forgotpassword.state])

  const execRedirects = (e) => {
    if (!e.status && e.err) {
      // fetch fail
      const { isStatus, data } = forgotpassword.state
      const { forgotpasswordobj } = data
      const { response } = forgotpasswordobj
      const { outComeCode } = response.data
      const key = outComeCode.toString()
      const manipulatedOutComeCode = key.replace('\r\n', '')
      const isSuccessfullyUpdated =
        data && manipulatedOutComeCode === '0' ? true : false
      const message = currentlanguage.errorresponse_forgotPassword[
        manipulatedOutComeCode
      ]
        ? currentlanguage.errorresponse_forgotPassword[manipulatedOutComeCode]
        : 'Unknown Error'
      execForgotPasswordReset()
      props.history.push('/forgot_password/confirm', {
        isSuccessfullyUpdated,
        message,
      })
    } else {
      // fetch success
      const { isStatus, data } = forgotpassword.state
      const { forgotpasswordobj } = data
      const { outComeCode } = forgotpasswordobj
      const key = outComeCode.toString()
      const manipulatedOutComeCode = key.replace('\r\n', '')
      const isSuccessfullyUpdated =
        data && manipulatedOutComeCode === '0' ? true : false
      const message = currentlanguage.errorresponse_forgotPassword[
        manipulatedOutComeCode
      ]
        ? currentlanguage.errorresponse_forgotPassword[manipulatedOutComeCode]
        : 'Unknown Error'
      execForgotPasswordReset()
      props.history.push('/forgot_password/confirm', {
        isSuccessfullyUpdated,
        message,
      })
    }
  }

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleLogin,
    OBJECT
  )

  const email = {
    ...OBJECT.email,
    error: error.email,
    value: values.email,
    onChange,
    translation: currentlanguage,
  }
  const isSubmitButtonDisabled = !verified || !loaded || exprired ? true : false
  const captchaData = {
    verified,
    verifyCallback,
    expiredCallback,
  }
  const submitButton = { fullWidth: true }

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModXlWrapp>
        <ModPageTitleWrapp>
          <ModTitleText>
            {currentlanguage.forgottenpasswordpage.page_reset_password}
          </ModTitleText>
          <ModContentText>
            {currentlanguage.forgottenpasswordpage.page_reset_email}
          </ModContentText>
        </ModPageTitleWrapp>
      </ModXlWrapp>
      <ModXlWrapp>
        <ModFormLabelText>
          {currentlanguage.forgottenpasswordpage.page_reset_email_1}
        </ModFormLabelText>
        <Inputs.Text {...email} />
      </ModXlWrapp>
      <br />
      <Captcha.Basic {...captchaData} />
      <br />
      <ModXlWrapp>
        <Buttons.Normal {...submitButton}>
          {currentlanguage.forgottenpasswordpage.page_reset_reset}
        </Buttons.Normal>
      </ModXlWrapp>
    </form>
  )
}

export default index
