const isPasswordSpecialC = data => {

  let value = data.value
  const key = 'isPasswordSpecialC'
  value = value.trim()

  const pattern = /(?=.*[?!@#\$%\^&\*])/
  const isTestOk = pattern.test(value) ? true : false
  const error = isTestOk ? false : key
  return error ? { error, data } : false
}

export default isPasswordSpecialC
