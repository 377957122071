import { LocalStorage } from '../../lib-atomic-functions'

const index = (props) => {
  LocalStorage.addLS('login', {})
  LocalStorage.addLS('member', {})
  window.location.replace('/home')
  return null
}

export default index
