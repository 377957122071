import React, { useState, useEffect, useRef } from "react";
import { RotationContainer } from "./styles.js";

const startSpinTime = 1000;
const stopSpinTime = 4000;

export const Wheel = ({ mustStartSpinning, prizeNumber, wheelImage, onClickingStop, prizeTotal, country, onStopSpinning = () => null,
}) => {
  const [contSpinTime, setContSpinningTime] = useState(1);
  const [startRotationDegrees, setStartRotationDegrees] = useState(0);
  const [finalRotationDegrees, setFinalRotationDegrees] = useState(0);
  const [hasStartedSpinning, setHasStartedSpinning] = useState(false);
  const [hasStoppedSpinning, setHasStoppedSpinning] = useState(false);
  const [isCurrentlySpinning, setIsCurrentlySpinning] = useState(false);
  const mustStopSpinning = useRef(false);
  const randoNumber = Math.random() * 1.6;
  const randomNoNegative = Math.round(randoNumber) ? 1 : -1;
  
  const resetWheelPos = () => {
    setStartRotationDegrees(0);
    setFinalRotationDegrees(0);
    setHasStartedSpinning(false);
    setHasStoppedSpinning(true);
    setIsCurrentlySpinning(false);
    console.log('RESET WHEEL')
  };

  let prizeToUse;
  // PRIZEID : WHEEL POSITION ]
  // Adjust value returned from backend so it in the correct wheel position
  if (country === 'fr') {
    const convertPrize = {
      // 169	Paquet de Doritos 1
      // 170	Code Cheerz 2
      // 171	Deliveroo 3
      // 172	Serviette de plage Doritos  4
      // 173	Lose (Perdu) 5
      5:1,
      4:2,
      5:3,
      3:4,
      5:5,
      1:6,
      5:7,
      2:8,
    };
    prizeToUse = convertPrize[prizeNumber];
  } else if (country === 'gb') {
    const convertPrize = {
      9: 1,
      6: 2,
      2: 3,
      7: 4,
      8: 5,
      10: 6,
      3: 7,
      5: 8,
      4: 9,
      1: 10,
    };
    prizeToUse = convertPrize[prizeNumber];
  } 
  else if (country === 'nl') {
    const convertPrize = {
      1:1, 2:2, 3:3, 4:4, 5:5
    };
    prizeToUse = convertPrize[prizeNumber];
  }
  else if (country === 'ie') {
    const convertPrize = {
      // 1	1000 Points
      // 2	500 Points
      // 3	200 Points
      // 4	100 Points
      // 5	Ear Buds
      // 6	Flask
      // 7	Socks
      // 8	Lunchbox
      // 9	Pop Sockets
      // 10	No Win
      9: 1,
      6: 2,
      2: 3,
      7: 4,
      8: 5,
      10: 6,
      3: 7,
      5: 8,
      4: 9,
      1: 10,
    };
    prizeToUse = convertPrize[prizeNumber];
  }

  const getRotationDegrees = (prizeToUse, numberOfPrizes) => {
    const degreesPerPrize = 360 / numberOfPrizes;
    const prizeRotation = degreesPerPrize * (numberOfPrizes - prizeToUse);
    return numberOfPrizes - prizeToUse > numberOfPrizes / 2 ? -360 + prizeRotation : prizeRotation;
  };

  const startSpinning = () => {
    resetWheelPos();
    setHasStartedSpinning(true);
    setHasStoppedSpinning(false);
    mustStopSpinning.current = true;
  };

  useEffect(() => {
    if (mustStartSpinning && !isCurrentlySpinning) {
      setIsCurrentlySpinning(true);
      startSpinning();
    }
  }, [mustStartSpinning]);

  useEffect(() => {
    const finalRotationDegreesCalculated = getRotationDegrees(prizeToUse, prizeTotal);
    const finalDegree = (finalRotationDegreesCalculated + ((randoNumber * (randomNoNegative * 3)) * 3.15))
    setFinalRotationDegrees(finalDegree);
    if (prizeToUse) {
      setTimeout(() => {
        if (mustStopSpinning.current) {
          mustStopSpinning.current = false;
          setStartRotationDegrees(finalDegree);
          setHasStartedSpinning(false);
          setHasStoppedSpinning(true);
          onStopSpinning();
        }
      }, startSpinTime + stopSpinTime - 15);
    }
  }, [prizeToUse])


  // Return Animation Classnames to wheel
  const checkPrizeNo = () => {
    if (hasStartedSpinning && !prizeToUse) {return "started-spinning";
    } else if (hasStartedSpinning && prizeToUse && onClickingStop) { return "cont-spinning";} 
    else { return ""; }
  }

  return (
    <>
      <RotationContainer
        className={checkPrizeNo()}
        startSpinningTime={startSpinTime}
        continueSpinningTime={contSpinTime}
        stopSpinningTime={stopSpinTime}
        startRotationDegrees={startRotationDegrees}
        finalRotationDegrees={finalRotationDegrees}
      >
        <img
          src={wheelImage}
          alt="wheel"
          style={{
            position: "relative",
            width: "100%",
            maxWidth: "295",  
            height: "100%",
            margin: "0 auto",
            transform: `rotate(${ (360/prizeTotal) / 2}deg)`
          }}
        />
      </RotationContainer>
    </>
  );
};
