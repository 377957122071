import React, { useEffect, Fragment } from 'react'

/* container */
import { LocalStorage } from '../../lib-atomic-functions'
/* container */

import PopupBox from '../popup'

const index = (props) => {
  return (
    <Fragment>
      <PopupBox {...props} />
    </Fragment>
  )
}

export default index
