import React, { useContext } from 'react'

import { GlobalContext } from '../../lib-atomic-context'

import {
  Box,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const index = (props) => {
  const { pages } = useContext(GlobalContext)
  const { masterpages } = pages.state.data
  const { header, subtitle, json } =
    masterpages && masterpages.length > 0 ? masterpages[0] : null
  return (
    <Container
      component={Box}
      my={5}
      maxWidth="md"
      disableGutters
      style={{ paddingLeft: 20, paddingRight: 20 }}
    >
      {header && header.length > 0 ? (
        <Typography
          color="primary"
          variant="h4"
          gutterBottom
          className="subway-footlong"
        >
          {header}
        </Typography>
      ) : null}
      {json && json.newfaq && (
        <Box my={3}>
          {json.newfaq.title && json.newfaq.title.length > 0 ? (
            <Typography
              key={index}
              color="primary"
              variant="h5"
              gutterBottom
              className="subway-footlong"
              style={{ paddingBottom: 10 }}
            >
              {json.newfaq.title}
            </Typography>
          ) : null}
          {
            json && json.faqclosuresub && (
              <div>
                <p>{json.faqclosuresub.title}</p>
                <p>{json.faqclosuresub.subtitle}</p>
              </div>
            )
          }
          {/* <ol>
            {json && json.newfaq.subtitle && json.newfaq.subtitle.length > 0 ? (
              <Typography
                style={{ marginTop: 10 }}
                gutterBottom
                className="subway-footlong"
              >
                <li>
                  <a href="#newfaq">{json.newfaq.subtitle}</a>
                </li>
              </Typography>
            ) : null}

            {json &&
            json.joinnew &&
            json.joinnew.subtitle &&
            json.joinnew.subtitle.length > 0 ? (
              <Typography
                style={{ marginTop: 10 }}
                gutterBottom
                className="subway-footlong"
              >
                <li>
                  <a href="#joinnew">{json.joinnew.subtitle}</a>
                </li>
              </Typography>
            ) : null}
          </ol> */}

          <a
            name="newfaq"
            style={{
              display: 'block',
              position: 'relative',
              top: -60,
              visibility: 'hidden',
            }}
          />
          <Box my={3} name="faqanchor">
            {json &&
              json.newfaq.contents &&
              json.newfaq.contents.length > 0 &&
              json.newfaq.contents.map(({ q, a }, index) => (
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<AddIcon />}
                    aria-controls={`q${index}bh-content`}
                    id={`q${index}bh-header`}
                  >
                    <Typography
                      color="primary"
                      variant="h6"
                      className="subway-footlong"
                      style={{ wordBreak: 'break-word' }}
                    >
                      {<div dangerouslySetInnerHTML={{ __html: q }} />}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography
                      variant="body2"
                      style={{ 'white-space': 'pre-wrap', fontSize: 16 }}
                      className="subway-six-inch"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: a }} />}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </Box>
        </Box>
      )}
      <a
        name="joinnew"
        style={{
          display: 'block',
          position: 'relative',
          top: -60,
          visibility: 'hidden',
        }}
      />
      <div style={{ height: 20 }} />

    </Container>
  )
}

export default index
