import React, { useState, State, useEffect, Fragment, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ModPublicWrapp } from '../../lib-atomic-design-section'
/* container */
import RegisterContainer from '../../app-module-login-container/default'
/* container */
import { GlobalContext } from '../../lib-atomic-context'
/* package */
import { ModMainWrapp } from '../../lib-atomic-design-section'
/* package */
//dialogBox
import DialogBox from '../../lib-atomic/dialog/dialogbox'
/* form */
import RegisterForm from '../form/register'
/* form */
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import GdprForm from '../form/gdprform'

import CONFIG from '../config'

/* constant */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '70%',
    margin: 'auto',
    maxWidth: 825,
    marginTop: '40px',
    marginBottom: '100px',
  },
  gridList: {
    width: 800,
  },
  style: {
    marginTop: '30px',
    marginBottom: '55px',
  },
  rootmobile: {
    flexGrow: 1,
    marginLeft: '20px',

    width: '90%',
  },
}))
/* constant */
const mediaPath = CONFIG.setup.assets.media
const ENDPOINTS = CONFIG.application.account.api.endpoints
const REGISTRATION = {
  url: ENDPOINTS.register.url,
  method: ENDPOINTS.register.method,
  headers: ENDPOINTS.register.headers,
  responseType: 'json',
}

const index = (props) => {
  const [data, setdata] = React.useState()
  const [data2, setdata2] = React.useState()
  const [response, setresponse] = React.useState()
  const [open, setOpen] = useState(false)
  const [redirect, setredirect] = useState(false)
  const { register } = useContext(GlobalContext)
  const [activeStep, setActiveStep] = useState(1)

  const values = (values) => {
    const data = values
    setdata2(data)
  }
  const mediaPath = CONFIG.setup.assets.media
  const gdprvalues = async (t) => {
    const gdpr = t
    setdata(gdpr)
  }
  const callbackFunction = (n) => {
    setActiveStep(n)
  }
  const stepBack = (b) => {
    setActiveStep(b)
    console.log('b', b)
  }
  const handleClose = (value) => {
    setOpen(false)
    setredirect()
  }
  console.log('step', activeStep)
  const stepOne = activeStep === 1 ? true : false

  const stepTwo = activeStep === 0 ? true : false
  const stepsData = {
    props,
  }
  console.log('step', activeStep)
  const { bannerscontents } = useContext(GlobalContext)
  const imgToDisplay = bannerscontents.state.data.masterbannerscontents.filter(
    (page) => {
      return page.pageName === 'Registration'
    }
  )[0]

  const classes = useStyles()

  if (window.screen.width < 800) {
    return (
      <RegisterContainer>
        <ModPublicWrapp {...props} imgToDisplay={imgToDisplay}>
          <div className={classes.style}>
            <div className={classes.rootmobile}>
              {stepOne && (
                <RegisterForm
                  {...stepsData}
                  parentCallback={callbackFunction}
                  values={values}
                />
              )}
              {stepTwo && (
                <GdprForm
                  {...stepsData}
                  {...data2}
                  gdprvalues={gdprvalues}
                  back={stepBack}
                  parentCallback={callbackFunction}
                />
              )}
            </div>
          </div>
          <DialogBox
            selectedValue={response}
            open={open}
            onClose={handleClose}
          />
        </ModPublicWrapp>
      </RegisterContainer>
    )
  } else {
    return (
      <RegisterContainer>
        <ModPublicWrapp {...props} imgToDisplay={imgToDisplay}>
          {/* <div className={classes.style}> */}
          <div className={classes.root}>
            {stepOne && (
              <RegisterForm
                {...stepsData}
                parentCallback={callbackFunction}
                values={values}
              />
            )}
            {stepTwo && (
              <GdprForm
                {...stepsData}
                {...data2}
                gdprvalues={gdprvalues}
                back={stepBack}
                parentCallback={callbackFunction}
              />
            )}
          </div>
          {/* </div> */}
          <DialogBox
            selectedValue={response}
            open={open}
            onClose={handleClose}
          />
        </ModPublicWrapp>
      </RegisterContainer>
    )
  }
}
export default index
