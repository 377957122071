import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'

/* container */
import VerificationContainer from '../../app-module-verification-container/default'
/* container */

/* package */
import { ModPublicWrapp } from '../../lib-atomic-design-section'
import { ModMainWrapp } from '../../lib-atomic-design-section'
import { GlobalContext } from '../../lib-atomic-context'
/* package */

/* form */
import ActivationForm from '../form/verification'
/* form */

import CONFIG from '../config'

/* CONSTANTS */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 80,
    maxWidth: 380,
    minWidth: 300,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 'auto',
  },
  gridList: {
    width: 800,
  },
}))
const mediaPath = CONFIG.setup.assets.media
/* CONSTANTS */

const index = (props) => {
  const classes = useStyles()
  /* state */
  const { bannerscontents } = useContext(GlobalContext)
  const imgToDisplay = bannerscontents.state.data.masterbannerscontents.filter(
    (page) => {
      return page.pageName === 'Verification'
    }
  )[0]
  /* state */
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  useEffect(() => {
    // GArecordPageView(cookies)
  }, [])
  return (
    <VerificationContainer {...props}>
      <ModPublicWrapp {...props} imgToDisplay={imgToDisplay}>
        <div className={classes.root}>
          <ActivationForm {...props} />
        </div>
      </ModPublicWrapp>
    </VerificationContainer>
  )
}

export default index
