import React, { useContext } from 'react'

import { GlobalContext } from '../../lib-atomic-context'

/* container */
import DashboardContainer from '../../app-module-dashboard-container/default'
/* container */

import PageContent from '../page/dashboardpage'

import { ModPublicWrapp } from '../../lib-atomic-design-section'

import ScrollToTop from '../../../scrollToTop'

const index = (props) => {
  const { bannerscontents } = useContext(GlobalContext)
  
  const imgToDisplay = bannerscontents.state.data.masterbannerscontents.filter(
    (page) => {
      return page.pageName === 'Dashboard'
    }
  )[0]
  return (
    <DashboardContainer {...props}>
      <ModPublicWrapp {...props} imgToDisplay={imgToDisplay}>
        <ScrollToTop {...props} />
        <PageContent {...props} />
      </ModPublicWrapp>
    </DashboardContainer>
  )
}

export default index
