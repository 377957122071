import React, { useEffect, useState, Fragment, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import QueryString from 'query-string'

import { Grid, Box, Typography } from '@material-ui/core'
import { GlobalContext } from '../../lib-atomic-context'
import { Buttons } from '../../lib-atomic'
import { LocalStorage, FetchContextApiV2 } from '../../lib-atomic-functions'
import { ModXlWrapp } from '../../lib-atomic-design-section'

import CONFIG from '../config'

const index = (props) => {
  const currentlanguage = LocalStorage.getLS().translation.json
  return (
    <ModXlWrapp>
      {/* <Typography
            variant="body2"
            paragraph
            className="subway-six-inch"
            style={{ marginBottom: 30 }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: currentlanguage.subrewards_website_contents1,
              }}
            />
          </Typography>       */}
      <Typography
        color="primary"
        variant="h4"
        gutterBottom
        className="subway-footlong"
      >
        {
          currentlanguage.unsubscribe_website_page
            .unsubscribe_website_header_fail
        }
      </Typography>
      <Typography
        color="primary"
        variant="h6"
        gutterBottom
        className="subway-footlong"
      >
        {currentlanguage.unsubscribe_website_page.unsubscribe_website_text_fail}
      </Typography>
    </ModXlWrapp>
  )
}

export default withRouter(index)
