import React, { useContext, useEffect } from 'react'

/* package */
import { Grid, Box, Typography } from '@material-ui/core'
import { ModPublicWrapp } from '../../lib-atomic-design-section'
import { GlobalContext } from '../../lib-atomic-context'
import { Buttons } from '../../lib-atomic'
import Link from '../../lib-atomic/link'
import { WindowSize, LocalStorage } from '../../lib-atomic-functions'
/* package */
import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'

import CONFIG from '../config'

/* CONSTANTS */
const mediaPath = CONFIG.setup.assets.media
/* CONSTANTS */

const google_play = CONFIG.setup.assets.google_play
const app_store = CONFIG.setup.assets.app_store

//TODO: move somewhere else
// const googlePlayLink =
//   'https://play.google.com/store/apps/details?id=com.altaine.subcardde'
// const appStoreLink =
//   'https://itunes.apple.com/de/app/subway-subcard/id464702402?mt=8'

const Default = (props) => {
  const currentlanguage =
    LocalStorage.getLS().translation.json.subsquad_website_page
  console.log(currentlanguage)
  const { country, lang } = LocalStorage.getLS()
  const { width, height } = WindowSize()
  /* state */
  const { bannerscontents } = useContext(GlobalContext)
  console.log("page",bannerscontents.state.data.masterbannerscontents)
  const imgToDisplay = bannerscontents.state.data.masterbannerscontents.filter(
    (page) => {
      return page.pageName === 'Subsquad'
    }
  )[0]
  /* state */
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  useEffect(() => {
    // GArecordPageView(cookies)
  }, [])

  console.log("imgToDisplay",imgToDisplay)

  return (
    <ModPublicWrapp {...props} imgToDisplay={imgToDisplay}>
      <Grid container item justify="center" style={{ marginTop: 50 }}>
        <Grid item xs={12} sm={8} md={8} display="flex" flexDirection="column">
          <p
            className="subway-six-inch subway-footlong"
            gutterBottom
            style={{ fontSize: 25 }}
          >
            {currentlanguage.subsquad_website_title}
          </p>

          <Typography variant="body2" paragraph className="subway-six-inch">
            {currentlanguage.subsquad_website_contents1}
          </Typography>
          <Typography variant="body2" paragraph className="subway-six-inch">
            {currentlanguage.subsquad_website_contents2}
          </Typography>

          <Buttons.Normal
            fullWidth={width < 600 ? true : false}
            className="button-height"
            style={{ width: 200 }}
            onClick={() => {
              props.history.push('/subsquadfaq')
            }}
          >
            {currentlanguage.subsquad_website_find_out_more_button}
          </Buttons.Normal>

          <div
            style={{
              display: 'flex',
              justifyContent: width < 600 ? 'space-between' : 'flex-start',
              alignItems: 'center',
              margin: width < 600 ? 'auto' : 0,
              width: width < 600 ? '80%' : width < 1000 ? '55%' : '50%',
            }}
          >
            <Box alignItems="center" my={3}>
              <a
                href={currentlanguage.subsquad_website_google_play_icon_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={currentlanguage.subsquad_website_google_play_icon}
                  alt="Google Play"
                  width="100%"
                  maxWidth="90"
                />
              </a>
            </Box>
            <div
              style={{
                marginLeft:
                  width < 450
                    ? 10
                    : width < 600
                    ? 30
                    : width > 860 && width < 960
                    ? 30
                    : 10,
                marginRight:
                  width < 450
                    ? 10
                    : width < 600
                    ? 30
                    : width > 860 && width < 960
                    ? 30
                    : 10,
              }}
            />
            <Box alignItems="center" my={3}>
              <a
                href={currentlanguage.subsquad_website_apple_store_icon_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={currentlanguage.subsquad_website_apple_store_icon}
                  alt="App Store"
                  width="100%"
                  maxWidth="150"
                />
              </a>
            </Box>
          </div>

          <Typography variant="body2" paragraph>
            <Link to="/terms" style={{ textDecoration: 'underline' }}>
              {currentlanguage.subsquad_website_terms_and_conditions}
            </Link>
          </Typography>

          <div style={{ height: 40 }} />
        </Grid>
      </Grid>
    </ModPublicWrapp>
  )
}

export default Default
