import SimpleCrypto from 'simple-crypto-js'

const i = 'subway'

const encrypt = object => {
  const crypto = new SimpleCrypto(i)
  return crypto.encrypt(JSON.stringify(object))
}

const decrypt = text => {
  const crypto = new SimpleCrypto(i)
  return crypto.decrypt(text)
}

const addLS = (key, value) => {
  try {
    const ls = getLS()
    const obj = ls ? ls : {}
    obj[key] = value
    const newLs = encrypt(JSON.stringify(obj))
    return localStorage.setItem('ls', newLs)
  } catch (e) {
    // console.log(e)
    return false
  }
}

const getLS = () => {
  try {
    const ls = localStorage.getItem('ls')
      ? decrypt(localStorage.getItem('ls'))
      : localStorage.getItem('ls')
    return JSON.parse(JSON.parse(ls))
  } catch (e) {
    return false
  }
}

const removeLS = () => {
  try {
    return localStorage.removeItem('ls')
  } catch (e) {
    return false
  }
}

export default {
  addLS,
  getLS,
  removeLS,
}
