import React, { useState, useContext } from 'react'
// import { Link } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'

// import { GlobalContext } from '../../lib-atomic-context'
/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import { Inputs, Buttons } from '../../lib-atomic'
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
} from '../../lib-atomic-functions'
import {
  ModXlWrapp,
  ModPageTitleWrapp,
  ModTitleText,
  ModContentText,
  ModFormLabelText,
} from '../../lib-atomic-design-section'
// import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

import CONFIG from '../config'

/* CONSTANTS */

const index = (props) => {
  console.log(LocalStorage.getLS(), ' LSS ')
  const currentlanguage = LocalStorage.getLS().translation.json
  // console.log(currentlanguage)

  const isSuccessfullyUpdated = props.location.state
    ? props.location.state.isSuccessfullyUpdated
    : false
  const message = props.location.state ? props.location.state.message : false

  // const isAlreadyUsed = props.location.state
  //   ? props.location.state.isAlreadyUsed
  //   : false
  // const isAlreadyExpired = props.location.state
  //   ? props.location.state.isAlreadyExpired
  //   : false

  const { country, lang } = LocalStorage.getLS()

  const backButton = {
    fullWidth: true,
    onClick: () => {
      props.history.push('/verification')
    },
  }

  const forwardButton = {
    fullWidth: true,
    onClick: () => {
      // LocalStorage.removeLS()
      props.history.push('/login')
    },
  }
  return (
    <div>
      <ModXlWrapp>
        <ModPageTitleWrapp>
          {!isSuccessfullyUpdated && <ModTitleText>Sorry</ModTitleText>}
          <ModContentText>
            {message ? message : 'Something went wrong'}
          </ModContentText>
        </ModPageTitleWrapp>
      </ModXlWrapp>
      <ModXlWrapp>
        {/* {!isSuccessfullyUpdated && ( */}
        <Buttons.Normal {...backButton}>
          {currentlanguage.verification.page_verify_back}
        </Buttons.Normal>
        {/* )} */}
      </ModXlWrapp>
    </div>
  )
}

export default index
