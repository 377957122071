import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_ERROR_RESPONSE: "FETCH_ERROR_RESPONSE"
};

const fetchErrorResponse = async (state, errorresponse) => {
  state = {
    ...state,
    isStatus: errorresponse.status,
    expireToken: sanitizeExpire({
      status: errorresponse.status,
      data: errorresponse.data,
      name: "errorresponseobj"
    }),
    data: sanitizeData({
      status: errorresponse.status,
      data: errorresponse.data,
      name: "errorresponseobj"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ERROR_RESPONSE:
      return fetchErrorResponse(state, action.errorresponse);
    default:
      return state;
  }
};

const errorresponse = {
  init,
  actionTypes,
  reducer
};

export default errorresponse;
