import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { LocalStorage } from '../../lib-atomic-functions'

export function GArecordPageView(cookies) {
  // console.log('ay', cookies.performanceCookie)
  const { country } = LocalStorage.getLS()
  // console.log(process.env, country, ' TEST...GTAG')
  const GTAGS = JSON.parse(process.env.REACT_APP_GTAG)
  const COUNTRYGTAG = GTAGS[country]

  if (cookies.performanceCookie === 'true') {
    ReactGA.set({ page: window.location.pathname })

    ReactGA.initialize(COUNTRYGTAG)
    ReactGA.pageview(window.location.pathname)

    // START: REACT GOOGLE TAG MANAGER
    // const tagManagerArgs = {
    //   dataLayer: {
    //     gtmId: COUNTRYGTAG,
    //     page: window.location.pathname,
    //   },
    //   dataLayerName: 'PageDataLayer',
    // }
    const tagManagerArgs = {
      gtmId: COUNTRYGTAG,
    }
    // console.log(tagManagerArgs, ' GTAG INIT')
    TagManager.initialize(tagManagerArgs)
    // console.log('done .... GTAG')
    // END: REACT GOOGLE TAG MANAGER
  }
}
