import React, { useEffect, useContext, useState } from 'react'

/* packages */
import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import { LocalStorage } from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
/* module */

import CONFIG from '../config'

const ENDPOINTS = CONFIG.application.config.api.endpoints
const USERDETAILCONFIG = {
  url: ENDPOINTS.enquiry.url,
  method: ENDPOINTS.enquiry.method,
  headers: ENDPOINTS.enquiry.headers,
  responseType: 'json',
}
const index = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  const { token } = LocalStorage.getLS().login
  const { member } = useContext(GlobalContext)
  const [fetchMembers] = FetchContextApiV2(USERDETAILCONFIG)

  const execMembers = async ({ newHeader }) => {
    member.dispatch({
      type: member.actionTypes.FETCH_MEMBER,
      member: await fetchMembers({ newHeader }, member),
    })
  }

  const execMemberReset = async () => {
    member.dispatch({
      type: member.actionTypes.CLEAR_DATA,
    })
  }
  useEffect(() => {
    // GArecordPageView(cookies)
    const newHeader = {
      ...USERDETAILCONFIG.headers,
      Authorization: token,
    }
    execMembers({ newHeader })
  }, [])
  useEffect(() => {
    if (member.state.data) {
      if (!member.state.isStatus) {
        const { memberObject } = member.state.data
        const { response } = memberObject
        if (response) {
          if (response.status === 400) {
            const { country, lang } = LocalStorage.getLS()
            // LocalStorage.removeLS()
            execMemberReset()
            LocalStorage.addLS('login', {})
            props.history.push('/login')
          }
        }
      }
    }
  }, [member.state])
  const isMemberDetailReady =
    !member.state.isLoading &&
    member.state.data &&
    member.state.data.memberObject &&
    member.state.isStatus
      ? true
      : false
  if (!isMemberDetailReady) return <Spinner2.Popup />

  return props.children
}

export default index
