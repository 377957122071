import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'History'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    config: {
      api: {
        endpoints: {
          list: {
            headers: {
              'Content-Type': 'application/json',
            },
            doc: 'https://tr4ns10.tr4ns.com/Westfield-Reporting',
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.subcard.url[environment] + '/tx-sub/transactions',
            method: 'GET',
          },
          detail: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: null,
            },
            doc: 'https://tr4ns10.tr4ns.com/Westfield-Reporting',
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.subcard.url[environment] + '/tx-sub/transactions',
            method: 'GET',
          },
        },
      },
      objects: {
        companyname: {
          name: 'companyname',
          initial: '',
          placeholder: 'Company Name',
          helperText: false,
          rules: ['isNotNull'],
        },
        abn: {
          name: 'abn',
          initial: '',
          placeholder: 'ABN',
          helperText: false,
          rules: ['isNotNull'],
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'History',
    prefix: '/history',
  },
  setup,
}

export default config
