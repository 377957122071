import styled from "styled-components";

export const RotationContainer = styled.div`
  @media only screen and (max-width: 321px) {
    top: -25px;
  }
  width: 100%;
  left: 0px;
  right: 0px;
  top: 10px;
  bottom: 0px;
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${(props) => props.startRotationDegrees}deg);
  &.started-spinning {
    animation: 
      spin ${({ startSpinningTime }) => startSpinningTime / 1000}s
        cubic-bezier(0.71, -0.29, 0.96, 0.9) 0s 1 normal forwards running,
        continueSpin 0.2s linear
        ${({ startSpinningTime }) => startSpinningTime / 1000}s infinite normal
        forwards running
  }
  &.cont-spinning {
    animation:
    continueSpin 0.2s linear
    ${({ startSpinningTime }) => startSpinningTime / 1000}s infinite normal
    forwards running,
    stopSpin ${({ stopSpinningTime }) => stopSpinningTime / 1000}s cubic-bezier(.05,.26,.25,1)
    ${({ startSpinningTime }) => (startSpinningTime) / 1000}s 1 normal forwards running;

    -webkit-animation-name: continueSpin,stopSpin;
    -webkit-animation-duration: 0.2s,${({ stopSpinningTime }) => stopSpinningTime / 1000}s;
    -webkit-animation-timing-function: linear, cubic-bezier(.05,.26,.25,1);
    -webkit-animation-iteration-count: infinite, 1;
    -webkit-animation-direction: normal, normal;
    -webkit-animation-fill-mode: forwards, forwards!IMPORTANT;
    -webkit-animation-delay: 0 ,${({ startSpinningTime }) => (startSpinningTime) / 1000};
  }

  @keyframes spin {
    from {
      transform: rotate(${(props) => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => props.startRotationDegrees + 360}deg);
    }
  }
  @keyframes continueSpin {
    from {
      transform: rotate(${(props) => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => props.startRotationDegrees + 360}deg);
    }
  }
  @keyframes stopSpin {
    from {
      transform: rotate(${(props) => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => 1440 + props.finalRotationDegrees}deg);
    }
  }
`;

export const DisplayMessageImg = styled.img`
  display: flex;
  width: 100%;
  max-width: 36.5rem;
  margin: 0 auto;
  align-items: center;
  transform-origin: top;
  justify-content: center;
  transform: rotate(180deg);
  &.start-rotate {
    animation: 
      rotateIn 1.6s cubic-bezier(.095,.29,.11,1.36) 0s 1 normal forwards running,
      rotateEase 1.2s cubic-bezier(.5,-1.9,.55,1) 1.6s 1 normal forwards running
  }
  &.end-rotate {
    animation: 
      rotateOut 1.6s cubic-bezier(.095,.29,.11,1.36) 0s 1 normal forwards running,
  }

  @keyframes rotateIn {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(6deg);
    }
  }
  @keyframes rotateEase {
    from {
      transform: rotate(6deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  @keyframes rotateOut {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
`;
export const RotateDisplayPrize = styled.img`
  display: flex;
  width: 100%;
  max-width: 405px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  transform: rotate(-260deg);
  opacity: 0;
  &.start-rotate {
    animation: 
      rotateIn 0.7s ease-out 0s 1 normal forwards running
  }

  @keyframes rotateIn {
    from {
      opacity:1;
      transform: rotate(-260deg) scale(0);
    }
    to {
      transform: rotate(0deg) scale(1);
      opacity:1;
    }
  }
`;

export const BackgroundSettings = styled.div`
background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  padding-bottom: 220px;
  background-size: 529px;
  background-position-x: 50%;
  background-position-y: 100px;
`;

DisplayMessageImg.defaultProps = {
  src: props => props.src
};