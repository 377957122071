import appConf from '../../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const { default_country, default_lang } = setup.setup
const returnContLang = ({
  mastercountrylanguage,
  countryFromLS,
  langFromLS,
}) => {
  const hostname = window.location.hostname

  // const country = hostname.split('.')[hostname.split('.').length - 1]
  // const lang = mastercountrylanguage.filter((item) => {
  //   return item.mastercountries.abbr === country
  // })
  // if (country === 'localhost') {
  //   return { country: default_country, lang: default_lang }
  // } else {
  //   if (lang.length !== 0) {
  //     return {
  //       country,
  //       lang: lang[0].masterlanguages.abbr,
  //     }
  //   } else {
  //     if (!countryFromLS && !langFromLS) {
  //       // no localstorage found
  //       return { country: country, lang: country }
  //     } else {
  //       // localstorage found
  //       if (country !== countryFromLS) {
  //         // localstorage country not equal to what is from domain i.e. user changed url
  //         if (lang.length !== 0) {
  //           return {
  //             country,
  //             lang: lang[0].masterlanguages.abbr,
  //           }
  //         } else {
  //           // no matching mastercountrylanguage found; passing random lang(redirect to 404 is handled outside this function)
  //           return { country: country, lang: country }
  //         }
  //       } else {
  //         // localstorage match with domain i.e. normal redirect(refresh/jumping around page)
  //         return { country: countryFromLS, lang: langFromLS }
  //       }
  //     }
  //   }
  // }
  //

  const hostnameArray = hostname.split(/[\s.-]+/).map((i) => {
    if (i === 'uk') {
      return 'gb'
    } else {
      return i
    }
  })
  const countrylist = mastercountrylanguage.map((i) => i.mastercountries.abbr)
  const matchingCountryFound = hostnameArray.filter((i) =>
    countrylist.some((i2) => i === i2)
  )
  const country =
    matchingCountryFound.length > 0 ? matchingCountryFound[0] : null
  const lang =
    country &&
    mastercountrylanguage.filter((item) => {
      return item.mastercountries.abbr === country
    })
  if (hostnameArray[0] === 'localhost') {
    return { country: default_country, lang: default_lang }
  } else {
    if (lang) {
      if (lang.length !== 0) {
        return {
          country,
          lang: lang[0].masterlanguages.abbr,
        }
      } else {
        if (!countryFromLS && !langFromLS) {
          // no localstorage found
          return { country: country, lang: country }
        } else {
          // localstorage found
          if (country !== countryFromLS) {
            // localstorage country not equal to what is from domain i.e. user changed url
            if (lang.length !== 0) {
              return {
                country,
                lang: lang[0].masterlanguages.abbr,
              }
            } else {
              // no matching mastercountrylanguage found; passing random lang(redirect to 404 is handled outside this function)
              return { country: country, lang: country }
            }
          } else {
            // localstorage match with domain i.e. normal redirect(refresh/jumping around page)
            return { country: countryFromLS, lang: langFromLS }
          }
        }
      }
    } else {
      return { country: null, lang: null }
    }
  }
}
export default returnContLang
