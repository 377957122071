import React from 'react'

import Button from '@material-ui/core/Button'

/*
: properties
children
size
fullWidth
*/

const normal = (props) => {
  return (
    <Button
      {...props}
      className="subway-footlong"
      style={{
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
        margin: 'auto',
        justifyContent: 'center',
        ...props.customStyle,
      }}
      disabled={props.InputProps}
      variant="contained"
      color="primary"
      type="submit"
    >
      {props.children}
    </Button>
  )
}

export default normal
