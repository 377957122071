import React, { useEffect, useState, Fragment, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import Recaptcha, { propTypes } from 'react-recaptcha'

import { GlobalContext } from '../../lib-atomic-context'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { Captcha, Inputs, Buttons, Invalid, Link } from '../../lib-atomic'
import queryString from 'query-string'
import {
  FormHook,
  WindowSize,
  LocalStorage,
  FetchContextApiV2,
} from '../../lib-atomic-functions'
import {
  ModXlWrapp,
  ModPageTitleWrapp,
  ModTitleText,
  ModContentText,
  ModFormLabelText,
} from '../../lib-atomic-design-section'

import CONFIG from '../config'
import { Typography } from '@material-ui/core'

/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.account.objects
const ENDPOINTS = CONFIG.application.account.api.endpoints
const LOGINOBJECTSCONFIG = {
  url: ENDPOINTS.auth.url,
  method: ENDPOINTS.auth.method,
  headers: ENDPOINTS.auth.headers,
  responseType: 'json',
}

//const LOGINFAILRESPONSE = CONFIG.application.account.responses.fail
const UNVERIFIEDRESPONSE = CONFIG.application.account.responses.unverified
const RECAPTCHA_SITEKEY = CONFIG.application.recaptcha.siteKey
/* CONSTANTS */

const index = ({ history, match }) => {
  const onlineOrder = queryString.parse(history.location.search)

  const currentlanguage = LocalStorage.getLS().translation.json
  const { width, height } = WindowSize()
  //const fetchTokenObj12 = Geolocation()
  LOGINOBJECTSCONFIG.headers.longitude = 1 //fetchTokenObj12.longitude
  LOGINOBJECTSCONFIG.headers.latitude = 1 //fetchTokenObj12.latitude

  const { logon } = useContext(GlobalContext)

  /* -- recaptcha : states -- */
  const [loaded, setLoaded] = useState(false)
  const [verified, setVerified] = useState(false)
  const [exprired, setExpired] = useState(false)
  const [errorMessagePrepared, setErrorMessagePrepared] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [visiblePW, setVisiblePW] = useState(true)

  const verifyCallback = (response) => {
    if (response) {
      setVerified(true)
      setLoaded(true)
      setExpired(false)
    }
  }
  const expiredCallback = (response) => {
    setExpired(true)
  }
  /* -- recaptcha : states -- */

  /* --- logon */
  const [fetchLogonObj] = FetchContextApiV2(LOGINOBJECTSCONFIG)

  const fetchLogonExecute = async (body) => {
    logon.dispatch({
      type: logon.actionTypes.FETCH_LOGON,
      logon: await fetchLogonObj({ body }, logon),
    })
  }

  const logonResetExecute = () => {
    logon.dispatch({
      type: logon.actionTypes.RESET,
    })
  }
  useEffect(() => {
    if (logon.state.data) {
      const { logonobj } = logon.state.data
      const { outcomeCode, flActive } = logonobj
      if (outcomeCode === 0) {
        logonResetExecute()
        if (flActive === 1) {
          history.push('/verification')
        } else {
          history.push('/activation')
        }
      } else {
        // sending verification failed.
        // show something went wrong message?
        const { response } = logonobj
        setErrorMessagePrepared(
          currentlanguage.errorresponse_logon[response.data.outcomeCode]
            ? currentlanguage.errorresponse_logon[response.data.outcomeCode]
            : 'Unknown error occured'
        )
        logonResetExecute()
        setShowErrorMessage(true)
        // setVerified(false)
      }
    }
  }, [logon.state])
  useEffect(() => {
    notmatchLogin = { error: [errorMessagePrepared] }
  }, [errorMessagePrepared])

  const handleLogin = async () => {
    const body = {
      username: values.email.toLowerCase(),
      password: values.password,
    }

    if (verified) {
      LocalStorage.addLS('emailAddress', values.email.toLowerCase())
      LocalStorage.addLS('onlineOrder', onlineOrder)

      await fetchLogonExecute(body)
    }
  }

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleLogin,
    LOGINOBJECTS
  )

  const email = {
    ...LOGINOBJECTS.email,
    error: error.email,
    value: values.email,
    onChange,
    translation: currentlanguage,
  }
  const password = {
    ...LOGINOBJECTS.password,
    error: error.password,
    value: values.password,
    onChange,
    translation: currentlanguage,
  }

  /* -- data props --   */
  const isSubmitButtonDisabled = !verified || !loaded || exprired ? true : false
  const verifyLogin = { error: [UNVERIFIEDRESPONSE] }
  let notmatchLogin = { error: [errorMessagePrepared] }
  const captchaData = {
    verified,
    verifyCallback,
    expiredCallback,
  }
  const submitButton = { fullWidth: true }
  /* -- data props --   */

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      style={{ width: '100%', marginTop: 30 }}
    >
      <ModXlWrapp>
        <ModFormLabelText>
          {!verified && loaded && (
            <Fragment>
              <Invalid {...verifyLogin} />
            </Fragment>
          )}
          {showErrorMessage ? (
            <Fragment>
              <Invalid {...notmatchLogin} />
            </Fragment>
          ) : null}
        </ModFormLabelText>
      </ModXlWrapp>
      <ModXlWrapp>
        <ModFormLabelText>
          {currentlanguage.loginpage.page_log_email}
        </ModFormLabelText>
        <Inputs.Text {...email} />
      </ModXlWrapp>
      <ModXlWrapp>
        <ModFormLabelText>
          {currentlanguage.loginpage.page_log_password}
        </ModFormLabelText>

        <Inputs.Password
          style={{ backgroundColor: '#d3d3d352' }}
          {...password}
          visible={visiblePW}
        />

        <div
          onClick={() => {
            setVisiblePW(!visiblePW)
          }}
          style={{
            position: 'absolute',
            right: 19,
            top: 50,
            justifyContent: 'center',
          }}
        ></div>
      </ModXlWrapp>
      <ModXlWrapp>
        <ModFormLabelText>
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Link to="/forgot_password">
              {currentlanguage.loginpage.page_log_f_password}
            </Link>
          </div>
        </ModFormLabelText>
      </ModXlWrapp>
      <Captcha.Basic {...captchaData} />
      <br />
      <ModXlWrapp>
        <Buttons.Normal {...submitButton}>
          {currentlanguage.loginpage.page_log_login}
        </Buttons.Normal>
      </ModXlWrapp>
    </form>
  )
}

export default withRouter(index)
