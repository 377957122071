import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Unsubscribe Fail'

const config = {
  routes: {
    label: 'Unsubscribe Fail',
    prefix: '/unsubscribe/fail',
  },
  setup,
}

export default config
