import React from 'react'

/* mui */
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  wrapp: {
    position: 'relative',
  },
}))
/* CONSTANTS */

const xl = props => {
  /* mui */
  const classes = useStyles()
  /* mui */

  return (
    <Grid className={classes.wrapp} item xs={4} sm={4} md={4} lg={4} xl={4}>
      {props.children}
    </Grid>
  )
}

export default xl
