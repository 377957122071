import { LocalStorage } from '../../lib-atomic-functions'

const LSKEY_LOGIN = 'login'
const LSKEY_USER = 'user'

const INITIAL_STATE = {
  login:
    LocalStorage.getLS() && LocalStorage.getLS()[LSKEY_LOGIN]
      ? LocalStorage.getLS()[LSKEY_LOGIN]
      : {},
  user:
    LocalStorage.getLS() && LocalStorage.getLS()[LSKEY_USER]
      ? LocalStorage.getLS()[LSKEY_USER]
      : {},
}

const actionTypes = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      const { login, user } = action.payload
      if (login) {
        LocalStorage.addLS(LSKEY_LOGIN, login)
      }
      if (user) {
        LocalStorage.addLS(LSKEY_USER, user)
      }

      return {
        ...state,
        login,
        user,
      }
    case actionTypes.LOGOUT:
      LocalStorage.removeLS(LSKEY_LOGIN)
      LocalStorage.removeLS(LSKEY_USER)
      LocalStorage.removeLS()
      return {
        ...state,
        login: false,
        user: false,
      }
    default:
      return state
  }
}

const auth = {
  init: INITIAL_STATE,
  actionTypes,
  reducer,
}

export default auth
