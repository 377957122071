import React, { useState, useContext } from 'react'
// import { Link } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'

// import { GlobalContext } from '../../lib-atomic-context'
/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import { Inputs, Buttons } from '../../lib-atomic'
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
} from '../../lib-atomic-functions'
import {
  ModXlWrapp,
  ModPageTitleWrapp,
  ModTitleText,
  ModContentText,
  ModFormLabelText,
} from '../../lib-atomic-design-section'
// import { FetchContextApi } from "../../lib-atomic-functions";
/* packages */

import CONFIG from '../config'

/* CONSTANTS */

const index = (props) => {
  const { country, lang } = LocalStorage.getLS()
  const currentlanguage = LocalStorage.getLS().translation.json

  const isSuccessfullyUpdated = props.location.state
    ? props.location.state.isSuccessfullyUpdated
    : false
  const message = props.location.state ? props.location.state.message : false

  const backButton = {
    fullWidth: true,
    onClick: () => {
      props.history.push('/verification/resend')
    },
  }

  const forwardButton = {
    fullWidth: true,
    onClick: () => {
      // LocalStorage.removeLS()
      props.history.push('/verification')
    },
  }
  return (
    <div>
      <ModXlWrapp>
        <ModPageTitleWrapp>
          {!isSuccessfullyUpdated && <ModTitleText>Sorry</ModTitleText>}

          {/* content wording */}
          <ModContentText>
            {message ? message : 'Something went wrong'}
          </ModContentText>
        </ModPageTitleWrapp>
      </ModXlWrapp>

      <ModXlWrapp>
        {!isSuccessfullyUpdated && (
          <Buttons.Normal {...backButton}>
            {currentlanguage.verification.page_verify_back}
          </Buttons.Normal>
        )}
        {isSuccessfullyUpdated && (
          <Buttons.Normal {...forwardButton}>
            {currentlanguage.verification.page_verify_cont_verify}
          </Buttons.Normal>
        )}
      </ModXlWrapp>
    </div>
  )
}

export default index
