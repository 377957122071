import React, { Fragment, useState, useEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'

import moment from 'moment'
import {
  FormHook,
  WindowSize,
  WindowLayout,
  WindowFont,
} from '../../lib-atomic-functions' // LocalStorage
import { Inputs, Buttons, Invalid } from '../../lib-atomic'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from '@material-ui/core'

/* modules */
import RowHeader from './row-header'
import RowBody from './row-body'
/* modules */
import MuiTablePagination from '@material-ui/core/TablePagination'
import MuiTableCell from '@material-ui/core/TableCell'
const TablePagination = withStyles({
  root: {
    width: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
    alignSelf: 'center',
  },
  spacer: { flex: 0, width: '100%' },
  toolbar: {
    alignItems: 'center',
    textAlign: 'left',
    width: '100%',
    justifyContent: 'flex-start',
  },
})(MuiTablePagination)

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell)
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
const isBetweenTwoDate = (theDate, PublishFrom, PublishTo) => {
  const noFromDate = PublishFrom === null || PublishFrom === ''
  const noToDate = PublishTo === null || PublishTo === ''
  const publishFrom = noFromDate
    ? ' '
    : moment(PublishFrom, 'DD-MM-YYYY').format('YYYY MM DD').toString()
  const publishTo = noToDate
    ? ' '
    : moment(PublishTo, 'DD-MM-YYYY').format('YYYY MM DD').toString()
  const isBetweenFromTo =
    publishFrom === ' ' && publishTo === ' '
      ? true
      : publishFrom === ' ' && publishTo !== ' '
      ? moment(theDate).isBefore(moment(publishTo).format('YYYY MM DD'))
      : publishFrom !== ' ' && publishTo === ' '
      ? moment(theDate).isAfter(moment(publishFrom).format('YYYY MM DD'))
      : moment(theDate).isBetween(
          moment(publishFrom).format('YYYY MM DD'),
          moment(publishTo).format('YYYY MM DD')
        )
  return isBetweenFromTo
}
function filterByDateRange(array, from, to) {
  const filtered = array.filter((i) =>
    isBetweenTwoDate(i.transactionDate, from, to)
  )
  return filtered
}

const INITOBJECT = {
  from: {
    name: 'from',
    initial: null,
    placeholder: 'From',
    helperText: false,
    rules: false,
  },
  to: {
    name: 'to',
    initial: null,
    placeholder: 'To',
    helperText: false,
    rules: false,
  },
}

const normal = (props) => {
  const { width, height } = WindowSize()

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('transactionDate')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')
  const [doReset, setDoReset] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleSubmit = async (e) => {
    setDateFrom(values.from)
    setDateTo(values.to)
  }
  const handleReset = async () => {
    onPick('from', null)
    onPick('to', null)
    onReset({ from, to })
    setDoReset(true)
  }
  const goToDetail = (id) => {
    const { linkToDetail } = props
    linkToDetail(id)
  }
  const { values, onReset, onPick, onSubmit, error } = FormHook.useFormHook(
    handleSubmit,
    INITOBJECT
  )
  var from = {
    ...props,
    ...INITOBJECT.from,
    error: false,
    value: moment(values.from).format('dd/MM/yyyy'),
    formatDisplay: 'dd/MM/yyyy',
    onPick,
    doReset,
  }
  var to = {
    ...props,
    ...INITOBJECT.to,
    error: false,
    value: moment(values.to).format('dd/MM/yyyy'),
    formatDisplay: 'dd/MM/yyyy',
    onPick,
    doReset,
  }
  useEffect(() => {
    if (doReset) {
      setDoReset(false)
    }
  }, [doReset])
  const submitButton = {
    fullWidth: true,
    // onClick: handleSubmit.bind(this),
    style: {
      marginTop: 5,
      marginBottom: 5,
    },
  }
  const resetButton = {
    fullWidth: true,
    onClick: handleReset.bind(this),
    style: {
      marginTop: 5,
      marginBottom: 5,
      color: '#008938',
      backgroundColor: 'white',
      borderWidth: 2,
      borderColor: '#008938',
      borderStyle: 'solid',
      '&:hover': {
        color: 'red',
      },
    },
  }
  console.log(props)
  const {
    config,
    data,
    listTitle,
    toText,
    listFilterTitle,
    listEmpty,
    filterBtn,
    resetBtn,
    viewsText,
  } = props
  if (data.length < 1)
    return (
      <div style={{ width: '100%', marginBottom: 50 }}>
        <p
          style={{
            justifyContent: 'center',
            textAlign: 'center',
            color: '#444444',
            fontWeight: 900,
            fontSize: 16,
            marginTop: 50,
            fontFamily: 'SubwayFootlong',
          }}
        >
          {listTitle}
        </p>
        <div
          style={{
            backgroundColor: '#bbbbbb',
            height: 1,
            marginTop: 50,
            marginBottom: 70,
          }}
        />
        <div
          style={{
            textAlign: 'center',
            color: '#444444',
            marginBottom: 100,
            fontFamily: 'SubwaySixInch',
          }}
        >
          {listEmpty}
        </div>
      </div>
    )
  return (
    <div style={{ width: '100%', paddingBottom: 50 }}>
      <p
        style={{
          justifyContent: 'center',
          textAlign: 'center',
          color: '#444444',
          fontWeight: 900,
          fontSize: 16,
          marginTop: 50,
          fontFamily: 'SubwayFootlong',
        }}
      >
        {listTitle}
      </p>
      <div
        style={{
          backgroundColor: '#bbbbbb',
          height: 1,
          marginTop: 50,
          marginBottom: 30,
        }}
      />
      <div>
        <p
          style={{
            justifyContent: 'center',
            textAlign: 'center',
            color: '#444444',
            fontWeight: 700,
            fontSize: 14,
            fontFamily: 'SubwayFootlong',
          }}
        >
          {listFilterTitle}
        </p>

        <form onSubmit={onSubmit} noValidate>
          <div
            style={{
              display: width < 500 ? 'inline' : 'flex',
              alignItems: 'center',
              width: '80%',
              margin: 'auto',
            }}
          >
            <Inputs.Date {...from} />
            <div
              style={{
                textAlign: 'center',
                margin: 'auto',
                marginLeft: 10,
                marginRight: 10,
                color: '#bbbbbb',
                fontFamily: 'SubwaySixInch',
              }}
            >
              {' '}
              {toText}{' '}
            </div>
            <Inputs.Date {...to} />
          </div>
          {width < 500 ? (
            <div style={{ marginBottom: 5 }}>
              <br />
            </div>
          ) : (
            <br />
          )}
          {/* <br /> */}
          <div
            style={{
              display: width < 500 ? 'inline' : 'flex',
              width: '60%',
              height: '70%',
              margin: 'auto',
            }}
          >
            <Buttons.Normal {...submitButton}>{filterBtn}</Buttons.Normal>
            <div
              style={{
                marginLeft: 5,
                marginRight: 5,
                marginTop: 20,
                marginBottom: 20,
              }}
            />
            <Buttons.Normal {...resetButton}>{resetBtn}</Buttons.Normal>
          </div>
        </form>
      </div>
      <div
        style={{
          backgroundColor: '#bbbbbb',
          height: 1,
          marginTop: 50,
          marginBottom: 30,
        }}
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={
          filterByDateRange(
            stableSort(data, getComparator(order, orderBy)),
            dateFrom,
            dateTo
          ).length
        }
        labelRowsPerPage={viewsText ? `${viewsText}:` : 'Views:'}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <TableContainer style={{ width: '100%', height: '100%' }}>
        <Table size={'small'} padding={'0px'}>
          <TableHead>
            <TableRow>
              {config.map((data, index) => {
                return <TableCell key={index}>{data.label}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {filterByDateRange(
              stableSort(data, getComparator(order, orderBy)),
              dateFrom,
              dateTo
            ).length < 1 ? (
              <div
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  color: '#444444',
                  marginBottom: 100,
                }}
              >
                {listEmpty}
              </div>
            ) : (
              filterByDateRange(
                stableSort(data, getComparator(order, orderBy)),
                dateFrom,
                dateTo
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <TableRow
                      key={index}
                      hover
                      style={{ cursor: 'pointer' }}
                      onClick={goToDetail.bind(this, item.transactionId)}
                    >
                      {config.map((conf, index) => {
                        const content = conf.template
                          ? conf.template(item[conf.field], item)
                          : item[conf.field]
                        return <TableCell key={index}>{content}</TableCell>
                      })}
                    </TableRow>
                  )
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default normal
