import React, { useContext } from 'react'

import { GlobalContext } from '../../lib-atomic-context'

import {
  Box,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const index = (props) => {
  const { pages } = useContext(GlobalContext)
  const { masterpages } = pages.state.data
  const { header, subtitle, json } =
    masterpages && masterpages.length > 0 ? masterpages[0] : null

  return (
    <Container
      component={Box}
      my={5}
      maxWidth="sm"
      disableGutters
      style={{
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      {json && json.impressum && (
        <Box my={5}>
          {json.impressum.impressum_represent_organisation &&
          json.impressum.impressum_represent_organisation.length > 0 ? (
            <Typography
              color="primary"
              variant="h6"
              gutterBottom
              className="subway-footlong"
            >
              {json.impressum.impressum_represent_organisation}
            </Typography>
          ) : null}

          {json.impressum.impressum_represent_address &&
          json.impressum.impressum_represent_address.length > 0 ? (
            <Typography
              style={{ marginTop: 10, fontSize: 16 }}
              gutterBottom
              className="subway-six-inch"
            >
              {json.impressum.impressum_represent_address}
            </Typography>
          ) : null}

          <br />

          {json.impressum.impressum_represented_by_header &&
          json.impressum.impressum_represented_by_header.length > 0 ? (
            <Typography
              color="primary"
              variant="h6"
              gutterBottom
              className="subway-footlong"
            >
              {json.impressum.impressum_represented_by_header}:
            </Typography>
          ) : null}

          {json.impressum.impressum_represented_by_content &&
          json.impressum.impressum_represented_by_content.length > 0 ? (
            <Typography
              style={{ marginTop: 10, fontSize: 16 }}
              gutterBottom
              className="subway-six-inch"
            >
              {json.impressum.impressum_represented_by_content}
            </Typography>
          ) : null}

          <br />

          {json.impressum.impressum_represent_contact_header &&
          json.impressum.impressum_represent_contact_header.length > 0 ? (
            <Typography
              color="primary"
              variant="h6"
              gutterBottom
              className="subway-footlong"
            >
              {json.impressum.impressum_represent_contact_header}
            </Typography>
          ) : null}

          {json.impressum.impressum_represent_contact_content &&
          json.impressum.impressum_represent_contact_content.length > 0 ? (
            <Typography
              style={{ marginTop: 10, fontSize: 16 }}
              gutterBottom
              className="subway-six-inch"
            >
              {json.impressum.impressum_represent_contact_content
                .split('<br />')
                .map((i) => {
                  return (
                    <span>
                      {i}
                      <br />
                    </span>
                  )
                })}
            </Typography>
          ) : null}

          <br />

          {json.impressum.impressum_represent_register_header &&
          json.impressum.impressum_represent_register_header.length > 0 ? (
            <Typography
              color="primary"
              variant="h6"
              gutterBottom
              className="subway-footlong"
            >
              {json.impressum.impressum_represent_register_header}
            </Typography>
          ) : null}

          {json.impressum.impressum_represent_register_content &&
          json.impressum.impressum_represent_register_content.length > 0 ? (
            <Typography
              style={{ marginTop: 10, fontSize: 16 }}
              gutterBottom
              className="subway-six-inch"
            >
              {json.impressum.impressum_represent_register_content
                .split('<br />')
                .map((i) => {
                  return (
                    <span>
                      {i}
                      <br />
                    </span>
                  )
                })}
            </Typography>
          ) : null}
        </Box>
      )}
    </Container>
  )
}

export default index
