import React from 'react'
// import { Link } from "react-router-dom";

// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItem from "@material-ui/core/ListItem";
// import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

// import CONFIG from "./config";

const App = props => {
  // const url = props.match ? props.match.url : "";
  // const { label, prefix } = CONFIG.routes;
  return <div></div>
}

export default App
