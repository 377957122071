import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'

import CountrySelectorDialog from './country-selector.dialog'
import { LocalStorage } from '../../../components/lib-atomic-functions'

const CountrySelector = ({ match }) => {
  const { country, lang } = LocalStorage.getLS()
  const [open, setOpen] = useState(false)
  const handleClick = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button
        disableElevation
        onClick={handleClick}
        color="inherit"
        endIcon={<ExpandMore />}
      >
        <img
          style={{ width: 50, height:30, border: '1px solid #ddd' }}
          // src={`https://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/${country}.svg`}
          src={`https://flagcdn.com/h40/${country}.png`}
          // src={require(`./flags/${country}.svg`)}
          alt={country}
        />
      </Button>
      <CountrySelectorDialog open={open} onClose={handleClose} />
    </>
  )
}

export default withRouter(CountrySelector)
