import React, { useEffect } from 'react'
import moment from 'moment'
// import TextField from '@material-ui/core/TextField';

/* atoms */
import { Invalid } from '../feedbacks'
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

import 'date-fns'
import { format } from 'date-fns'
import en from 'date-fns/locale/en-US'
import { de, nl, fr, fi } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { LocalStorage } from '../../lib-atomic-functions'

class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'LLLL yyyy', { locale: this.locale })
  }

  getDatePickerHeaderText(date) {
    return format(date, 'dd MMMM', { locale: this.locale })
  }
}

const localeMap = {
  en: en,
  de: de,
  nl: nl,
  fr: fr,
  fi: fi,
}
const date = (props) => {
  const {
    formatDisplay,
    formatProcess,
    // value,
    initial,
    placeholder,
    name,
    label,
    onPick,
    doReset,
  } = props
  const { lang } =
    LocalStorage.getLS() && LocalStorage.getLS().lang
      ? LocalStorage.getLS()
      : { lang: 'en' }
  const [selectedDate, setSelectedDate] = React.useState(initial)
  const langList = ['en', 'de', 'nl', 'fr', 'fi']
  const langSelected = langList.filter((i) => lang === i)
  const langSelectedFinal =
    langSelected && langSelected.length > 0 ? langSelected[0] : 'en'
  if (selectedDate !== null && doReset) {
    setSelectedDate(null)
  }
  

  const handleDateChange = (date) => {
    if (!date) {
      date = ''
    } else {
      setSelectedDate(date)
      date = moment(date).format('DD-MM-YYYY')
    }
    onPick(name, date)
  }
  console.log("langSelectedFinal",langSelectedFinal)
  return (
    <MuiPickersUtilsProvider
      locale={localeMap[langSelectedFinal]}
      utils={LocalizedUtils}
    >
      {/* disableToolbar */}
      <KeyboardDatePicker
        name={name}
        label={label}
        autoOk
        fullWidth
        variant="inline"
        format={formatDisplay}
        defaultValue={selectedDate}
        margin="dense"
        placeholder={placeholder}
        helperText={null}
        InputProps={{
          style: {
            backgroundColor: '#d3d3d352',
            //marginLeft: '5px',
            width: '100%',
            fontFamily: 'SubwaySixInch',
          },
        }}
        error={false}
        id="date-picker-inline"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
      <Invalid {...props} />
    </MuiPickersUtilsProvider>
  )
}

export default date
