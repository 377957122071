import React, { useEffect, useContext, useState } from 'react'
import { withRouter } from 'react-router-dom'
import QueryString from 'query-string'

/* GA */
import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'
/* GA */

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2 } from '../../lib-atomic-functions'

import { LocalStorage } from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
/* module */
import SomethingWentWrong from '../../app-something-went-wrong/default'

import CONFIG from '../config'

const ENDPOINTS = CONFIG.application.config.api.endpoints

const MEMBERENQUIRY = {
  url: ENDPOINTS.memberequiry.url,
  method: ENDPOINTS.memberequiry.method,
  headers: ENDPOINTS.memberequiry.headers,
  responseType: 'json',
}

const index = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  const [resetMember, setResetMember] = useState(false)
  /* state */
  const { member } = useContext(GlobalContext)
  /* state */

  const resetLoginObj = () => {
    LocalStorage.addLS('login', {})
  }
  /* member */
  const [fetchMemberEnquiry] = FetchContextApiV2(MEMBERENQUIRY)

  const exeFetchMemberEnquiry = async ({ newHeader }) => {
    member.dispatch({
      type: member.actionTypes.FETCH_MEMBER,
      member: await fetchMemberEnquiry({ newHeader }, member),
    })
  }
  /* member */

  // useEffect(() => {
  //   const isFromLogout = LocalStorage.getLS()
  //     ? LocalStorage.getLS().isFromLogout
  //     : false
  //   if (isFromLogout) {
  //     LocalStorage.addLS('isFromLogout', false)
  //     window.location.reload(false)
  //   }
  // }, [])
  useEffect(() => {
    // GArecordPageView(cookies)

    console.log(LocalStorage.getLS())
    const token = LocalStorage.getLS()
      ? LocalStorage.getLS().login
        ? LocalStorage.getLS().login.token
          ? LocalStorage.getLS().login.token
          : false
        : false
      : false
    if (!token) {
      resetLoginObj()
    } else {
      const newHeader = {
        ...MEMBERENQUIRY.headers,
        Authorization: 'Bearer ' + token,
      }
      exeFetchMemberEnquiry({ newHeader })
    }
  }, [])

  useEffect(() => {
    if (member.state.data) {
      if (!member.state.isStatus) {
        resetLoginObj()
      }
      setResetMember(true)
    }
  }, [member.state])

  useEffect(() => {
    if (resetMember) {
      setResetMember(false)
      member.dispatch({
        type: member.actionTypes.CLEAR_DATA,
      })
      props.history.push('/dashboard')
    }
  }, [resetMember])

  return props.children
}

export default index
