import React, { useState,useEffect } from 'react';
import QuoteClose from '../../icons/quote-closed.png'
import QuoteOpen from '../../icons/quote-open.png'
import '../styles.css';

const SplitText = (props) => {

  const [nextTextGo1, setNextTextGo1] = useState(false)
  const [nextTextGo2, setNextTextGo2] = useState(false)
  
 
  useEffect(() => {
    setNextTextGo1(false);
    setNextTextGo2(false);

    setTimeout(() => {
      setNextTextGo1(true);
    }, props.line1.length * 100);
    setTimeout(() => {
      setNextTextGo2(true);
    }, ((props.line1.length + props.line2.length) * 100));

  }, [props.line1])

  return (
  // THREE LINES
  props.line3  ? 
  <div className='flavorMessage subway-sans-cond-bold'>
    <div className="apostroContainShort">
      <img className='apostroImg apoOpen fadeInText-anim' style={{"animationDelay": "0.2s"}} src={QuoteOpen} alt=""/>
      { props.line1.split('').map(function(char, index){
        const style = {"animationDelay": (0.2 + index / 14 + "s")};
        return (<span className={`flavorText fadeInText-anim ${char === ' ' ? 'breakspace' : ''}`}
        aria-hidden="true" 
        key={index} style={style}
        >
          {char}
        </span>);
      })}
    </div>
    <div className='centerTextContainer'>
      { nextTextGo1 ? props.line2.split('').map(function(char, index){
          const style = {"animationDelay": (0.2 + index / 14 + "s")};
          return (<span className={`flavorText centerText yellow fadeInText-anim ${char === ' ' ? 'breakspace' : ''}`}
          aria-hidden="true" 
          key={index} style={style}
          >
            {char}
          </span>);
        }) : 
        <span className="flavorText flavorTextMiddle"></span> 
      }
    </div>
    <div className="apostroContainShort white">
      { nextTextGo2 ? props.line3.split('').map(function(char, index){
        const style = {"animationDelay": (0.2 + index / 14 + "s")};
        return (<span className={`flavorText fadeInText-anim ${char === ' ' ? 'breakspace' : ''}`} 
        aria-hidden="true" 
        key={index} style={style}
        >
          {char}
        </span>);
      })
      :
      ""
      }
      { nextTextGo2 ? 
        <img style={{"animationDelay": ((1.2 +props.line2.length + props.line3.length + props.line1.length) / 14.5 + "s")}} 
        className={`apostroImg apoClose fadeInText-anim`}
        src={QuoteClose} alt=""
      />
      : 
        <img style={{"animationDelay": ((1.2 + props.line2.length + props.line3.length + props.line1.length) / 14.5 + "s")}} 
        className={`apostroImg apoClose fadeInText-anim ${nextTextGo2 ? '' : 'opacityzero'}`}
        src={QuoteClose} alt=""
      />
      }
    </div>
  </div> 
  : 
  // TWO LINES
  <div key={props.id} className='flavorMessage subway-sans-cond-bold'>
    <div className="apostroContainShort">
      <img className='apostroImg apoOpen fadeInText-anim' style={{"animationDelay": "0.2s"}} src={QuoteOpen} alt=""/>
      { props.line1.split('').map(function(char, index){
        const style = {"animationDelay": (0.2 + index / 14 + "s")};
        return (<span className={`flavorText fadeInText-anim ${char === ' ' ? 'breakspace' : ''}`} 
        aria-hidden="true" 
        key={index} style={style}
        >
          {char}
        </span>);
      })}
    </div>
    <div className="apostroContainShort">
      { nextTextGo1 ? props.line2.split('').map(function(char, index){
        const style = {"animationDelay": (0.2 + index / 14 + "s")};
        return (<span className={`flavorText yellow fadeInText-anim ${char === ' ' ? 'breakspace' : ''}`} 
        aria-hidden="true" 
        key={index} style={style}
        >
          {char}
        </span>);
      })
      :
      ""
      }
      { nextTextGo1 ? <img style={{"animationDelay": (((0.2 * props.line1.length) + (0.2 * props.line2.length) / 15 + "s"))}} 
        className={`apostroImg apoClose fadeInText-anim`}
        src={QuoteClose} alt=""
      />
      : 
      <img style={{"animationDelay": (((0.2 * props.line1.length) + (0.2 * props.line2.length) / 15 + "s"))}} 
        className={`apostroImg apoClose fadeInText-anim ${nextTextGo1 ? 'opacityzero' : 'opacityzero'}`}
        src={QuoteClose} alt=""
      />
      }
    </div>
  </div>
  )
}

export default SplitText