import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_VERIFICATION: 'FETCH_VERIFICATION',
  RESET: 'RESET',
}

const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}

const fetchVerification = async (state, verification) => {
  state = {
    ...state,
    isStatus: verification.status,
    expireToken: sanitizeExpire({
      status: verification.status,
      data: verification.data,
      name: 'verificationobject',
    }),
    data: sanitizeData({
      status: verification.status,
      data: verification.data,
      name: 'verificationobject',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_VERIFICATION:
      return fetchVerification(state, action.verification)
    case actionTypes.RESET:
      return fetchReset(state)
    default:
      return state
  }
}

const verification = {
  init,
  actionTypes,
  reducer,
}

export default verification
