import React, { useState, useEffect, useContext, Fragment } from 'react' // useEffect, useContext
import PropTypes from 'prop-types'
// import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import Recaptcha from 'react-recaptcha'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
/* packages */
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
//switches//
/* container */
import { GlobalContext } from '../../lib-atomic-context'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import MaskedInput from 'react-text-mask'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import { Inputs, Buttons, Invalid } from '../../lib-atomic'
import { FormHook } from '../../lib-atomic-functions' // LocalStorage
import Checkbox from '@material-ui/core/Checkbox'
import { green } from '@material-ui/core/colors'
import CONFIG from '../config'
import { LocalStorage } from '../../lib-atomic-functions'
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
import DialogBox from '../../lib-atomic/dialog/dialogbox'
/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.account.objects
const UNVERIFIEDRESPONSE = CONFIG.application.account.responses.unverified
const ENDPOINTS = CONFIG.application.account.api.endpoints
const UPDATE = {
  url: ENDPOINTS.register.url,
  method: ENDPOINTS.register.method,
  headers: ENDPOINTS.register.headers,
  responseType: 'json',
}
/* CONSTANTS */
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    paddingTop: '10px',
    paddingBottom: '10px',
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

const FormControlLabelDetails = withStyles((theme) => ({
  root: {
    left: '100px',
    // position: "relative"
  },
  paper: {
    // padding: theme.spacing(3),
    textAlign: 'center',
    color: 'black',
    marginBottom: '50px',
  },
  inline: {
    display: 'flex',
  },
}))(FormControlLabel)

const useStyles = makeStyles((theme) => ({
  margin: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  paper: {
    // padding: theme.spacing(3),
    color: 'black',
    marginBottom: '50px',
  },
}))
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#008938',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        margin: classes.margin,
      }}
      {...props}
    />
  )
})

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      className="dob-input"
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholder="DD/MM/YYYY" // showMask
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

const login = (props) => {
  const errorPopup = LocalStorage.getLS().translation.json
    .errorresponse_formValidation
  const mainCurrentlanguage = LocalStorage.getLS().translation.json
  const currentlanguageform = LocalStorage.getLS().translation.json
    .registrationpage
  const { token } = LocalStorage.getLS().login
  const currentlanguage = LocalStorage.getLS().translation.json.preferencepage
  const { gdprupdate, cmscountry } = useContext(GlobalContext)
  const { translation } = LocalStorage.getLS()
  const flOldUser = LocalStorage.getLS().flOldUser
  const { mobile, postCode, mobileCountryCode } = props

  const { mastercountry } = cmscountry.state.data
  LOGINOBJECTS.mobprefix.options = LOGINOBJECTS.mobprefix.options.filter((i) =>
    mastercountry.some((i2) => i2.forRegistration && i2.abbr === i.abbr)
  )

  LOGINOBJECTS.mobile.initial = mobile
  LOGINOBJECTS.postcode.initial = postCode
  if (mobileCountryCode == null) {
    if (translation) {
      const mCode = translation.mastercountries.abbr
     
      if (mCode == 'de') {
        LOGINOBJECTS.mobprefix.initial = '+49'
      } else if (mCode == 'at') {
        LOGINOBJECTS.mobprefix.initial = '+43'
      } else if (mCode == 'fr') {
        LOGINOBJECTS.mobprefix.initial = '+33'
      } else if (mCode == 'gb') {
        LOGINOBJECTS.mobprefix.initial = '+44'
      } else if (mCode == 'ie') {
        LOGINOBJECTS.mobprefix.initial = '+353'
      } 
      // else if (mCode == 'ni') {
      //   LOGINOBJECTS.mobprefix.initial = '+44'
      // } 
      else if (mCode == 'se') {
        LOGINOBJECTS.mobprefix.initial = '+46'
      } else if (mCode == 'fi') {
        LOGINOBJECTS.mobprefix.initial = '+358'
      } else {
        // netherlands
        LOGINOBJECTS.mobprefix.initial = '+31'
      }
    }
  } else {
    LOGINOBJECTS.mobprefix.initial = mobileCountryCode
  }
  

  var eValue = false
  var sValue = false
  var aValue = false
  var allValue = false
  var everyValue = false
  var oValuie = false
  var pValue = false
  var nValue = false
  var ism = false
  var ispost = false
  var gdprValue = props.gdpr

  var typeOffers = gdprValue.typeOffer
  var typeCompetition = gdprValue.typeCompetitions
  var typeNew = gdprValue.typeNews
  var channelEmails = gdprValue.channelEmail
  var channelApps = gdprValue.channelApp
  var channelSMSs = gdprValue.channelSMS

  if (typeOffers == 1) {
    oValuie = true
    ispost = true
  }
  if (typeCompetition == 1) {
    pValue = true
  }
  if (typeNew == 1) {
    nValue = true
  }
  if (channelEmails == 1) {
    eValue = true
  }
  if (channelApps == 1) {
    aValue = true
  }
  if (channelSMSs == 1) {
    sValue = true
    ism = true
  }
  // const currentlanguage = LocalStorage.getLS().translation[0].json const {
  // token } = LocalStorage.getLS().login;
  /* state */
  const [loaded, setLoaded] = useState(false)
  const [verified, setVerified] = useState(false)
  const [visiblePW, setVisiblePW] = useState(false)
  const [visiblePWC, setVisiblePWC] = useState(false) // setVisiblePWC
  const [response, setresponse] = React.useState()
  const [open, setOpen] = useState(false)
  const [selectedAll, setSelectedAll] = React.useState(allValue)
  const [isAPP, setSelectedAPP] = React.useState(aValue)
  const [isEmail, setSelectedEmail] = React.useState(eValue)
  const [isSms, setSelectedSMS] = React.useState(sValue)

  const [isEvrything, setSelectedEvrything] = React.useState(everyValue)
  const [isOffers, setSelectedOffers] = React.useState(oValuie)
  const [isCampaign, setSelectedCampaign] = React.useState(pValue)
  const [isNews, setSelectedNews] = React.useState(nValue)
  const [isMobilenumber, setisMobilenumber] = useState(ism)
  const [isPostcode, setisPostcode] = useState(ispost)
  const [redirect, setredirect] = useState(false)
  const [tnc, setSelectedtnc] = React.useState(false)
  const [isAllow, setSelectedisAllow] = React.useState(true)
  const [spin, setSpin] = useState(false)

  const [isdialog, setisdialog] = useState(false)
  const callback = () => {
    console.log('loaded recaptcha...')
  }

  // specifying verify callback function
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true)
      setLoaded(true)
    }
  }
  const [gdprupdateData] = FetchContextApiV2(UPDATE)
  const updateExecute = async ({ body, newHeader }) => {
    gdprupdate.dispatch({
      type: gdprupdate.actionTypes.FETCH_GDPR,
      gdprupdate: await gdprupdateData(
        {
          body,
          newHeader,
        },
        gdprupdate
      ),
    })
  }
  const gdprResetExecute = () => {
    gdprupdate.dispatch({ type: gdprupdate.actionTypes.RESET })
  }
  useEffect(() => {
    if (flOldUser == 0) {
      setSelectedisAllow(false)
    }
    if (flOldUser == 1) {
      setSelectedisAllow(true)
      values.mobile = ''
    }
  }, [])
  useEffect(() => {
    if (gdprupdate.state.data) {
      const { gdprupdateobj } = gdprupdate.state.data
      console.log('gdprupdate', gdprupdate)

      if (gdprupdateobj.response) {
        setisdialog(false)
        var message = {
          outComeCode: '1',
          title: 'Something went wrong!',
          outComeMessage: gdprupdateobj.response.data,
        }
        setresponse()
        setOpen(false)
        console.log('open1', open)
      } else {
        console.log('dialog11', isdialog)
        setSpin(false)
        setOpen(true)
        setisdialog(true)
        var message = {
          outComeCode: '0',
          title: '',
          outComeMessage: currentlanguage.page_success_response,
        }
        setresponse(message)
   
        gdprResetExecute()
        // props.history.push('/nz/en/home')
      }
      // if (registerObject.response.data.outComeCode != 0) {
      // console.log("outComeCode",registerObject.response.data.outComeCode);
      // console.log("outComeMessage",registerObject.response.data.outComeMessage);
      // console.log("outComeUserMessage",registerObject.response.data.outComeUserMess
      // a ge); setresponse(registerObject.response.data); setOpen(true); }
    }
  }, [gdprupdate.state])

  const handleClose = (value) => {
    setisdialog(false)
    const flOldUser = LocalStorage.getLS().flOldUser
    if (flOldUser === 1) {
      LocalStorage.addLS('flOldUser', 0)

      const isCampaignCodeFound = LocalStorage.getLS().campaignCode
      if (isCampaignCodeFound) {
        const { campaignCode } = LocalStorage.getLS()
        LocalStorage.addLS('campaignCode', null)
        props.history.push('/campaign?campaignCode=' + campaignCode)
      } else {
        window.location.replace('/dashboard')
      }
    }
    // setresponse(false)
  }
  const handleSubmit = async () => {
    var App = 0
    var Sms = 0
    var Email = 0
    var Offers = 0
    var Campaign = 0
    var News = 0

    if (selectedAll == true) {
      App = 1
      Sms = 1
      Email = 1
    }
    if (isEvrything == true) {
      Offers = 1
      Campaign = 1
      News = 1
    }
    if (isEmail == true) {
      Email = 1
      Offers = 1
      Campaign = 1
      News = 1
    }
    if (isSms == true) {
      Sms = 1
      Offers = 1
      Campaign = 1
      News = 1
    }
    if (isAPP == true) {
      App = 1
      Offers = 1
      Campaign = 1
      News = 1
    }

    if (isOffers == true) {
      Offers = 1
    }
    if (isCampaign == true) {
      Campaign = 1
    }
    if (isNews == true) {
      News = 1
    }
    console.log('isSmsandMobile', isSms, values.mobile)
    if (isSms == true && values.mobile == null) {
      setOpen(false)
      setisdialog(true)
      var message = {
        outComeCode: '0',
        title: '',
        outComeMessage: errorPopup.isMobile,
      }
      setresponse(message)
    }
    if (
      (isOffers == true || isCampaign == true || isNews == true) &&
      values.postcode == ''
    ) {
      setOpen(false)
      setisdialog(true)
      var message = {
        outComeCode: '0',
        title: '',
        outComeMessage: errorPopup.isPostcode,
      }
      setresponse(message)
    } else {
      if (values.mobile != '' && values.postcode != '') {
        setVerified(true)
      }
      // if (isSms == false) {
      //   values.mobile = null
      //   values.mobprefix = null
      // }
      setLoaded(true)
      setSpin(true)

      var body = {
        gdpr: {
          channelApp: App,
          channelSMS: Sms,
          channelEmail: Email,
          typeOffer: Offers,
          typeCompetitions: Campaign,
          typeNews: News,
        },
        mobile: values.mobile,
        postCode: values.postcode,
        mobileCountryCode: values.mobprefix,
      }

      var newHeader = {
        Authorization: token,
      }

      await updateExecute({ body, newHeader })
    }
    // if (verified) { // await accountLoginMethod({ body: JSON.stringify(values),
    //   props.gdprvalues(body) } if (verified) {   // await accountLoginMethod({
    // body: JSON.stringify(values), query: false });   //
    // LocalStorage.addLS("login", {   // ...accountLoginContext.data.json,   //
    // username: values.username   // }); // if
    // (accountLoginContext.data.invalidToken) return false;   //
    // props.history.push("/u/login-logout-redirect"); }
  }

  const backStep = () => {
    var bakValue = 1
    props.back(bakValue)
  }

  const { values, onChange, onPick, onSubmit, error } = FormHook.useFormHook(
    handleSubmit,
    LOGINOBJECTS
  )
  const mobileNumber = {
    ...LOGINOBJECTS.mobile,
    error: error.mobile,
    value: values.mobile,
    placeholder: currentlanguage.page_pref_mobile_number,
    onChange,
    translation: mainCurrentlanguage,
  }
  const mobprefix = {
    ...LOGINOBJECTS.mobprefix,
    error: error.mobprefix,
    value: values.mobprefix,
    onChange,
  }
  const postcode = {
    ...LOGINOBJECTS.postcode,
    error: error.postcode,
    value: values.postcode,
    placeholder: currentlanguage.page_pref_postcode,
    translation: mainCurrentlanguage,
    onChange,
  }
  const isSmsActive = {
    ...LOGINOBJECTS.isSmsActive,
    value: isSms,
  }

  console.log('isSmsActive', isSmsActive)
  const submitButton = {
    fullWidth: true,
  }
  const back = {
    fullWidth: true,
    onClick: backStep.bind(this),
  }
  // const failLogin = { error: [LOGINFAILRESPONSE] }
  const verifyLogin = {
    error: [UNVERIFIEDRESPONSE],
  }

  const handleChangeterms = (event) => {
    setSelectedtnc(!tnc)
    setSelectedisAllow(tnc)
  }
  //SelectALL
  const handleAll = (event) => {
    setSelectedAll(!selectedAll)
    if (!selectedAll == true) {
      setSelectedEmail(true)
      setSelectedAPP(true)
      setSelectedSMS(true)
      setisMobilenumber(true)
      setSelectedOffers(true)
      setSelectedCampaign(true)
      setSelectedNews(true)
      setisPostcode(true)
    }
    if (!selectedAll == false) {
      setSelectedEmail(false)
      setSelectedAPP(false)
      setSelectedSMS(false)
      setisMobilenumber(false)
      setSelectedOffers(false)
      setSelectedCampaign(false)
      setSelectedNews(false)
      setisPostcode(false)
    }
  }

  //setSelectedEmail
  const handlEmail = (event) => {
    setSelectedEmail(!isEmail)
    setSelectedOffers(!isEmail)
    setSelectedCampaign(!isEmail)
    setSelectedNews(!isEmail)
    setisPostcode(!isEmail)
  }
  // //setSelectedSMS
  const handleSMS = (event) => {
    setSelectedSMS(!isSms)
    setisMobilenumber(!isMobilenumber)
    setSelectedOffers(!isSms)
    setSelectedCampaign(!isSms)
    setSelectedNews(!isSms)
    setisPostcode(!isSms)
  }
  // //setSelectedAPP
  const handleApp = (event) => {
    setSelectedAPP(!isAPP)
      setSelectedOffers(!isAPP)
      setSelectedCampaign(!isAPP)
      setSelectedNews(!isAPP)
      setisPostcode(!isAPP)
  }

  // //isEvrything
  // const handleEvrything = (event) => {
  //   setSelectedEvrything(!isEvrything)
  //   if (!isEvrything == true) {
  //     setSelectedOffers(true)
  //     setSelectedCampaign(true)
  //     setSelectedNews(true)
  //     setisPostcode(true)
  //   }
  //   if (!isEvrything == false) {
  //     setSelectedOffers(false)
  //     setSelectedCampaign(false)
  //     setSelectedNews(false)
  //     setisPostcode(false)
  //   }
  // }

  //setSelectedEmail
  const handlisOffers = (event) => {
    setSelectedOffers(!isOffers)

    if (!isOffers == true || isCampaign == true || isNews == true) {
      setisPostcode(true)
    } else {
      setisPostcode(false)
    }
  }

  //setSelectedAPP
  const handleisCampaign = (event) => {
    setSelectedCampaign(!isCampaign)

    if (isOffers == true || !isCampaign == true || isNews == true) {
      setisPostcode(true)
    } else {
      setisPostcode(false)
    }
  }
  const handleisNews = (event) => {
    setSelectedNews(!isNews)

    if (isOffers == true || isCampaign == true || !isNews == true) {
      setisPostcode(true)
    } else {
      setisPostcode(false)
    }
  }

  if (window.screen.width < 900) {
  }

  const classes = useStyles()

  if (redirect) {
    return <Redirect to="home" />
  } else {
    return (
      <form onSubmit={onSubmit} noValidate>
        {spin && <Spinner2.Popup />}
        {isdialog && (
          <DialogBox
            selectedValue={response}
            open={isdialog}
            onClose={handleClose}
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} spacing={3}>
            <Typography
              variant="h5"
              className={classes.paper}
              style={{
                marginBottom: '20px',
              }}
            >
              <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                {currentlanguage.page_pref_details_title}
              </p>
              <p
                style={{
                  fontFamily: 'SubwayFootlong',
                  fontSize: 18,
                  margin: 0,
                  marginTop: 10,
                  minHeight: 50,
                }}
              >
                {currentlanguage.page_pref_hear_from_us}
              </p>
            </Typography>

            <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2">
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_pref_select_all}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch checked={selectedAll} onChange={handleAll} />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_pref_email}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch checked={isEmail} onChange={handlEmail} />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_pref_app_notif}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={<IOSSwitch checked={isAPP} onChange={handleApp} />}
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_pref_SMS}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={<IOSSwitch checked={isSms} onChange={handleSMS} />}
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            {isMobilenumber && (
              <div
                style={{
                  display: 'flex',
                  margin: 'auto',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'inline-block',
                    float: 'left',
                    width: '100%',
                  }}
                >
                  <Typography variant="body2" className={classes.text}>
                    <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                      {currentlanguage.page_pref_mobile_number}
                    </p>
                  </Typography>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Inputs.Mobileselector {...mobprefix} />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Inputs.Text
                        {...mobileNumber}
                        inputProps={{
                          maxLength: 10,
                          minLength: 9,
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
             {(isEmail || isSms || isAPP) && (
              <div
                style={{
                  display: 'flex',
                  margin: 'auto',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'inline-block',
                    float: 'left',
                    width: '100%',
                  }}
                >
                  <Typography variant="body2" className={classes.text}>
                    <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                      {currentlanguage.page_pref_postcode}
                    </p>
                    <Inputs.Text {...postcode} />
                  </Typography>
                </div>
              </div>
            )}
          </Grid>

          {/* <Grid item xs={12} sm={6} spacing={3}> */}
            {/* <Typography
              variant="h5"
              className={classes.paper}
              style={{
                marginBottom: '20px',
              }}
            >
              <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>{' '}
              <p
                style={{
                  fontFamily: 'SubwayFootlong',
                  margin: 0,
                  marginTop: 10,
                  fontSize: 18,
                  minHeight: 50,
                }}
              >
                {currentlanguage.page_pref_hear_about}
              </p>
            </Typography> */}
            {/* <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  alignSelf: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_pref_tell_everything}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch
                      checked={isEvrything}
                      onChange={handleEvrything}
                    />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div> */}

            {/* <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_pref_spec_offers}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch checked={isOffers} onChange={handlisOffers} />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div> */}

            {/* <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_pref_comp_prize_draws}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch
                      checked={isCampaign}
                      onChange={handleisCampaign}
                    />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div> */}

            {/* <div
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                    {currentlanguage.page_pref_sub_news}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch checked={isNews} onChange={handleisNews} />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div> */}

            {/* {isPostcode && (
              <div
                style={{
                  display: 'flex',
                  margin: 'auto',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'inline-block',
                    float: 'left',
                    width: '100%',
                  }}
                >
                  <Typography variant="body2" className={classes.text}>
                    <p style={{ fontFamily: 'SubwayFootlong', margin: 0 }}>
                      {currentlanguage.page_pref_postcode}
                    </p>
                    <Inputs.Text {...postcode} />
                  </Typography>
                </div>
              </div>
            )} */}
          {/* </Grid> */}
        </Grid>
        <Grid container item xs={12} sm={6} spacing={3}>
          {flOldUser == 1 && (
            <div
              style={{
                paddingTop: '20px',
                paddingBottom: '20px',
                marginLeft: '15px',
              }}
            >
              <FormControlLabel
                control={
                  <GreenCheckbox checked={tnc} onChange={handleChangeterms} />
                }
                label={
                  <Typography variant="body2" className={classes.text}>
                    <strong>
                      <span
                        style={{
                          fontFamily: 'SubwayFootlong',
                          margin: 0,
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            fontFamily: 'SubwayFootlong',
                            margin: 0,
                          }}
                        >
                          {currentlanguage.page_pref_agree_wording}
                        </p>
                        <a
                          style={{
                            textDecoration: 'underline',
                            fontFamily: 'SubwayFootlong',
                          }}
                          href="terms"
                        >
                          {' '}
                          {currentlanguage.page_pref_agree_link_1}{' '}
                        </a>{' '}
                        {currentlanguage.page_pref_agree_wording_2}{' '}
                        <a
                          style={{
                            textDecoration: 'underline',
                            fontFamily: 'SubwayFootlong',
                          }}
                          href="privacynotice"
                        >
                          {' '}
                          {currentlanguage.page_pref_agree_link_2}{' '}
                        </a>{' '}
                        {currentlanguage.page_pref_agree_wording_3}
                      </span>
                    </strong>
                  </Typography>
                }
              />
            </div>
          )}
        </Grid>

        <br />
        <Grid container item xs={12} sm={5} spacing={3}>
          <Grid item xs={12} sm={12}>
            <div>
              <Buttons.Normal InputProps={isAllow} {...submitButton}>
                {flOldUser
                  ? currentlanguage.page_pref_save_button_old_user
                  : currentlanguage.page_pref_save_button}
              </Buttons.Normal>
            </div>
          </Grid>
        </Grid>
      </form>
    )
  }
}

export default login
