import React, { useContext } from 'react'

import { GlobalContext } from '../../lib-atomic-context'

/* container */
import SubRewardsContainer from '../../app-module-subrewards-container/default'
/* container */

import Contents from '../contents/contents'

import ScrollToTop from '../../../scrollToTop'

const index = (props) => {
  return (
    <SubRewardsContainer {...props}>
      <ScrollToTop {...props} />
      <Contents {...props} />
    </SubRewardsContainer>
  )
}

export default index
