import React from 'react'

/* mui */
import { makeStyles } from '@material-ui/core/styles'
/* mui */

/* CONSTANTS */
const useStyles = makeStyles((theme) => ({
  wrapp: {
    textAlign: 'center',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
}))
/* CONSTANTS */

const index = (props) => {
  /* mui */
  const classes = useStyles()
  /* mui */

  return <div className={classes.wrapp}>{props.children}</div>
}

export default index
