import React from 'react' // useContext
import { Link } from 'react-router-dom'

import { Paper, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      padding: theme.spacing(10),
    },
  },
}))

const index = (props) => {
  const classes = useStyles()

  /* state */
  // const { bannerscontents } = useContext(GlobalContext)
  // const loginBannerBg =
  //   mediaPath + bannerscontents.state.data.masterbannerscontents[4].homeImage.url
  /* state */

  // if (CONFIG.routes.prefix === "reporting/404") {
  return (
    <div className={classes.root}>
      <Paper>
        <h1>Sorry</h1>{' '}
        <p>
          The page you're trying to view might not exist or you may need to
          login to access the page.
        </p>
        <p>
          <Link to="/home">Home</Link>
        </p>
      </Paper>
    </div>
  )
}

export default index
