import React, { useContext } from 'react'

import { GlobalContext } from '../../lib-atomic-context'

import {
  Box,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const index = (props) => {
  const { pages } = useContext(GlobalContext)
  const { masterpages } = pages.state.data
  // console.log(masterpages)
  const { header, subtitle, json } =
    masterpages && masterpages.length > 0 ? masterpages[0] : null
  return (
    <Container
      component={Box}
      my={5}
      maxWidth="md"
      disableGutters
      style={{ paddingLeft: 20, paddingRight: 20 }}
    >
      {/* <div
        dangerouslySetInnerHTML={{
          __html: `
          <div style='display:flex; border-style:solid; border-width:1px; border-color:red;'>
            <div style='border-style:solid; border-width:1px; border-color:red;'>
              <div>
              aa
              </div>
              <div>
              bb
              </div>
            </div>
            <div style='border-style:solid; border-width:1px; border-color:red;'>
            cc
            </div>
          </div>
          `,
        }}
      /> */}
    
      {header && header.length > 0 ? (
        <Typography
          color="primary"
          variant="h4"
          gutterBottom
          className="subway-footlong"
        >
          {header}
        </Typography>
      ) : null}
      {json && json.cookies && (
        <Box my={3}>
          {json.cookies.title && json.cookies.title.length > 0 ? (
            <Typography
              key={index}
              color="primary"
              variant="h5"
              gutterBottom
              className="subway-footlong"
            >
              {json.cookies.title}
            </Typography>
          ) : null}

          {json.cookies.subtitle && json.cookies.subtitle.length > 0 ? (
            <Typography
              style={{ marginTop: 10 }}
              gutterBottom
              className="subway-six-inch"
            >
              {
                <div
                  dangerouslySetInnerHTML={{ __html: json.cookies.subtitle }}
                />
              }
            </Typography>
          ) : null}

          <Box my={3}>
          {console.log(" json.cookies.title", json.cookies.contents)}
            {json.cookies.contents &&
              json.cookies.contents.length > 0 &&
              json.cookies.contents.map(({ q, a }, index) => (
                
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<AddIcon />}
                    aria-controls={`q${index}bh-content`}
                    id={`q${index}bh-header`}
                  >
                    <Typography
                      color="primary"
                      variant="h6"
                      className="subway-footlong"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: q }} />}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography
                      variant="body2"
                      style={{
                        'white-space': 'pre-wrap',
                        fontSize: 16,
                        wordBreak: 'break-word',
                      }}
                      className="subway-six-inch"
                    >
                      <div dangerouslySetInnerHTML={{ __html: a }} />
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </Box>
        </Box>
      )}
    </Container>
  )
}

export default index
