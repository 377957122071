import React, { useContext, useEffect, useState } from 'react'

/* package */
import { Grid, Box, Typography, makeStyles } from '@material-ui/core'
import { GlobalContext } from '../../lib-atomic-context'

import { WindowSize } from '../../lib-atomic-functions'
import { ModPublicWrapp } from '../../lib-atomic-design-section'
import { Inputs, Buttons, Invalid } from '../../lib-atomic'
import { FormHook, LocalStorage } from '../../lib-atomic-functions' // LocalStorage
/* package */
import { FetchContextApiV2 } from '../../lib-atomic-functions'
/* container */
import LostCardContainer from '../../app-module-lostcard-container/default'
/* container */
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
import CONFIG from '../config'
import DialogBox from '../../lib-atomic/dialog/dialogbox'
/* CONSTANTS */
const mediaPath = CONFIG.setup.assets.media
/* CONSTANTS */

const google_play = CONFIG.setup.assets.google_play
const app_store = CONFIG.setup.assets.app_store
const phone = CONFIG.setup.assets.phone
const LOGINOBJECTS = CONFIG.objects
//TODO: move somewhere else
const googlePlayLink = 'https://google.com'
const appStoreLink = 'https://google.com'

const TRANSFER = {
  url: CONFIG.endpoints.transfer.url,
  method: CONFIG.endpoints.transfer.method,
  headers: CONFIG.endpoints.transfer.headers,
  responseType: 'json',
}

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: 10,
  },
  downloadApp: {
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(1),
      order: 2,
    },
  },
  downloadAppRightPadded: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(0),
    order: 2,
    margin: 'auto',
  },
  orangeText: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
  },
  phone: {
    width: 130,
    position: 'absolute',
    top: -80,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  img: {
    maxWidth: '88%',
    height: 'auto',
    margin: '29px',
  },
}))
const Default = (props) => {
  const country = LocalStorage.getLS().country
  const currentlanguage = LocalStorage.getLS().translation.json.lostcard
  const { token } = LocalStorage.getLS().login
  // const currentlanguage = LocalStorage.getLS().translation.json.registrationpage
  const { width, height } = WindowSize()
  const classes = useStyles()
  const [spin, setSpin] = useState(false)
  const [response, setresponse] = React.useState()
  const [open, setOpen] = useState(false)
  const [isresponse, setisresponse] = useState(false)
  /* state */
  const { bannerscontents, transfer } = useContext(GlobalContext)
  // TODO: change to subsquad banner image
  const imgToDisplay = bannerscontents.state.data.masterbannerscontents.filter(
    (page) => {
      return page.pageName === 'LostCard'
    }
  )[0]
  /* state */

  const [fetchLogonObj] = FetchContextApiV2(TRANSFER)

  const fetchLogonExecute = async (body, newHeader) => {
    transfer.dispatch({
      type: transfer.actionTypes.FETCH_TRANSFER,
      transfer: await fetchLogonObj({ body, newHeader }, transfer),
    })
  }

  const logonResetExecute = () => {
    transfer.dispatch({
      type: transfer.actionTypes.RESET,
    })
  }
  useEffect(() => {
    if (transfer.state.data) {
      const { transferobj } = transfer.state.data
      const { outComeCode, flActive } = transferobj

      if (outComeCode == 0) {
        setSpin(false)
        setOpen(true)
        setisresponse(true)
        var message = {
          outComeCode: '0',
          title: '',
          outComeMessage: currentlanguage.psge_lost_transfer_popup_success,
        }
        setresponse(message)
        logonResetExecute()

        if (outComeCode != 0) {
          setSpin(false)
          setOpen(true)
          setisresponse(true)
          var message = {
            outComeCode: '0',
            title: '',
            outComeMessage: currentlanguage.psge_lost_popup_missingfield,
          }
          setresponse(message)
          logonResetExecute()
        }
        // }
      } else {
        setSpin(false)
        setOpen(true)
        setisresponse(true)
        var message = {
          outComeCode: '0',
          title: '',
          outComeMessage: currentlanguage.psge_lost_popup_error,
        }
        setresponse(message)
        logonResetExecute()
      }
    }
  }, [transfer.state])
  // useEffect(() => {
  //   console.log(errorMessagePrepared)
  //   notmatchLogin = { error: [errorMessagePrepared] }
  // }, [errorMessagePrepared])

  const handleClose = (value) => {
    setOpen(false)
    // setresponse(false)
  }

  const handleSubmit = async () => {
    setSpin(true)
    const body = {
      newCard: values.newCard,
      newCardPin: values.accessCode,
    }
    var newHeader = {
      Authorization: token,
    }

    // if (verified) {
    await fetchLogonExecute(body, newHeader)
    // }
  }

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleSubmit,
    LOGINOBJECTS
  )
  const userNewCard = {
    ...LOGINOBJECTS.newCard,
    error: error.newCard,
    value: values.newCard,
    onChange,
  }
  const userAccessCode = {
    ...LOGINOBJECTS.accessCode,
    error: error.accessCode,
    value: values.accessCode,
    onChange,
  }
  const submitButton = {
    fullWidth: true,
    onClick: handleSubmit.bind(this),
  }
  return (
    <div>
      {spin && <Spinner2.Popup />}
      {isresponse && (
        <DialogBox selectedValue={response} open={open} onClose={handleClose} />
      )}
      <ModPublicWrapp {...props} imgToDisplay={imgToDisplay}>
        <Grid
          // component={Box}
          container
          xs={12}
          sm={10}
          md={8}
          px={6}
          mx="auto"
          style={{ margin: 'auto', textAlign: 'left', marginTop: '30px' }}
          paddingTop={5}
          paddingBottom={12}
        >
          <Typography className="subway-six-inch" style={{ fontSize: 25 }}>
            {currentlanguage.page_lost_transfer_title1}
          </Typography>

          <Typography className="subway-six-inch" variant="body2" paragraph>
            {currentlanguage.page_lost_para1}
          </Typography>
          <Grid item xs={12} sm={6} spacing={3}>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '100%',
                }}
              >
                <Typography variant="body2" className="subway-footlong">
                  {currentlanguage.page_lost_transfer_newcard}
                  <Inputs.Text {...userNewCard} />
                </Typography>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '100%',
                }}
              >
                <Typography variant="body2" className="subway-footlong">
                  {currentlanguage.page_lost_transfer_accesscode}
                  <Inputs.Text {...userAccessCode} />
                </Typography>
              </div>
            </div>
            <br />
            <br />
            <Buttons.Normal fullWidth {...submitButton}>
              {currentlanguage.page_lost_transfer_submit_button}
            </Buttons.Normal>
          </Grid>
          <Grid item xs={12} sm={6} spacing={3}>
          {(country == 'gb' || country === 'ie') && (
                      <img
                        className={classes.img}
                        src="https://thq-stormborn-uat.s3-ap-southeast-2.amazonaws.com/assets/stormborn/cards/uk.png"
                      />
                    )}
                    {country != 'gb' && country !== 'ie' && (
                      <img
                        className={classes.img}
                        src="https://thq-stormborn-uat.s3-ap-southeast-2.amazonaws.com/assets/stormborn/cards/newcard+(1).png"
                      />
                    )}
          </Grid>
        </Grid>
      </ModPublicWrapp>

      <Grid
        style={{
          width: '100%',
          backgroundColor: '#f0f6f1',
          marginTop: 100,
          paddingTop: 20,
          paddingBottom: 60,
        }}
        // component={Box}
        // item
      >
        <Grid component={Box} container xs={12} sm={10} md={6} px={5} mx="auto">
          <Grid
            component={Box}
            item
            xs={12}
            sm={8}
            md={8}
            className={
              width < 960 ? classes.downloadAppRightPadded : classes.downloadApp
            }
          >
            <Typography
              variant="h5"
              gutterBottom
              className="subway-six-inch"
              style={{
                color: '#f15c22',
                alignSelf: 'center',
              }}
            >
              {currentlanguage.page_lost_footer_para1}
            </Typography>

            <Typography variant="body2" paragraph className="subway-six-inch">
              {currentlanguage.page_lost_footer_para2}
            </Typography>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: 'auto',
                width: '90%',
              }}
            >
              <Box alignItems="center" my={3}>
                <a
                  href={currentlanguage.page_lost_google_play_icon_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={currentlanguage.page_lost_google_play_icon}
                    alt="App Store"
                    width="100%"
                    maxWidth="150"
                  />
                </a>
              </Box>
              <div
                style={{
                  marginLeft:
                    width < 450
                      ? 10
                      : width < 600
                      ? 30
                      : width > 860 && width < 960
                      ? 30
                      : 10,
                  marginRight:
                    width < 450
                      ? 10
                      : width < 600
                      ? 30
                      : width > 860 && width < 960
                      ? 30
                      : 10,
                }}
              />
              <Box alignItems="center" my={3}>
                <a
                  href={currentlanguage.page_lost_apple_store_icon_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={currentlanguage.page_lost_apple_store_icon}
                    alt="App Store"
                    width="100%"
                    maxWidth="150"
                  />
                </a>
              </Box>
            </div>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            style={{ position: 'relative' }}
            component={Box}
            height={320}
          >
            <img src={phone} alt="phone" className={classes.phone} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Default
