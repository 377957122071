const isEmail = (data) => {
  const key = 'isEmail'
  const { value } = data
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const isTestOk = pattern.test(value) ? true : false
  const error = isTestOk ? false : key
  return error ? { error, data } : false
}

export default isEmail
