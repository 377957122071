export default {
  thor: {
    moduleCode: {
      production: 'SUB_RdkL7ruaFG',
      development: 'SUB_RdkL7ruaFG',
    },
    programId: null,
  },
  api: {
    tranxactor: {
      url: {
        // UAT TX Server
        production: 'https://subcarduat.tr4ns.com/tx-auth',
        development: 'https://subcarduat.tr4ns.com/tx-auth',
        // live TX Server
        // production: 'https://rewards.subway.co.uk/tx-auth',
        // development: 'https://rewards.subway.co.uk/tx-auth',
      },
    },
    cms: {
      url: {
        // local(non-beta)
        // production: 'https://strapi.tranxactor.com',
        // development: 'https://strapi.tranxactor.com',
        // live(beta)
        // production: 'https://strapi-sub.tranxactor.com',
        // development: 'https://strapi-sub.tranxactor.com',
        // old uat(beta)
        production: 'https://stormborn-strapi.tr4ns.com',
        development: 'https://stormborn-strapi.tr4ns.com',
        // production:
        //   'http://ec2-54-194-138-98.eu-west-1.compute.amazonaws.com:1337',
        // development:
        //   'http://ec2-54-194-138-98.eu-west-1.compute.amazonaws.com:1337',
      },
    },
    stormborn: {
      url: {
        // UAT TX Server
        production: 'https://subcarduat.tr4ns.com/tx-auth',
        development: 'https://subcarduat.tr4ns.com/tx-auth',
        mockapi: 'https://subcarduat.tr4ns.com/mobilegateway',
        api: 'https://subcarduat.tr4ns.com/tx-sub',
        // live TX Server
        // production: 'https://rewards.subway.co.uk/tx-auth',
        // development: 'https://rewards.subway.co.uk/tx-auth',
        // mockapi: 'https://rewards.subway.co.uk/mobilegateway',
        // api: 'https://rewards.subway.co.uk/tx-sub',
      },
    },
    campaign: {
      url: {
        // UAT rewards
        production: 'https://subcarduat.tr4ns.com/tx-sub/campaign/enrol',
        development: 'https://subcarduat.tr4ns.com/tx-sub/campaign/enrol',
        // Live rewards
        // production: 'https://rewards.subway.co.uk',
        // development: 'https://rewards.subway.co.uk',
      },
    },
    subcard: {
      url: {
        // UAT rewards
        production: 'https://subcarduat.tr4ns.com',
        development: 'https://subcarduat.tr4ns.com',
        // Live rewards
        // production: 'https://rewards.subway.co.uk',
        // development: 'https://rewards.subway.co.uk',
      },
    },
    onlineOrder: {
      url: {
        // UAT rewards
        production: 'https://altainesubwayexpressukwuat.azurewebsites.net',
        development: 'https://altainesubwayexpressukwuat.azurewebsites.net',
        // Live rewards
        // production: 'https://order.subway.co.uk',
        // development: 'https://order.subway.co.uk',
      },
    },
    subway: {
      url: {
        // UAT rewards
        production: 'https://subwayuat.tr4ns.com',
        development: 'https://subwayuat.tr4ns.com',
        // Live rewards
        // production: 'https://rewards.subway.co.uk',
        // development: 'https://rewards.subway.co.uk',
      },
    },
  },
}
