import React, { useState, useContext, useEffect, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Recaptcha from 'react-recaptcha'

/* CONSTANTS */
const useStyles = ({ formatt }) => {
  return makeStyles((theme) => ({
    alignment: {
      textAlign: formatt.alignment + '!important',
    },
  }))()
}

const Basic = ({ verified, verifyCallback, expiredCallback, alignment }) => {
  const formatt = { alignment: alignment ? alignment : 'center' }
  const classes = useStyles({ formatt })
  return (
    <Fragment>
      <br />
      <Recaptcha
        className={classes.alignment}
        sitekey="6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE"
        render="explicit"
        verifyCallback={verifyCallback}
        expiredCallback={expiredCallback}
      />
    </Fragment>
  )
}

export default Basic
