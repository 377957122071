import { sanitizeData, sanitizeExpire } from './Sanitize'

const init = {
  isLoading: false,
  isStatus: true,
  expireToken: false,
  data: false,
}

const actionTypes = {
  FETCH_TRANSACTION_LIST: 'FETCH_TRANSACTION_LIST',
  RESET: 'RESET',
}

const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}
const fetchTransactionList = async (state, transactionlist) => {
  state = {
    ...state,
    isStatus: transactionlist.status,
    expireToken: sanitizeExpire({
      status: transactionlist.status,
      data: transactionlist.data,
      name: 'transactionlistobj',
    }),
    data: sanitizeData({
      status: transactionlist.status,
      data: transactionlist.data,
      name: 'transactionlistobj',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSACTION_LIST:
      return fetchTransactionList(state, action.transactionlist)
    case actionTypes.RESET:
      return fetchReset(state)
    default:
      return state
  }
}

const transactionlist = {
  init,
  actionTypes,
  reducer,
}

export default transactionlist
