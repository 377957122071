import React, { useEffect, useState } from 'react'

/* modules */
import { LocalStorage } from '../../lib-atomic-functions'
import DialogBox from '../../lib-atomic/dialog/dialogbox'

const index = (props) => {
  const { visibleResend, setVisibleResend } = props
  const currentlanguage = LocalStorage.getLS().translation.json

  var message = {
    outComeCode: '0',
    title: '',
    outComeMessage: currentlanguage.activationpage.page_resend_popup_text,
  }

  const [visibleModalContent, setVisibleModalContent] = useState(false)

  useEffect(() => {
    setVisibleModalContent(visibleResend)
  }, [visibleResend])

  const popupModal = () => {
    const x = !visibleModalContent
    setVisibleModalContent(x)
    setVisibleResend(x)
  }

  return (
    <>
      {visibleModalContent && (
        <DialogBox
          selectedValue={message}
          open={visibleModalContent}
          onClose={popupModal}
        />
      )}
    </>
  )
}

export default index
