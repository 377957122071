import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_TOKEN: 'FETCH_TOKEN',
  CLEAR_DATA: 'CLEAR_DATA',
  RESET: 'RESET',
}

const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}

const fetchToken = async (state, token) => {
  state = {
    ...state,
    isStatus: token.status,
    expireToken: sanitizeExpire({
      status: token.status,
      data: token.data,
      name: 'tokenObject',
    }),
    data: sanitizeData({
      status: token.status,
      data: token.data,
      name: 'tokenObject',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TOKEN:
      return fetchToken(state, action.token)
    case actionTypes.CLEAR_DATA:
      return init
    case actionTypes.RESET:
      return fetchReset(state)
    default:
      return state
  }
}

const token = {
  init,
  actionTypes,
  reducer,
}

export default token
