import React, { useEffect, useContext } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2, LocalStorage } from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
/* module */

import CONFIG from '../config'
// import { init } from '../../lib-atomic-context/reducers/init'

/* CONSTANTS */
// const CONTENTENVIRONMENT = 'production' //CONFIG.environment;
const DEVTOKEN = CONFIG.setup.setup.dev_env_token
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
// const CMSMASTERBOXESCONFIG = {
//   url: CMSENDPOINTS.masterboxes.url,
//   method: CMSENDPOINTS.masterboxes.method,
//   headers: CMSENDPOINTS.masterboxes.headers,
//   responseType: 'json',
// }
const CMSMASTERSETUPCONFIG = {
  url: CMSENDPOINTS.mastersetup.url,
  method: CMSENDPOINTS.mastersetup.method,
  headers: CMSENDPOINTS.mastersetup.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTSCONFIG = {
  url: CMSENDPOINTS.mastercontents.url,
  method: CMSENDPOINTS.mastercontents.method,
  headers: CMSENDPOINTS.mastercontents.headers,
  responseType: 'json',
}
/* CONSTANTS */

const index = (props) => {
  /* state */
  const { setup, contents } = useContext(GlobalContext)
  /* --- setup */
  const [fetchMasterSetups] = FetchContextApiV2(CMSMASTERSETUPCONFIG)

  const fetchMasterSetupsExecute = async ({ query }) => {
    setup.dispatch({
      type: setup.actionTypes.FETCH_SETUPS,
      setup: await fetchMasterSetups({ query }, setup),
    })
  }

  const fetchCleanMasterSetupsExecute = async ({ contents, authToken }) => {
    setup.dispatch({
      type: setup.actionTypes.FETCH_CLEAN_SETUPS,
      authToken,
      contents,
      thePlatform: 'WebContents',
    })
  }
  /* --- setup */

  /* --- contents */
  const [fetchMasterContents] = FetchContextApiV2(CMSMASTERCONTENTSCONFIG)
  const fetchMasterContentsExecute = async ({ query }) => {
    contents.dispatch({
      type: contents.actionTypes.FETCH_CONTENTS,
      contents: await fetchMasterContents({ query }, contents),
    })
  }
  const fetchCarouselMasterContentsExecute = async ({ query, idInOrder }) => {
    contents.dispatch({
      type: contents.actionTypes.FETCH_CAROUSEL_CONTENTS,
      contents: await fetchMasterContents({ query }, contents),
      idInOrder: idInOrder,
    })
  }
  /* --- contents */

  /* state */

  useEffect(() => {
    // ================================
    // fetch the setup mastersetups for the first time
  }, [])

  useEffect(() => {
    // ================================
    // if master setup is available
    // fetch the corresponding master contents
    if (
      setup.state.data &&
      setup.state.data.mastersetup.length > 0 &&
      !contents.state.data
    ) {
      const parameters = setup.state.data.mastersetup[0].WebContents.map(
        (content) => {
          return content.mastercontents.id
        }
      )
      // console.log(parameters)
      const qs = QueryString.stringify({ id_in: parameters })
      fetchMasterContentsExecute({ query: '?' + qs })
    }

    // ================================%
    // master setups - default if no setup mastersetup content found
    if (setup.state.data && setup.state.data.mastersetup.length < 1) {
      props.history.push('/404')
    }
  }, [setup.state.data])

  useEffect(() => {
    if (
      setup.state.data &&
      contents.state.data &&
      contents.state.data.mastercontents
    ) {
      // const initial = contents.state.data.mastercontents
      const { mastercontents } = contents.state.data

      // ================================
      // check whenever there is a carousel content
      // fetch for carousel master contents
      const CarouselContentsList = mastercontents.filter(
        (content) => content.type === 'Carousel'
      )

      const carouselsItems = CarouselContentsList.map((carousel) => {
        return carousel.CarouselContents.map((items) => {
          return items.mastercontents.id
        })
      })[0]
      const isCarouselsItems =
        carouselsItems && carouselsItems.length > 0 ? true : false

      const isCarouselItemsExist = CarouselContentsList.some((carousellist) => {
        return carousellist.CarouselContents.some((cc) => {
          // console.log(carouselsItems.indexOf(cc.id))
          return carouselsItems.indexOf(cc.id) > -1
        })
      })

      // console.log(
      //   CarouselContentsList,
      //   carouselsItems,
      //   isCarouselsItems,
      //   isCarouselItemsExist
      // )

      /* if carousel is present and carousel items not yet updated  */
      if (isCarouselsItems && !isCarouselItemsExist) {
        const qs = QueryString.stringify({ id_in: carouselsItems })
        // console.log('QS', qs, carouselsItems)
        fetchCarouselMasterContentsExecute({
          query: '?' + qs,
          idInOrder: carouselsItems,
        })
      }
      // fetch for carousel master contents

      // ================================
      /* if no carousel is present OR carousel items already updated */
      const needsCleanSetup =
        setup.state.data.mastersetup.length === 1 &&
        setup.state.data.mastersetup[0].environment !== undefined
      if (needsCleanSetup && (!isCarouselsItems || isCarouselItemsExist)) {
        const authToken = LocalStorage.getLS().login
          ? LocalStorage.getLS().login.token
            ? true
            : false
          : false
        // console.log(setup)
        fetchCleanMasterSetupsExecute({
          contents: contents.state.data,
          authToken,
        })
      }
    }
  }, [contents.state.data])
  /* state */

  // const { mastersetup } = setup.state.data
  // const { mastercontents } = contents.state.data
  // const checkResult = () => {
  //   return !mastersetup ||
  //     !mastercontents ||
  //     mastersetup.length < 1 ||
  //     mastercontents.length < 1
  //     ? false
  //     : true
  // }

  // if (!checkResult() || setup.state.isLoading || contents.state.isLoading) {
  //   return <Spinner2.Popup />
  // }

  const isSetupReady =
    !setup.state.isLoading &&
    setup.state.data &&
    setup.state.data.mastersetup &&
    setup.state.isStatus
      ? true
      : false
  const isContentsReady =
    !contents.state.isLoading &&
    contents.state.data &&
    contents.state.data.mastercontents &&
    contents.state.isStatus
      ? true
      : false

  // if (!isSetupReady || !isContentsReady) return <Spinner2.Popup />
  if (!isSetupReady || !isContentsReady) return <></>
  return props.children
}

export default index
