import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
// import Recaptcha from "react-recaptcha";

/* mui */
import { makeStyles } from '@material-ui/core/styles'
/* mui */

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  WindowSize,
  WindowLayout,
  // WindowFont
} from '../../lib-atomic-functions'
import { ModPublicWrapp } from '../../lib-atomic-design-section'

/* packages */
import Questions from '../questions/contentLayout'
import BooleanTypeComponent from '../booleantype/contentLayout'

import CONFIG from '../config'

const map_placeholder = CONFIG.setup.assets.map_placeholder

/* modules */
// const NewsBox = lazy(() => import("./newsBox"));
/* modules */

/* constant */
const MEDIAPATH = CONFIG.setup.assets.media
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: 'auto',
    textAlign: 'center',
  },
  root: {
    borderRadius: 10,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    // backgroundColor: "#028940",
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 550,
    minWidth: 300,
    height: 400,
    padding: 1,
    background: 'linear-gradient(90deg,#f7c626 15%,#f68c2f 40%,#e5127d 85%)',
    margin: 'auto',
    alignSelf: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  content: {
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    background: 'white',
    height: '100%',
  },
  gridList: {
    width: 800,
  },
}))
/* constant */
const login = (props) => {
  const { params, url } = props.match
  const classes = useStyles()

  const { width, height } = WindowSize()
  const {
    layoutWidth,
    layoutHeight,
    // layoutCols,
    // getColsWidthHeight
  } = WindowLayout(width, height)
  // const { getFontSizes } = WindowFont();

  const { campaign } = useContext(GlobalContext)
  const { campaignobj } = campaign.state.data
  // console.log(campaignobj)
  const campaignFound =
    campaignobj && campaignobj.length > 0
      ? { ...campaignobj[0], placeholder: map_placeholder }
      : {
          mastercontents: {
            title: 'Sorry event is not available',
            description: '',
            homeImage: null,
            placeholder: map_placeholder,
          },
          questions: [],
          inCorrectLoad: true,
        }

  const imageItem = campaignFound.mastercontents.homeImage
    ? campaignFound.mastercontents.homeImage.url
    : campaignFound.mastercontents.placeholder
  const imgToDisplay = {
    type: 'campaign',
    pageName: 'campaign',
    homeImage: {
      url: imageItem,
    },
  }
  return (
    <div className={classes.content}>
      <ModPublicWrapp {...props} imgToDisplay={imgToDisplay}>
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 10,
            margin: 'auto',
            marginTop: '1%',
            width: '100%',
            height: '100%',
            backgroundColor: '#ffffff',
            minHeight: campaignFound.inCorrectLoad ? 300 : null,
          }}
        >
          <div
            style={{
              color: '#006230',
              fontSize: width < 500 ? 30 : 40,
              fontWeight: 800,
              lineHeight: 1,
              marginTop: campaignFound.inCorrectLoad ? 35 : 0,
              marginBottom: campaignFound.inCorrectLoad ? 30 : 0,
            }}
          >
            {campaignFound.mastercontents.title}
          </div>
          <div
            style={{
              paddingTop: width < 500 ? 0 : 30,
              color: '#111111',
              fontSize: 17,
              fontWeight: 500,
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: campaignFound.mastercontents.description,
              }}
            />
          </div>
          {!campaignFound.inCorrectLoad && (
            <div
              style={{
                paddingTop: 10,
                paddingBottom: 50,
                color: '#111111',
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              {campaignFound.campaignType === 'ChoiceType' ? (
                campaignFound.questions.length !== 0 && (
                  <Questions
                    {...{
                      ...props,
                      ...{
                        masterCampaignId: campaignFound.id,
                        questions: campaignFound.questions,
                        tandc: campaignFound.mastertandc,
                        jsonProps: campaignFound.json,
                      },
                    }}
                  />
                )
              ) : (
                <div>
                  <BooleanTypeComponent
                    {...{
                      ...props,
                      ...{
                        masterCampaignId: campaignFound.id,
                        questions: campaignFound.questions,
                        tandc: campaignFound.mastertandc,
                        campaignCode: campaignFound.campaignCode,
                        jsonProps: campaignFound.json,
                      },
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </ModPublicWrapp>
    </div>
  )
}

export default login
