import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FORGOT_PASSWORD_RESET: 'FORGOT_PASSWORD_RESET',
  RESET: 'RESET',
}

const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}
const fetchForgotPasswordReset = async (state, forgotpasswordreset) => {
  state = {
    ...state,
    isStatus: forgotpasswordreset.status,
    expireToken: sanitizeExpire({
      status: forgotpasswordreset.status,
      data: forgotpasswordreset.data,
      name: 'forgotpasswordresetobj',
    }),
    data: sanitizeData({
      status: forgotpasswordreset.status,
      data: forgotpasswordreset.data,
      name: 'forgotpasswordresetobj',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_RESET:
      return fetchForgotPasswordReset(state, action.forgotpasswordreset)
    case actionTypes.RESET:
      return fetchReset(state)
    default:
      return state
  }
}

const forgotpasswordreset = {
  init,
  actionTypes,
  reducer,
}

export default forgotpasswordreset
