const isNotThreeConsecutive = (data) => {
  const key = 'isNotThreeConsecutive'
  const { value } = data
  const pattern = /(.)\1\1/
  const isTestOk = pattern.test(value) ? false : true
  const error = isTestOk ? false : key
  return error ? { error, data } : false
}

export default isNotThreeConsecutive
