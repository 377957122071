import React from 'react'

import appConf from '../../components/app-conf'
import Page404 from '../../components/app-404/default'

const Themes = appConf[process.env.REACT_APP_CLIENT].themes
const Main = (props) => {
  return (
    <Themes>
      <Page404 {...props} />
    </Themes>
  )
}

export default Main
