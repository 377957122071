import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Verification'
const server = null
const service = null
// const options = {   preference_options: [     { name: "Preference Type 1",
// value: "1" },     { name: "Preference Type 2", value: "2" }   ] };
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        endpoints: {
          verification: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: null,
              moduleCode: setup.setup.thor.moduleCode[environment],
            },
            doc: '{{host}}/tx-sub/registration/verification/00NOBF',
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.subcard.url[environment] +
              '/tx-sub/registration/verification/',
            method: 'PUT',
          },
          verificationresend: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: null,
              moduleCode: setup.setup.thor.moduleCode[environment],
            },
            doc: '{{host}}/tx-sub/registration/resendCode',
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.subcard.url[environment] +
              '/tx-sub/registration/resendCode',
            method: 'POST',
          },
          onlineOrder: {
            headers: {
              'Content-Type': 'application/json',
            },
            doc: '{{host}}/tx-sub/registration/verification/00NOBF',
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.onlineOrder.url[environment] +
              '/Api/App/StartOrderPage2',
            method: 'POST',
          },
        },
      },
      objects: {
        code: {
          name: 'code',
          initial: '',
          placeholder: '',
          helperText: false,
          rules: ['isNumberAndLetter'],
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'Verification',
    prefix: '/verification',
  },
  setup,
}

export default config
