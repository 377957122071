import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Typography,
} from '@material-ui/core'

import { LocalStorage } from '../../../components/lib-atomic-functions'

import { GlobalContext } from '../../../components/lib-atomic-context'
import appConf from '../../../components/app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const DOMAINPREFIX = setup.setup.domain_prefix

const CountrySelectorDialog = ({ open, onClose, history, match }) => {
  console.log(LocalStorage.getLS().login, ' LOGIN TOKEN ... ')
  console.log(LocalStorage.getLS().flOldUser, ' OLD USER FLAG ... ')

  const { countrylanguage } = useContext(GlobalContext)
  const { country, lang } = LocalStorage.getLS()
  const currentlanguage = LocalStorage.getLS().translation.json
  const [myCountry, setMyCountry] = useState(country)
  const [myLanguage, setMyLanguage] = useState(lang)

  const handleClose = () => {
    onClose()
  }

  const handleClick = () => {
    const isLoggedIn = LocalStorage.getLS()
      ? LocalStorage.getLS().login
        ? LocalStorage.getLS().login.token
          ? true
          : false
        : false
      : false
    handleClose()
    const { mastercountrylanguage } = countrylanguage.state.data
    const countrylist = mastercountrylanguage.map((i) => i.mastercountries.abbr)
    // window.location.hostname
    const newhostname = window.location.hostname
      .split('.')
      .map((partial) => {
        const containDash = partial.indexOf('-') > -1
        if (containDash) {
          const newPartial = partial
            .split('-')
            .map((innerPartial) => {
              const matchingCountryFound = countrylist.filter(
                (i) =>
                  i === innerPartial || (i === 'gb' && innerPartial === 'uk')
              )
              if (matchingCountryFound.length > 0) {
                return country === 'gb' ? 'uk' : country
              } else {
                return innerPartial
              }
            })
            .join('-')
          return newPartial
        } else {
          const matchingCountryFound = countrylist.filter(
            (i) => i === partial || (i === 'gb' && partial === 'uk')
          )

          if (matchingCountryFound.length > 0) {
            return country === 'gb' ? 'uk' : country
          } else {
            return partial
          }
        }
      })
      .join('.')
    // console.log(window.location.hostname)
    const httpsFound = newhostname.indexOf('https') > -1
    if (httpsFound) {
      window.location = `${newhostname}/home`
    } else {
      window.location = `http://${newhostname}/home`
    }
  }

  const handleListItemClick = ({ myCountry, myLanguage }) => {
    setMyCountry(myCountry)
    setMyLanguage(myLanguage)
    LocalStorage.addLS('country', myCountry)
    LocalStorage.addLS('lang', myLanguage)
  }

  if (!countrylanguage.state.data) return null
  const { mastercountrylanguage } = countrylanguage.state.data
  // console.log('XXXXXXXXXXXXX loading the menu country selector...')

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="select-country"
      open={open}
    >
      <DialogTitle style={{ textAlign: 'center', margin: 0 }}>
        <p className="subway-footlong" style={{ margin: 0 }}>
          {currentlanguage.countrylanguage.page_country_title}
        </p>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          variant="body2"
          color="primaryText"
          className="subway-six-inch"
        >
          <p className="subway-six-inch" style={{ margin: 0 }}>
            {currentlanguage.countrylanguage.page_country_title_2}
          </p>
        </DialogContentText>
        <List>
          {mastercountrylanguage.map(
            ({ id, mastercountries, masterlanguages }) => (
              <ListItem
                button
                selected={myCountry === mastercountries.abbr.toLowerCase()}
                onClick={() =>
                  handleListItemClick({
                    myCountry: mastercountries.abbr.toLowerCase(),
                    myLanguage: masterlanguages.abbr.toLowerCase(),
                  })
                }
                key={id}
              >
                <ListItemIcon>
                  <img
                    style={{ height: 31, width: 50, marginRight: 20 }}
                    // src={`https://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/${mastercountries.abbr}.svg`}
                    src={`https://flagcdn.com/${mastercountries.abbr}.svg`}
                    // src={require(`./flags/${mastercountries.abbr}.svg`)}
                    alt={mastercountries.abbr}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className="subway-six-inch">
                      {mastercountries.translation &&
                      mastercountries.translation.length > 0
                        ? mastercountries.translation
                        : mastercountries.name}
                    </Typography>
                  }
                />
              </ListItem>
            )
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          fullWidth={true}
          onClick={handleClick}
          variant="contained"
          color="primary"
          className="subway-footlong"
        >
          {currentlanguage.countrylanguage.page_country_button}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withRouter(CountrySelectorDialog)
