import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import ListItem from '@material-ui/core/ListItem'

const useStyles = makeStyles({
  labelContainer: {
    backgroundColor: 'transparent',
    color: 'black',
    height: 35,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 5,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      borderBottomWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderColor: 'red',
      borderStyle: 'solid',
      transitionProperty: 'width',
      transition: 'border-color 0.5s ease',
    },
  },
  label: {
    backgroundColor: 'transparent',
    fontWeight: 600,
    color: 'black',
    borderColor: 'transparent',
    fontSize: 17,
    '&:hover': {
      color: 'black',
      textDecoration: 'none',
    },
  },
})
const App = props => {
  const classes = useStyles()
  // const url = props.match ? props.match.url : "";
  const { label, prefix } = props
  return (
    <div>
      <ListItem
        className={classes.labelContainer}
        onClick={() => {
          props.props.history.push(prefix)
        }}
      >
        <Link className={classes.label} to={prefix}>
          {label.toUpperCase()}
        </Link>
      </ListItem>
    </div>
  )
}

export default App
