import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

/* package */
import { ModMainWrapp } from '../../lib-atomic-design-section'
// import { ModulePaddedWrapperLg } from "../../lib-atomic-design-section";
/* package */

/* container */
import IouContainer from '../../app-module-iou-container/default'
/* container */

/* form */
import IouForm from '../form/iou'
/* form */
import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'

/* constant */
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 100,
    paddingBottom: 100,
  },
  gridList: {
    width: 800,
  },
}))
/* constant */

const index = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  const classes = useStyles()

  useEffect(() => {
    // GArecordPageView(cookies)
  }, [])
  return (
    <IouContainer {...props}>
      {/* <ModMainWrapp> */}
      <div className={classes.root}>
        <IouForm {...props} />
      </div>
      {/* </ModMainWrapp> */}
    </IouContainer>
  )
}

export default index
