import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]

const config = {
  routes: {
    label: 'Subrewards',
    prefix: '/subrewards',
  },
  setup,
}

export default config
