import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Activation'
const server = null
const service = null
// const options = {
//   preference_options: [
//     { name: "Preference Type 1", value: "1" },
//     { name: "Preference Type 2", value: "2" }
//   ]
// };
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        endpoints: {
          activation: {
            headers: {
              'Content-Type': 'application/json',
              Authorization: null,
              moduleCode: setup.setup.thor.moduleCode[environment],
            },
            doc: '{{host}}/tx-sub/registration/resendCode',
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.subcard.url[environment] +
              '/tx-sub/registration/resendCode',
            method: 'POST',
          },
        },
      },
      objects: {
        email: {
          name: 'email',
          initial: '',
          placeholder: '',
          helperText: false,
          rules: ['isNotNull'],
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'Activation',
    prefix: '/activation/resend',
  },
  setup,
}

export default config
