import React, { useState, useEffect, useContext, Fragment } from 'react' // useEffect, useContext
import PropTypes from 'prop-types'
// import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import Recaptcha from 'react-recaptcha'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
/* packages */
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
//switches//
/* container */
import { GlobalContext } from '../../lib-atomic-context'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import MaskedInput from 'react-text-mask'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import { Inputs, Buttons, Invalid } from '../../lib-atomic'
import { FormHook } from '../../lib-atomic-functions' // LocalStorage
import Checkbox from '@material-ui/core/Checkbox'
import { green } from '@material-ui/core/colors'
import CONFIG from '../config'
import { LocalStorage } from '../../lib-atomic-functions'
import Spinner2 from '../../app-module-cms-section-page-spinner/default'

/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.account.objects

const UNVERIFIEDRESPONSE = CONFIG.application.account.responses.unverified
const ENDPOINTS = CONFIG.application.account.api.endpoints
const UPDATE = {
  url: ENDPOINTS.register.url,
  method: ENDPOINTS.register.method,
  headers: ENDPOINTS.register.headers,
  responseType: 'json',
}
/* CONSTANTS */
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    paddingTop: '10px',
    paddingBottom: '10px',
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

const FormControlLabelDetails = withStyles((theme) => ({
  root: {
    left: '100px',
    // position: "relative"
  },
  paper: {
    // padding: theme.spacing(3),
    textAlign: 'center',
    color: 'black',
    marginBottom: '50px',
  },
  inline: {
    display: 'flex',
  },
}))(FormControlLabel)

const useStyles = makeStyles((theme) => ({
  margin: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  paper: {
    // padding: theme.spacing(3),
    color: 'black',
    marginBottom: '50px',
  },
}))
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#008938',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        margin: classes.margin,
      }}
      {...props}
    />
  )
})

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      className="dob-input"
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholder="DD/MM/YYYY" // showMask
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}
const login = (props) => {
  const { token } = LocalStorage.getLS().login

  const { update } = useContext(GlobalContext)

  const { mobile, postCode, mobileCountryCode } = props

  LOGINOBJECTS.mobile.initial = mobile
  LOGINOBJECTS.postcode.initial = postCode
  LOGINOBJECTS.mobprefix.initial = mobileCountryCode

  var eValue = false
  var sValue = false
  var aValue = false
  var allValue = false
  var everyValue = false
  var oValuie = false
  var pValue = false
  var nValue = false
  var ism = false
  var ispost = false
  var gdprValue = props.gdpr

  var typeOffers = gdprValue.typeOffer
  var typeCompetition = gdprValue.typeCompetitions
  var typeNew = gdprValue.typeNews
  var channelEmails = gdprValue.channelEmail
  var channelApps = gdprValue.channelApp
  var channelSMSs = gdprValue.channelSMS

  if (typeOffers == 1) {
    oValuie = true
    ispost = true
  }
  if (typeCompetition == 1) {
    oValuie = true
  }
  if (typeNew == 1) {
    nValue = true
  }
  if (channelEmails == 1) {
    eValue = true
  }
  if (channelApps == 1) {
    aValue = true
  }
  if (channelSMSs == 1) {
    sValue = true
    ism = true
  }
  // const currentlanguage = LocalStorage.getLS().translation[0].json const {
  // token } = LocalStorage.getLS().login;
  /* state */
  const [loaded, setLoaded] = useState(false)
  const [verified, setVerified] = useState(false)
  const [visiblePW, setVisiblePW] = useState(false)
  const [visiblePWC, setVisiblePWC] = useState(false) // setVisiblePWC
  const [response, setresponse] = React.useState()
  const [open, setOpen] = useState(false)
  const [selectedAll, setSelectedAll] = React.useState(allValue)
  const [isAPP, setSelectedAPP] = React.useState(aValue)
  const [isEmail, setSelectedEmail] = React.useState(eValue)
  const [isSms, setSelectedSMS] = React.useState(sValue)

  const [isEvrything, setSelectedEvrything] = React.useState(everyValue)
  const [isOffers, setSelectedOffers] = React.useState(oValuie)
  const [isCampaign, setSelectedCampaign] = React.useState(pValue)
  const [isNews, setSelectedNews] = React.useState(nValue)
  const [isMobilenumber, setisMobilenumber] = useState(ism)
  const [isPostcode, setisPostcode] = useState(ispost)
  const [redirect, setredirect] = useState(false)
  const [tnc, setSelectedtnc] = React.useState(false)
  const [isAllow, setSelectedisAllow] = React.useState(true)
  const callback = () => {
    console.log('loaded recaptcha...')
  }

  // specifying verify callback function
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true)
      setLoaded(true)
    }
  }
  const [updateData] = FetchContextApiV2(UPDATE)
  const updateExecute = async ({ body, newHeader }) => {
    update.dispatch({
      type: update.actionTypes.FETCH_UPDATE,
      update: await updateData(
        {
          body,
          newHeader,
        },
        update
      ),
    })
  }

  useEffect(() => {
    if (update.state.data) {
      const { updateObject } = update.state.data

      if (updateObject.response) {
        setresponse(updateObject.response.data)
      } else {
        setresponse(updateObject)

        // props.history.push('/nz/en/home')
      }
      // if (registerObject.response.data.outComeCode != 0) {
      // console.log("outComeCode",registerObject.response.data.outComeCode);
      // console.log("outComeMessage",registerObject.response.data.outComeMessage);
      // console.log("outComeUserMessage",registerObject.response.data.outComeUserMess
      // a ge); setresponse(registerObject.response.data); setOpen(true); }
    }
  }, [update.state])

  const handleSubmit = async () => {
    var App = 0
    var Sms = 0
    var Email = 0
    var Offers = 0
    var Campaign = 0
    var News = 0

    if (selectedAll == true) {
      App = 1
      Sms = 1
      Email = 1
    }
    if (isEvrything == true) {
      Offers = 1
      Campaign = 1
      News = 1
    }
    if (isEmail == true) {
      Email = 1
    }
    if (isSms == true) {
      Sms = 1
    }
    if (isAPP == true) {
      App = 1
    }

    if (isOffers == true) {
      Offers = 1
    }
    if (isCampaign == true) {
      Campaign = 1
    }
    if (isNews == true) {
      News = 1
    }

    if (values.mobile != '' && values.postcode != '') {
      setVerified(true)
    }
    setLoaded(true)

    var body = {
      gdpr: {
        channelApp: App,
        channelSMS: Sms,
        channelEmail: Email,
        typeOffer: Offers,
        typeCompetitions: Campaign,
        typeNews: News,
      },
      mobile: values.mobile,
      postcode: values.postcode,
      email: props.email,
      firstName: props.firstName,
      lastName: props.lastName,
    }

    var newHeader = {
      Authorization: token,
    }

    await updateExecute({ body, newHeader })

    // if (verified) { // await accountLoginMethod({ body: JSON.stringify(values),
    //     props.gdprvalues(body)
    // }

    // if (verified) {   // await accountLoginMethod({ body: JSON.stringify(values),
    // query: false });   // LocalStorage.addLS("login", {   //
    // ...accountLoginContext.data.json,   //   username: values.username   // });
    // // if (accountLoginContext.data.invalidToken) return false;   //
    // props.history.push("/u/login-logout-redirect"); }
  }

  const backStep = () => {
    var bakValue = 1
    props.back(bakValue)
  }

  const { values, onChange, onPick, onSubmit, error } = FormHook.useFormHook(
    handleSubmit,
    LOGINOBJECTS
  )
  const mobileNumber = {
    ...LOGINOBJECTS.mobile,
    error: error.mobile,
    value: values.mobile,
    onChange,
  }
  const mobprefix = {
    ...LOGINOBJECTS.mobprefix,
    error: error.mobprefix,
    value: values.mobprefix,
    onChange,
  }
  const postcode = {
    ...LOGINOBJECTS.postcode,
    error: error.postcode,
    value: values.postcode,

    onChange,
  }

  const submitButton = {
    fullWidth: true,
    onClick: handleSubmit.bind(this),
  }
  const back = {
    fullWidth: true,
    onClick: backStep.bind(this),
  }
  // const failLogin = { error: [LOGINFAILRESPONSE] }
  const verifyLogin = {
    error: [UNVERIFIEDRESPONSE],
  }

  const handleChangeterms = (event) => {
    setSelectedtnc(!tnc)
    setSelectedisAllow(!isAllow)
  }
  //SelectALL
  const handleAll = (event) => {
    setSelectedAll(!selectedAll)
    if (!selectedAll == true) {
      setSelectedEmail(true)
      setSelectedAPP(true)
      setSelectedSMS(true)
      setisMobilenumber(true)
    }
    if (!selectedAll == false) {
      setSelectedEmail(false)
      setSelectedAPP(false)
      setSelectedSMS(false)
      setisMobilenumber(false)
    }
  }
  //setSelectedEmail
  const handlEmail = (event) => {
    setSelectedEmail(!isEmail)
  }
  //setSelectedSMS
  const handleSMS = (event) => {
    setSelectedSMS(!isSms)
    setisMobilenumber(!isMobilenumber)
  }
  //setSelectedAPP
  const handleApp = (event) => {
    setSelectedAPP(!isAPP)
  }

  //isEvrything
  const handleEvrything = (event) => {
    setSelectedEvrything(!isEvrything)
    if (!isEvrything == true) {
      setSelectedOffers(true)
      setSelectedCampaign(true)
      setSelectedNews(true)
      setisPostcode(true)
    }
    if (!isEvrything == false) {
      setSelectedOffers(false)
      setSelectedCampaign(false)
      setSelectedNews(false)
      setisPostcode(false)
    }
  }
  //setSelectedEmail
  const handlisOffers = (event) => {
    setSelectedOffers(!isOffers)
    setisPostcode(!isPostcode)
  }

  //setSelectedAPP
  const handleisCampaign = (event) => {
    setSelectedCampaign(!isCampaign)
  }
  const handleisNews = (event) => {
    setSelectedNews(!isNews)
  }

  if (window.screen.width < 900) {
  }

  const classes = useStyles()

  if (redirect) {
    return <Redirect to="home" />
  } else {
    return (
      <form onSubmit={onSubmit} noValidate>
        {open && <Spinner2.Popup />}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} spacing={3}>
            <Typography variant="h5" className={classes.paper}>
              <b>Contact Preferences</b>
              <p
                style={{
                  fontSize: 'small',
                  fontWeight: 'bold',
                }}
              >
                How would you like to hear from us?
              </p>
            </Typography>

            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                }}
              >
                <Typography variant="body2">
                  <strong>Select All:</strong>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch checked={selectedAll} onChange={handleAll} />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <strong>Email:</strong>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch checked={isEmail} onChange={handlEmail} />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <strong>SMS:</strong>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                }}
              >
                <FormControlLabelDetails
                  control={<IOSSwitch checked={isSms} onChange={handleSMS} />}
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <strong>App notifications:</strong>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                }}
              >
                <FormControlLabelDetails
                  control={<IOSSwitch checked={isAPP} onChange={handleApp} />}
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>
            {isMobilenumber && (
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    display: 'inline-block',
                    float: 'left',
                    width: '100%',
                  }}
                >
                  <Typography variant="body2" className={classes.text}>
                    <strong>Mobile Number</strong>
                  </Typography>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Inputs.Select {...mobprefix} />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Inputs.Text {...mobileNumber} />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={6} spacing={3}>
            <Typography variant="h5" className={classes.paper}>
              <b>What would you like to hear about</b>
              <p
                style={{
                  fontSize: 'small',
                  fontWeight: 'bold',
                  color: 'white',
                }}
              >
                How would you like to hear from us?
              </p>
            </Typography>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <strong>Tell me everything:</strong>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch
                      checked={isEvrything}
                      onChange={handleEvrything}
                    />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <strong>Special offers and promotion:</strong>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch checked={isOffers} onChange={handlisOffers} />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <strong>Competitions and Campaigns:</strong>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch
                      checked={isCampaign}
                      onChange={handleisCampaign}
                    />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  float: 'left',
                  width: '50%',
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  <strong>Subway news:</strong>
                </Typography>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  width: '50%',
                  textAlign: 'end',
                }}
              >
                <FormControlLabelDetails
                  control={
                    <IOSSwitch checked={isNews} onChange={handleisNews} />
                  }
                  id="panel1d-header"
                  aria-controls="panel1d-content"
                />
              </div>
            </div>

            {isPostcode && (
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    display: 'inline-block',
                    float: 'left',
                    width: '100%',
                  }}
                >
                  <Typography variant="body2" className={classes.text}>
                    <strong>Postcode</strong>
                    <Inputs.Text {...postcode} />
                  </Typography>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6} spacing={3}>
          <div
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              marginLeft: '15px',
            }}
          >
            <FormControlLabel
              control={
                <GreenCheckbox checked={tnc} onChange={handleChangeterms} />
              }
              label={
                <span>
                  Yes, I agree to the Subway&reg; app{' '}
                  <a style={{ textDecoration: 'underline' }} href="terms">
                    Terms and Condition
                  </a>{' '}
                  and{' '}
                  <a
                    style={{ textDecoration: 'underline' }}
                    href="privacynotice"
                  >
                    Privacy Notice
                  </a>
                </span>
              }
            />
          </div>
        </Grid>
        <Grid container item xs={12} sm={6} spacing={3}>
          <Grid item xs={12} sm={6}>
            <div>
              <Buttons.Back {...back}>Back</Buttons.Back>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div>
              <Buttons.Normal {...submitButton} disabled={isAllow}>
                save changes
              </Buttons.Normal>
            </div>
          </Grid>
        </Grid>
      </form>
    )
  }
}

export default login
