import React, { useContext } from 'react'

import { GlobalContext } from '../../lib-atomic-context'

import {
  Box,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const index = (props) => {
  const { pages } = useContext(GlobalContext)
  const { masterpages } = pages.state.data
  const { header, subtitle, json } =
    masterpages && masterpages.length > 0 ? masterpages[0] : null
  return (
    <Container
      component={Box}
      my={5}
      maxWidth="md"
      disableGutters
      style={{ paddingLeft: 20, paddingRight: 20 }}
    >
      {header && header.length > 0 ? (
        <Typography
          color="primary"
          variant="h4"
          gutterBottom
          className="subway-footlong"
        >
          {header}
        </Typography>
      ) : null}
      {json && json.faq && (
        <Box my={3}>
          {json.faq.title && json.faq.title.length > 0 ? (
            <Typography
              key={index}
              color="primary"
              variant="h5"
              gutterBottom
              className="subway-footlong"
              style={{ paddingBottom: 10 }}
            >
              {json.faq.title}
            </Typography>
          ) : null}

          {/* anchors */}
          <ol>
            {json && json.faq.subtitle && json.faq.subtitle.length > 0 ? (
              <Typography
                style={{ marginTop: 10 }}
                gutterBottom
                className="subway-footlong"
              >
                <li>
                  <a href="#faq">{json.faq.subtitle}</a>
                </li>
              </Typography>
            ) : null}

            {json &&
            json.subsquadfaq &&
            json.subsquadfaq.subtitle &&
            json.subsquadfaq.subtitle.length > 0 ? (
              <Typography
                style={{ marginTop: 10 }}
                gutterBottom
                className="subway-footlong"
              >
                <li>
                  <a href="#subsquadfaq">{json.subsquadfaq.subtitle}</a>
                </li>
              </Typography>
            ) : null}

            {json &&
            json.inviteafriend &&
            json.inviteafriend.subtitle &&
            json.inviteafriend.subtitle.length > 0 ? (
              <Typography
                style={{ marginTop: 10 }}
                gutterBottom
                className="subway-footlong"
              >
                <li>
                  <a href="#inviteafriend">{json.inviteafriend.subtitle}</a>
                </li>
              </Typography>
            ) : null}

            {json &&
            json.sponsorshipcode &&
            json.sponsorshipcode.subtitle &&
            json.sponsorshipcode.subtitle.length > 0 ? (
              <Typography
                style={{ marginTop: 10 }}
                gutterBottom
                className="subway-footlong"
              >
                <li>
                  <a href="#sponsorshipcode">{json.sponsorshipcode.subtitle}</a>
                </li>
              </Typography>
            ) : null}

            {json &&
            json.mondaysublime &&
            json.mondaysublime.subtitle &&
            json.mondaysublime.subtitle.length > 0 ? (
              <Typography
                style={{ marginTop: 10 }}
                gutterBottom
                className="subway-footlong"
              >
                <li>
                  <a href="#mondaysublime">{json.mondaysublime.subtitle}</a>
                </li>
              </Typography>
            ) : null}
            {json &&
            json.newFAQpanel &&
            json.newFAQpanel.subtitle &&
            json.newFAQpanel.subtitle.length > 0 ? (
              <Typography
                style={{ marginTop: 10 }}
                gutterBottom
                className="subway-footlong"
              >
                <li>
                  <a href="#newFAQpanel">{json.newFAQpanel.subtitle}</a>
                </li>
              </Typography>
            ) : null}
            {json &&
            json.newFAQpanel2 &&
            json.newFAQpanel2.subtitle &&
            json.newFAQpanel2.subtitle.length > 0 ? (
              <Typography
                style={{ marginTop: 10 }}
                gutterBottom
                className="subway-footlong"
              >
                <li>
                  <a href="#newFAQpanel2">{json.newFAQpanel2.subtitle}</a>
                </li>
              </Typography>
            ) : null}
          </ol>
          <a
            name="faq"
            style={{
              display: 'block',
              position: 'relative',
              top: -60,
              visibility: 'hidden',
            }}
          />
          <Box my={3} name="faqanchor">
            {json &&
              json.faq.contents &&
              json.faq.contents.length > 0 &&
              json.faq.contents.map(({ q, a }, index) => (
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<AddIcon />}
                    aria-controls={`q${index}bh-content`}
                    id={`q${index}bh-header`}
                  >
                    <Typography
                      color="primary"
                      variant="h6"
                      className="subway-footlong"
                      style={{ wordBreak: 'break-word' }}
                    >
                      {<div dangerouslySetInnerHTML={{ __html: q }} />}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography
                      variant="body2"
                      style={{ 'white-space': 'pre-wrap', fontSize: 16 }}
                      className="subway-six-inch"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: a }} />}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </Box>
        </Box>
      )}
      <a
        name="subsquadfaq"
        style={{
          display: 'block',
          position: 'relative',
          top: -60,
          visibility: 'hidden',
        }}
      />
      <div style={{ height: 20 }} />
      {json && json.subsquadfaq && (
        <Box my={3}>
          {json.subsquadfaq.title && json.subsquadfaq.title.length > 0 ? (
            <Typography
              key={index}
              color="primary"
              variant="h5"
              gutterBottom
              className="subway-footlong"
            >
              {json.subsquadfaq.title}
            </Typography>
          ) : null}
          {/* {json.subsquadfaq.subtitle && json.subsquadfaq.subtitle.length > 0 ? (
            <Typography
              style={{ marginTop: 10 }}
              gutterBottom
              className="subway-six-inch"
            >
            </Typography>
          ) : null} */}

          <Box my={3}>
            {json.subsquadfaq.contents &&
              json.subsquadfaq.contents.length > 0 &&
              json.subsquadfaq.contents.map(({ q, a }, index) => (
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<AddIcon />}
                    aria-controls={`q${index}bh-content`}
                    id={`q${index}bh-header`}
                  >
                    <Typography
                      color="primary"
                      variant="h6"
                      className="subway-footlong"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: q }} />}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography
                      variant="body2"
                      style={{ 'white-space': 'pre-wrap', fontSize: 16 }}
                      className="subway-six-inch"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: a }} />}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </Box>
        </Box>
      )}
      <a
        name="inviteafriend"
        style={{
          display: 'block',
          position: 'relative',
          top: -60,
          visibility: 'hidden',
        }}
      />
      <div style={{ height: 20 }} />
      {json && json.inviteafriend && (
        <Box my={3}>
          {json.inviteafriend.title && json.inviteafriend.title.length > 0 ? (
            <Typography
              key={index}
              color="primary"
              variant="h5"
              gutterBottom
              className="subway-footlong"
            >
              {json.inviteafriend.title}
            </Typography>
          ) : null}
          <Box my={3}>
            {json.inviteafriend.contents &&
              json.inviteafriend.contents.length > 0 &&
              json.inviteafriend.contents.map(({ q, a }, index) => (
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<AddIcon />}
                    aria-controls={`q${index}bh-content`}
                    id={`q${index}bh-header`}
                  >
                    <Typography
                      color="primary"
                      variant="h6"
                      className="subway-footlong"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: q }} />}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography
                      variant="body2"
                      style={{ 'white-space': 'pre-wrap', fontSize: 16 }}
                      className="subway-six-inch"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: a }} />}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </Box>
        </Box>
      )}
      <a
        name="sponsorshipcode"
        style={{
          display: 'block',
          position: 'relative',
          top: -60,
          visibility: 'hidden',
        }}
      />
      <div style={{ height: 20 }} />
      {json && json.sponsorshipcode && (
        <Box my={3}>
          {json.sponsorshipcode.title &&
          json.sponsorshipcode.title.length > 0 ? (
            <Typography
              key={index}
              color="primary"
              variant="h5"
              gutterBottom
              className="subway-footlong"
            >
              {json.sponsorshipcode.title}
            </Typography>
          ) : null}
          <Box my={3}>
            {json.sponsorshipcode.contents &&
              json.sponsorshipcode.contents.length > 0 &&
              json.sponsorshipcode.contents.map(({ q, a }, index) => (
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<AddIcon />}
                    aria-controls={`q${index}bh-content`}
                    id={`q${index}bh-header`}
                  >
                    <Typography
                      color="primary"
                      variant="h6"
                      className="subway-footlong"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: q }} />}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography
                      variant="body2"
                      style={{ 'white-space': 'pre-wrap', fontSize: 16 }}
                      className="subway-six-inch"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: a }} />}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </Box>
        </Box>
      )}
      <a
        name="mondaysublime"
        style={{
          display: 'block',
          position: 'relative',
          top: -60,
          visibility: 'hidden',
        }}
      />
      <div style={{ height: 20 }} />
      {json && json.mondaysublime && (
        <Box my={3}>
          {json.mondaysublime.title && json.mondaysublime.title.length > 0 ? (
            <Typography
              key={index}
              color="primary"
              variant="h5"
              gutterBottom
              className="subway-footlong"
            >
              {json.mondaysublime.title}
            </Typography>
          ) : null}
          <Box my={3}>
            {json.mondaysublime.contents &&
              json.mondaysublime.contents.length > 0 &&
              json.mondaysublime.contents.map(({ q, a }, index) => (
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<AddIcon />}
                    aria-controls={`q${index}bh-content`}
                    id={`q${index}bh-header`}
                  >
                    <Typography
                      color="primary"
                      variant="h6"
                      className="subway-footlong"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: q }} />}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography
                      variant="body2"
                      style={{ 'white-space': 'pre-wrap', fontSize: 16 }}
                      className="subway-six-inch"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: a }} />}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </Box>
        </Box>
      )}
      <a
        name="mondaysublime"
        style={{
          display: 'block',
          position: 'relative',
          top: -60,
          visibility: 'hidden',
        }}
      />
      <div style={{ height: 20 }} />
      {json && json.newFAQpanel && (
        <Box my={3}>
          {json.newFAQpanel.title && json.newFAQpanel.title.length > 0 ? (
            <Typography
              key={index}
              color="primary"
              variant="h5"
              gutterBottom
              className="subway-footlong"
            >
              {json.newFAQpanel.title}
            </Typography>
          ) : null}
          <Box my={3}>
            {json.newFAQpanel.contents &&
              json.newFAQpanel.contents.length > 0 &&
              json.newFAQpanel.contents.map(({ q, a }, index) => (
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<AddIcon />}
                    aria-controls={`q${index}bh-content`}
                    id={`q${index}bh-header`}
                  >
                    <Typography
                      color="primary"
                      variant="h6"
                      className="subway-footlong"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: q }} />}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography
                      variant="body2"
                      style={{ 'white-space': 'pre-wrap', fontSize: 16 }}
                      className="subway-six-inch"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: a }} />}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </Box>
        </Box>
      )}
      <a
        name="newFAQpanel"
        style={{
          display: 'block',
          position: 'relative',
          top: -60,
          visibility: 'hidden',
        }}
      />
      <div style={{ height: 20 }} />
      {json && json.newFAQpanel2 && (
        <Box my={3}>
          {json.newFAQpanel2.title && json.newFAQpanel2.title.length > 0 ? (
            <Typography
              key={index}
              color="primary"
              variant="h5"
              gutterBottom
              className="subway-footlong"
            >
              {json.newFAQpanel2.title}
            </Typography>
          ) : null}
          <Box my={3}>
            {json.newFAQpanel2.contents &&
              json.newFAQpanel2.contents.length > 0 &&
              json.newFAQpanel2.contents.map(({ q, a }, index) => (
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<AddIcon />}
                    aria-controls={`q${index}bh-content`}
                    id={`q${index}bh-header`}
                  >
                    <Typography
                      color="primary"
                      variant="h6"
                      className="subway-footlong"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: q }} />}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography
                      variant="body2"
                      style={{ 'white-space': 'pre-wrap', fontSize: 16 }}
                      className="subway-six-inch"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: a }} />}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </Box>
        </Box>
      )}
      <a
        name="newFAQpanel2"
        style={{
          display: 'block',
          position: 'relative',
          top: -60,
          visibility: 'hidden',
        }}
      />
    </Container>
  )
}

export default index
