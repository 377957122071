// import React from 'react';

import Routes from './routes'
// import Links from './links'
import Config from './config'

export default {
  Routes,
  // Links,
  Config,
}
