import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

/*
: properties
children
size
fullWidth
*/
const yellowTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#5dade2',
      main: '#5dade2',
      dark: '#0d7fcb',
      contrastText: '#000000',
    },
  },
})

const normal = (props) => {
  return (
    <ThemeProvider theme={yellowTheme}>
      <Button
        {...props}
        className="subway-footlong"
        style={{
          // border: '2px solid #feba12',
          // maxHeight: 50,
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 5,
          paddingBottom: 5,
          margin: 'auto',
          justifyContent: 'center',
        }}
        variant="contained"
        color="primary"
        type="submit"
      >
        {props.children}
      </Button>
    </ThemeProvider>
  )
}

export default normal
