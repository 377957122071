import React, { Fragment } from 'react'

import Radio from '@material-ui/core/Radio'
/* atoms */
import { Invalid } from '../feedbacks'
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

const radio = props => {
  // console.log('radio', props)
  const { name, value, label } = props
  if (value === '1') {
    var isChecked = true
  } else {
    isChecked = false
  }

  return (
    <Fragment>
      <span>{label} </span>
      {isChecked && <Radio color="primary" {...props} />}
      {isChecked === false && (
        <Radio color="secondary" value={name} {...props} InputProps={{ style: {   backgroundColor: '#d3d3d352' } }} />
      )}

      <Invalid {...props} />
    </Fragment>
  )
}

export default radio
