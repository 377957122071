import React, { useContext } from 'react'

import { GlobalContext } from '../../lib-atomic-context'

/* container */
import MyPointsContainer from '../../app-module-my-points-container/default'
/* container */

import PageContent from '../contents/index'

import { ModPublicWrapp } from '../../lib-atomic-design-section'

import ScrollToTop from '../../../scrollToTop'

const index = (props) => {
  // const { bannerscontents } = useContext(GlobalContext)
  // const imgToDisplay = bannerscontents.state.data.masterbannerscontents.filter(
  //   (page) => {
  //     return page.pageName === 'Dashboard'
  //   }
  // )[0]
  return (
    <MyPointsContainer {...props}>
      {/* <ModPublicWrapp {...props} imgToDisplay={imgToDisplay}> */}
      <ScrollToTop {...props} />
      <PageContent {...props} />
      {/* </ModPublicWrapp> */}
    </MyPointsContainer>
  )
}

export default index
