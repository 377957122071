import React from 'react'

/* container */
import PrivacyNoticeContainer from '../../app-module-privacynotice-container/default'
/* container */

import Contents from '../contents/contents'

import ScrollToTop from '../../../scrollToTop'

const index = (props) => {
  return (
    <PrivacyNoticeContainer {...props}>
      <ScrollToTop {...props} />
      <Contents {...props} />
    </PrivacyNoticeContainer>
  )
}

export default index
