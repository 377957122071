import React from 'react'

/* mui */
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
    wrapp: {
        position: 'relative',
        paddingTop: '2px!important',
        paddingBottom:'2px !important'
    }
}))
/* CONSTANTS */

const xl = props => {
    /* mui */
    const classes = useStyles()
    /* mui */

    return (
        <Grid className={classes.wrapp} item xs={5}  md={5}>
            {props.children}
        </Grid>
    )
}

export default xl
