import { init } from './init'
import { sanitizeData, sanitizeExpire } from './Sanitize'

const actionTypes = {
  FETCH_SEND_VERIFICATION: 'FETCH_SEND_VERIFICATION',
  RESET: 'RESET',
}

const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  }
  return state
}

const fetchVerification = async (state, sendverification) => {
  state = {
    ...state,
    isStatus: sendverification.status,
    expireToken: sanitizeExpire({
      status: sendverification.status,
      data: sendverification.data,
      name: 'sendverificationobj',
    }),
    data: sanitizeData({
      status: sendverification.status,
      data: sendverification.data,
      name: 'sendverificationobj',
    }),
  }
  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SEND_VERIFICATION:
      return fetchVerification(state, action.sendverification)
    case actionTypes.RESET:
      return fetchReset(state)
    default:
      return state
  }
}

const sendverification = {
  init,
  actionTypes,
  reducer,
}

export default sendverification
