const isPassword = data => {

  let value = data.value
  const key = 'isPassword'
  const minLimit = 6
 
 
  const isTestOk =  value.length === minLimit || value.length > minLimit ? true : false
  const error = isTestOk ? false : key
  return error ? { error, data } : false
}

export default isPassword
