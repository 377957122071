import React, { useContext, Fragment } from 'react'

import { GlobalContext } from '../../lib-atomic-context'

import {
  Box,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const index = (props) => {
  const { pages } = useContext(GlobalContext)
  const { masterpages } = pages.state.data
  const { header, subtitle, json } =
    masterpages && masterpages.length > 0 ? masterpages[0] : null
  return (
    <Container
      component={Box}
      my={5}
      maxWidth="sm"
      disableGutters
      style={{ paddingLeft: 20, paddingRight: 20 }}
    >
      {header && header.length > 0 ? (
        <Typography
          color="primary"
          variant="h4"
          gutterBottom
          className="subway-footlong"
        >
          {header}
        </Typography>
      ) : null}
      {json && json.terms && (
        <Box my={3}>
          {json.terms.title && json.terms.title.length > 0 ? (
            <Typography
              key={index}
              color="primary"
              variant="h5"
              gutterBottom
              className="subway-footlong"
            >
              {json.terms.title}
            </Typography>
          ) : null}

          {json.terms.subtitle && json.terms.subtitle.length > 0 ? (
            <Typography
              style={{ marginTop: 20 }}
              gutterBottom
              className="subway-six-inch"
            >
              {
                <div
                  dangerouslySetInnerHTML={{ __html: json.terms.subtitle }}
                />
              }
            </Typography>
          ) : null}

          <Box my={3}>
            {json.terms.contents &&
              json.terms.contents.length > 0 &&
              json.terms.contents.map(({ q, a }, index) => (
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<AddIcon />}
                    aria-controls={`q${index}bh-content`}
                    id={`q${index}bh-header`}
                  >
                    <Typography
                      color="primary"
                      variant="h6"
                      className="subway-footlong"
                    >
                      {q}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography
                      variant="body2"
                      style={{ 'white-space': 'pre-wrap', fontSize: 16 }}
                      className="subway-six-inch"
                    >
                      {<div dangerouslySetInnerHTML={{ __html: a }} />}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </Box>
        </Box>
      )}
    </Container>
  )
}

export default index
