import React from 'react'

import Button from '@material-ui/core/Button'

/*
: properties
children
onClick
size
fullWidth
*/

const text = (props) => {
  return (
    <Button
      {...props}
      style={{
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
        margin: 'auto',
        justifyContent: 'center',
        color: '#008938',
      }}
      className="subway-footlong"
      color="primary"
    >
      {props.children}
    </Button>
  )
}

export default text
