import React from 'react'

/* mui */
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}))
/* CONSTANTS */

const paper = props => {
  /* mui */
  const classes = useStyles()
  /* mui */

  return <Paper className={classes.paper}>{props.children}</Paper>
}

export default paper
