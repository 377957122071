import React, { createContext } from 'react'

import useAsyncReducer from './useAsyncReducer'

import {
  versionReducer,
  countrylanguageReducer,
  errorResponseReducer,
  translationReducer,
  tokenReducer,
  activationReducer,
  verificationReducer,
  bannersReducer,
  bannersContentsReducer,
  boxesReducer,
  setupReducer,
  setupAllReducer,
  contentsReducer,
  newsContentsReducer,
  promotionsContentsReducer,
  memberReducer,
  memberReducer2,
  rewardsContentsReducer,
  validationReducer,
  registerReducer,
  transactionListReducer,
  transactionDetailReducer,
  drawerReducer,
  authReducer,
  geoLocationReducer,
  resetPasswordTokenCheckReducer,
  forgotPasswordReducer,
  forgotPasswordResetReducer,
  processIouReducer,
  sendVerificationReducer,
  logonReducer,
  cmsJwtReducer,
  privatePromotionContentsReducer,
  privateOfferListReducer,
  passwordReducer,
  updateReducer,
  transfercardReducer,
  freezeReducer,
  pagesReducer,
  spinthewheelReducer,
  gdprupdateReducer,
  countryReducer,
  unsubscribeReducer,
  onlineOrderReducer,
  campaignReducer,
  campaignResponseReducer,
  campaignResponseReducer2,
} from './reducers'

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  // const [masterSetup, setMasterSetup] = useState([]); const [masterContent,
  // setMasterContent] = useState([]);

  /* version reducer */
  const [versionState, versionDispatch] = useAsyncReducer(
    versionReducer.reducer,
    {
      ...versionReducer.init,
    }
  )
  //onlineOrderReducer
  const [orderState, orderDispatch] = useAsyncReducer(
    onlineOrderReducer.reducer,
    {
      ...onlineOrderReducer.init,
    }
  )
  // memberReducer
  const [memberState, memberDispatch] = useAsyncReducer(memberReducer.reducer, {
    ...memberReducer.init,
  })

  // memberReducer
  const [memberState2, memberDispatch2] = useAsyncReducer(
    memberReducer2.reducer,
    {
      ...memberReducer2.init,
    }
  )

  //validationREducer
  const [validState, validDispatch] = useAsyncReducer(
    validationReducer.reducer,
    {
      ...validationReducer.init,
    }
  )
  //freezecard
  const [freezeState, freezeDispatch] = useAsyncReducer(freezeReducer.reducer, {
    ...freezeReducer.init,
  })
  const [gdprupdateState, gdprupdateDispatch] = useAsyncReducer(
    gdprupdateReducer.reducer,
    {
      ...gdprupdateReducer.init,
    }
  )
  // passwordValidation
  const [passwordState, passwordDispatch] = useAsyncReducer(
    passwordReducer.reducer,
    {
      ...passwordReducer.init,
    }
  )
  // transferCard
  const [transferState, transferDispatch] = useAsyncReducer(
    transfercardReducer.reducer,
    {
      ...transfercardReducer.init,
    }
  )

  /* error response reducer */
  const [errorResponseState, errorResponseDispatch] = useAsyncReducer(
    errorResponseReducer.reducer,
    {
      ...errorResponseReducer.init,
    }
  )
  //REgistration
  const [registerState, registerDispatch] = useAsyncReducer(
    registerReducer.reducer,
    {
      ...registerReducer.init,
    }
  )

  // update
  const [updateState, updateDispatch] = useAsyncReducer(updateReducer.reducer, {
    ...updateReducer.init,
  })

  /* country and language reducer */
  const [countrylanguageState, countrylanguageDispatch] = useAsyncReducer(
    countrylanguageReducer.reducer,
    {
      ...countrylanguageReducer.init,
    }
  )
  /* translation reducer */
  const [translationState, translationDispatch] = useAsyncReducer(
    translationReducer.reducer,
    {
      ...translationReducer.init,
    }
  )
  /* banners reducer */
  const [bannersState, bannersDispatch] = useAsyncReducer(
    bannersReducer.reducer,
    {
      ...bannersReducer.init,
    }
  )
  /* banners contents reducer */
  const [bannersContentsState, bannersContentsDispatch] = useAsyncReducer(
    bannersContentsReducer.reducer,
    {
      ...bannersContentsReducer.init,
    }
  )
  /* token reducer */
  const [tokenState, tokenDispatch] = useAsyncReducer(tokenReducer.reducer, {
    ...tokenReducer.init,
  })

  /* activation reducer */
  const [activationState, activationDispatch] = useAsyncReducer(
    activationReducer.reducer,
    {
      ...activationReducer.init,
    }
  )

  /* verification reducer */
  const [verificationState, verificationDispatch] = useAsyncReducer(
    verificationReducer.reducer,
    {
      ...verificationReducer.init,
    }
  )

  /* boxes reducer */
  const [boxesState, boxesDispatch] = useAsyncReducer(boxesReducer.reducer, {
    ...boxesReducer.init,
  })
  /* setup reducer */
  const [setupState, setupDispatch] = useAsyncReducer(setupReducer.reducer, {
    ...setupReducer.init,
  })
  /* setup all reducer */
  const [setupAllState, setupAllDispatch] = useAsyncReducer(
    setupAllReducer.reducer,
    {
      ...setupAllReducer.init,
    }
  )
  /* contents reducer */
  const [contentsState, contentsDispatch] = useAsyncReducer(
    contentsReducer.reducer,
    {
      ...contentsReducer.init,
    }
  )
  /* news contents reducer */
  const [newsContentsState, newsContentsDispatch] = useAsyncReducer(
    newsContentsReducer.reducer,
    {
      ...newsContentsReducer.init,
    }
  )
  /* promotions contents reducer */
  const [promotionsContentsState, promotionsContentsDispatch] = useAsyncReducer(
    promotionsContentsReducer.reducer,
    {
      ...promotionsContentsReducer.init,
    }
  )
  /* rewards contents reducer */
  const [rewardsContentsState, rewardsContentsDispatch] = useAsyncReducer(
    rewardsContentsReducer.reducer,
    {
      ...rewardsContentsReducer.init,
    }
  )
  /* transaction list reducer */
  const [transactionListState, transactionListDispatch] = useAsyncReducer(
    transactionListReducer.reducer,
    {
      ...transactionListReducer.init,
    }
  )
  /* transaction detail reducer */
  const [transactionDetailState, transactionDetailDispatch] = useAsyncReducer(
    transactionDetailReducer.reducer,
    {
      ...transactionDetailReducer.init,
    }
  )
  /* drawer reducer */
  const [drawerState, drawerDispatch] = useAsyncReducer(drawerReducer.reducer, {
    ...drawerReducer.init,
  })

  /* auth reducer */
  const [authState, authDispatch] = useAsyncReducer(authReducer.reducer, {
    ...authReducer.init,
  })

  /* geolocation reducer */
  const [geoLocationState, geoLocationDispatch] = useAsyncReducer(
    geoLocationReducer.reducer,
    {
      ...geoLocationReducer.init,
    }
  )

  /* reset token validity reducer */
  const [resetPasswordTokenCheckState, resetPasswordTokenCheckDispatch] =
    useAsyncReducer(resetPasswordTokenCheckReducer.reducer, {
      ...resetPasswordTokenCheckReducer.init,
    })

  /* forgot password reducer */
  const [forgotPasswordState, forgotPasswordDispatch] = useAsyncReducer(
    forgotPasswordReducer.reducer,
    {
      ...forgotPasswordReducer.init,
    }
  )

  /* forgot password update reducer */
  const [forgotPasswordResetState, forgotPasswordResetDispatch] =
    useAsyncReducer(forgotPasswordResetReducer.reducer, {
      ...forgotPasswordResetReducer.init,
    })

  /* process iou reducer */
  const [processIouState, processIouDispatch] = useAsyncReducer(
    processIouReducer.reducer,
    {
      ...processIouReducer.init,
    }
  )

  /* resend verification reducer */
  const [sendVerificationState, sendVerificationDispatch] = useAsyncReducer(
    sendVerificationReducer.reducer,
    {
      ...sendVerificationReducer.init,
    }
  )

  /* logon reducer */
  const [logonState, logonDispatch] = useAsyncReducer(logonReducer.reducer, {
    ...logonReducer.init,
  })

  /* cms jwt reducer */
  const [cmsJwtState, cmsJwtDispatch] = useAsyncReducer(cmsJwtReducer.reducer, {
    ...cmsJwtReducer.init,
  })

  /* private promotion reducer */
  const [privatePromotionContentsState, privatePromotionContentsDispatch] =
    useAsyncReducer(privatePromotionContentsReducer.reducer, {
      ...privatePromotionContentsReducer.init,
    })

  /* private offer list reducer */
  const [privateOfferListState, privateOfferListDispatch] = useAsyncReducer(
    privateOfferListReducer.reducer,
    {
      ...privateOfferListReducer.init,
    }
  )
  /* master pages reducer */
  const [pagesState, pagesDispatch] = useAsyncReducer(pagesReducer.reducer, {
    ...pagesReducer.init,
  })

  /* Spin the wheel reducer */
  const [spinthewheelState, spinthewheelDispatch] = useAsyncReducer(spinthewheelReducer.reducer, {
    ...spinthewheelReducer.init,
  })

  /* master country reducer */
  const [countryState, countryDispatch] = useAsyncReducer(
    countryReducer.reducer,
    {
      ...countryReducer.init,
    }
  )

  /* unsubscribe reducer */
  const [unsubsribeState, unsubsribeDispatch] = useAsyncReducer(
    unsubscribeReducer.reducer,
    {
      ...unsubscribeReducer.init,
    }
  )

  /* campaign reducer */
  const [campaignState, campaignDispatch] = useAsyncReducer(
    campaignReducer.reducer,
    {
      ...campaignReducer.init,
    }
  )

  /* campaign response reducer */
  const [campaignResponseState, campaignResponseDispatch] = useAsyncReducer(
    campaignResponseReducer.reducer,
    {
      ...campaignResponseReducer.init,
    }
  )
  /* campaign response reducer */
  const [campaignResponseState2, campaignResponseDispatch2] = useAsyncReducer(
    campaignResponseReducer2.reducer,
    {
      ...campaignResponseReducer2.init,
    }
  )

  const store = {
    version: {
      state: versionState,
      dispatch: versionDispatch,
      actionTypes: versionReducer.actionTypes,
    },
    countrylanguage: {
      state: countrylanguageState,
      dispatch: countrylanguageDispatch,
      actionTypes: countrylanguageReducer.actionTypes,
    },
    order: {
      state: orderState,
      dispatch: orderDispatch,
      actionTypes: onlineOrderReducer.actionTypes,
    },
    errorresponse: {
      state: errorResponseState,
      dispatch: errorResponseDispatch,
      actionTypes: errorResponseReducer.actionTypes,
    },
    translation: {
      state: translationState,
      dispatch: translationDispatch,
      actionTypes: translationReducer.actionTypes,
    },
    banners: {
      state: bannersState,
      dispatch: bannersDispatch,
      actionTypes: bannersReducer.actionTypes,
    },
    bannerscontents: {
      state: bannersContentsState,
      dispatch: bannersContentsDispatch,
      actionTypes: bannersContentsReducer.actionTypes,
    },
    freeze: {
      state: freezeState,
      dispatch: freezeDispatch,
      actionTypes: freezeReducer.actionTypes,
    },
    gdprupdate: {
      state: gdprupdateState,
      dispatch: gdprupdateDispatch,
      actionTypes: gdprupdateReducer.actionTypes,
    },

    transfer: {
      state: transferState,
      dispatch: transferDispatch,
      actionTypes: transfercardReducer.actionTypes,
    },

    token: {
      state: tokenState,
      dispatch: tokenDispatch,
      actionTypes: tokenReducer.actionTypes,
    },
    activation: {
      state: activationState,
      dispatch: activationDispatch,
      actionTypes: activationReducer.actionTypes,
    },
    verification: {
      state: verificationState,
      dispatch: verificationDispatch,
      actionTypes: verificationReducer.actionTypes,
    },
    boxes: {
      state: boxesState,
      dispatch: boxesDispatch,
      actionTypes: boxesReducer.actionTypes,
    },
    setup: {
      state: setupState,
      dispatch: setupDispatch,
      actionTypes: setupReducer.actionTypes,
    },
    setupAll: {
      state: setupAllState,
      dispatch: setupAllDispatch,
      actionTypes: setupAllReducer.actionTypes,
    },
    contents: {
      state: contentsState,
      dispatch: contentsDispatch,
      actionTypes: contentsReducer.actionTypes,
    },
    newsContents: {
      state: newsContentsState,
      dispatch: newsContentsDispatch,
      actionTypes: newsContentsReducer.actionTypes,
    },
    promotionsContents: {
      state: promotionsContentsState,
      dispatch: promotionsContentsDispatch,
      actionTypes: promotionsContentsReducer.actionTypes,
    },
    rewardsContents: {
      state: rewardsContentsState,
      dispatch: rewardsContentsDispatch,
      actionTypes: rewardsContentsReducer.actionTypes,
    },
    member: {
      state: memberState,
      dispatch: memberDispatch,
      actionTypes: memberReducer.actionTypes,
    },
    member2: {
      state: memberState2,
      dispatch: memberDispatch2,
      actionTypes: memberReducer2.actionTypes,
    },
    valid: {
      state: validState,
      dispatch: validDispatch,
      actionTypes: validationReducer.actionTypes,
    },
    password: {
      state: passwordState,
      dispatch: passwordDispatch,
      actionTypes: passwordReducer.actionTypes,
    },
    register: {
      state: registerState,
      dispatch: registerDispatch,
      actionTypes: registerReducer.actionTypes,
    },
    update: {
      state: updateState,
      dispatch: updateDispatch,
      actionTypes: updateReducer.actionTypes,
    },
    transactionlist: {
      state: transactionListState,
      dispatch: transactionListDispatch,
      actionTypes: transactionListReducer.actionTypes,
    },
    transactiondetail: {
      state: transactionDetailState,
      dispatch: transactionDetailDispatch,
      actionTypes: transactionDetailReducer.actionTypes,
    },
    drawer: {
      state: drawerState,
      dispatch: drawerDispatch,
      actionTypes: drawerReducer.actionTypes,
    },
    auth: {
      state: authState,
      dispatch: authDispatch,
      actionTypes: authReducer.actionTypes,
    },
    geoLocation: {
      state: geoLocationState,
      dispatch: geoLocationDispatch,
      actionTypes: geoLocationReducer.actionTypes,
    },
    validresettoken: {
      state: resetPasswordTokenCheckState,
      dispatch: resetPasswordTokenCheckDispatch,
      actionTypes: resetPasswordTokenCheckReducer.actionTypes,
    },
    forgotpassword: {
      state: forgotPasswordState,
      dispatch: forgotPasswordDispatch,
      actionTypes: forgotPasswordReducer.actionTypes,
    },
    forgotpasswordreset: {
      state: forgotPasswordResetState,
      dispatch: forgotPasswordResetDispatch,
      actionTypes: forgotPasswordResetReducer.actionTypes,
    },
    processiou: {
      state: processIouState,
      dispatch: processIouDispatch,
      actionTypes: processIouReducer.actionTypes,
    },
    sendverification: {
      state: sendVerificationState,
      dispatch: sendVerificationDispatch,
      actionTypes: sendVerificationReducer.actionTypes,
    },
    logon: {
      state: logonState,
      dispatch: logonDispatch,
      actionTypes: logonReducer.actionTypes,
    },
    cmsjwt: {
      state: cmsJwtState,
      dispatch: cmsJwtDispatch,
      actionTypes: cmsJwtReducer.actionTypes,
    },
    masterprivatecontents: {
      state: privatePromotionContentsState,
      dispatch: privatePromotionContentsDispatch,
      actionTypes: privatePromotionContentsReducer.actionTypes,
    },
    privateofferlist: {
      state: privateOfferListState,
      dispatch: privateOfferListDispatch,
      actionTypes: privateOfferListReducer.actionTypes,
    },
    pages: {
      state: pagesState,
      dispatch: pagesDispatch,
      actionTypes: pagesReducer.actionTypes,
    },
    spinthewheel: {
      state: spinthewheelState,
      dispatch: spinthewheelDispatch,
      actionTypes: spinthewheelReducer.actionTypes,
    },
    cmscountry: {
      state: countryState,
      dispatch: countryDispatch,
      actionTypes: countryReducer.actionTypes,
    },
    unsubscribe: {
      state: unsubsribeState,
      dispatch: unsubsribeDispatch,
      actionTypes: unsubscribeReducer.actionTypes,
    },
    campaign: {
      state: campaignState,
      dispatch: campaignDispatch,
      actionTypes: campaignReducer.actionTypes,
    },
    campaignresponse: {
      state: campaignResponseState,
      dispatch: campaignResponseDispatch,
      actionTypes: campaignResponseReducer.actionTypes,
    },
    campaignresponse2: {
      state: campaignResponseState2,
      dispatch: campaignResponseDispatch2,
      actionTypes: campaignResponseReducer2.actionTypes,
    },
  }

  return (
    <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>
  )
}
