const INITIAL_STATE = {
  country_code: undefined,
  language: undefined,
}

const actionTypes = {
  SET_COUNTRY: 'SET_COUNTRY',
  SET_LANGUAGE: 'SET_LANGUAGE',
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_COUNTRY:
      return {
        ...state,
        country_code: action.payload,
      }
    case actionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      }
    default:
      return state
  }
}

const geoLocation = {
  init: INITIAL_STATE,
  actionTypes,
  reducer,
}

export default geoLocation
