import Text from './text'
import Max4Digits from './max-4-degit-number'
import Password from './password'
import Textarea from './textarea'
import Suggest from './suggest'
import Checkbox from './checkbox'
import Select from './select'
import Date from './date'
import Radio from './radio'
import DateModal from './date-modal'
import Mobileselector from './mobile-selector'


export default {
  Radio,
  Text,
  Max4Digits,
  Password,
  Textarea,
  Suggest,
  Checkbox,
  Select,
  Date,
  DateModal,
  Mobileselector
}
