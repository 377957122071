const environment = process.env.NODE_ENV
const project = 'Profile'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  routes: {
    label: 'Profile',
    prefix: '/profile',
  },
}

export default config
