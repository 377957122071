import React, { Fragment } from 'react'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

/* function */
import inputErrorCleaner from './_input_error_cleaner'
/* function */

/* atoms */
import { Invalid } from '../feedbacks'
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
options
*/

const options = ({ options }) => {
  console.log('options', options)
  return options.map((option, index) => {
    return (
      <MenuItem key={index} value={option.value}>
        <p className="subway-footlong" style={{ margin: 0 }}>
          {option.name}
        </p>
      </MenuItem>
    )
  })
}

const select = (props) => {
  console.log('defaultValues', props)
  const selectOptions = options(props)
  const cleanProps = inputErrorCleaner(props)
  return (
    <Fragment>
      <TextField
        {...cleanProps}
        select
        fullWidth
        variant="outlined"
        margin="dense"
        InputLabelProps={{ style: { color: 'grey' } }}
        InputProps={{ style: { backgroundColor: '#d3d3d352' } }}
      >
        {selectOptions}
      </TextField>
      <Invalid {...props} />
    </Fragment>
  )
}

export default select
