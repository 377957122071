import React, { useState, useEffect } from 'react';
import useSound from 'use-sound';
import ErrorOverlay from './components/ErrorOverlay';
import SplitText from './components/SplitText';
import { BackgroundSettings } from "./components/styles.js";
import pressSound from "../sound/press.wav";
import introSound from "../sound/intro.wav";
import reelSound from "../sound/reel.wav";
import winSound from "../sound/win.wav";
import loseSound from "../sound/lose.wav";
import './styles.css';

import bagIcon from "../icons/bag.png"
import batteryIcon from "../icons/battery.png"
import cookieIcon from "../icons/cookie.png"
import exclusiveofferIcon from "../icons/exclusiveoffer.png"
import giftIcon from "../icons/gift.png"
import giftcardIcon from "../icons/giftcard.png"
import glassesIcon from "../icons/glasses.png"
import hatIcon from "../icons/hat.png"
import offersIcon from "../icons/offers.png"
import offerbgIcon from "../icons/offerbg.png"
import points100Icon from "../icons/points100.png"
import points200Icon from "../icons/points200.png"
import points500Icon from "../icons/points500.png"
import points1000Icon from "../icons/points1000.png"
import statueIcon from "../icons/statue.png"
import subIcon from "../icons/sub.png"
import loseIcon from "../icons/lose.png"

const index = (props) => {
  const [hasPushed, setHasPushed] = useState(false);
  const [revealTop, setRevealTop] = useState(false);
  const [prizeNum, setPrizeNum] = useState();
  const [cycleItems, setCycleItems] = useState();

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  };

  const flavorText = {
    msg1: {
      text1:"HIT ME WITH YOUR",
      text2:"BIGGEST PUSH",
    },
    msg2: {
      text1:"WOOOAAHHH",
      text2:"THAT WAS BIG",
    },
    msg3: {
      text1: "HERE WE",
      text2: "GOOOOOOOOO",
    },
    prize:{
      glasses: {
        text1: 'COOOLIO!',
        text2: "YOU'VE SCOOPED UP A",
        text3: 'PAIR OF SUBWAY® SHADES',
      },
      battery: {
        text1: "POW! YOU'VE BAGGED A",
        text2: "SUBWAY® POWER BANK",
      },
      statue: {
        text1: 'OH MY BLEEEEEEP',
        text2: "YOU'VE WON A TRIP TO",
        text3: 'NEW YORK!!!',
      },
      points1000: {
        text1: 'WINNER WINNER',
        text2: "1000 POINTS FOR DINNER",
      },
      points500: {
        text1: 'WINNER WINNER',
        text2: "500 POINTS FOR DINNER",
      },
      points200: {
        text1: 'WINNER WINNER',
        text2: "200 POINTS FOR DINNER",
      },
      points100: {
        text1: 'WINNER WINNER',
        text2: "100 POINTS FOR DINNER",
      },
      hat: {
        text1: 'HATS OFF TO YA',
        text2: "YOU'VE JUST WON OUR",
        text3: 'STYLISH SUBWAY® HAT',
      },
      bag: {
        text1: "CHECK YOU OUT",
        text2: "YOU'VE JUST BAGGED",
        text3: "A SUBWAY® BUMBAG",
      },
      cookie: {
        text1: "SAWEEEET! YOU'VE WON",
        text2: "3 FREE COOKIES WITH YOUR",
        text3: "NEXT FOOTLONG SUB",
      },
      giftcard: {
        text1: "YAY! YOU'VE WON A",
        text2: "£25 AMAZON VOUCHER",
      },
      sub: {
        text1: "YOU'VE WON FREE FOOD",
        text2: "GRAB ONE FOOTLONG",
        text3: "GET ONE FREE. YIPPEE!",
      },
      offers: {
        text1: "YOU'VE WON BIG",
        text2: "LIKE FOOTLONG BIG. GRAB ANY",
        text3: "FOOTLONG FOR £3.99",
      },
      exclusiveoffer: {
        text1: "WOOHOO! YOU AND YOUR BFF",
        text2: "CAN DINE FOR £7.99",
      },
      lose: {
        text1: "GO BIG WITH A FOOTLONG",
        text2: "FOR ANOTHER PUSH",
      },
    },
  }
  const [currentText, setCurrentText] = useState(flavorText.msg1);
  const [newText, setNewText] = useState();

  // const wheelcontent = spinthewheel.state.data.spinthewheelimages[0];
  // console.log(wheelcontent);

  const prize2Arr = [glassesIcon, batteryIcon, points100Icon, statueIcon,
  points200Icon, hatIcon, cookieIcon, points1000Icon, subIcon, bagIcon, offersIcon,
  points500Icon, giftcardIcon, exclusiveofferIcon,loseIcon];
  const prizeArr = ['glasses', 'battery', 'points100', 'statue',
  'points200', 'hat', 'cookie', 'points1000' ,'sub', 'bag', 'offers',
  'points500', 'giftcard', 'exclusiveoffer','lose'];
  const bgArr = [ glassesIcon, batteryIcon,statueIcon,points1000Icon,giftIcon,
  hatIcon,cookieIcon,subIcon, bagIcon, offerbgIcon, points500Icon,giftcardIcon,
  exclusiveofferIcon, offersIcon,points200Icon,points100Icon,loseIcon ];

  const convertPrize = {
    25: 0,
    24: 1,
    14: 2,
    27: 3,
    15: 4,
    23: 5,
    19: 6,
    17: 7,
    21: 8,
    22: 9,
    20: 10,
    16: 11,
    26: 12,
    18: 13,
    28: 14
  }
// 14	100 points
// 15	200 points
// 16	500 points
// 17	1000 points
// 18	2 can dine for £7.99
// 19	Buy a Footlong Sub, get 3 cookies free
// 20	Buy a Footlong Sub for £3.99
// 21	Buy One Get One Free on Footlong Subs
// 22	Bumbag
// 23	Bucket hat
// 24	Charger
// 25	Sunglasses
// 26	£25 Amazon voucher
// 27	New York week 1
// 28	No win

  const introAudio = new Audio(introSound);
  const pressAudio = new Audio(pressSound);
  const [pressPlay] = useSound(pressSound, { volume: 0.8 });
  const [reelPlay] = useSound(reelSound, { volume: 0.8 });
  const [winPlay] = useSound(winSound, { volume: 0.3 });
  const [losePlay] = useSound(loseSound, { volume: 0.3 });

  useEffect(() => {
    introAudio.play();
  }, [])

  useEffect(() => {
    if(revealTop){
      if(prizeNum !== 14 ){
        winPlay();
      } else {
        losePlay();
      }
      let prizeWon = eval(`flavorText.prize.${prizeArr[prizeNum]}`);
      setCurrentText(prizeWon);
    }
  }, [revealTop])

  useEffect(() => {
    setNewText(<SplitText 
      line1={currentText.text1} 
      line2={currentText.text2} 
      line3={!null ? currentText.text3 : ''} id={+new Date()} />)
  }, [currentText])
  
  const handlePush = async () => {
  let prizeConverted;
  if(!hasPushed) {
    try{
      await fetch(props.useApi + props.gameToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => response.json())
      .then(data =>{
        console.log(data);
        prizeConverted = convertPrize[data.prizes[0].prizeId];
        setPrizeNum(prizeConverted);
        console.log("PRIZE WON", data.prizes[0].prizeId);
      });
    } catch (error) {
      console.error(error);
    }
    let counter = 0;
    let intervalTimer = 75;

    setCurrentText(flavorText.msg3);
    // pressAudio.play();
    pressPlay();
    setHasPushed(true);
    // const randomPrize = getRandomInt(0,prize2Arr.length); // Total 14 (15)
    // setPrizeNum(randomPrize);

    setTimeout(() => {
      reelPlay();
    }, 950);

    // FOR API LOGIC
    const logic = () => {
      if (counter !== prize2Arr.length){
        counter++;
        setCycleItems(counter);
      }  
      if (counter === prize2Arr.length){
        counter = 0;
        setCycleItems(0);
      }
      setTimeout(() => {
        setRevealTop(true);
        setTimeout(()=> {
          clearInterval(myInterval);
          setCycleItems(prizeConverted);
        },intervalTimer)
      }, 5750);
    }

    // FOR DEV LOGIC - OLD
    // const logic = () => {
    //   if (counter !== prize2Arr.length){
    //     counter++;
    //     setCycleItems(prize2Arr[counter]);
    //   }  
    //   if (counter === prize2Arr.length){
    //     counter = 0;
    //     setCycleItems(prize2Arr[0]);
    //   }
    //   setTimeout(() => {
    //     setRevealTop(true);
    //     setTimeout(()=> {
    //       clearInterval(myInterval);
    //       setCycleItems(prize2Arr[randomPrize]);
    //     },intervalTimer)
    //   }, 5750);
    // // }
    
    // FOR DEV LOGIC
    // const logic = () => {
    //   if (counter !== prize2Arr.length){
    //     counter++;
    //     setCycleItems(counter);
    //   }  
    //   if (counter === prize2Arr.length){
    //     counter = 0;
    //     setCycleItems(0);
    //   }
    //   setTimeout(() => {
    //     setRevealTop(true);
    //     setTimeout(()=> {
    //       clearInterval(myInterval);
    //       setCycleItems(randomPrize);
    //     },intervalTimer)
    //   }, 5750);
    // }

    const myInterval = setInterval(logic, intervalTimer);
    setTimeout(() => {
      return(myInterval);
    }, 1000);
  }
}
  
  return (
  <div className='spincontainer'>
    <ErrorOverlay outcomeCode={props.tokenRes.outcomeCode} />
    <BackgroundSettings bgColor="#43923a">
        <div className='big-button-container'>
          <div className={`prize-incoming ${revealTop && prizeNum !== 14 ? 'slideIn-anim' : ""}`}>
            <img src="/assets/stormborn/bigbutton/prize-incoming-banner.png" alt="" />
          </div>
          <div className='button-bg-container'>
            <div className='bigButton' >
              <div className='relative'>
                <div onClick={() => handlePush()}>
                  <img className={`buttonImg absolute`} src="/assets/stormborn/bigbutton/buttonbg.png" alt="" />
                  <img className={`buttonImg ${hasPushed ? 'pushed-anim' : ''}`} 
                    src="/assets/stormborn/bigbutton/buttoncenter.png" alt="" />
                </div>
                <div className={`subway-sans-cond-bold button-content ${hasPushed ? 'fadeOut-anim' : ''}`}>
                  <p>GO</p>
                  <p className='yellow'>BIG<span className='enlarge-anim'>GER</span></p>
                  <p>BUTTON</p>
                </div>
                { prize2Arr.map(function(prizeImg, index){
                    return(
                      <div key={index} className={`prize-reveal ${hasPushed ? 'fadeIn-anim' : ''}`}>
                        <img key={index} src={prizeImg} alt="" className={index === cycleItems ? "opacityshow" : "opacityzero" } />
                      </div>
                    )
                })}

                <div className={`iconBgContainer ${hasPushed ? 'fadeOutSlow-anim' : ''}`}>
                  {
                    bgArr.map(function(bgArr,index){
                      return(
                        <img key={index} 
                        style={{"animationDelay": ("-" + index / 5 + "s")}} 
                        className="iconBg"
                        src={bgArr} alt="" />
                      )
                    })
                  }
                </div>
              </div>
    
            </div>
            {/* Big Button Icon Background */}
            {/* End Big Button Icon Background */}
          </div>
          
          {/* Flavour Message Container   */}
           {newText}
          {/* End Flavour Message container */}

        </div>
        <div className='tandc'>
          <p>Between 18th July and 28th August 2022, UK residents 18+ who are registered and activated 
            Subway Rewards® members, who purchase a Footlong Sub either in a Subway® store and scan their 
            QR code or order a Footlong Sub ahead via the Subway® App, will get the chance to “press The 
            GO BIGGER Button” to be in with a chance of winning from a selection of prizes. 9,206 prizes 
            to be won. Any unwon/unclaimed Big Prizes, Merchandise Prizes or Amazon vouchers will be 
            available to be won via a wrap-up prize draw at the end of the Promotion. Registration for the 
            Promotion, Footlong Sub purchase and T&Cs acceptance necessary for entry. <a href="/promotions">Click here</a> for full T&Cs & prize details. Images are for representation 
            purposes only. Promoter: Subway Franchisee Advertising Fund Trust B.V.</p>
        </div>
    </BackgroundSettings>
  </div>
  );
}

export default index
