import React, { useEffect } from 'react'
import { GArecordPageView } from '../../lib-atomic-functions/google-analytics/ga-client'
import { useCookies } from 'react-cookie'
import { LocalStorage } from '../../lib-atomic-functions'

const index = (props) => {
  const currentlanguage = LocalStorage.getLS().translation.json

  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'strictCookie',
  //   'performanceCookie',
  // ])
  useEffect(() => {
    // GArecordPageView(cookies)
    if (
      currentlanguage.moremenu_website_page.moremenu_website_contact_link &&
      currentlanguage.moremenu_website_page.moremenu_website_contact_link
        .length !== 0
    ) {
      return window.location.replace(
        currentlanguage.moremenu_website_page.moremenu_website_contact_link
      )
    }
  }, [])
  return props.children
}

export default index
